import {IGetYieldPerPixelImages} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/yieldPerPixel';
import {orderBy} from 'lodash';
import {IFarm} from './farm';

export const getYieldPerPixelImagesSelector = (state: IYieldPerPixelState) => state.getYieldPerPixelImages;
export const yieldPerPixelImagesSelector = (state: IYieldPerPixelState) => orderBy(state.yieldPerPixelImages, i => i.date, 'desc');
export const yieldPerPixelActiveFarmSelector = (state: IYieldPerPixelState) => state.activeFarm;
export const yieldPerPixelSelectedDateSelector = (state: IYieldPerPixelState) => state.selectedDate;

export interface IYieldPerPixelState {
  getYieldPerPixelImages: {
    error: unknown;
    loading: boolean;
  };
  yieldPerPixelImages: IGetYieldPerPixelImages[];
  activeFarm: IFarm;
  selectedDate: number;
}

const initialState: IYieldPerPixelState = {
  getYieldPerPixelImages: {
    error: false,
    loading: false,
  },
  yieldPerPixelImages: null,
  activeFarm: null,
  selectedDate: null,
};

const reducer = (state: IYieldPerPixelState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.YIELD_PER_PIXEL_GET_START:
      return updateObject(state, {
        getYieldPerPixelImages: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.YIELD_PER_PIXEL_GET_SUCCESS:
      return updateObject(state, {
        yieldPerPixelImages: action.images,
        activeFarm: action.activeFarm,
        getYieldPerPixelImages: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.YIELD_PER_PIXEL_GET_FAIL:
      return updateObject(state, {
        getYieldPerPixelImages: {
          error: action.error,
          loading: false,
        },
      });

    case ActionTypes.YIELD_PER_PIXEL_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default reducer;
