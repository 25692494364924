import React, {useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {Tabs, Tab, AppBar} from '@material-ui/core';
import MapOverlay from '../../../components/UI/MapOverlay';
import {Forbidden} from '../../../components/UI/MapPlaceholder';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import CreateFarm from './CreateFarm';
import Layout from '../../Layout';
import EditFarm from './EditFarm';
import {useTranslation} from 'react-i18next';
import theme from '../../../utilities/theme';
import {useLocation} from 'react-router-dom';
import {polygonPostSuccessSelector, selectedOrganizationSelector} from '../../../store/selectors';

const Root = styled.div`
  flex-grow: 1;
  overflow: hidden;
  max-height: calc(100vh - 64px);
`;

const TabBar = styled(AppBar)`
  && {
    background: ${() => theme.palette.grey['50']};
    color: ${() => theme.palette.primary.main};
  }
`;

const TabsStyled = styled(Tabs)`
  background-color: ${() => theme.palette.grey['50']};
`;

const Settings = () => {
  const location = useLocation<{pageIndex?: number; farmId?: string}>();
  const [activeTabIndex, setActiveTabIndex] = useState(location?.state?.pageIndex || 0);
  const {t} = useTranslation();
  const polygonPostSuccess = useSelector(polygonPostSuccessSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const {loadingGetOrganizations, loadingGetFarm, loadingGetUser, allFarms, userDetails} = useFarmSelection();
  const organizationToUserPermission = selectedOrganization?.organizationToUsers[0]?.permission?.name;

  const handleTabChange = (_, tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  const isLoading = loadingGetOrganizations || loadingGetFarm || loadingGetUser;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && organizationToUserPermission !== 'MEMBER' && <Forbidden />}
      <Root>
        <TabBar position="static">
          <TabsStyled value={activeTabIndex} onChange={handleTabChange} indicatorColor="primary">
            <Tab label={t('setting.tab.create')} />
            <Tab label={t('setting.tab.edit')} />
          </TabsStyled>
        </TabBar>
        {activeTabIndex === 0 && <CreateFarm postPolySuccess={polygonPostSuccess} allFarms={allFarms} />}
        {activeTabIndex === 1 && <EditFarm passedFarmId={location?.state?.farmId} allFarms={allFarms} userDetails={userDetails} />}
      </Root>
    </Layout>
  );
};

export default Settings;
