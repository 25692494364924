import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {IGetDiseaseImages} from '@deep-planet/api-interfaces';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';

/////////////////////////////////////////////
// GET disease images
/////////////////////////////////////////////

interface IdiseaseImageGetStart {
  type: ActionTypes.DISEASE_PER_PIXEL_GET_START;
}

export const diseaseImageGetStart = (): IdiseaseImageGetStart => ({
  type: ActionTypes.DISEASE_PER_PIXEL_GET_START,
});

interface IdiseaseImageGetSuccess {
  type: ActionTypes.DISEASE_PER_PIXEL_GET_SUCCESS;
  images: IGetDiseaseImages[];
  farmId: string;
  activeFarm: IFarm;
}

export const diseaseImageGetSuccess = (images: IGetDiseaseImages[], farmId: string, activeFarm: IFarm): IdiseaseImageGetSuccess => ({
  type: ActionTypes.DISEASE_PER_PIXEL_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface IdiseaseImageGetFail {
  type: ActionTypes.DISEASE_PER_PIXEL_GET_FAIL;
  error: unknown;
}

export const diseaseImageGetFail = (error): IdiseaseImageGetFail => ({
  type: ActionTypes.DISEASE_PER_PIXEL_GET_FAIL,
  error,
});

interface IdiseaseImageSetSelectedDate {
  type: ActionTypes.DISEASE_PER_PIXEL_SET_SELECTED_DATE;
  selectedDate: number;
}

export const diseaseImageSetSelectedDate = (selectedDate: number): IdiseaseImageSetSelectedDate => ({
  type: ActionTypes.DISEASE_PER_PIXEL_SET_SELECTED_DATE,
  selectedDate,
});

export const getdiseaseImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(diseaseImageGetStart());
    const diseaseImageGetUrl = `${baseApiUrl}/disease/images/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<IGetDiseaseImages[]>(diseaseImageGetUrl);
      dispatch(diseaseImageGetSuccess(data?.length ? data : [], farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
      if (!data?.length) dispatch(enqueueSnackbar({message: i18n.t('error.http.response.disease.not.found', {name: farm.name}), options: {variant: 'error'}}));
    } catch (err) {
      console.log(err);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response.disease.not.found', {name: farm.name}), options: {variant: 'error'}}));
      dispatch(diseaseImageGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof diseaseImageGetStart>
  | ReturnType<typeof diseaseImageGetSuccess>
  | ReturnType<typeof diseaseImageGetFail>
  | ReturnType<typeof farmIdUpdate>
  | ReturnType<typeof diseaseImageSetSelectedDate>;
