import React, {useCallback, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {removeSnackbar} from '../../../store/actions';
import {useDispatch, useSelector} from 'react-redux';
import {notificationsSelector} from '../../../store/selectors/snackbar';

const Notifier = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [displayedNotificationKeys, setDisplayedNotificationKeys] = useState<Array<string | number>>([]);
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);

  const storeDisplayed = useCallback(
    (key: string | number) => {
      setDisplayedNotificationKeys([...displayedNotificationKeys, key]);
    },
    [displayedNotificationKeys]
  );

  useEffect(() => {
    if (!notifications.length) {
      setDisplayedNotificationKeys([]);
    }

    for (const notification of notifications) {
      if (notification.dismissed) {
        closeSnackbar(notification.key);
        dispatch(removeSnackbar(notification.key as number));
      }
    }
  }, [closeSnackbar, dispatch, notifications]);

  useEffect(() => {
    notifications.forEach(({key, message, options}) => {
      // Do nothing if snackbar is already displayed
      if (displayedNotificationKeys.includes(key)) return;

      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }

          // Dispatch action to remove snackbar from redux store
          dispatch(removeSnackbar(key as number));
        },
      });
      // Keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [dispatch, displayedNotificationKeys, enqueueSnackbar, notifications, storeDisplayed]);

  return null;
};

export default Notifier;
