import React, {useEffect, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import LoginForm from './Form';
import {ButtonAlt} from '../../UI/Form';
import {Login} from '@deep-planet/react-cognito';
import AuthSplash from '../../UI/AuthSplash';
import {useTranslation} from 'react-i18next';
import {vineSignalRoutes} from '../../../utilities/routes';

const LoginPage = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      history.push(vineSignalRoutes.DASHBOARD.slug);
    }
  });

  return (
    <AuthSplash>
      <>
        <Login>
          <LoginForm />
        </Login>
        <ButtonAlt>
          <Link to="/reset-password">{t('auth.login.forgot')}</Link>
        </ButtonAlt>
        <ButtonAlt>
          <a target="_blank" href="https://www.deepplanet.ai/" rel="noopener noreferrer">
            {t('auth.login.go.to.main.page')}
          </a>
        </ButtonAlt>
      </>
    </AuthSplash>
  );
};

export default LoginPage;
