import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Form, InputField, ButtonOutline, Error} from '../../UI/Form';
import {withTranslation} from 'react-i18next';

const INITIAL_STATE = {
  code: '',
  codeSent: '',
  password1: '',
  password2: '',
};

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      code: '',
      codeSent: '',
      password1: '',
      password2: '',
      message: '',
      error: props.error,
      passError: '',
      showPassword: false,
    };
  }

  validatePasswordMatch = () => {
    const {t} = this.props;
    let isValid = true;
    if (this.state.password1 === this.state.password2) return isValid;
    this.setState({passError: t('forms.password.not.match')});
    return (isValid = false);
  };

  onSubmit = event => {
    event.preventDefault();
    if (this.validatePasswordMatch()) {
      const {t} = this.props;
      this.props
        .setPassword(this.state.username, this.state.code, this.state.password1)
        .then(() =>
          this.setState({
            message: t('auth.forgot.password.done'),
            error: '',
            ...INITIAL_STATE,
          })
        )
        .catch(err => {
          this.setState({
            message: '',
            error: err.message,
          });
        });
    }
  };

  sendVerificationCode = event => {
    const {t} = this.props;
    event.preventDefault();
    this.props
      .sendVerificationCode(this.state.username)
      .then(() =>
        this.setState({
          message: t('auth.forgot.password.code.sent'),
          error: '',
          codeSent: true,
        })
      )
      .catch(err => {
        console.log(err);
        if (err.code === 'UserNotFoundException') {
          this.setState({error: t('auth.forgot.password.no.user')});
        } else {
          this.setState({error: err.message});
        }
      });
  };

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleClickShowPassword = () => {
    this.setState(state => ({showPassword: !state.showPassword}));
  };

  render() {
    const {password1, password2, showPassword, passError, code, codeSent, username, message, error} = this.state;
    const {t} = this.props;

    return (
      <>
        {codeSent ? (
          <Form onSubmit={this.onSubmit}>
            <InputField id="code" name="code" value={code} placeholder={t('auth.confirmation.form.placeholder')} margin="normal" variant="outlined" onChange={this.onChange} required />
            <InputField
              id="password1"
              name="password1"
              variant="outlined"
              placeholder={t('forms.password')}
              type={showPassword ? 'text' : 'password'}
              value={password1}
              onChange={this.onChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label={t('forms.password.visibility')} onClick={this.handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <InputField
              id="password2"
              name="password2"
              variant="outlined"
              placeholder={t('forms.password.repeat')}
              type={showPassword ? 'text' : 'password'}
              value={password2}
              onChange={this.onChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label={t('forms.password.visibility')} onClick={this.handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <ButtonOutline type="submit">{t('forms.password.new')}</ButtonOutline>
          </Form>
        ) : (
          <Form onSubmit={this.sendVerificationCode}>
            <InputField id="username" name="username" value={username} placeholder={t('forms.username')} margin="normal" variant="outlined" onChange={this.onChange} required />
            <ButtonOutline type="submit">{t('auth.forgot.password.send.code')}</ButtonOutline>
          </Form>
        )}
        {message && <Error variant="subtitle1">{message}</Error>}
        {passError && <Error variant="subtitle1">{passError}</Error>}

        {error && !error.includes('Password must have symbol characters') && <Error variant="subtitle1">{error}</Error>}
        {error && error.includes('Password must have symbol characters') && (
          <>
            <Error variant="subtitle1">{`${error} from the following list:`}</Error>
            <Error variant="subtitle1">{`^ $ * . [ ] { } ( ) ? " ! @ # % & / \\\\ , > < \\' : ; | _ ~ \``}</Error>
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(PasswordResetForm);
