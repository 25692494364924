import {getLocalDateString} from '../../../helpers/dateHelpers';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useSoil} from '../../../hooks/useSoil';
import Layout from '../../Layout';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from '@material-ui/core';
import MapOverlay from '../../../components/UI/MapOverlay';
import * as _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';
import styled from 'styled-components';
import theme from '../../../utilities/theme';

const headers = [
  {
    title: 'Block',
  },
  {
    title: 'Bare Soil (m^2)',
    tooltip: 'The sum total are in meters which are bare soil with no plants on.',
  },
  {
    title: 'Overgrazed Area (m^2)',
    tooltip: 'The sum total area in meters of land which is overgrazed.',
  },
  {
    title: 'Forest Biomass (kg/ha)',
    tooltip: 'The kg of dry grass material that would be harvested in 1 hectare of grass that height and quality.',
  },
  {
    title: 'Grassland Biomass (kg/ha)',
    tooltip: 'The kg of dry grass material that would be harvested in 1 hectare of grass that height and quality.',
  },
  {
    title: 'Leaf Area (%)',
    tooltip: 'The area in each pixel that is made up of photosynthesising leaf where 5 is a pixel almost entirely filled with leaves.',
  },
  {
    title: 'Plant Density (%)',
    tooltip: 'The density of plants in the pixel relative to the maximum possible density of plants.',
  },
  {
    title: 'Plant Height (%)',
    tooltip: 'The height of the plants in the pixel out of their maximum possible height.',
  },
  {
    title: 'Plant Mature (%)',
    tooltip: 'The percentage of plants in the pixel which are mature plants.',
  },
  {
    title: 'Plant Young (%)',
    tooltip: 'The percentage of plants in the pixel which are young plants.',
  },
];

const DesktopTooltip = styled(Tooltip)`
  opacity: 0.5;
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`;

const SoilSignalSummary = () => {
  const {allFarms, loadingGetFarm, activeFarm} = useFarmSelection();
  const {latestSoilValues, loadingGetFarmSoil} = useSoil(activeFarm);

  const polygonNames = _.chain(allFarms && allFarms[0]?.polygons)
    .map(({name}) => name)
    .sortBy()
    .value();

  const getRoundedValue = (value: string) => Math.round(Number(value) * 2) / 2;

  const getLocalDate = (date: Date) => {
    return getLocalDateString(date);
  };

  const isLoading = loadingGetFarmSoil || loadingGetFarm;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !!latestSoilValues?.length && (
        <>
          <Box p={2}>
            <Typography variant="h6" align="center" gutterBottom>
              {`Summary (${getLocalDate(latestSoilValues[0].date)})`}
            </Typography>
          </Box>
          <Box p={2}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headers.map(({title, tooltip}) => (
                      <TableCell key={title}>
                        <Box display="flex">
                          <Typography>{title}</Typography>
                          {!!tooltip && (
                            <DesktopTooltip title={tooltip}>
                              <Box ml={1}>
                                <InfoIcon color="primary" />
                              </Box>
                            </DesktopTooltip>
                          )}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {polygonNames.map(name => {
                    const rowValues = latestSoilValues.filter(({polygonName}) => polygonName === name);
                    return (
                      <TableRow key={name}>
                        {headers.map(({title}) => {
                          const rowValue = rowValues.find(({product}) => title.includes(product));
                          return <TableCell key={`${name}${title}`}>{rowValue ? getRoundedValue(rowValue.value) : name}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default SoilSignalSummary;
