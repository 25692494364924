import React from 'react';
import {Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, MenuItem, MenuList, Paper, Popper} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Spinner from '../../../components/UI/Spinner';

interface ActionProps {
  open: boolean;
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
  handleDialogOpen: () => void;
  anchorEl: React.MutableRefObject<HTMLButtonElement>;
  menuTitle: string;
}

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading: boolean;
}

export const NoteDeleteDialog = ({open, handleClose, isLoading, handleSubmit}: DialogProps) => {
  const {t} = useTranslation();
  return (
    <Dialog fullScreen={false} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="delete-note-dialog-title">{t('note.action.delete.subtitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('note.action.delete.title')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
          {isLoading && <Spinner size={15} color="primary" />}
          {t('forms.delete')}
        </Button>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained" disabled={isLoading}>
          {t('forms.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NoteActionPopper = ({open, handleClose, handleDialogOpen, anchorEl, menuTitle}: ActionProps) => {
  return (
    <Popper open={open} anchorEl={anchorEl?.current} role={undefined} transition disablePortal style={{zIndex: 10}}>
      {({TransitionProps, placement}) => (
        <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                <MenuItem onClick={handleDialogOpen}>{menuTitle}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
