import {useState, useEffect} from 'react';
import {Layout} from 'plotly.js';

export function useLayoutConfig(maturityPrediction, data, setData, polygonName, metric, multiplier, isSugar, setIsSugar, shapes, annotations, title, finalYear) {
  // Function that specifies the structure of the layout and allows to be dynamically updated via it's parameters
  const generateLayout = (
    polygonName: string,
    title: string,
    metric: string,
    isSugar: boolean,
    shapes: Partial<Layout>['shapes'] = [],
    annotations: Partial<Layout>['annotations'] = [],
    year: number = finalYear
  ): Partial<Layout> => {
    const xRange = maturityPrediction.seasons[year].predictedBaume.dates.sort();
    // x axis padding in days
    const padding = 5;
    return {
      title: `Harvest Curve for Block ${polygonName}: ${title}`,
      autosize: true,
      yaxis: {
        title: `Sugar conc. (${metric[0].toUpperCase() + metric.slice(1)}) / Cum. GDD ${isSugar ? '(GDD x 10^-1)' : '(GDD x 10^-2)'}`,
        showline: true,
        showgrid: true,
        type: 'linear',
        range: [0, 25],
      },
      yaxis2: {
        title: `Rainfall (mm)`,
        showline: true,
        showgrid: false,
        type: 'linear',
        overlaying: 'y',
        side: 'right',
        range: [0, 25],
      },
      xaxis: {
        showline: true,
        showgrid: true,
        type: 'date',
        // Values dynamically generated with padding -> more on latter to allow room for text
        range: [new Date(xRange[0]).setDate(new Date(xRange[0]).getDate() - padding), new Date(xRange[xRange.length - 1]).setDate(new Date(xRange[0]).getDate() + padding * 13)],
      },
      legend: {
        x: 1.05,
      },
      clickmode: 'event+select',
      paper_bgcolor: 'transparent',
      shapes,
      annotations,
    };
  };

  // Initialise layout
  const [layout, setLayout] = useState(() => generateLayout(polygonName, data.variety, metric, isSugar, shapes, annotations) || null);

  const handleLegendClick = (event: any) => {
    const clickedSeries = event.data[event.curveNumber];
    const year = parseInt(clickedSeries.x[0].toString().split('-')[0]);

    if (year.toString() === finalYear.toString()) {
      // If latest year show annotations
      setLayout(() => generateLayout(polygonName, data.variety, metric, isSugar, shapes, annotations, year));
    } else {
      // Else remove annotations
      setLayout(() => generateLayout(polygonName, data.variety, metric, isSugar, [], [], year));
    }
    // Stops default legend click behaviour
    return false;
  };

  const handleRelayout = eventData => {
    for (const event in eventData) {
      // Detect when user goes below 0
      if (event === 'yaxis.range[0]' || event === 'yaxis2.range[0]') {
        // If user scrolls below 0
        if (eventData[event] < 0) {
          // Reset y axis
          setLayout(prevLayout => ({
            ...prevLayout,
            yaxis: {...prevLayout.yaxis, range: [0, eventData['yaxis.range[1]'] - eventData['yaxis.range[0]']]},
            yaxis2: {...prevLayout.yaxis2, range: [0, eventData['yaxis2.range[1]'] - eventData['yaxis2.range[0]']]},
          }));
        }
      }
    }
  };

  // Handles title of graph when user changes polygon
  useEffect(() => {
    setLayout(prevLayout => ({
      ...prevLayout,
      title: `Harvest Curve for Block ${polygonName}: ${title}`,
    }));
  }, [polygonName, title]);

  useEffect(() => {
    const maxYRange = (metric === 'baume' && 25) || (metric === 'brix' && 45) || (metric === 'sugar' && 450);
    const yAxisTitle = `Sugar conc. (${metric[0].toUpperCase() + metric.slice(1)}) / Cum. GDD ${metric === 'sugar' ? '(GDD x 10^-1)' : '(GDD x 10^-2)'}`;
    setLayout(prevLayout => ({
      ...prevLayout,
      yaxis: {...prevLayout.yaxis, range: [0, maxYRange], title: yAxisTitle},
      yaxis2: {...prevLayout.yaxis2, range: [0, maxYRange]},
    }));
  }, [metric]);

  // Handles dynamic changes to the graph -> user changing metric
  useEffect(() => {
    const getGddMultiplier = () => {
      let gddMultiplier = 0;
      if (metric === 'baume' || metric === 'brix') {
        gddMultiplier = 1;
      } else if (metric === 'sugar') {
        gddMultiplier = 10;
        setIsSugar(true);
      } else {
        console.error('Invalid metric');
        return;
      }
      return gddMultiplier;
    };

    // Take in original maturity predictions
    const updatedMaturityPrediction = JSON.parse(JSON.stringify(maturityPrediction));
    const gddMultiplier = getGddMultiplier() || 1;
    let newTargetBaume = 0;

    // Loop through seasons
    Object.values(updatedMaturityPrediction.seasons).forEach((season: any) => {
      // Multiply values by metric multiplier
      season.predictedBaume.values = season.predictedBaume.values.map((value: number) => value * multiplier);
      season.maturityBaume.values = season.maturityBaume.values.map((value: number) => value * multiplier);
      // Multiply Gdd by Gdd multiplier
      season.gdd.values = season.gdd.values.map((value: number) => value * gddMultiplier);
      // If season has targetBaume -> it is latest year
      if (season.targetBaume) {
        // Set targetBaume
        season.targetBaume *= multiplier;
        newTargetBaume = season.targetBaume;
      }
    });

    // Set data state to new converted values
    setData(updatedMaturityPrediction);

    // Update layout with new yAxis title and annotations
    setLayout(prevLayout => {
      const annotations = prevLayout.annotations || [];
      const shapes = prevLayout.shapes || [];

      // If there are annotations
      if (annotations.length > 0) {
        annotations[0] = {
          ...annotations[0],
          // Update target baume with new metric (first letter capitalized)
          text: `Target ${metric[0].toUpperCase() + metric.slice(1)}: ${newTargetBaume.toFixed(2)}`,
          y: newTargetBaume,
        };
      }
      if (annotations.length > 1) {
        annotations[1] = {
          ...annotations[1],
          y: newTargetBaume * 1.5,
        };
      }
      // If there is a dotted line
      if (shapes.length > 0) {
        shapes[0] = {
          ...shapes[0],
          // Update y value
          y0: newTargetBaume,
          y1: newTargetBaume,
        };
      }
      // Return layout
      return {
        ...prevLayout,
        yaxis: {
          ...prevLayout.yaxis,
          // With potentially updated y axis title
          title: `Sugar conc. (${metric[0].toUpperCase() + metric.slice(1)}) / Cum. GDD ${isSugar ? '(GDD x 10^-1)' : '(GDD x 10^-2)'}`,
        },
        annotations,
        shapes,
      };
    });
  }, [multiplier, maturityPrediction, metric, isSugar, setData, setIsSugar]);

  return {layout, handleLegendClick, handleRelayout};
}
