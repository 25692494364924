import {IForecastWeather} from '@deep-planet/api-interfaces';
import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import WeatherIcon from './WeatherIcon';
import {useTranslation} from 'react-i18next';
import theme from '../../../utilities/theme';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const Container = styled.div``;

const Card = styled(Paper)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    min-height: 100%;
    & svg {
      fill: ${() => theme.palette.primary.main};
    }
  }
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 1rem;
  }
`;

const Date = styled(Typography)`
  && {
    color: ${() => theme.palette.primary.main};
  }
`;

const IconText = styled(Typography)`
  && {
    margin-bottom: 1.5rem;
    opacity: 70%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  &:first-child {
    padding: 0 1rem;
    border-right: 1px solid ${() => theme.palette.primary.main};
  }
  &:last-child {
    padding: 0 1rem;
  }
`;

const Icon = styled.div`
  & svg {
    width: 50px;
    height: 50px;
    margin: 0.5rem 0;
  }
`;

const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;

const Col = styled.div`
  min-height: 50px;
  padding: 1rem;
  width: 100%;
  ${theme.breakpoints.down('sm')} {
    width: 100%;
  }
  ${theme.breakpoints.between('sm', 'md')} {
    width: calc(100% / 2);
  }
  ${theme.breakpoints.between('lg', 'xl')} {
    width: calc(100% / 3);
  }
  ${theme.breakpoints.up('xl')} {
    width: calc(100% / 5);
  }
`;

interface Props {
  forecastWeather: IForecastWeather[];
}

const WeatherForecast = ({forecastWeather}: Props) => {
  const {t} = useTranslation();
  const {temperatureUnit, convertTemperature} = useMeasurementConversion();

  return (
    <Container>
      <Title variant="h5">{t('weather.forecast')}</Title>

      <Row>
        {!!forecastWeather &&
          forecastWeather.map((dateWeather, index) => (
            <Col key={index}>
              <Card>
                <Icon>
                  <WeatherIcon icon={dateWeather.day.icon} />
                </Icon>
                <Date variant="body1">
                  <strong>{handleDateFormat(dateWeather.epochTime * 1000)}</strong>
                </Date>
                <IconText variant="body2">{dateWeather.day.iconText}</IconText>

                <BoxRow>
                  <Box>
                    <BoxRow>
                      <Typography variant="body1">
                        {convertTemperature(dateWeather.temperature.minimum.value)} °{temperatureUnit[0].toUpperCase()}
                      </Typography>
                    </BoxRow>
                  </Box>

                  <Box>
                    <BoxRow>
                      <Typography variant="body1">
                        {convertTemperature(dateWeather.temperature.maximum.value)} °{temperatureUnit[0].toUpperCase()}
                      </Typography>
                    </BoxRow>
                  </Box>
                </BoxRow>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default WeatherForecast;
