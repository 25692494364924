import React from 'react';
import Typography from '@material-ui/core/Typography';

interface points {
  title: string;
  description: string;
}

export const title = (text: string) => {
  return (
    text && (
      <Typography variant="body1" style={{marginBottom: '0.25rem'}}>
        <strong>{text}</strong>
      </Typography>
    )
  );
};

export const headerTitle = (text: string) => {
  return (
    text && (
      <Typography variant="h6" align="center" style={{marginBottom: '0.75rem'}}>
        <strong>{text}</strong>
      </Typography>
    )
  );
};

export const paragraph = (item: string[]) => {
  return item.map((item, idx) => (
    <Typography key={idx} variant="body1" style={{marginBottom: '0.5rem'}}>
      {item}
    </Typography>
  ));
};

export const points = (item: any[]) => {
  return (
    !!item && (
      <ul style={{marginBottom: '0.5rem'}}>
        {item.map((item, idx) => {
          return item?.title ? <li key={idx}>{bulletPointsWithHeader(item)}</li> : <li key={idx}>{bulletPoints(item)}</li>;
        })}
      </ul>
    )
  );
};

const bulletPointsWithHeader = ({title, description}: points) => (
  <span>
    <Typography variant="body1">
      <strong>{title}</strong>
      {description}
    </Typography>
  </span>
);

const bulletPoints = (text: string) => <Typography variant="body1">{text}</Typography>;
