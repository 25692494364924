import React from 'react';
import {Form, InputField, ButtonOutline, Error} from '../../UI/Form';
import {withTranslation} from 'react-i18next';

class ConfirmForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      verificationCode: '',
    };
  }

  onSubmit = event => {
    event.preventDefault();
    this.props
      .onSubmit(this.state.verificationCode)
      .then(user => {
        //
      })
      .catch(error => {
        this.setState({error});
      });
  };

  onResend = event => {
    const {t} = this.props;
    event.preventDefault();
    this.props
      .onResend()
      .then(() => {
        this.setState({error: t('auth.confirmation.form.resend.notification')});
      })
      .catch(error => {
        this.setState({error});
      });
  };

  changeVerificationCode = event => {
    this.setState({verificationCode: event.target.value});
  };

  render() {
    const {verificationCode, error} = this.state;
    const {t} = this.props;

    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <InputField
            id="verificationCode"
            name="verificationCode"
            value={verificationCode}
            placeholder={t('auth.confirmation.form.placeholder')}
            margin="normal"
            variant="outlined"
            onChange={this.changeVerificationCode}
            required
          />
          <ButtonOutline type="submit">{t('forms.submit')}</ButtonOutline>
          <ButtonOutline type="button" onClick={this.onResend}>
            {t('auth.confirmation.form.resend')}
          </ButtonOutline>
          <ButtonOutline type="button" onClick={this.props.onCancel}>
            {t('forms.cancel')}
          </ButtonOutline>
        </Form>
        {error && <Error variant="subtitle1">{error}</Error>}
      </>
    );
  }
}

export default withTranslation()(ConfirmForm);
