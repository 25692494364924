import {getUsers} from '../../../../store/actions/user';
import {getUsersSelector, postMoistureSensorsSelector, userNamesSelector} from '../../../../store/selectors';
import Layout from '../../../Layout';
import React, {useLayoutEffect, useState} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {useHttp} from '../../../../hooks/http';
import Button from '@material-ui/core/Button';
import {useEffect} from 'react';
import styled from 'styled-components';
import {baseApiUrl} from '../../../../config/const';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import theme from '../../../../utilities/theme';
import {useTranslation} from 'react-i18next';
import {postSoilMoistureSensors} from '../../../../store/actions';
import {useDispatch, useSelector} from 'react-redux';

const Form = styled(FormControl)`
  && {
    width: 100%;
  }
`;

const FormWrapper = styled(Box)`
  padding: 0 2rem;
  width: 100%;
  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const ContentWrapper = styled(Grid)`
  padding: 4rem 2rem;
  width: 100%;
  ${theme.breakpoints.down('sm')} {
    padding: 2rem 1rem;
  }
`;

const MoistureSensors = () => {
  const [selectedUser, setSelectedUser] = useState('');
  const [sensorData, setSensorData] = useState('');

  const [isLoadingMoistureSensors, fetchedMoistureSensors] = useHttp<{data: string}>(selectedUser ? `${baseApiUrl}/moisture-sensor/${selectedUser}` : null);
  const dispatch = useDispatch();
  const users = useSelector(userNamesSelector);
  const {loading: isLoading} = useSelector(getUsersSelector);
  const postMoistureSensors = useSelector(postMoistureSensorsSelector);
  const {t} = useTranslation();

  useLayoutEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setSensorData(fetchedMoistureSensors?.data || '');
  }, [isLoadingMoistureSensors, fetchedMoistureSensors]);

  const updateSensor = () => {
    dispatch(postSoilMoistureSensors(selectedUser, JSON.parse(JSON.stringify(sensorData))));
  };

  const openViewer = () => {
    window.open(`http://geojson.io/#data=data:application/json,${encodeURIComponent(sensorData)}`, '_blank');
  };

  return (
    <Layout>
      <Grid container alignItems="center" justify="center">
        <ContentWrapper item sm={6} lg={6} xs={12}>
          <Box m={2}>
            <Typography variant="h6" align="center" gutterBottom>
              {t('admin.moisture.title')}
            </Typography>
          </Box>
          <FormWrapper>
            <Form>
              <InputLabel htmlFor="users-select">{t('admin.moisture.user')}</InputLabel>
              <Select
                native
                value={selectedUser}
                onChange={e => setSelectedUser(e.currentTarget.value as string)}
                inputProps={{
                  name: 'age',
                  id: 'users-select',
                }}
              >
                {!isLoading
                  ? users?.map(username => (
                      <option key={username} value={username}>
                        {username}
                      </option>
                    ))
                  : null}
                <option aria-label="None" value="" />
              </Select>
              <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder={t('admin.moisture.placeholder')} value={sensorData} onChange={e => setSensorData(e.currentTarget.value)} />
              <Button variant="contained" color="primary" onClick={updateSensor} disabled={postMoistureSensors.loading}>
                {t('admin.moisture.update')}
              </Button>
              <Button variant="contained" color="secondary" onClick={openViewer}>
                {t('admin.moisture.view')}
              </Button>
            </Form>
          </FormWrapper>
        </ContentWrapper>
      </Grid>
    </Layout>
  );
};

export default MoistureSensors;
