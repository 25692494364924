import React from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../../components/UI/Spinner';
import {withTranslation} from 'react-i18next';
import {handleDateFormat} from '../../../helpers/dateHelpers';

function getStepContent(step, t) {
  switch (step) {
    case 0:
      return t('segmentation.sidebar.step.identify.search');
    case 1:
      return t('segmentation.sidebar.step.snapshot.wait');
    default:
      return 'Unknown step';
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`;

const ActionsContainer = styled.div`
  margin-bottom: 1rem;
`;

const ActionsButton = styled(Button)`
  && {
    margin-right: 1rem;
    margin-top: 1rem;
  }
`;

const StyledPaper = styled(Paper)`
  && {
    padding: 1rem;
    margin: 2rem 0 1rem 0;
  }
`;

const ResetContainer = styled(Paper)`
  padding: 1rem;
`;

class SegmentationSidebar extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = payload => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
    if (this.state.activeStep === 0) {
      this.props.handleCenterSaved();
      this.props.onResetSegment();
      this.props.handleImageListReset();
    }
    if (this.state.activeStep === 1) {
      this.props.onPostSegment(this.props.user.username, payload);
      this.props.handleSnapshotTaken();
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
    if (this.state.activeStep === 1) {
      this.props.handleCenterReset();
      this.props.handleImageListReset();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
    this.props.handleSnapshotTaken();
    this.props.handleCenterReset();
    this.props.onResetSegment();
    this.props.handleImageListReset();
  };

  componentWillUnmount = () => {
    this.props.handleCenterReset();
  };

  render() {
    const {activeStep} = this.state;
    const {centerCoords, user, loadingPostSegment, loadingGetSegment, handleUpdateDateIndex, imagesList, t} = this.props;
    const steps = [t('segmentation.sidebar.step.identify'), t('segmentation.sidebar.step.snapshot')];

    const payload = {
      userid: !!user && user.username,
      centerPoint: {
        lat: !!centerCoords && centerCoords.lat,
        long: !!centerCoords && centerCoords.lng,
      },
    };

    return (
      <Root>
        <Typography variant="h6" style={{marginTop: '-50px'}}>
          {t('segmentation.title')}
        </Typography>

        {loadingGetSegment && <Spinner size={30} color="primary" style={{margin: '2rem 0 1rem 0'}} />}

        <StyledPaper elevation={1}>
          <Typography variant="body1">{t('segmentation.description')}</Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index, t)}</Typography>
                  <ActionsContainer>
                    <div>
                      <ActionsButton disabled={activeStep === 0} onClick={this.handleBack}>
                        {t('segmentation.sidebar.back')}
                      </ActionsButton>
                      <ActionsButton variant="contained" color="primary" onClick={() => this.handleNext(payload)}>
                        {activeStep === steps.length - 1 ? t('segmentation.sidebar.snapshot') : t('segmentation.sidebar.next')}
                      </ActionsButton>
                    </div>
                  </ActionsContainer>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length &&
            (loadingPostSegment ? (
              <Spinner size={15} color="primary" />
            ) : (
              <ResetContainer square elevation={0}>
                <Typography>{t('segmentation.sidebar.done')}</Typography>
                <ActionsButton onClick={this.handleReset}>{t('segmentation.sidebar.reset')}</ActionsButton>
              </ResetContainer>
            ))}
        </StyledPaper>

        <List style={{maxHeight: '250px', overflowY: 'scroll'}}>
          {!!imagesList &&
            !loadingGetSegment &&
            !loadingPostSegment &&
            imagesList.map((image, idx) => (
              <ListItem button key={idx} onClick={() => handleUpdateDateIndex(idx)}>
                <ListItemText primary={handleDateFormat(new Date(image.date.slice(0, 10)))} secondary="Snapshot date" />
              </ListItem>
            ))}
        </List>
      </Root>
    );
  }
}

export default withTranslation()(SegmentationSidebar);
