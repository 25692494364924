import React from 'react';
import _ from 'lodash';
import {GoogleMap, GroundOverlay, Marker, StandaloneSearchBox} from '@react-google-maps/api';
import styled from 'styled-components';

const StyledGoogleMap = styled(GoogleMap)`
  & > div {
    z-index: -1 !important;
  }
`;

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 129px)',
};

interface IRefs {
  searchBox: google.maps.places.SearchBox;
  map: google.maps.Map;
}

interface ICenter {
  lat: number;
  lng: number;
}

interface Props {
  bounds: google.maps.LatLngBounds;
  center: ICenter;
  centerCoords: google.maps.LatLng | google.maps.LatLngLiteral;
  defaultCenter: ICenter;
  handleCenterReset: () => void;
  handleImageListReset: () => void;
  handlepassCenterUpstate: (center: ICenter) => void;
  imageToDisplay: string;
  markers: any[];
  onBoundsChanged: () => void;
  onMapMounted: (ref: google.maps.Map<Element>) => void;
  onPlacesChanged: () => void;
  onSearchBoxMounted: (ref: google.maps.places.SearchBox) => void;
  saveCenter: boolean;
  segment: {
    processedImage: string;
    imageBounds: {
      west: number;
      south: number;
      east: number;
      north: number;
    };
  };
  snapshotTaken: boolean;
}

interface IState {
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  bounds: google.maps.LatLngBounds;
  map: google.maps.Map;
  markers: any[];
  onBoundsChanged: () => void;
  onMapMounted: (ref: google.maps.Map<Element>) => void;
  onSearchBoxMounted: (ref: google.maps.places.SearchBox) => void;
  onPlacesChanged: () => void;
}

function withLifecycle(WrappedComponent: React.FunctionComponent<any>) {
  return class extends React.Component<Props, IState> {
    componentDidUpdate(prevProps) {
      // check when the user toggles saveCenter bool to send a centre snapshot upstate
      if (this.props.saveCenter !== prevProps.saveCenter && this.props.saveCenter === true) {
        const center = this.state.map.getCenter();
        const centerCoords = {lat: center.lat(), lng: center.lng()};
        this.props.handlepassCenterUpstate(centerCoords);
      }
      if (this.props.defaultCenter !== prevProps.defaultCenter) {
        this.setState({center: this.props.defaultCenter});
      }
    }
    componentDidMount() {
      const refs: IRefs = {
        searchBox: null,
        map: null,
      };

      this.setState({
        bounds: null,
        center: this.props.defaultCenter,
        markers: [],
        onMapMounted: (ref: google.maps.Map<Element>) => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            map: refs.map,
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: (ref: google.maps.places.SearchBox) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          console.log('onPlacesChanged');
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));

          // Bear this in mind when you start messing around with centering of map
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
          this.props.handleCenterReset();
          this.props.handleImageListReset();

          this.setState({
            center: nextCenter,
          });
          // refs.map.fitBounds(bounds);
        },
      });
    }
    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

const SegmentationMap = ({center, onMapMounted, onSearchBoxMounted, bounds, onPlacesChanged, onBoundsChanged, segment, imageToDisplay, centerCoords}: Props) => {
  return (
    <StyledGoogleMap onLoad={onMapMounted} zoom={15} center={center} onIdle={onBoundsChanged} mapTypeId={window.google.maps.MapTypeId.HYBRID} mapContainerStyle={containerStyle}>
      <StandaloneSearchBox onLoad={onSearchBoxMounted} bounds={bounds} onPlacesChanged={onPlacesChanged}>
        <input
          type="text"
          placeholder="Search for location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            marginTop: `16px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
        />
      </StandaloneSearchBox>
      {!!segment && !!imageToDisplay && (
        <GroundOverlay
          url={imageToDisplay}
          bounds={
            new window.google.maps.LatLngBounds(
              new window.google.maps.LatLng(segment.imageBounds.south, segment.imageBounds.west),
              new window.google.maps.LatLng(segment.imageBounds.north, segment.imageBounds.east)
            )
          }
          opacity={0.8}
        />
      )}
      {!!centerCoords && <Marker position={centerCoords} />}
    </StyledGoogleMap>
  );
};

export default withLifecycle(SegmentationMap);
