import React from 'react';
import {Box} from '@material-ui/core';
import Spinner from '../../../../components/UI/Spinner';

const Loader = () => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255)',
        zIndex: 20,
        marginTop: 50,
      }}
    >
      <Spinner size={'80px'} style={{marginBottom: 150}} />
    </Box>
  );
};

export default Loader;
