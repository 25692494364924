import {RootState} from '../reducers';
import * as Marker from '../reducers/marker';

export const getMarkerSelector = (state: Pick<RootState, 'marker'>) => Marker.getMarkerSelector(state.marker);
export const markersSelector = (state: Pick<RootState, 'marker'>) => Marker.markersSelector(state.marker);
export const markerActiveFarmSelector = (state: Pick<RootState, 'marker'>) => Marker.markerActiveFarmSelector(state.marker);
export const markerSelectedDateSelector = (state: Pick<RootState, 'marker'>) => Marker.markerSelectedDateSelector(state.marker);
export const markerColorSelector = (state: Pick<RootState, 'marker'>) => Marker.markerColorSelector(state.marker);
export const markerPostSelector = (state: Pick<RootState, 'marker'>) => Marker.markerPostSelector(state.marker);
export const markerDeleteSelector = (state: Pick<RootState, 'marker'>) => Marker.markerDeleteSelector(state.marker);
