import {ISaveSeasonRequest} from '@deep-planet/api-interfaces';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../../components/UI/Modal/Modal';
import {Box, FormControl, TextField} from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../../utilities/theme';
import {useDispatch, useSelector} from 'react-redux';
import {seasonPostSelector, selectedOrganizationSelector} from '../../../../store/selectors';
import {postSeason} from '../../../../store/actions/farm';
import DatePeriodPicker from '../../../../components/UI/Pickers/DatePeriodPicker';

const FormWrapper = styled(Box)`
  flex-direction: row;
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const InputWrapper = styled(FormControl)`
  padding-right: 16px !important;
  ${theme.breakpoints.down('sm')} {
    padding-right: 0 !important;
    padding-bottom: 16px !important;
  }

  &:last-of-type {
    padding-right: 0 !important;
  }
`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const CreateSeasonModal = ({isOpen, handleClose}: Props) => {
  const [seasonName, setSeasonName] = useState('');
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
  const [endDate, setEndDate] = useState<Date>(new Date(new Date().getFullYear(), 11, 31));
  const [errors, setErrors] = useState({
    name: {
      value: false,
      text: '',
    },
  });

  const {loading} = useSelector(seasonPostSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = () => {
    if (seasonName.length) {
      const season: ISaveSeasonRequest = {
        organizationId: selectedOrganization.id,
        name: seasonName,
        fromDate: startDate,
        toDate: endDate,
      };
      dispatch(postSeason(season, handleClose));
    } else {
      setErrors({...errors, name: {value: true, text: t('forms.mandatory.to.fill')}});
    }
  };

  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const handleSeasonNameChange = e => {
    setSeasonName(e.target.value);
  };

  return (
    <Modal
      title={t('yield.season.modal.title')}
      isOpen={isOpen}
      submitText={t('forms.save')}
      isSubmitButtonDisabled={loading}
      isLoading={loading}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    >
      <FormWrapper display="flex" paddingTop={2} paddingBottom={4}>
        <InputWrapper>
          <TextField
            required
            error={errors.name.value}
            helperText={errors.name.value ? errors.name.text : ''}
            value={seasonName}
            label={t('yield.season.modal.name')}
            type="text"
            placeholder={t('yield.season.modal.name.placeholder')}
            variant="outlined"
            onChange={handleSeasonNameChange}
          />
        </InputWrapper>
        <InputWrapper>
          <DatePeriodPicker variant="outlined" startDate={startDate} endDate={endDate} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange} direction="row" />
        </InputWrapper>
      </FormWrapper>
    </Modal>
  );
};

export default CreateSeasonModal;
