import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Plot from 'react-plotly.js';
import {Box, Typography} from '@material-ui/core';

import {IFarm} from '../../../store/reducers/farm';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';
import {colors, GROWING_DEGREE_DAYS} from '../../../config/const';
import {polygonInfoWindow, ISeasonIndicesResponse} from '@deep-planet/api-interfaces';

interface PlotsProps {
  metric: string;
  index: string;
  scale: number;
  smallScreen?: boolean;
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  seasonIndices: any[];
  loadingSeasonIndices: boolean;
  seasonIndicesError: unknown;
}

const Plots = ({metric, index, scale, smallScreen, selectedFarm, selectedPolygon, seasonIndices, loadingSeasonIndices, seasonIndicesError}: PlotsProps) => {
  const fromDate = new Date();
  fromDate.setFullYear(fromDate.getFullYear() - 5);
  const toDate = new Date(Date.now());

  const {t} = useTranslation();
  const {distanceUnit, convertMmInches} = useMeasurementConversion();

  const [noData, setNoData] = useState(false);

  const plotStyle = {
    minHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    maxHeight: smallScreen ? '50vh' : `calc(45vh * ${scale})`,
    width: smallScreen ? '100%' : `calc(33vw * ${scale * scale})`,
  };

  const graphColors = colors.slice(1);
  const polygonId = selectedPolygon?.polygonId;

  // Capitalises index title correctly
  const getIndexTitle = (index: string) => {
    if (index === 'gdd') {
      return GROWING_DEGREE_DAYS;
    } else if (index === 'ndvi' || index === 'ndwi') {
      return index.toUpperCase();
    } else {
      return index[0].toUpperCase() + index.slice(1);
    }
  };

  // Generates an array containing the start of every month within a specified period of years
  const generateMappedDates = (startYear: number, endYear: number) => {
    const dates = [];
    for (let year = startYear; year <= endYear; year++) {
      for (let month = 1; month <= 12; month++) {
        // Ensure month is always two digits
        const monthStr = month < 10 ? `0${month}` : `${month}`;
        dates.push(`${year}-${monthStr}-01`);
      }
    }
    return dates;
  };

  // Goes through each trace and converts date to latest season -> allows traces to overlap
  const extrapolateTraces = (traces: any[]) => {
    // Calculate amount of years between highest and lowest year in data
    let timeToAdd = fromDate.getFullYear() - toDate.getFullYear();
    traces.forEach(trace => {
      const newXValues = [];
      for (const date of trace.x) {
        // Adds necessary amount of years for
        newXValues.push(new Date(date.getFullYear() + timeToAdd, date.getMonth(), date.getDate()));
      }
      trace.x = newXValues;
      trace.customdata = trace.originalDates;
      trace.hovertemplate = `<b>%{customdata|%Y}:</b> %{y:.4f} <extra></extra>`; // On hover show original year and then y value
      delete trace.originalDates;
      timeToAdd--;
      if (timeToAdd === 0) {
        trace.opacity = 0.85; // Make previous seasons less visible
      }
    });

    return traces;
  };

  // Gets data values for given index
  const getValuesIndex = (data: ISeasonIndicesResponse[], index: string) => {
    const traces = [];
    let prevDate = null;
    let xValues = [];
    let yValues = [];
    // Keeps track of the original date for each plot
    let originalDates = [];
    let colorIndex = 0;
    let crossedYear = false;

    data?.forEach(item => {
      // If polygon does not match do not show data
      if (item.polygonId !== polygonId) {
        return;
      }

      const currentDate = new Date(item.date);
      // If long date period between plots -> detects new season
      if (prevDate && prevDate.getTime() + 2 * 30 * 24 * 60 * 60 * 1000 < currentDate.getTime()) {
        // Pushes separate trace for each season
        traces.push({
          x: xValues,
          y: yValues,
          originalDates: originalDates,
          name: crossedYear ? `${currentDate.getFullYear() - 1}/${currentDate.getFullYear() + -2000}` : currentDate.getFullYear() - 1,
          opacity: 0.35,
          hoverinfo: 'x+y',
          mode: 'lines',
          line: {
            color: graphColors[colorIndex],
          },
        });
        crossedYear = false;
        xValues = [currentDate];
        yValues = [index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]];
        originalDates = [currentDate];
      } else {
        if (prevDate && prevDate.getFullYear() === currentDate.getFullYear() - 1) {
          crossedYear = true;
        }
        // Adds plot values to array
        xValues.push(currentDate);
        yValues.push(index === 'rainfall' ? convertMmInches(parseFloat(item[index])).toString() : item[index]);
        originalDates.push(currentDate);
      }
      prevDate = currentDate;
      colorIndex++;
    });

    // Handle last trace
    if (xValues.length > 0) {
      traces.push({
        x: xValues,
        y: yValues,
        originalDates: originalDates,
        name: crossedYear ? `${new Date().getFullYear()}/${new Date().getFullYear() + -1999}` : new Date().getFullYear(),
        hoverinfo: 'x+y',
        mode: 'lines',
        line: {
          color: 'blue',
        },
      });
    }

    return extrapolateTraces(traces);
  };

  const renderPlot = (index: string) => {
    const data = getValuesIndex(seasonIndices, index);
    if (data.length === 0) {
      setNoData(true);
    }
    // Add slight padding to graph
    const paddingFactor = 0.2;
    const xValues = data.flatMap(trace => trace.x);
    const xMin = Math.min(...xValues);
    const xMax = Math.max(...xValues);
    const padding = (xMax - xMin) * paddingFactor;
    return (
      <Plot
        data={data}
        layout={{
          title: getIndexTitle(index),
          autosize: true,
          hovermode: 'x',
          xaxis: {
            title: 'Month',
            range: [xMin - padding, xMax + padding],
            autorange: false,
            type: 'date',
            tickformat: '%b', // Show name of month
            tickvals: generateMappedDates(2015, 2035), // List of exact start dates for each month from 2015 to 2035
            hoverformat: '%b %d',
          },
          yaxis: {
            title: getIndexTitle((index === 'rainfall' && `Rainfall (${(distanceUnit[0] === 'meters' && 'mm') || (distanceUnit[0] === 'feet' && 'in')})`) || (index === 'gdd' && 'GDD (ºC)') || index),
            autorange: true,
          },
        }}
        style={plotStyle}
      />
    );
  };

  return (
    <Box
      style={{
        paddingInline: 10,
        width: '100%',
        overflowY: metric === 'year' ? 'scroll' : 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        height: (seasonIndicesError || noData) && '80vh',
        justifyContent: (seasonIndicesError || noData) && 'center',
      }}
    >
      {!loadingSeasonIndices && (seasonIndicesError || noData) ? (
        <Typography variant="h5" style={{marginBottom: 100, textAlign: 'center', color: 'black', fontWeight: 'bold', opacity: 0.5}}>
          {t('season.indices.no.data')}
        </Typography>
      ) : !loadingSeasonIndices && index === 'All' ? (
        <>
          {renderPlot('ndvi')}
          {renderPlot('ndwi')}
          {renderPlot('gdd')}
          {renderPlot('rainfall')}
        </>
      ) : (
        renderPlot(index === GROWING_DEGREE_DAYS ? 'gdd' : index.toLowerCase())
      )}
    </Box>
  );
};

export default Plots;
