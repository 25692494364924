import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#253647',
    }, // Dark blue
    grey: {
      50: 'rgba(0,0,0,.12)',
    }, // Light Grey
  },
});

export default responsiveFontSizes(theme);

export const primaryColorGradient = `to right, rgb(128,0,0) 0%, rgb(241,8,0) 10%, rgb(255,100,0) 20%, rgb(255,193,0) 30%, rgb(212,255,35) 40%, rgb(131,255,115) 50%, rgb(51,255,196) 60%, rgb(0,192,255) 70%, rgb(0,93,255) 80%, rgb(0,0,255) 90%, rgb(0,0,128) 100%`;

export const primaryDiscreteColorGradient = `to right,
      rgb(128,0,0)      0%,
      rgb(128,0,0)      9.09%,
      rgb(241,8,0)      9.09%,
      rgb(241,8,0)      18.18%,
      rgb(255,100,0)    18.18%,
      rgb(255,100,0)    27.27%,
      rgb(255,193,0)    27.27%,
      rgb(255,193,0)    36.36%,
      rgb(212,255,35)   36.36%,
      rgb(212,255,35)   45.45%,
      rgb(131,255,115)  45.45%,
      rgb(131,255,115)  54.54%,
      rgb(51,255,196)   54.54%,
      rgb(51,255,196)   63.63%,
      rgb(0,192,255)    63.63%,
      rgb(0,192,255)    72.72%,
      rgb(0,93,255)     72.72%,
      rgb(0,93,255)     81.81%,
      rgb(0,0,255)      81.81%,
      rgb(0,0,255)      90.90%,
      rgb(0,0,128)      90.90%,
      rgb(0,0,128)      100%`;

export const RdYlGnDiscreteColorGradient = `to right,
      rgb(215,  48,  39)    0%,
      rgb(215,  48,  39)    12.5%,
      rgb(244, 109,  67)    12.5%,
      rgb(244, 109,  67)    25%,
      rgb(253, 174,  97)    25%,
      rgb(253, 174,  97)    37.5%,
      rgb(254, 224, 139)    37.5%,
      rgb(254, 224, 139)    50%,
      rgb(217, 239, 139)    50%,
      rgb(217, 239, 139)    62.5%,
      rgb(166, 217, 106)    62.5%,
      rgb(166, 217, 106)    75%,
      rgb(102, 189,  99)    75%,
      rgb(102, 189,  99)    87.5%,
      rgb(26 , 152,  80)    87.5%,
      rgb(26 , 152,  80)    100%`;

export const GnRDY1DiscreteColorGradient = `to right,
      rgb(26 , 152,  80)    0%,
      rgb(26 , 152,  80)    12.5%,
      rgb(102, 189,  99)    12.5%,
      rgb(102, 189,  99)    25%,
      rgb(166, 217, 106)    25%,
      rgb(166, 217, 106)    37.5%,
      rgb(217, 239, 139)    37.5%,
      rgb(217, 239, 139)    50%,
      rgb(254, 224, 139)    50%,
      rgb(254, 224, 139)    62.5%,
      rgb(253, 174,  97)    62.5%,
      rgb(253, 174,  97)    75%,
      rgb(244, 109,  67)    75%,
      rgb(244, 109,  67)    87.5%,
      rgb(215,  48,  39)    87.5%,
      rgb(215,  48,  39)    100%`;
