import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/farm';
import {ICenter, IFarmImageEntity, IOrganizationEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {BBox} from '@turf/turf';

const sortFarmsByName = (farm1, farm2) => {
  if (farm1.name < farm2.name) return -1;
  if (farm1.name > farm2.name) return 1;
  return 0;
};

export const allFarmsSelector = (state: FarmState) => state.allFarms;
export const farmGetSelector = (state: FarmState) => state.farmGet;
export const farmDeleteSelector = (state: FarmState) => state.farmDelete;
export const soilValuesSelector = (state: FarmState) => state.soilValues;
export const farmGetSoilSelector = (state: FarmState) => state.farmGetSoil;
export const farmImagesSelector = (state: FarmState) => state.images;
export const farmGetImagesSelector = (state: FarmState) => state.farmGetImages;
export const farmPutSelector = (state: FarmState) => state.farmPut;
export const activeFarmIdSelector = (state: FarmState) => state.activeFarm.index;
export const allSoilValuesSelector = (state: FarmState) => state.allSoilValues;
export const farmGetSoilsSelector = (state: FarmState) => state.farmGetSoils;
export const seasonsSelector = (state: FarmState) => state.seasons;
export const seasonsGetSelector = (state: FarmState) => state.seasonGet;
export const seasonPostSelector = (state: FarmState) => state.seasonPost;

export interface IPolygonWithArea extends IPolygonEntity {
  hectares: number;
}

export interface IFarm {
  id: string;
  name: string;
  farmid: string;
  farmCenter?: ICenter;
  polygons?: IPolygonWithArea[];
  bbox: BBox;
  ownerOrganization: IOrganizationEntity;
}

export interface SoilValues {
  polygonName: string;
  product: string;
  value: string;
  date: Date;
}

export interface Season {
  id: string;
  name: string;
  fromDate: string;
  toDate: string;
  organization: IOrganizationEntity;
}

export interface FarmState {
  allFarms: IFarm[];
  farmName: string;
  farmId: string;
  activeFarm: {
    index: string;
  };
  farmGet: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  farmPost: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  farmDelete: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  farmPut: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  farmGetSoil: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  soilValues: SoilValues[];
  farmGetImages: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  images: IFarmImageEntity[];
  farmGetSoils: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  allSoilValues: SoilValues[];
  seasonGet: {
    error: unknown;
    loading: boolean;
  };
  seasonPost: {
    error: unknown;
    loading: boolean;
  };
  seasons: Season[];
}

const initialState: FarmState = {
  allFarms: null,
  farmName: '',
  farmId: '',
  activeFarm: {
    index: null,
  },
  farmGet: {
    success: false,
    error: null,
    loading: false,
  },
  farmPost: {
    success: false,
    error: null,
    loading: false,
  },
  farmDelete: {
    success: false,
    error: null,
    loading: false,
  },
  farmPut: {
    success: false,
    error: null,
    loading: false,
  },
  farmGetSoil: {
    success: false,
    error: null,
    loading: false,
  },
  soilValues: null,
  farmGetImages: {
    success: false,
    error: null,
    loading: false,
  },
  images: null,
  farmGetSoils: {
    success: false,
    error: null,
    loading: false,
  },
  allSoilValues: null,
  seasonGet: {
    error: null,
    loading: false,
  },
  seasonPost: {
    error: null,
    loading: false,
  },
  seasons: null,
};

const updatePolygon = (item, payload) => {
  const polygons = item.polygons.map(polygon => {
    if (polygon.id === payload.polyId) {
      const {name, geoJson} = payload;
      return {...polygon, name, geoJson};
    }
    return polygon;
  });
  return {...item, polygons};
};

const reducer = (state: FarmState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.FARM_GET_START:
      return updateObject(state, {
        farmGet: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_GET_SUCCESS:
      return updateObject(state, {
        allFarms: new Array(...action.allFarms.sort(sortFarmsByName)),
        activeFarm: {
          index: action.activeFarmIndex,
        },
        farmGet: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FARM_GET_FAIL:
      return updateObject(state, {
        farmGet: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.FARM_POST_INIT:
      return updateObject(state, {
        farmPost: {
          success: false,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FARM_POST_START:
      return updateObject(state, {
        farmPost: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_POST_SUCCESS:
      return updateObject(state, {
        allFarms: action.farm ? [...state.allFarms, action.farm].sort(sortFarmsByName) : state.allFarms,
        farmName: action.farmName,
        farmId: action.farmId,
        farmPost: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FARM_POST_FAIL:
      return updateObject(state, {
        farmPost: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.FARM_DELETE_START:
      return updateObject(state, {
        farmDelete: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_DELETE_SUCCESS:
      return updateObject(state, {
        allFarms: new Array(...state.allFarms.filter(item => item.farmid !== action.deletedFarmId).sort(sortFarmsByName)),
        farmDelete: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FARM_DELETE_FAIL:
      return updateObject(state, {
        farmDelete: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.FARM_UPDATE_START:
      return updateObject(state, {
        farmPut: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_UPDATE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedItems = state.allFarms.map(item => {
        if (item.farmid === action.farmId) {
          if (action.payload?.polyId) {
            return updatePolygon(item, action.payload);
          }
          return {...item, ...action.payload};
        }
        return item;
      });
      return updateObject(state, {
        allFarms: new Array(...updatedItems),
        farmPut: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FARM_UPDATE_FAIL:
      return updateObject(state, {
        farmPut: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.FARM_UPDATE_INDEX:
      return updateObject(state, {
        activeFarm: {
          index: action.farmId,
        },
      });
    case ActionTypes.FARM_GET_SOIL_START:
      return updateObject(state, {
        farmGetSoil: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_GET_SOIL_SUCCESS:
      return updateObject(state, {
        farmGetSoil: {
          success: true,
          error: null,
          loading: false,
        },
        soilValues: action.payload,
      });
    case ActionTypes.FARM_GET_SOIL_FAIL:
      return updateObject(state, {
        farmGetSoil: {
          success: false,
          error: true,
          loading: false,
        },
        soilValues: [],
      });
    case ActionTypes.FARM_GET_IMAGE_START:
      return updateObject(state, {
        farmGetImages: {
          success: false,
          error: false,
          loading: true,
        },
      });
    case ActionTypes.FARM_GET_IMAGE_SUCCESS:
      return updateObject(state, {
        farmGetImages: {
          success: true,
          error: false,
          loading: false,
        },
        images: action.payload,
      });
    case ActionTypes.FARM_GET_IMAGE_FAIL:
      return updateObject(state, {
        farmGetImages: {
          success: false,
          error: true,
          loading: false,
        },
        images: [],
      });
    case ActionTypes.FARM_GET_SOILS_START:
      return updateObject(state, {
        farmGetSoils: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FARM_GET_SOILS_SUCCESS:
      return updateObject(state, {
        farmGetSoils: {
          success: true,
          error: null,
          loading: false,
        },
        allSoilValues: action.payload,
      });
    case ActionTypes.FARM_GET_SOILS_FAIL:
      return updateObject(state, {
        farmGetSoils: {
          success: false,
          error: true,
          loading: false,
        },
        allSoilValues: [],
      });
    case ActionTypes.SEASONS_GET_START:
      return updateObject(state, {
        seasonGet: {
          loading: true,
          error: false,
        },
      });
    case ActionTypes.SEASONS_GET_SUCCESS:
      return updateObject(state, {
        seasonGet: {
          loading: false,
          error: false,
        },
        seasons: action.seasons,
      });
    case ActionTypes.SEASONS_GET_FAIL:
      return updateObject(state, {
        seasonGet: {
          loading: false,
          error: true,
        },
      });
    case ActionTypes.SEASON_POST_START:
      return updateObject(state, {
        seasonPost: {
          loading: true,
          error: false,
        },
      });
    case ActionTypes.SEASON_POST_SUCCESS:
      return updateObject(state, {
        seasonPost: {
          loading: false,
          error: false,
        },
        seasons: [...state.seasons, action.season],
      });
    case ActionTypes.SEASON_POST_FAIL:
      return updateObject(state, {
        seasonPost: {
          loading: false,
          error: true,
        },
      });
    default:
      return state;
  }
};

export default reducer;
