import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import theme from '../../../utilities/theme';
import logo from '../../../../assets/dp-white.png';
import {WithTranslation, withTranslation} from 'react-i18next';
import * as Sentry from '@sentry/react';

const Container = styled(Box)`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${() => theme.palette.primary.main};
  flex-direction: column;
`;

const Padding = styled(Box)`
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

const WhiteText = styled(Typography)`
  color: white;
  text-align: center;
  font-size: 2rem !important;
  ${theme.breakpoints.down('sm')} {
    font-size: 1rem !important;
  }
`;

const Logo = styled.img`
  height: 400px;
  ${theme.breakpoints.down('sm')} {
    height: 200px;
  }
`;

class ErrorBoundary extends React.Component<WithTranslation, {hasError: boolean}> {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // Sending info about the error to Sentry
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const {t} = this.props;
    if (this.state.hasError) {
      return (
        <Sentry.ErrorBoundary>
          <Container display="flex">
            <Padding>
              <Logo src={logo} />
              <WhiteText>{t('error.boundary.title')}</WhiteText>
            </Padding>
          </Container>
        </Sentry.ErrorBoundary>
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
