import {Button, Typography, Hidden, Box} from '@material-ui/core';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import theme from '../../../utilities/theme';
import FeedbackModal from './FeedbackModal';

const Title = styled(Typography)`
  color: white;
  text-transform: none !important;
`;

const StyledBox = styled(Box)`
  margin-right: 16px;
  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
`;

const FeedbackButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {t} = useTranslation();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <StyledBox>
        <Button color="inherit" onClick={handleOpenModal}>
          <Hidden mdUp implementation="css">
            <Box mt={1}>
              <RateReviewOutlinedIcon />
            </Box>
          </Hidden>
          <Hidden smDown implementation="css">
            <Box display="flex">
              <RateReviewOutlinedIcon />
              <Box ml={1}>
                <Title variant="body1">{t('feedback.title')}</Title>
              </Box>
            </Box>
          </Hidden>
        </Button>
      </StyledBox>
      {isOpen && <FeedbackModal isOpen={isOpen} handleCloseModal={handleCloseModal} />}
    </>
  );
};

export default React.memo(FeedbackButton);
