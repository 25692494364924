import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import DatePeriodPicker from '../../../components/UI/Pickers/DatePeriodPicker';
import FarmPicker from '../../../components/UI/Pickers/FarmPicker';
import {IFarm} from '../../../store/reducers/farm';
import {TimelapseProduct} from './index';

const SideList = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 230px;
`;

interface Props {
  activeFarm: IFarm;
  allFarms: IFarm[];
  handleFarmChange: (id: string) => void;
  startDate: Date;
  endDate: Date;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
  selectedProduct: TimelapseProduct;
  handleProductChange: (product: TimelapseProduct) => void;
  handlePostRequest: () => void;
}

const Sidebar = ({activeFarm, allFarms, handleFarmChange, startDate, endDate, handleStartDateSelection, handleEndDateSelection, selectedProduct, handleProductChange, handlePostRequest}: Props) => {
  const {t} = useTranslation();

  const handleTypeChange = event => {
    handleProductChange(event.target.value);
  };

  return (
    <SideList>
      <Grid container alignItems="center" alignContent="center">
        <Box mt={2} mb={1} style={{width: '100%'}}>
          <Grid sm={12} xs={12} item>
            <FarmPicker activeFarm={activeFarm.farmid} allFarms={allFarms} handleChange={handleFarmChange} />
          </Grid>
        </Box>
        <DatePeriodPicker
          disableToolbar={false}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateSelection}
          handleEndDateChange={handleEndDateSelection}
          disableFuture={true}
        />
        <Box mt={2} mb={2}>
          <Grid sm={12} xs={12} item>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="position" name="position" defaultValue="ndvi" value={selectedProduct} onChange={handleTypeChange}>
                <FormControlLabel value="ndvi" control={<Radio color="primary" />} label="NDVI" labelPlacement="end" />
                <FormControlLabel value="ndwi" control={<Radio color="primary" />} label="NDWI" labelPlacement="end" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        <Button fullWidth variant="contained" color="primary" onClick={handlePostRequest} style={{margin: '1rem 0'}}>
          {t('timelapse.sidebar.update')}
        </Button>
      </Grid>
    </SideList>
  );
};

export default Sidebar;
