import {RootState} from '../reducers';
import * as irrigation from '../reducers/irrigation';

export const irrigationFarmsSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationFarmsSelector(state.irrigation);
export const irrigationImagesSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationImagesSelector(state.irrigation);
export const irrigationActiveFarmSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationActiveFarmSelector(state.irrigation);
export const irrigationActiveFarmImageSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationActiveFarmImageSelector(state.irrigation);
export const irrigationFarmGetSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationFarmGetSelector(state.irrigation);
export const irrigationImagesGetSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.getIrrigationImagesGetSelector(state.irrigation);
export const irrigationSelectedDateSelector = (state: Pick<RootState, 'irrigation'>) => irrigation.irrigationSelectedDateSelector(state.irrigation);
