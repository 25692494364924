import React from 'react';
import styled from 'styled-components';
import bgmobile from '../../../../assets/bg-mobile.jpg';
import bg from '../../../../assets/bg.jpg';
import logo from '../../../../assets/dp-white.png';
import {isMobile} from 'react-device-detect';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const Overlay = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
`;

const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  && h5 {
    color: white;
    margin-bottom: 1rem;
  }
`;

const Logo = styled.img`
  height: 200px;
  margin: 2rem 0 1rem 0;
`;

const Img = styled.img`
  object-fit: cover;
  height: 100vh;
`;

const AuthSplash = ({children}: {children: JSX.Element}) => {
  return (
    <Background>
      {isMobile ? <img src={bgmobile} alt="bgmobile" /> : <Img src={bg} alt="bg2" />}
      <Overlay />
      <Container>
        <Logo src={logo} alt="Deep Planet Logo (white)" />
        {children}
      </Container>
    </Background>
  );
};

export default AuthSplash;
