// Import necessary React hooks and components
import React, {useCallback} from 'react';
import {Typography, TextField, MenuItem, Select, Box, BoxProps, InputLabel, FormControl, SelectProps} from '@material-ui/core';
import styled from 'styled-components';
import {organizationsSelector} from '../../../store/selectors';
import theme from '../../../utilities/theme';
import IconButton from '@material-ui/core/IconButton';
import {IFileManagerDTO} from '@deep-planet/api-interfaces';
import {chain, some} from 'lodash';
import {useDropzone} from 'react-dropzone';
import {enqueueSnackbar} from '../../../store/actions';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import Modal from '../Modal/Modal';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {getOrgUserFarms} from '../../../utilities/parseFarm';
import {useFileManager} from '../../../hooks/useFileManager';
import Spinner from '../Spinner';

// Define the Props interface for the FileUploadModal component
interface Props extends WithUserProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (params: IFileManagerDTO, files: ISelectedFile[]) => void;
  submitLoading: boolean;
  modalTitle?: string;
}

// Define the IForm interface
export interface IForm {
  summary: {
    value: boolean;
    text: string;
  };
  description: {
    value: boolean;
    text: string;
  };
  farm: {
    value: boolean;
    text: string;
  };
  organization: {
    value: boolean;
    text: string;
  };
  type: {
    value: boolean;
    text: string;
  };
  file: {
    value: boolean;
    text: string;
  };
}

// Define the ISelectedFile interface
export interface ISelectedFile {
  name: string;
  type: string;
  size: number;
}

// Define a styled Select component
const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

// Define a styled Box component for input wrappers
const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 200px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

// Define a styled div for the drop area
const DropArea = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: auto;
  width: 100%;
  padding: 18.5px 14px;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    height: 100%;
    width: 100%;
  }
`;

// Define constants for max file size and accepted file extensions
const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15mb
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.doc', '.docx', '.xlsx', '.kml', '.kmz', '.shp', '.dbf', '.prj', '.cpg', '.shx', '.sbn', '.sbx', '.tif', '.tiff', '.csv'];
const MAX_FILE_COUNT = 10;

// Define the FileUploadModal component
const FileUploadModal = ({isOpen, handleClose, handleSubmit, submitLoading, modalTitle, user}: Props) => {
  // Use the useTranslation hook to get the translation function
  const {t} = useTranslation();

  // Use the useDispatch hook to get the dispatch function
  const dispatch = useDispatch();

  // Use the useSelector hook to get the organizations from the store
  const organizations = useSelector(organizationsSelector);

  // Use the useFileManager hook to get the file manager state and functions
  const {
    description,
    selectedType,
    summary,
    selectedFarm,
    selectedFiles,
    selectedOrganization,
    fileTypes,
    isFileTypesLoading,
    errors,
    setErrors,
    handleNoteAttachmentChange,
    handleTypeSelection,
    handleChangeSummary,
    handleNoteAttachmentCancel,
    handleOrganizationSelection,
    handleFarmSelection,
    handleChangeDescription,
  } = useFileManager();

  // Define a callback function for submitting the form
  const onClickSubmit = async () => {
    // Check if the farm is selected
    if (!selectedFarm) {
      return setErrors({...errors, farm: {value: true, text: t('forms.mandatory.to.fill')}});
    }
    // Check if the organization is selected
    if (!selectedOrganization) {
      return setErrors({...errors, organization: {value: true, text: t('forms.mandatory.to.fill')}});
    }
    // Check if the type is selected
    if (!selectedType?.id) {
      return setErrors({...errors, type: {value: true, text: t('forms.mandatory.to.fill')}});
    }
    // Check if the summary is filled
    if (!summary?.length) {
      return setErrors({...errors, summary: {value: true, text: t('forms.mandatory.to.fill')}});
    }
    // Check if files are selected
    if (!selectedFiles?.length) {
      return setErrors({...errors, file: {value: true, text: t('forms.file.upload.prompt.error')}});
    }
    // Check if there are any errors
    const isAnyError = some(errors, ({value}) => value);
    if (!isAnyError) {
      // Create a new IFileManagerDTO object
      const params: IFileManagerDTO = {
        summary,
        description,
        typeId: selectedType.id,
        files: [],
        organizationId: selectedOrganization || organizations[0].id,
        date: new Date(),
        farmId: selectedFarm,
      };
      // Call the handleSubmit function with the params and selectedFiles
      handleSubmit(params, selectedFiles);
    }
  };
  // Define a callback function for dropping files
  const onDrop = useCallback(
    async (acceptedFiles: ISelectedFile[]) => {
      // Check if the files are valid
      const size = acceptedFiles.reduce((acc, item) => item.size + acc, 0);
      if (acceptedFiles.length <= MAX_FILE_COUNT && size <= MAX_FILE_SIZE) {
        handleNoteAttachmentChange(acceptedFiles);
      } else if (size > MAX_FILE_SIZE) {
        dispatch(enqueueSnackbar({message: t('file.max.size'), options: {variant: 'error'}}));
      } else {
        dispatch(enqueueSnackbar({message: t('file.upload.invalid'), options: {variant: 'error'}}));
      }
      setErrors({...errors, file: {value: false, text: ''}});
    },
    [dispatch, errors, handleNoteAttachmentChange, setErrors, t]
  );
  // Raise an error if a file is rejected
  const onDropRejected = (e: {errors: {message: string}[]}[]) => {
    const errorsArray = e.map(({errors}) => errors.map(({message}) => message));
    const errors = chain(errorsArray).flatten().uniq().value();
    for (const error of errors) {
      dispatch(enqueueSnackbar({message: error, options: {variant: 'error'}}));
    }
  };

  // get inout props to drop a file
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected,
    onDrop,
    accept: ACCEPTED_EXTENSIONS,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILE_COUNT,
    disabled: submitLoading,
  });

  // Get farms of selected organization to populate farm input parameter
  const farms = getOrgUserFarms(organizations, selectedOrganization);
  // Check if any error is found
  const isAnyError = some(errors, ({value}) => value);
  return (
    // Prompt modal dialog box with children components
    <Modal
      title={modalTitle || t('')}
      submitText={t('forms.save')}
      isOpen={isOpen}
      isSubmitButtonDisabled={submitLoading || isAnyError}
      isLoading={submitLoading}
      handleClose={handleClose}
      handleSubmit={onClickSubmit}
      modalWidth="496px"
    >
      <form>
        {/* If file types are loading, show a spinner */}
        {isFileTypesLoading ? (
          <Spinner size={30} color="primary" style={{margin: '0.5rem auto'}} />
        ) : (
          <>
            {/* Display form controls for type and organization selection */}
            <Box display="flex" alignItems="baseline" flexWrap="wrap">
              {/* Wrapper for type selection input */}
              <InputWrapperBox>
                <FormControl required fullWidth disabled={submitLoading}>
                  <InputLabel id="type-label" error={errors.type.value}>
                    {t('note.table.type')} {/* Label for type input */}
                  </InputLabel>
                  <StyledSelect labelId="type" value={selectedType?.id} name="type" onChange={({target: {value}}) => handleTypeSelection(value as string)}>
                    {/* Map through fileTypes to create menu items for the select input */}
                    {fileTypes?.map(({id, name}) => (
                      <MenuItem key={id} value={id}>
                        {t(`${name}`)}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </InputWrapperBox>
              {/* Wrapper for organization selection input */}
              <InputWrapperBox>
                <FormControl required fullWidth disabled={submitLoading}>
                  <InputLabel id="organization-label" error={errors.organization.value}>
                    Organization {/* Label for organization input */}
                  </InputLabel>
                  <StyledSelect required labelId="organization" value={selectedOrganization} name="organization" onChange={({target: {value}}) => handleOrganizationSelection(value as string)}>
                    {/* Map through organizations to create menu items for the select input */}
                    {organizations?.map(o => (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </InputWrapperBox>
            </Box>
            {/* Wrapper for farm selection input */}
            <InputWrapperBox>
              <FormControl required fullWidth disabled={submitLoading}>
                <InputLabel error={errors.farm.value} id="farm-label">
                  {t('note.table.farm')} {/* Label for farm input */}
                </InputLabel>
                <StyledSelect labelId="farm" value={selectedFarm || ''} name="farm" onChange={({target: {value}}) => handleFarmSelection(value as string)}>
                  {/* Map through farms to create menu items for the select input */}
                  {farms &&
                    farms.map(({id, name}) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
            </InputWrapperBox>
            {/* Text input for summary */}
            <Box display="flex" mt={2} mb={2}>
              <TextField
                required
                error={errors.summary.value}
                helperText={errors.summary.value ? errors.summary.text : ''}
                disabled={submitLoading}
                value={summary}
                onChange={({target: {value}}) => handleChangeSummary(value)}
                variant="outlined"
                label={t('file.label.summary')}
                fullWidth
              />
            </Box>
            {/* Text area for description */}
            <Box display="flex" mt={2} mb={2}>
              <TextField
                error={errors.description.value}
                helperText={errors.description.value ? errors.description.text : ''}
                disabled={submitLoading}
                value={description}
                onChange={({target: {value}}) => handleChangeDescription(value)}
                variant="outlined"
                multiline
                rows={5}
                label={t('note.label.description')}
                fullWidth
              />
            </Box>
            {/* Drop area for file upload */}
            <Box display="flex" mt={2} mb={2}>
              <DropArea {...getRootProps()}>
                <input {...getInputProps()} name="file" required />
                {isDragActive ? (
                  <Typography>{t('setting.create.farm.step.uploading.drop')}</Typography>
                ) : (
                  <>
                    <CloudUploadIcon color="primary" fontSize="default" />
                    <Typography align="center">{t('setting.create.farm.step.uploading.click')}</Typography>
                  </>
                )}
              </DropArea>
            </Box>
            {/* Display error message for file input */}
            {errors.file.value && <p style={{color: 'red'}}>{errors.file.text}</p>}
            {/* Display list of selected files with option to remove */}
            <Box display="flex" flexDirection="column" mt={2} mb={2}>
              {selectedFiles.map(file => (
                <Box alignItems="center" display="flex" key={file.name}>
                  <Typography>{file.name}</Typography>
                  <IconButton disabled={submitLoading} onClick={() => handleNoteAttachmentCancel(file.name)}>
                    <ClearIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </>
        )}
      </form>
    </Modal>
  );
};

export default withUser(FileUploadModal);
