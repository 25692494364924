import {Box, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../utilities/theme';

interface labels {
  name: string;
  value: string;
}

interface Props {
  labels: labels[];
  value: string;
  handleSwitch: (value: string) => void;
}

const StyledBox = styled(Box)`
  padding: 1px 14px;
  border-radius: 3px;
  position: relative;
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;

  ${theme.breakpoints.only('sm')} {
    height: calc(100% - 121px);
    flex-direction: column;
    margin-left: -175px;
    height: 40px;
    margin-top: 52px;
  }

  ${theme.breakpoints.only('xs')} {
    height: calc(100% - 121px);
    flex-direction: column;
    margin-left: -175px;
    width: calc(100% - 103px);
    height: 40px;
    margin-top: 52px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`;

export const RadioButton = ({labels, value, handleSwitch}: Props) => {
  return (
    <StyledBox display="flex" alignItems="center">
      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" defaultValue={labels[0].value} value={value} onChange={m => handleSwitch(m.target.value)}>
        {labels.map(label => (
          <FormControlLabel key={label.name} style={{marginRight: '6px'}} value={label.value} control={<Radio />} label={label.name} />
        ))}
      </RadioGroup>
    </StyledBox>
  );
};
