import React, {useState} from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import Sidebar from './Sidebar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../../utilities/theme';

const Root = styled.div`
  display: flex;
`;

const Content = styled.main`
  flex-grow: 1;
  height: 100vh;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  left: 200px;
  ${theme.breakpoints.down('sm')} {
    left: 0px;
  }
`;

interface Props {
  children: JSX.Element | JSX.Element[];
}

const Layout = ({children}: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <Navigation handleDrawerToggle={handleDrawerToggle} />
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <Content>
        <Toolbar />
        {children}
      </Content>
    </Root>
  );
};

export default Layout;
