import {RootState} from '../reducers';
import * as NDVI from '../reducers/ndvi';

export const planetGetSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.planetGetSelector(state.ndvi);
export const planetDataSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.planetDataSelector(state.ndvi);
export const ndviDataSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviDataSelector(state.ndvi);
export const ndviGetSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviGetSelector(state.ndvi);
export const ndviFarmIdSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviFarmIdSelector(state.ndvi);
export const ndviActiveFarmSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviActiveFarmSelector(state.ndvi);
export const ndviPlanetActiveFarmSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviPlanetActiveFarmSelector(state.ndvi);
export const ndviSelectedDateSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviSelectedDateSelector(state.ndvi);
export const ndviImageTypeSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviImageTypeSelector(state.ndvi);
export const ndviPlanetSelectedDateSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviPlanetSelectedDateSelector(state.ndvi);
export const ndviPlanetImageTypeSelector = (state: Pick<RootState, 'ndvi'>) => NDVI.ndviPlanetImageTypeSelector(state.ndvi);
