import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {imageMapping} from '../containers/pages/SoilSignal/NDVI/utils';
import {getFarmImages} from '../store/actions';
import {IFarm} from '../store/reducers/farm';
import {farmGetImagesSelector, farmImagesSelector, selectedOrganizationSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useFarmNDWIImage(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();
  const farmImages = useSelector(farmImagesSelector);
  const {error, loading: loadingNdwi} = useSelector(farmGetImagesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevNdwiImages = usePrevious(farmImages);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  const productImages = farmImages ? farmImages.filter(image => image.product === 'NDWI') : [];
  const ndwiImages = productImages && productImages.length ? imageMapping(productImages) : [];

  useEffect(() => {
    if (activeFarm && !loadingNdwi && !ndwiImages && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDWI'));
    }
  }, [activeFarm, dispatch, endDate, loadingNdwi, ndwiImages, startDate, lazy]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDWI', selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, prevSelectedOrganization, selectedOrganization, startDate, lazy]);

  useEffect(() => {
    if (activeFarm && startDate === prevStartDate && endDate === prevEndDate && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDWI', selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, prevEndDate, prevStartDate, selectedOrganization, startDate, lazy]);

  const onGetNDWI = (activeFarm: IFarm) => {
    dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDWI', selectedOrganization.id));
  };

  return {
    loadingNdwi,
    error,
    ndwiImages,
    prevNdwiImages,
    onGetNDWI,
  };
}
