import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/general';

export const feedbackPostSelector = (state: GeneralState) => state.feedbackPost;

export interface GeneralState {
  currentLanguage: string;
  prevPath: string;
  feedbackPost: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: GeneralState = {
  currentLanguage: '',
  prevPath: '',
  feedbackPost: {
    loading: false,
    error: false,
  },
};

const reducer = (state: GeneralState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PREV_PATH:
      return updateObject(state, {
        prevPath: action.path,
      });
    case ActionTypes.UPDATE_CURRENT_LANGUAGE:
      return updateObject(state, {
        currentLanguage: action.language,
      });
    case ActionTypes.FEEDBACK_POST_START:
      return updateObject(state, {
        feedbackPost: {
          loading: true,
          error: false,
        },
      });
    case ActionTypes.FEEDBACK_POST_SUCCESS:
      return updateObject(state, {
        feedbackPost: {
          loading: false,
          error: false,
        },
      });
    case ActionTypes.FEEDBACK_POST_FAIL:
      return updateObject(state, {
        feedbackPost: {
          loading: false,
          error: action.error,
        },
      });
    default:
      return state;
  }
};

export default reducer;
