import {useDispatch, useSelector} from 'react-redux';
import {markerColorSelector} from '../store/selectors';
import {useEffect} from 'react';
import {getMarkerTypes} from '../store/actions/marker';

export const useMarkerColor = () => {
  const dispatch = useDispatch();
  const markerColors = useSelector(markerColorSelector);
  useEffect(() => {
    if (!markerColors) {
      dispatch(getMarkerTypes());
    }
  }, [dispatch, markerColors]);

  return {
    markerColors,
  };
};
