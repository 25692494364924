// Import necessary React hooks and components
import React, {useState} from 'react';
import {MenuItem, Select, Box, BoxProps, InputLabel, FormControl, SelectProps} from '@material-ui/core';
import styled from 'styled-components';
import {filesGetSelector, filesSelector, organizationsSelector} from '../../../store/selectors';
import theme from '../../../utilities/theme';
import {IFileManagerDTO} from '@deep-planet/api-interfaces';
import {some} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../Modal/Modal';
import {getOrgUserFarms} from '../../../utilities/parseFarm';
import {useFileManager} from '../../../hooks/useFileManager';
import {deleteFile, getFiles} from '../../../store/actions/file';
import FileTable from './FileTable';
import DatePeriodPicker from '../Pickers/DatePeriodPicker';
import Spinner from '../Spinner';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (params: IFileManagerDTO, files: ISelectedFile[]) => void;
  submitLoading: boolean;
  modalTitle?: string;
}

export interface ISelectedFile {
  name: string;
  type: string;
  size: number;
}
const StyledSelect = styled(Select)<SelectProps>`
  width: auto;
`;

const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 200px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const InputWrapperBoxDate = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 250px;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

const FileManagerModal = ({isOpen, handleClose, handleSubmit, submitLoading, modalTitle}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {selectedFarm, errors, selectedOrganization, getFilesForFarm, getFilteredFilesForUser, handleOrganizationSelection, handleFarmSelection} = useFileManager();
  const organizations = useSelector(organizationsSelector);
  const files = useSelector(filesSelector);
  const {loading: isLoading} = useSelector(filesGetSelector);
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 14)));
  const [toDate, setToDate] = useState<Date>(new Date());

  // get the files from the backend for selected organization and duration
  const onClickSubmit = async () => {
    const farmFiles = getFilesForFarm(files, selectedFarm, fromDate, toDate);
    if (!farmFiles?.length) {
      dispatch(getFiles(selectedOrganization, fromDate, toDate));
    }
  };
  // delete the file
  function handleDeleteFile(fileInfoId: string, fileId: string): void {
    dispatch(deleteFile(fileInfoId, fileId));
  }
  // get farms of selected organization
  const farms = getOrgUserFarms(organizations, selectedOrganization);
  // check if any error is found
  const isAnyError = some(errors, ({value}) => value);
  // filter files for the selected farm and date
  const filteredFiles = getFilteredFilesForUser(files, selectedFarm, fromDate, toDate);

  return (
    // Prompt modal dialog box with the below input components
    <Modal
      title={modalTitle || t('')}
      submitText={t('Fetch')}
      isOpen={isOpen}
      isSubmitButtonDisabled={submitLoading || isAnyError}
      isLoading={submitLoading}
      handleClose={handleClose}
      handleSubmit={onClickSubmit}
      modalWidth={'1050px'}
    >
      <form>
        <Box display="flex" flexWrap="wrap">
          <InputWrapperBox>
            {/* Display form controls for organization and farm selection */}
            <FormControl required fullWidth disabled={submitLoading}>
              <InputLabel id="organization-label" required error={errors.organization.value}>
                Organization
              </InputLabel>
              <StyledSelect required labelId="organization-label" value={selectedOrganization} name="organization" onChange={({target: {value}}) => handleOrganizationSelection(value as string)}>
                {organizations?.map(o => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </InputWrapperBox>
          <InputWrapperBox>
            {/* Wrapper for farm selection input */}
            <FormControl fullWidth disabled={submitLoading}>
              <InputLabel id="farm-label">{t('note.table.farm')}</InputLabel>
              <StyledSelect labelId="farm-label" value={selectedFarm || ''} name="farm" onChange={({target: {value}}) => handleFarmSelection(value as string)}>
                {farms &&
                  farms.map(({id, name}) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
              </StyledSelect>
            </FormControl>
          </InputWrapperBox>
          {/* render the date period picker */}
          <InputWrapperBoxDate>
            <DatePeriodPicker direction={'row'} disableToolbar={false} startDate={fromDate} endDate={toDate} handleStartDateChange={setFromDate} handleEndDateChange={setToDate} disableFuture={true} />
          </InputWrapperBoxDate>
        </Box>
        {/* If file is getting deleted, show a spinner */}
        {isLoading && <Spinner size={30} color="primary" style={{margin: '0.5rem auto'}} />}
        {/*render the file table */}
        {filteredFiles && <FileTable files={filteredFiles} handleDeleteFile={handleDeleteFile} />}
      </form>
    </Modal>
  );
};

export default FileManagerModal;
