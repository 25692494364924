/* eslint-disable no-case-declarations */
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/user';
import {IUserPreferenceEntity} from '@deep-planet/api-interfaces';

export const userDetailsSelector = (state: UserState): IUserDetails => state.userDetails;
export const getUserSelector = (state: UserState) => state.getUser;
export const putUserSelector = (state: UserState) => state.putUser;
export const getUsersSelector = (state: UserState) => state.getUsers;
export const userNamesSelector = (state: UserState) => state.userNames;
export const noteNotificationSelector = (state: UserState) => {
  if (state.userDetails?.preferences) {
    const preference = state.userDetails?.preferences.find(p => p.type.name === 'NOTE_CREATION_NOTIFICATION');
    return preference ? Boolean(Number(preference.value)) : false;
  }
  return false;
};
export const fileNotificationSelector = (state: UserState) => {
  if (state.userDetails?.preferences) {
    const preference = state.userDetails?.preferences.find(p => p.type.name === 'FILE_CREATION_NOTIFICATION');
    return preference ? Boolean(Number(preference.value)) : false;
  }
  return false;
};
export const putUserPreferencesSelector = (state: UserState) => state.putUserPreferences;
export const postUserSelector = (state: UserState) => state.postUser;

export interface IUserDetails {
  userid: string;
  firstName: string;
  lastName: string;
  address: string;
  activeFarm: string;
  preferences: IUserPreferenceEntity[];
}

export interface UserState {
  userDetails: IUserDetails | null;
  getUser: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  postUser: {
    success: boolean;
    error: any;
    loading: boolean;
  };
  putUser: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  putUserPreferences: {
    loading: boolean;
    error: unknown;
  };
  getUsers: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  userNames: string[];
}

const initialState: UserState = {
  userDetails: null,
  getUser: {
    success: false,
    error: null,
    loading: false,
  },
  postUser: {
    success: false,
    error: null,
    loading: false,
  },
  putUser: {
    success: false,
    error: null,
    loading: false,
  },
  putUserPreferences: {
    loading: false,
    error: null,
  },
  getUsers: {
    success: false,
    error: null,
    loading: false,
  },
  userNames: null,
};

const userReducer = (state: UserState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.USER_GET_START:
      return updateObject<UserState>(state, {
        getUser: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.USER_GET_SUCCESS:
      return updateObject(state, {
        userDetails: {...action.payload},
        getUser: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.USER_GET_FAIL:
      return updateObject(state, {
        getUser: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.USER_POST_INIT:
      return updateObject(state, {
        postUser: {
          success: false,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.USER_POST_START:
      return updateObject(state, {
        postUser: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.USER_POST_SUCCESS:
      return updateObject(state, {
        postUser: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.USER_POST_FAIL:
      return updateObject(state, {
        postUser: {
          success: false,
          error: action.error.response.data,
          loading: false,
        },
      });
    case ActionTypes.USER_PUT_START:
      return updateObject(state, {
        putUser: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.USER_PUT_SUCCESS:
      return updateObject(state, {
        userDetails: {...state.userDetails, ...action.payload},
        putUser: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.USER_PUT_FAIL:
      return updateObject(state, {
        putUser: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.USERS_GET_START:
      return updateObject(state, {
        getUsers: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.USERS_GET_SUCCESS:
      return updateObject(state, {
        getUsers: {
          success: true,
          error: null,
          loading: false,
        },
        userNames: action.payload,
      });
    case ActionTypes.USERS_GET_FAIL:
      return updateObject(state, {
        getUsers: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.USER_PUT_PREFERENCES_START:
      return updateObject(state, {
        putUserPreferences: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.USER_PUT_PREFERENCES_SUCCESS:
      const currentPreferenceIds = state.userDetails.preferences.map(({id}) => id);
      const updatedPreferenceIds = action.updatedPreferences.map(({id}) => id);

      const newPreference = action.updatedPreferences.filter(({id}) => !currentPreferenceIds.includes(id));
      const updatedPrevious = state.userDetails.preferences.filter(({id}) => updatedPreferenceIds.includes(id));
      const notUpdatedPrevious = state.userDetails.preferences.filter(({id}) => !updatedPreferenceIds.includes(id));

      return updateObject(state, {
        putUserPreferences: {
          loading: false,
          error: null,
        },
        userDetails: {
          ...state.userDetails,
          preferences: [
            ...notUpdatedPrevious,
            ...newPreference,
            ...updatedPrevious.map(up => {
              const updatedPreference = action.updatedPreferences.find(({id}) => id === up.id);
              return {
                ...up,
                ...updatedPreference,
              };
            }),
          ],
        },
      });
    case ActionTypes.USER_PUT_PREFERENCES_FAIL:
      return updateObject(state, {
        putUserPreferences: {
          loading: false,
          error: action.error,
        },
      });

    default:
      return state;
  }
};

export default userReducer;
