import {IUpdateUser} from '@deep-planet/api-interfaces';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../../components/UI/Spinner';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IUserDetails} from '../../../store/reducers/user';
import {IFarm} from '../../../store/reducers/farm';
import {usePrevious} from '../../../hooks/usePrevious';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Btn = styled(Button)`
  && {
    margin-top: 1rem;
  }
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 2rem;
  }
`;

const Error = styled(Typography)`
  && {
    margin-top: 0.5rem;
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const SetupLink = styled(Typography)`
  color: ${({theme}) => theme.palette.primary.main};
  text-decoration: underline;
`;

interface Props {
  getUserDetailsLoading: boolean;
  putUserDetailsLoading: boolean;
  onPutUserDetails: (payload: IUpdateUser) => void;
  allFarms: IFarm[];
  userDetails: IUserDetails;
}

const PersonalInformation = ({getUserDetailsLoading, putUserDetailsLoading, allFarms, onPutUserDetails, userDetails}: Props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [activeFarmId, setActiveFarmId] = useState('');
  const [error, setError] = useState('');
  const {t} = useTranslation();
  const prevUserDetails = usePrevious(userDetails);

  const validatePayload = (payload: IUpdateUser) => {
    if ((payload.firstName && payload.firstName.length < 1) || (payload.lastName && payload.lastName.length < 1) || (payload.address && payload.address.length) < 1) {
      setError(t('account.personal.information.mandatory'));
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = (e, payload: IUpdateUser) => {
    e.preventDefault();
    if (validatePayload(payload)) {
      onPutUserDetails(payload);
      return '';
    }
  };

  useEffect(() => {
    if (prevUserDetails !== userDetails) {
      setFirstName(userDetails?.firstName);
      setLastName(userDetails?.lastName);
      setAddress(userDetails?.address);
      setActiveFarmId(userDetails?.activeFarm);
    }
  }, [userDetails, prevUserDetails]);

  const payload: IUpdateUser = {
    firstName,
    lastName,
    address,
    activeFarmId,
  };

  const sortFarmsByName = (farm1, farm2) => {
    if (farm1.name < farm2.name) return -1;
    if (farm1.name > farm2.name) return 1;
    return 0;
  };

  return (
    <Form onSubmit={e => handleSubmit(e, payload)}>
      <Title variant="body1">{t('account.personal.information.description')}</Title>
      <TextField
        id="firstName"
        name="firstName"
        label={t('account.personal.information.first.name')}
        value={firstName || ''}
        onChange={({target: {value}}) => setFirstName(value)}
        margin="normal"
        variant="outlined"
        style={{marginTop: 0}}
      />
      <TextField
        id="lastName"
        name="lastName"
        label={t('account.personal.information.last.name')}
        value={lastName || ''}
        onChange={({target: {value}}) => setLastName(value)}
        margin="normal"
        variant="outlined"
        style={{marginBottom: '1rem'}}
      />
      <TextField
        id="address"
        name="address"
        label={t('account.personal.information.address')}
        value={address || ''}
        onChange={({target: {value}}) => setAddress(value)}
        margin="normal"
        variant="outlined"
        style={{marginTop: 0}}
      />

      {!activeFarmId && !getUserDetailsLoading && (
        <Box>
          <Typography variant="body1">{t('account.personal.information.no.farm')}</Typography>
          <SetupLink variant="body1">
            <Link to="/settings">{t('account.personal.information.setup.farm')}</Link>
          </SetupLink>
        </Box>
      )}

      {activeFarmId && !getUserDetailsLoading && (
        <TextField
          id="activeFarm"
          name="activeFarm"
          select
          label={t('account.personal.information.default.farm')}
          value={activeFarmId || ''}
          onChange={({target: {value}}) => setActiveFarmId(value)}
          margin="normal"
          variant="outlined"
        >
          {!!allFarms &&
            allFarms.sort(sortFarmsByName).map(farm => {
              return (
                <MenuItem key={farm.farmid} value={farm.farmid || ''}>
                  {farm.name}
                </MenuItem>
              );
            })}
        </TextField>
      )}

      {getUserDetailsLoading ? (
        <Spinner size={30} color="primary" style={{margin: '0.5rem auto'}} />
      ) : (
        <Btn variant="contained" color="primary" type="submit" disabled={putUserDetailsLoading}>
          {t('account.personal.information.save')}
          {putUserDetailsLoading && <Spinner size={15} color="primary" />}
        </Btn>
      )}
      {error && <Error variant="body1">{error}</Error>}
    </Form>
  );
};

export default PersonalInformation;
