import {CreateFeedbackDTO, IFeedbackEntity} from '@deep-planet/api-interfaces';
import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {ISelectedFile} from '../../containers/pages/Notes/withEdit';
import ActionTypes from './actionTypes';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {getFormData, uploadFile} from './utils';

///////////////////////////////
// Update previous path
///////////////////////////////

interface IUpdatePrevPath {
  type: ActionTypes.UPDATE_PREV_PATH;
  path: string;
}

export const updatePrevPath = (path: string): IUpdatePrevPath => ({
  type: ActionTypes.UPDATE_PREV_PATH,
  path,
});

///////////////////////////////
// Update language
///////////////////////////////

interface IUpdateCurrentLanguage {
  type: ActionTypes.UPDATE_CURRENT_LANGUAGE;
  language: string;
}

export const updateCurrentLanguage = (language: string): IUpdateCurrentLanguage => ({
  type: ActionTypes.UPDATE_CURRENT_LANGUAGE,
  language,
});

///////////////////////////////
// Send feedback
///////////////////////////////

interface IFeedbackPostStart {
  type: ActionTypes.FEEDBACK_POST_START;
}

const feedbackPostStart = (): IFeedbackPostStart => ({
  type: ActionTypes.FEEDBACK_POST_START,
});

interface IFeedbackPostSuccess {
  type: ActionTypes.FEEDBACK_POST_SUCCESS;
}

const feedbackPostSuccess = (): IFeedbackPostSuccess => ({
  type: ActionTypes.FEEDBACK_POST_SUCCESS,
});

interface IFeedbackPostFail {
  type: ActionTypes.FEEDBACK_POST_FAIL;
  error: unknown;
}

const feedbackPostFail = (error: unknown): IFeedbackPostFail => ({
  type: ActionTypes.FEEDBACK_POST_FAIL,
  error,
});

export const createFeedback = (params: CreateFeedbackDTO, files: ISelectedFile[], closeModal: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    try {
      dispatch(feedbackPostStart());
      const getPreSignedUrl = `${baseApiUrl}/feedback/upload-url`;
      const formDataArray = await Promise.all(
        files.map(async file =>
          getFormData(
            {
              name: file.name,
              contentType: file.type,
            },
            file,
            getPreSignedUrl
          )
        )
      );
      await Promise.all(formDataArray.map(({bucketUrl, formData}) => uploadFile(bucketUrl, formData)));
      params.attachments = formDataArray.map(({name, url, size, uploadedAt}) => ({name, url, size, uploadedAt}));
      await axios.post<IFeedbackEntity>(`${baseApiUrl}/feedback`, params);

      dispatch(feedbackPostSuccess());
      dispatch(enqueueSnackbar({message: i18n.t('feedback.created'), options: {variant: 'success'}}));
      closeModal();
    } catch (e) {
      dispatch(feedbackPostFail(e));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

export type Action =
  | ReturnType<typeof updatePrevPath>
  | ReturnType<typeof updateCurrentLanguage>
  | ReturnType<typeof feedbackPostStart>
  | ReturnType<typeof feedbackPostSuccess>
  | ReturnType<typeof feedbackPostFail>;
