import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/polygon';

export const polygonPostSuccessSelector = (state: PolygonState) => state.polygonPost.success;

export interface PolygonState {
  polygons: any[];
  polygonGet: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  polygonPost: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  polygonDelete: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  polygonPut: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  farmCenter: any;
}

const initialState: PolygonState = {
  polygons: [],
  polygonGet: {
    success: null,
    error: null,
    loading: false,
  },
  polygonPost: {
    success: null,
    error: null,
    loading: false,
  },
  polygonDelete: {
    success: null,
    error: null,
    loading: false,
  },
  polygonPut: {
    success: null,
    error: null,
    loading: false,
  },
  farmCenter: null,
};

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.POLYGON_GET_START:
      return updateObject(state, {
        polygonGet: {
          success: null,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.POLYGON_GET_SUCCESS:
      return updateObject(state, {
        farmCenter: action.farmCenter === false ? {lat: 43.47614, lng: 4.67668} : action.farmCenter,
        polygons: action.polyData,
        polygonGet: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_GET_FAIL:
      return updateObject(state, {
        polygonGet: {
          success: null,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_POST_START:
      return updateObject(state, {
        polygonPost: {
          success: null,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.POLYGON_POST_SUCCESS:
      return updateObject(state, {
        polygons: state.polygons.concat(action.polyData),
        polygonPost: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_POST_FAIL:
      return updateObject(state, {
        polygonPost: {
          success: null,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_DELETE_START:
      return updateObject(state, {
        polygonDelete: {
          success: null,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.POLYGON_DELETE_SUCCESS:
      return updateObject(state, {
        polygons: state.polygons.filter(item => item.polygonid !== action.polyId),
        polygonDelete: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_DELETE_FAIL:
      return updateObject(state, {
        polygonDelete: {
          success: null,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_PUT_START:
      return updateObject(state, {
        polygonPut: {
          success: null,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.POLYGON_PUT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedItems = state.polygons.map(item => {
        if (item.polygonid === action.polyId) {
          return {...item, ...action.payload};
        }
        return item;
      });
      return updateObject(state, {
        polygons: updatedItems,
        polygonPut: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.POLYGON_PUT_FAIL:
      return updateObject(state, {
        polygonPut: {
          success: null,
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
