import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {PasswordReset} from '@deep-planet/react-cognito';
import PasswordResetForm from './Form';
import AuthSplash from '../../UI/AuthSplash';
import {ButtonAlt} from '../../UI/Form';
import {withTranslation} from 'react-i18next';

class ForgotPassword extends Component {
  render() {
    const {t} = this.props;
    return (
      <AuthSplash>
        <PasswordReset>
          <PasswordResetForm />
        </PasswordReset>
        <ButtonAlt>
          <Link to="/login">{t('forms.sign.in')}</Link>
        </ButtonAlt>
      </AuthSplash>
    );
  }
}

export default withTranslation()(ForgotPassword);
