import {INutrientTypeImage, ISoilNutrientImagesUI} from '@deep-planet/api-interfaces';

export const POTASSIUM = 'potassium';
export const MAGNESIUM = 'magnesium';
export const PHOSPHORUS = 'phosphorus';
export const NITROGEN = 'nitrogen';

export const getNutrientSpecificImages = (images, nutrientType): ISoilNutrientImagesUI[] => {
  return images?.map(s => {
    return {
      date: s.date,
      farmid: s.farmid,
      images: s.images.map(i => {
        return {
          polygonid: i?.polygonid,
          boundBox: i?.boundBox,
          ...transformToNutrientSpecific(images[nutrientType]),
        };
      }),
    };
  });
};

export const transformToNutrientSpecific = i => {
  return {
    imageAverageUrl: i?.avg_url,
    imagePredictedUrl: i?.pred_url,
    imagePreviousUrl: i?.prev_url,
    imageUrl: i?.curr_url,
    currThresholdLow: i?.curr_th_low,
    currThresholdHigh: i?.curr_th_high,
    prevThresholdLow: i?.prev_th_low,
    prevThresholdHigh: i?.prev_th_high,
    avgThresholdLow: i?.avg_th_low,
    avgThresholdHigh: i?.avg_th_high,
  };
};

export const getSoilNutreintsThresholds = (images: INutrientTypeImage[], polygonId: string, nutrientType: string, type: string) => {
  try {
    const values = images.find(p => p.polygonid === polygonId)[nutrientType];
    return getImageTypeThresholds(values, type);
  } catch (e) {
    return {thresholdLow: 0, thresholdHigh: 0};
  }
};

export const getThresholds = (images, polygonId: string, type: string) => {
  try {
    const values = images.find(p => p.polygonid === polygonId);
    return getImageTypeThresholds(values, type);
  } catch (e) {
    return {thresholdLow: 0, thresholdHigh: 0};
  }
};

const getImageTypeThresholds = (values, type) => {
  switch (type) {
    case 'current':
      return {thresholdLow: values.curr_th_low, thresholdHigh: values.curr_th_high};
    case 'previous':
      return {thresholdLow: values.prev_th_low, thresholdHigh: values.prev_th_high};
    case 'average':
      return {thresholdLow: values.avg_th_low, thresholdHigh: values.avg_th_high};
    case 'predicted':
      return {thresholdLow: values.pred_th_low, thresholdHigh: values.pred_th_high};
    case 'CURRENT':
      return {thresholdLow: values.current_th_low, thresholdHigh: values.current_th_high};
    case 'BASELINE':
      return {thresholdLow: values.baseline_th_low, thresholdHigh: values.baseline_th_high};
    case '1Y':
      return {thresholdLow: values.oneYear_th_low, thresholdHigh: values.oneYear_th_high};
    case '3Y':
      return {thresholdLow: values.threeYears_th_low, thresholdHigh: values.threeYears_th_high};
    case '5Y':
      return {thresholdLow: values.fiveYears_th_low, thresholdHigh: values.fiveYears_th_high};
    default:
      return {thresholdLow: 0, thresholdHigh: 0};
  }
};
