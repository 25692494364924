import {Box, Button, FormControl, IconButton, InputAdornment, MenuItem, Popover, TextField} from '@material-ui/core';
import {Clear as ClearIcon, Done as DoneIcon} from '@material-ui/icons';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {usePrevious} from '../../../hooks/usePrevious';
import theme from '../../../utilities/theme';
import FilterListIcon from '@material-ui/icons/FilterList';
import {FilterValue} from './filter-utils';

const StyledIconButton = styled(IconButton)`
  &&:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const StyledFilterListIcon = styled(FilterListIcon)<{$active: boolean}>`
  && {
    fill: ${p => (p.$active ? theme.palette.info.dark : theme.palette.grey[500])};
    border-radius: 50%;
  }
`;

const ListWrapper = styled(Box)`
  && {
    margin: 1rem;
    overflow-y: scroll;
    max-height: 300px;
  }
`;

interface Props {
  columnId: string;
  values: FilterValue[];
  handleToggleSelection: (label: string, columnId: string, selected: boolean) => void;
  handleClearAll: (columnId: string) => void;
  handleSelectAll: (columnId: string) => void;
}

const TableFilterButton = ({values, handleToggleSelection, columnId, handleClearAll, handleSelectAll}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const prevAnchorEl = usePrevious(anchorEl);
  const {t} = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (label: string, selected: boolean) => {
    handleToggleSelection(label, columnId, selected);
  };

  const handleSearchTermChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const getFilteredValueLabels = (originalValues: FilterValue[]): FilterValue[] => {
    return searchTerm
      ? values.filter(({label}) =>
          searchTerm
            .toLowerCase()
            .split(' ')
            .filter(Boolean)
            .some(newSearchTerm => label.toLowerCase().includes(newSearchTerm.toLowerCase()))
        )
      : originalValues;
  };

  const handleDeleteSearchTerm = () => {
    setSearchTerm('');
  };

  const clickOnClearAll = () => {
    setSearchTerm('');
    handleClearAll(columnId);
  };

  const clickOnSelectAll = () => {
    setSearchTerm('');
    handleSelectAll(columnId);
  };

  useEffect(() => {
    if (Boolean(prevAnchorEl) && !anchorEl) {
      setSearchTerm('');
    }
  }, [anchorEl, prevAnchorEl]);

  const active = !values?.every(value => value.selected);
  return (
    <>
      <StyledIconButton onClick={handleClick} disabled={!values?.length}>
        <StyledFilterListIcon $active={active} />
      </StyledIconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box display="flex" padding="1rem">
          <TextField
            fullWidth
            onChange={handleSearchTermChange}
            placeholder={t('table.filter.search')}
            variant="outlined"
            value={searchTerm}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon style={{cursor: 'pointer'}} onClick={handleDeleteSearchTerm} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <ListWrapper>
          <FormControl fullWidth>
            {getFilteredValueLabels(values)?.map(({label, selected}) => (
              <MenuItem key={label} value={label} onClick={() => handleSelection(label, !selected)}>
                <Box display="flex">
                  {selected ? <DoneIcon /> : <div style={{width: '24px', height: '24px'}} />}
                  {label}
                </Box>
              </MenuItem>
            ))}
          </FormControl>
        </ListWrapper>
        <Box display="flex" padding="1rem">
          <Button variant="contained" color="primary" onClick={clickOnSelectAll} style={{marginRight: '1rem'}}>
            {t('table.filter.select.all')}
          </Button>
          <Button variant="contained" color="primary" onClick={clickOnClearAll}>
            {t('table.filter.clear.all')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default TableFilterButton;
