import {ICurrentWeather} from '@deep-planet/api-interfaces';
import React from 'react';
import styled from 'styled-components';
import {Paper, Typography} from '@material-ui/core';
import WeatherIcon from './WeatherIcon';
import {useTranslation} from 'react-i18next';
import theme from '../../../utilities/theme';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const Root = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
  min-width: 250px;
`;

const Temp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem 0;

  svg {
    width: 40px;
    height: 40px;
    fill: ${() => theme.palette.primary.main};
    margin-right: 1rem;
  }
`;

const LargeDegree = styled(Typography)`
  && {
    font-size: 180%;
    color: ${() => theme.palette.primary.main};
  }
`;

const WeatherText = styled(Typography)`
  && {
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  currentWeather: ICurrentWeather;
}

const Temperature = ({currentWeather}: Props) => {
  const {t} = useTranslation();
  const {temperatureUnit, convertTemperature} = useMeasurementConversion();
  return (
    <Root>
      <Typography variant="h6">{t('weather.current')}</Typography>
      {!!currentWeather && (
        <Box>
          <Temp>
            <WeatherIcon icon={currentWeather.weatherIcon} />
            <LargeDegree variant="body1">
              <strong>
                {convertTemperature(currentWeather.temperature.metric.value)} °{temperatureUnit[0].toUpperCase()}
              </strong>
            </LargeDegree>
          </Temp>
          <WeatherText variant="body1">{currentWeather.weatherText}</WeatherText>
        </Box>
      )}
    </Root>
  );
};

export default Temperature;
