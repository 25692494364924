import * as Farm from '../reducers/farm';
import {RootState} from '../reducers';

export const allFarmsSelector = (state: Pick<RootState, 'farm'>) => Farm.allFarmsSelector(state.farm);
export const farmGetSelector = (state: Pick<RootState, 'farm'>) => Farm.farmGetSelector(state.farm);
export const farmDeleteSelector = (state: Pick<RootState, 'farm'>) => Farm.farmDeleteSelector(state.farm);
export const farmPutSelector = (state: Pick<RootState, 'farm'>) => Farm.farmPutSelector(state.farm);
export const soilValuesSelector = (state: Pick<RootState, 'farm'>) => Farm.soilValuesSelector(state.farm);
export const farmGetSoilSelector = (state: Pick<RootState, 'farm'>) => Farm.farmGetSoilSelector(state.farm);
export const farmImagesSelector = (state: Pick<RootState, 'farm'>) => Farm.farmImagesSelector(state.farm);
export const farmGetImagesSelector = (state: Pick<RootState, 'farm'>) => Farm.farmGetImagesSelector(state.farm);
export const activeFarmIdSelector = (state: Pick<RootState, 'farm'>) => Farm.activeFarmIdSelector(state.farm);
export const farmGetSoilsSelector = (state: Pick<RootState, 'farm'>) => Farm.farmGetSoilsSelector(state.farm);
export const allSoilValuesSelector = (state: Pick<RootState, 'farm'>) => Farm.allSoilValuesSelector(state.farm);
export const seasonsSelector = (state: Pick<RootState, 'farm'>) => Farm.seasonsSelector(state.farm);
export const seasonsGetSelector = (state: Pick<RootState, 'farm'>) => Farm.seasonsGetSelector(state.farm);
export const seasonPostSelector = (state: Pick<RootState, 'farm'>) => Farm.seasonPostSelector(state.farm);
