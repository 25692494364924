import {IIrrigationImageResDTO, IIrrigationImagesUI} from '@deep-planet/api-interfaces';
import i18n from 'apps/web-portal-ui/src/i18n';
import axios from 'axios';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {Dispatch} from 'redux';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';

interface IIrrigationFarmGetStart {
  type: ActionTypes.IRRIGATION_FARM_GET_START;
}

export const irrigationFarmGetStart = (): IIrrigationFarmGetStart => ({
  type: ActionTypes.IRRIGATION_FARM_GET_START,
});

interface IIrrigationFarmGetFail {
  type: ActionTypes.IRRIGATION_FARM_GET_FAIL;
  error: unknown;
}

export const irrigationFarmGetFail = (error): IIrrigationFarmGetFail => ({
  type: ActionTypes.IRRIGATION_FARM_GET_FAIL,
  error,
});

interface IIrrigationFarmGetSuccess {
  type: ActionTypes.IRRIGATION_FARM_GET_SUCCESS;
  irrigationFarms: IFarm[];
  activeFarm: IFarm;
}

export const irrigationFarmGetSuccess = (irrigationFarms: IFarm[], activeFarm: IFarm): IIrrigationFarmGetSuccess => ({
  type: ActionTypes.IRRIGATION_FARM_GET_SUCCESS,
  irrigationFarms,
  activeFarm,
});

interface IIrrigationImagesGetStart {
  type: ActionTypes.IRRIGATION_IMAGE_GET_START;
}

export const irrigationImagesGetStart = (): IIrrigationImagesGetStart => ({
  type: ActionTypes.IRRIGATION_IMAGE_GET_START,
});

interface IIrrigationImagesGetFail {
  type: ActionTypes.IRRIGATION_IMAGE_GET_FAIL;
  error: unknown;
}

export const irrigationImagesGetFail = (error): IIrrigationImagesGetFail => ({
  type: ActionTypes.IRRIGATION_IMAGE_GET_FAIL,
  error,
});

interface IIrrigationImagesGetSuccess {
  type: ActionTypes.IRRIGATION_IMAGE_GET_SUCCESS;
  irrigationImages: IIrrigationImageResDTO[];
  activeFarmImageId: string;
  activeFarm: IFarm;
  irrigationTypeImages?: IIrrigationImagesUI[];
}
export const irrigationImagesGetSuccess = (
  irrigationImages: IIrrigationImageResDTO[],
  activeFarmImageId: string,
  activeFarm: IFarm,
  irrigationTypeImages?: IIrrigationImagesUI[]
): IIrrigationImagesGetSuccess => ({
  type: ActionTypes.IRRIGATION_IMAGE_GET_SUCCESS,
  irrigationImages,
  activeFarmImageId,
  activeFarm,
  irrigationTypeImages,
});

interface IIrrigationSetSelectedDate {
  type: ActionTypes.IRRIGATION_SET_SELECTED_DATE;
  selectedDate: Date;
}

export const irrigationSetSelectedDate = (selectedDate: Date): IIrrigationSetSelectedDate => ({
  type: ActionTypes.IRRIGATION_SET_SELECTED_DATE,
  selectedDate,
});

export const getIrrigationImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(irrigationImagesGetStart());
    const url = `${baseApiUrl}/irrigation/images/${farm.farmid}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<IIrrigationImageResDTO[]>(url);
      if (data.length) {
        const irrigationImages = data.map(d => ({
          ...d,
          images: d.images.map(i => {
            const irrigationHistorical = i.irrigationHistorical.sort((a, b) => (a.date > b.date ? 1 : -1));
            const irrigationPrediction = i.irrigationPrediction.sort((a, b) => (a.futureDate > b.futureDate ? 1 : -1));
            return {...i, irrigationPrediction, irrigationHistorical};
          }),
        }));
        dispatch(irrigationImagesGetSuccess(irrigationImages, farm.id, farm));
      } else {
        dispatch(enqueueSnackbar({message: `${i18n.t('error.irrigation.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
        dispatch(irrigationImagesGetFail('not found'));
        // display empty map on failure
        dispatch(irrigationImagesGetSuccess([], farm.id, farm));
      }
      dispatch(farmIdUpdate(farm.id)); // global active farm
    } catch (err) {
      dispatch(enqueueSnackbar({message: `${i18n.t('error.irrigation.not.found')}  ${farm.name}`, options: {variant: 'info'}}));
      dispatch(irrigationImagesGetFail(err));
    }
  };
};
export const getIrrigationFarms = (allFarms: IFarm[]) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(irrigationFarmGetStart());
    const url = `${baseApiUrl}/farm-settings/options?portal=true`;
    try {
      const {data} = await axios.get(url);
      const farmIds = data.map(({farmId}) => farmId);
      const irrigationFarms = allFarms.filter(snf => farmIds.includes(snf.id));
      dispatch(irrigationFarmGetSuccess(irrigationFarms, irrigationFarms[0]));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(irrigationFarmGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof irrigationFarmGetStart>
  | ReturnType<typeof irrigationFarmGetSuccess>
  | ReturnType<typeof irrigationFarmGetFail>
  | ReturnType<typeof irrigationImagesGetStart>
  | ReturnType<typeof irrigationImagesGetFail>
  | ReturnType<typeof irrigationImagesGetSuccess>
  | ReturnType<typeof irrigationSetSelectedDate>
  | ReturnType<typeof farmIdUpdate>;
