// Import necessary dependencies and components from Material UI and React
import React, {useState} from 'react';
import {Box, Button, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, makeStyles} from '@material-ui/core';
import FileManagerIcon from '@material-ui/icons/InsertDriveFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FilesIcon from '@material-ui/icons/InsertDriveFile';
import {useTranslation} from 'react-i18next';

// Define the interface for the component's props
interface Props {
  handleFileUpload: () => void;
  handleFileManager: () => void;
}

// Export the FileManagerButton component
export const FileManagerButton = ({handleFileUpload, handleFileManager}: Props) => {
  // Initialize state for the dropdown menu
  const [isOpen, setISOpen] = useState(false);

  const {t} = useTranslation();

  // Initialize the useStyles hook with the theme
  const useStyles = makeStyles(theme => ({
    fileManager: {
      position: 'relative',
      width: 164,
      height: 40,
      backgroundColor: 'white',
      borderRadius: '2.5px',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 5,
      top: '10px',
      left: '184px',
      '& hr': {
        mx: 0,
      },
      [theme.breakpoints.down('sm')]: {
        left: '206px',
      },
    },
    listStyle: {
      zIndex: 5,
      width: 164,
      backgroundColor: theme.palette.background.paper,
      left: '184px',
      position: 'relative',
      borderRadius: '2px',
      top: '12px',
      [theme.breakpoints.down('sm')]: {
        left: '206px',
      },
    },
  }));

  // Get the classes from the useStyles hook
  const classes = useStyles();

  // Define the handleOnClick function to toggle the dropdown menu
  const handleOnClick = () => {
    setISOpen(!isOpen);
  };

  // Return the component's JSX
  return (
    <>
      {/* Render the File Manager button */}
      <Box display="flex" flexDirection="row" className={classes.fileManager} boxShadow={1} onClick={handleOnClick}>
        <Tooltip title={t('Manage your files here')} placement={'right'} arrow>
          <Button aria-label={t('File Manager')} style={{color: 'secondary', justifyContent: 'space-between'}}>
            <FileManagerIcon color="primary" fontSize="default" />
            <Typography variant="body1" style={{marginTop: 2, marginLeft: '10px', textTransform: 'capitalize'}}>
              {t('File Manager').toLowerCase()}
            </Typography>
          </Button>
        </Tooltip>
      </Box>

      {/* Render the dropdown menu if isOpen is true */}
      {isOpen && (
        <Box className={classes.listStyle} display={'grid'}>
          <List dense={true}>
            <ListItem button onClick={handleFileUpload}>
              <ListItemIcon>
                <CloudUploadIcon color="secondary" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="body2" noWrap>
                  File Upload
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem button onClick={handleFileManager}>
              <ListItemIcon>
                <FilesIcon color="secondary" />
              </ListItemIcon>
              <ListItemText disableTypography>
                <Typography variant="body2" noWrap>
                  File Manager
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      )}
    </>
  );
};
