import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Box, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Typography} from '@material-ui/core';
import styled from 'styled-components';
import axios from 'axios';
import {capitalize, sortBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import {INotificationEntity, IOrganizationEntity} from '@deep-planet/api-interfaces';
import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';
import {useHttp} from 'apps/web-portal-ui/src/app/hooks/http';
import {soilSignalRoutes, vineSignalRoutes} from 'apps/web-portal-ui/src/app/utilities/routes';
import Spinner from '../../../../components/UI/Spinner';
import {baseApiUrl} from '../../../../config/const';
import {enqueueSnackbar} from '../../../../store/actions';
import theme from '../../../../utilities/theme';
import {IOrganizationToUserEntity} from './../../../../../../../../libs/api-interfaces/src/lib/organization.type';
import {withUser, WithUserProps} from 'apps/web-portal-ui/src/app/hooks/useAuth';
import {getUserGroups} from '../../../authHOC';
import {Email, EmailOutlined} from '@material-ui/icons';
import {enable} from '@deep-planet/react-cognito';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const CreateNotification = ({user}: WithUserProps) => {
  const groups = getUserGroups(user);
  const [isLoadingOrganizations, organizations, setOrganizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>();
  const [selectedUsers, setSelectedUsers] = useState<IOrganizationToUserEntity[]>();
  const [notificationDescription, setNotificationDescription] = useState<string>();
  const [notificationHeader, setNotificationHeader] = useState<string>();
  const [status, setStatus] = useState<string>(`DRAFT`);
  const [path, setPath] = useState<string>();
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  // get routes base on user subscription and add to the links drop-down list
  const vineSignalIncluded = Object.entries(vineSignalRoutes).filter(key => groups && groups.includes(vineSignalRoutes[key[0]].role) && !vineSignalRoutes[key[0]].doNotDisplayInAccordion);
  const soilSignalIncluded = Object.entries(soilSignalRoutes).filter(key => groups && groups.includes('SOILSIGNAL') && groups.includes(soilSignalRoutes[key[0]].role));

  const routes = [
    ...Object.entries(vineSignalIncluded).map(s => {
      return {title: 'Vine-Signal-' + s[1][0], link: s[1][1].slug};
    }),
    ...Object.entries(soilSignalIncluded).map(s => {
      return {title: 'Soil-Signal-' + s[1][0], link: s[1][1].slug};
    }),
  ];

  const handleOrganizationSelect = (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
  };

  // set URL path
  const handlePathSelection = path => {
    setPath(path.link);
  };

  // set selected users
  const handleUsersSelect = orgToUsers => {
    setSelectedUsers(orgToUsers);
  };

  // set visibility of type private:draft and public:publish
  const handleStatus = e => {
    setStatus(e.target.value);
  };

  const handleEmail = e => {
    setSendEmail(e?.target?.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const notification = {
        organizationId: selectedOrganization?.id,
        toUser: selectedUsers?.map(({user}) => user.id),
        header: capitalize(notificationHeader),
        description: capitalize(notificationDescription),
        status: status,
        url: path,
        sendEmail: status === 'PUBLISH' ? sendEmail : false,
      };
      setLoading(true);
      const {data} = await axios.post<INotificationEntity>(`${baseApiUrl}/notifications`, notification);
      dispatch(enqueueSnackbar({message: 'notification successfully created', options: {variant: 'success'}}));
      // reset header, description and visibility after successful notification
      setNotificationHeader('');
      setNotificationDescription('');
      setStatus('DRAFT');
      setSendEmail(false);
    } catch (e) {
      console.warn(e);
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  const isLoading = isLoadingOrganizations;
  return (
    <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
      {isLoading && <MapOverlay />}
      <Typography variant="h3" align="center" gutterBottom>
        Create Notification
      </Typography>
      <FormWrapper padding="2rem 0">
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              options={sortBy(organizations, o => o.name.toLowerCase())}
              onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
              getOptionLabel={({name}) => name}
              renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
            />
          </FormControl>

          {selectedOrganization && (
            <Box padding="1rem 0">
              <FormControl fullWidth variant="outlined">
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  options={sortBy(selectedOrganization.organizationToUsers, ({user}) => user.username)}
                  onChange={(e, org) => handleUsersSelect(org as IOrganizationToUserEntity[])}
                  getOptionLabel={({user}) => user.username}
                  renderInput={params => <TextField {...params} label="select users" variant="outlined" />}
                />
              </FormControl>
            </Box>
          )}

          <FormControl fullWidth>
            <TextField
              fullWidth
              id="notificationHeader"
              name="notificationHeader"
              value={notificationHeader}
              placeholder={'Notification Header'}
              margin="normal"
              variant="outlined"
              onChange={event => {
                setNotificationHeader(event.target.value);
              }}
              inputProps={{
                maxLength: 150,
              }}
              required
              autoComplete="off"
            />

            <TextField
              multiline
              rows={4}
              fullWidth
              id="notificationDescription"
              name="notificationDescription"
              value={notificationDescription}
              placeholder={'Notification Body'}
              margin="normal"
              variant="outlined"
              onChange={event => {
                setNotificationDescription(event.target.value);
              }}
              inputProps={{
                maxLength: 1500,
              }}
              helperText={`${notificationDescription?.length}/1500`}
              required
            />
            <Autocomplete
              options={sortBy(routes, r => r.title)}
              onChange={(e, route) => handlePathSelection(route)}
              getOptionLabel={route => route.title}
              renderInput={params => <TextField {...params} label="Select Link" variant="outlined" />}
            />
            <FormControl fullWidth variant="outlined">
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={status} onChange={e => handleStatus(e)}>
                <FormControlLabel value="DRAFT" control={<Radio />} label="Draft" />
                <FormControlLabel value="PUBLISH" control={<Radio />} label="Publish" />
                <FormControlLabel control={<Checkbox checked={sendEmail} checkedIcon={<Email />} icon={<Email />} />} label="Email" onChange={e => handleEmail(e)} />
              </RadioGroup>
            </FormControl>

            <Box padding="1rem 0">
              <Button fullWidth disabled={loading} color="primary" variant="contained" type="submit">
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </FormControl>
        </form>
      </FormWrapper>
    </Box>
  );
};
export default withUser(CreateNotification);
