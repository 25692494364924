import React, {useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, Button, TablePagination, TableCellProps, Typography} from '@material-ui/core';
import {Order, createSortHandler, getComparator, stableSort} from '../Table/sort-utils';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {IFileList} from '@deep-planet/api-interfaces';
import {getDateString} from '../../../helpers/dateHelpers';
import {fileDeleteSelector} from '../../../store/selectors';
import {useSelector} from 'react-redux';

const StyledTableCell = styled(TableCell)<{width?: string} & TableCellProps>`
  text-overflow: ellipsis;
  max-width: ${({width}) => width || 'auto'};
  overflow: hidden;
`;

const StyledTypography = styled(Typography)<{$textWidth?: string}>`
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  width: ${({$textWidth}) => $textWidth || 'max-content'};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface Props {
  files: IFileList[];
  handleDeleteFile: (fileInfoId: string, fileId: string) => void;
}

const FileTable = ({files, handleDeleteFile}: Props) => {
  const {t} = useTranslation();
  // set default order type
  const [order, setOrder] = useState<Order>('desc');
  // set default sort filter on date
  const [orderBy, setOrderBy] = useState('date');
  // set first page as default
  const [page, setPage] = useState(0);
  // display 50 rows by default
  const [rowsPerPage, setRowsPerPage] = useState(50);
  // selector to  get loading spinner if file is in delete process
  const {loading: isDeleteLoading} = useSelector(fileDeleteSelector);

  // handles page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // handles number of rows to display per page
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Table columns
  const columns = [
    {
      id: 'id' as keyof IFileList,
      label: 'file.table.id',
      width: '10%',
      align: 'center' as TableCellProps['align'],
    },
    {
      id: 'date' as keyof IFileList,
      label: 'file.table.date',
      width: '30%',
      textWidth: 'auto',
    },
    {
      id: 'farmName' as keyof IFileList,
      label: 'file.table.farm.name',
      width: 'auto',
    },
    {
      id: 'fileName' as keyof IFileList,
      label: 'file.table.file.name',
      width: 'auto',
    },
    {
      id: 'type' as keyof IFileList,
      label: 'file.table.type',
      width: 'auto',
    },
    {
      id: 'size' as keyof IFileList,
      label: 'file.table.size',
      width: 'auto',
    },
    {
      id: 'download' as keyof IFileList,
      label: '',
      width: 'auto',
    },
    {
      id: 'delete' as keyof IFileList,
      label: ``,
      width: 'auto',
    },
  ];

  // render the table with table data
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/** provide sort filter on all columns */}
              {columns.map(column => (
                <StyledTableCell size="small" width={column.width} key={column.id} align={column.align || 'left'}>
                  <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={createSortHandler(column.id, orderBy, order, setOrder, setOrderBy)}>
                    <StyledTypography>{t(column.label)}</StyledTypography>
                  </TableSortLabel>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/*** sort files based on the selected column and display the no of rows as per slected using the slice */}
            {stableSort<IFileList>(files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator<IFileList>(order, orderBy)).map((row, idx) => (
              <TableRow key={row.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{getDateString(row.date)}</TableCell>
                <TableCell>{row.farmName}</TableCell>
                <TableCell>{row.fileName}</TableCell>
                <TableCell>{row.contentType}</TableCell>
                <TableCell>{(row.size * 0.000001).toFixed(2)} mb</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" href={row.url} target="_blank">
                    Download
                  </Button>
                </TableCell>
                <TableCell>
                  <Button disabled={isDeleteLoading} variant="contained" color="secondary" onClick={() => handleDeleteFile(row.fileInfoId, row.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/** pagination to the file */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={files?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default FileTable;
