import React from 'react';
import {withRouter, RouteProps} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';
import {Dispatch} from 'redux';

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onUpdatePrevPath: (path: string) => dispatch(actions.updatePrevPath(path)),
  };
};

interface Props extends RouteProps {
  onUpdatePrevPath: (path: string) => void;
}

export default function withLifecycle(WrappedComponent: React.FunctionComponent<any>) {
  class HOComponent extends React.Component<Props, null> {
    componentDidMount() {
      if (this.props.location.state) {
        // @ts-ignore
        this.props.onUpdatePrevPath(this.props.location.state.prevPath);
      }
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  }
  // @ts-ignore
  return connect(null, mapDispatchToProps)(withRouter(HOComponent));
}
