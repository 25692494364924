import React from 'react';
import styled from 'styled-components';
import {Button, List, ListItem, Typography, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FarmPicker from '../../../components/UI/Pickers/FarmPicker';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import DatePeriodPicker from '../../../components/UI/Pickers/DatePeriodPicker';
import {IFarm} from '../../../store/reducers/farm';
import {IFarmImageEntity} from '@deep-planet/api-interfaces';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const SideList = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

interface Props {
  allFarms: IFarm[];
  activeFarm: IFarm;
  images: IFarmImageEntity[];
  startDate: Date;
  endDate: Date;
  selectedDate: Date;
  handleDateChange: (date: Date) => void;
  handleFarmChange: (farmId: string) => void;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
  handleImageFetching: () => void;
}

const Sidebar = ({
  handleFarmChange,
  allFarms,
  activeFarm,
  images,
  handleDateChange,
  handleStartDateSelection,
  handleEndDateSelection,
  startDate,
  endDate,
  selectedDate,
  handleImageFetching,
}: Props) => {
  const {t} = useTranslation();

  return (
    <Container>
      <SideList>
        <Box width="100%">
          <FarmPicker activeFarm={activeFarm.farmid} allFarms={allFarms} handleChange={handleFarmChange} />
        </Box>
        <DatePeriodPicker
          disableToolbar={false}
          startDate={startDate}
          endDate={endDate}
          handleStartDateChange={handleStartDateSelection}
          handleEndDateChange={handleEndDateSelection}
          disableFuture={true}
        />
        <Button variant="contained" color="primary" onClick={handleImageFetching} style={{margin: '1rem 0'}}>
          {t('ndvi.sidebar.update')}
        </Button>
      </SideList>
      {!!images?.length && (
        <List style={{overflow: 'auto'}}>
          {images.map(({date}, idx) => (
            <ListItem button key={idx} style={{backgroundColor: selectedDate === date ? '#DBDBDB' : 'unset'}} onClick={() => handleDateChange(date)}>
              <Typography>{handleDateFormat((date as unknown) as string)}</Typography>
            </ListItem>
          ))}
        </List>
      )}

      {!images?.length && (
        <Box pr={2} pl={2}>
          <Typography align="center">{t('ndvi.sidebar.no.images')}</Typography>
        </Box>
      )}
    </Container>
  );
};

export default Sidebar;
