import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Link} from 'react-router-dom';

interface Props {
  title: string;
  content: string;
  open: boolean;
  url?: string;
  handleClose: () => void;
}

export const InformationDialog = ({title, content, open, url, handleClose}: Props) => {
  return (
    <Dialog fullWidth maxWidth={'sm'} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {url && (
          <Link to={url} color="blue">
            Click Here
          </Link>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
