import {useCallback, useEffect, useState} from 'react';
import {IBlockHealthUI, IDashboardYield, IClimateFeature} from '@deep-planet/api-interfaces';
import {baseApiUrl} from '../config/const';
import axios from 'axios';
import {IFarm} from '../store/reducers/farm';
import {getUserGroups} from '../containers/authHOC';
import {useSelector} from 'react-redux';
import {selectedOrganizationSelector} from '../store/selectors';
export function useDashboard(selectedFarm: IFarm, user: any) {
  const [blockHealth, setBlockHealth] = useState<IBlockHealthUI[]>();
  const [climateFeatures, setClimateFeatures] = useState<IClimateFeature>();
  const [yieldData, setYieldData] = useState<IDashboardYield[]>();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const getDashboardSnapshot = useCallback(async () => {
    if (!selectedFarm || !selectedOrganization) return;
    const farm = selectedOrganization.organizationToFarms.find(({farm}) => farm.id === selectedFarm.id);
    if (!farm) return;
    // const climateURL = `${baseApiUrl}/dashboard/climate-feature/${farm.id}?organizationId=${selectedOrganization?.id}`;
    const blockHealthURL = `${baseApiUrl}/dashboard/block-health/${selectedFarm.id}?organizationId=${selectedOrganization?.id}`;
    const yieldURL = `${baseApiUrl}/dashboard/yield/${selectedFarm.id}?organizationId=${selectedOrganization?.id}`;
    let yieldData = [];
    try {
      const blockHealth = await getDataFromBackend(blockHealthURL);
      // Disable climate feature until ML fix the cache issue
      // const climateFeature = await getDataFromBackend(climateURL);
      if (getUserGroups(user)?.includes('YIELD' || getUserGroups(user)?.includes('HARVEST'))) yieldData = await getDataFromBackend(yieldURL);
      setYieldData(yieldData);
      setBlockHealth(blockHealth);
      // setClimateFeatures(climateFeature[0]);
    } catch (e) {
      //
    }
  }, [selectedFarm, selectedOrganization, user]);

  useEffect(() => {
    getDashboardSnapshot();
  }, [getDashboardSnapshot]);

  const getDataFromBackend = async (url: string) => {
    try {
      const {data} = await axios.get(url);
      return data;
    } catch (e) {
      // return empty if found error
      return [];
    }
  };

  return {
    blockHealth,
    climateFeatures,
    yieldData,
  };
}
