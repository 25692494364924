import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {IFarm} from '../store/reducers/farm';
import {
  irrigationFarmsSelector,
  irrigationFarmGetSelector,
  irrigationImagesSelector,
  irrigationImagesGetSelector,
  irrigationActiveFarmSelector,
  irrigationSelectedDateSelector,
} from './../store/selectors/irrigation';
import {getIrrigationFarms, getIrrigationImages, irrigationSetSelectedDate} from './../store/actions/irrigation';
import {getStringDate} from '../helpers/dateHelpers';
import {selectedOrganizationSelector} from '../store/selectors';

/**
  1. First farm is a default selection on initialization.
  2. First farm is a default selection if some NON-IRRIGATION farm is selected on another feature.
  3. Select a irrigation farm and click update button. Switch to other feature and select a NON-IRRIGATION farm on other feature,
     previously selected irrigation farm will be remain active on the irrigation page.
 *  */
export const useIrrigationSelection = (allFarms: IFarm[], activeFarm: IFarm, selectedFarm: IFarm) => {
  const [selectedDate, setSelectedDate] = useState<number>();
  const [irrigationSelectedFarm, setIrrigationSelectedFarm] = useState<IFarm>();
  const [irrigationActiveFarm, setIrrigationActiveFarm] = useState<IFarm>();
  const irrigationFarms = useSelector(irrigationFarmsSelector);
  const irrigationImages = useSelector(irrigationImagesSelector);
  const activeIrrigationFarmInMemory = useSelector(irrigationActiveFarmSelector);
  const {loading: loadingImages, error} = useSelector(irrigationImagesGetSelector);
  const {loading: loadingIrrigationFarms} = useSelector(irrigationFarmGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const selectedDateInMemory = useSelector(irrigationSelectedDateSelector);
  const dispatch = useDispatch();

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setIrrigationSelectedFarm(selectedFarm);
  };

  const handleDateChange = (date: number) => {
    setSelectedDate(date);
    dispatch(irrigationSetSelectedDate(new Date(date)));
  };

  const handleGetImagesForPeriod = (farm: IFarm, startDate: Date, endDate: Date, organizationId?: string) => {
    dispatch(getIrrigationImages(farm, getStringDate(startDate), getStringDate(endDate), organizationId));
    setIrrigationSelectedFarm(farm);
    setIrrigationActiveFarm(farm);
  };

  useEffect(() => {
    // selectedFarm and ActiveFarm is set globally across all the features upon action of update on the sidebar,
    // but sometimes selected farm on one feature may not available in other features.
    // therefore, keep the previous farm ramain selected if previously selected on the irrigation page, else set first farm as a default farm.
    if (selectedFarm && irrigationFarms && !irrigationSelectedFarm) {
      const isFound = irrigationFarms.some(farm => farm.id === selectedFarm.id);
      if (!isFound) setIrrigationSelectedFarm(activeIrrigationFarmInMemory || irrigationFarms[0]);
      else setIrrigationSelectedFarm(selectedFarm);
    }
    // if global activeFarm is not available in the irrigation farms, just keep the previous farm remain active 
    // if previously selected on the irrigation page, else set first farm as default one
    if (activeFarm && irrigationFarms && !irrigationActiveFarm) {
      const soilNutrienFarm = irrigationFarms.some(f => f.id === activeFarm.id);
      if (!soilNutrienFarm) setIrrigationActiveFarm(activeIrrigationFarmInMemory || irrigationFarms[0]);
      else setIrrigationActiveFarm(activeFarm);
    }
  }, [selectedFarm, irrigationFarms, activeFarm, irrigationActiveFarm, irrigationSelectedFarm, activeIrrigationFarmInMemory]);

  useEffect(() => {
    if (!irrigationFarms && allFarms) {
      dispatch(getIrrigationFarms(allFarms));
    }

    if (!selectedDate && selectedDateInMemory) setSelectedDate(Number(new Date(selectedDateInMemory).getTime()));
  }, [irrigationFarms, dispatch, allFarms, selectedDate, selectedDateInMemory]);

  return {
    irrigationImages,
    irrigationFarms,
    loadingIrrigationFarms,
    loadingImages,
    irrigationSelectedFarm,
    irrigationActiveFarm,
    selectedDate,
    error,
    setIrrigationSelectedFarm,
    setIrrigationActiveFarm,
    handleFarmChange,
    handleGetImagesForPeriod,
    handleDateChange,
  };
};
