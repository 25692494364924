import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Spinner from '../../../../components/UI/Spinner';

interface DialogProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  loading: boolean;
}

const DeleteSeasonDialog = ({isOpen, handleClose, handleSubmit, loading}: DialogProps) => {
  const {t} = useTranslation();
  return (
    <Dialog fullScreen={false} open={isOpen} onClose={handleClose} aria-labelledby="delete season confirmation">
      <DialogTitle id="delete-season-dialog-title">You're about to delete a season</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleSubmit} color="secondary">
          {loading && <Spinner size={15} color="primary" />}
          {t('forms.delete')}
        </Button>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained">
          {t('forms.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSeasonDialog;
