import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableHead from '@material-ui/core/TableHead/TableHead';
import styled from 'styled-components';
import {TableContainer} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {TableHeader} from './TableHeader';
import {TableCellElement} from './TableCell';

const StyledBlockTableHeader = styled(TableHead)`
  background: linear-gradient(345deg, #99aec2 30%, #01366b 90%);
`;

interface tableData {
  label: string;
  value: string;
  label2: string;
  value2: string;
  icon?: boolean;
}

interface Props {
  dashboardData: tableData[];
  tableHeaderText: string;
  tableHeaderValue?: string;
}

const DashboardTable = ({dashboardData, tableHeaderText, tableHeaderValue}: Props) => {
  const {t} = useTranslation();
  const header = t(tableHeaderText) + '\xa0\xa0' + (tableHeaderValue || ' ');

  return (
    <TableContainer style={{marginBottom: 8}} component={Paper} elevation={3}>
      <Table stickyHeader size="small" aria-label="spanning table">
        <StyledBlockTableHeader>
          <TableHeader headerName={header} />
        </StyledBlockTableHeader>
        <TableBody>
          {dashboardData.map((d, idx) => (
            <TableCellElement key={idx} label={t(d.label)} value={d.value} label2={t(d.label2)} value2={d.value2} iconButton={d?.icon} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
