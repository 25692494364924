import React from 'react';
import BasePage from '../index';
import SingleColorLegend from '../../../../components/UI/Legend/SingleColorLegend';

const BareSoilLegend = () => {
  const legends = [{color: '#f60b00', label: 'Bare Soil'}];
  return <SingleColorLegend legends={legends} />;
};

const BareSoil = () => {
  return <BasePage product={'BareSoil'} infoSecondaryTitle={'Bare Soil (m^2)'} soilSignalValueName={'Bare Soil'} legend={<BareSoilLegend />} />;
};

export default BareSoil;
