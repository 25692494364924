import {IconButton} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../utilities/theme';
import {createSortHandler} from './sort-utils';
import {Props as TableHeaderCellProps} from './TableHeaderCell';

const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
  && {
    fill: ${theme.palette.grey[500]};
  }
`;

const StyledArrowDownwardIcon = styled(ArrowDownwardIcon)`
  && {
    fill: ${theme.palette.grey[500]};
  }
`;

interface Props extends TableHeaderCellProps {
  active: boolean;
}

const TableSortButton = ({active, order, orderBy, setOrderBy, setOrder, column}: Props) => {
  return active && <IconButton onClick={createSortHandler(column.id, orderBy, order, setOrder, setOrderBy)}>{order === 'asc' ? <StyledArrowUpwardIcon /> : <StyledArrowDownwardIcon />}</IconButton>;
};

export default TableSortButton;
