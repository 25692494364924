import {IFarmEntity, IFarmSettings, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete} from '@material-ui/lab';
import {sortBy} from 'lodash';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import {
  DISEASE,
  DISEASE_IMAGERY_DATES_URL_PATH,
  ESA,
  ESA_IMAGERY_DATES_URL_PATH,
  IRRIGATION,
  IRRIGATION_IMAGERY_DATES_URL_PATH,
  MATURITYCURVE,
  MATURITYCURVE_IMAGERY_DATES_URL_PATH,
  MATURITYMAP,
  MATURITY_MAP_IMAGERY_DATES_URL_PATH,
  PERPIXELYIELD,
  PER_PIXEL_YIELD_IMAGERY_DATES_URL_PATH,
  PLANET,
  SEASONINDICES,
  SOILCARBON_IMAGERY_DATES_URL_PATH,
  SOILNUTRIENT,
  SOILNUTRIENT_IMAGERY_DATES_URL_PATH,
  SOILORGANICCARBON,
  YIELD,
  YIELD_IMAGERY_DATES_URL_PATH,
  baseApiUrl,
  excludeFeature,
} from 'apps/web-portal-ui/src/app/config/const';
import {useHttp} from '../../../../../hooks/http';
import theme from '../../../../../utilities/theme';
import {YieldPerPixel} from './NewImagery/Yield/YieldPerPixel';
import {RadioButton} from 'apps/web-portal-ui/src/app/components/UI/Switch/RadioButton';
import {MaturityMap} from './NewImagery/MaturityMap';
import {SoilCarbon} from './NewImagery/Soil/SoilCarbon';
import {SoilNutrient} from './NewImagery/Soil/SoilNutrient';
import {Irrigation} from './NewImagery/Soil/Irrigation';
import {MaintainImagery} from './Maintain/MaintainImagery';
import {MaturityCurve} from './NewImagery/MaturityCurve/MaturityCurve';
import {DiseaseMap} from './NewImagery/Disease/Disease';
import {SeasonIndices} from './NewImagery/SeasonIndices';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const Imagery = () => {
  // const labels = ['Maturity Map', 'Soil Nutrient', 'Organic Carbon', 'Irrigation'];
  const [isLoadingOrganizations, organizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const [isLoadingGroups, groups] = useHttp<string[]>(`${baseApiUrl}/farm-settings/feature`);
  const [featureGroup, setFeatureGroup] = useState<string[]>();
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>(null);
  const [selectedFarm, setSelectedFarm] = useState<IFarmEntity>(null);
  const [selectedFeature, setSelectedFeature] = useState<string>(null);
  const [selectedType, setSelectedType] = useState<string>('Generate');
  const labels = [
    {name: 'Generate', value: 'Generate'},
    {name: 'Maintain', value: 'Maintain'},
  ];
  const [imageryURL, setImageryURL] = useState<string>();
  const [isLoadingFarmSettings, farmSettings] = useHttp<IFarmSettings>(selectedFarm ? `${baseApiUrl}/farm-settings/farm/${selectedFarm.id}` : null);

  const handleOrganizationSelect = async (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
    setSelectedFarm(null);
  };

  const handleFeatureGroup = (feature: string) => {
    setSelectedFeature(feature);
    switch (feature) {
      case ESA:
        setImageryURL(ESA_IMAGERY_DATES_URL_PATH);
        break;
      case MATURITYMAP:
        setImageryURL(MATURITY_MAP_IMAGERY_DATES_URL_PATH);
        break;
      case YIELD:
        setImageryURL(YIELD_IMAGERY_DATES_URL_PATH);
        break;
      case SOILNUTRIENT:
        setImageryURL(SOILNUTRIENT_IMAGERY_DATES_URL_PATH);
        break;
      case SOILORGANICCARBON:
        setImageryURL(SOILCARBON_IMAGERY_DATES_URL_PATH);
        break;
      case MATURITYCURVE:
        setImageryURL(MATURITYCURVE_IMAGERY_DATES_URL_PATH);
        break;
      case IRRIGATION:
        setImageryURL(IRRIGATION_IMAGERY_DATES_URL_PATH);
        break;
      case PERPIXELYIELD:
        setImageryURL(PER_PIXEL_YIELD_IMAGERY_DATES_URL_PATH);
        break;
      case DISEASE:
        setImageryURL(DISEASE_IMAGERY_DATES_URL_PATH);
        break;
      case PLANET:
        break;
    }
  };

  const handleFarmSelection = async (currentlySelectedFarm: IFarmEntity) => {
    setSelectedFarm(currentlySelectedFarm);
  };

  const handleSwitch = async (value: string) => {
    setSelectedType(value);
  };

  useEffect(() => {
    // update feature groups with no underscore between the words
    if (groups) {
      const newGroups = groups
        .filter(groupName => !excludeFeature.includes(groupName)) // exclude features which are not relevant for image generation
        .map(group => {
          if (group.includes('_')) {
            return group.split('_').reduce((acc, coll) => acc + coll);
          }
          return group;
        });

      setFeatureGroup(newGroups);
    }
  }, [groups]);

  const isLoading = isLoadingOrganizations || isLoadingGroups;
  return (
    <>
      {isLoading && <MapOverlay position="relative" />}
      {!isLoading && (
        <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3" align="center" gutterBottom>
            Generate Imagery
          </Typography>

          <RadioButton labels={labels} value={selectedType} handleSwitch={handleSwitch} />
          <FormWrapper padding="2rem 0">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedFeature}
                options={sortBy(featureGroup, fg => fg.toLowerCase())}
                onChange={(e, feature) => handleFeatureGroup(feature as string)}
                getOptionLabel={featureName => featureName}
                renderInput={params => <TextField {...params} label="Select Feature" variant="outlined" />}
              />
              <Box marginTop="2rem" width="100%">
                <Autocomplete
                  value={selectedOrganization}
                  options={sortBy(organizations, o => o.name.toLowerCase())}
                  onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                  getOptionLabel={({name}) => name}
                  renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
                />
              </Box>
              {selectedOrganization && (
                <>
                  <Box marginTop="2rem" width="100%">
                    <Autocomplete
                      value={selectedFarm}
                      options={sortBy(
                        selectedOrganization.organizationToFarms.map(({farm}) => farm),
                        ({name}) => name.toLowerCase()
                      )}
                      onChange={(e, farm) => handleFarmSelection(farm as IFarmEntity)}
                      getOptionLabel={({name}) => name}
                      renderInput={params => <TextField {...params} label="Select a farm" variant="outlined" />}
                    />
                  </Box>

                  <Box marginTop="2rem" width="100%">
                    {selectedFarm && selectedOrganization && selectedType === 'Generate' && (
                      <>
                        {selectedFeature === MATURITYMAP && (
                          <MaturityMap farm={selectedFarm} organization={selectedOrganization} farmSettings={farmSettings} isLoadingFarmSettings={isLoadingFarmSettings} />
                        )}
                        {selectedFeature === SOILNUTRIENT && <SoilNutrient farm={selectedFarm} organization={selectedOrganization} />}
                        {selectedFeature === SOILORGANICCARBON && (
                          <SoilCarbon farm={selectedFarm} organization={selectedOrganization} farmSettings={farmSettings} isLoadingFarmSettings={isLoadingFarmSettings} />
                        )}
                        {selectedFeature === IRRIGATION && <Irrigation farm={selectedFarm} organization={selectedOrganization} />}
                        {selectedFeature === PERPIXELYIELD && <YieldPerPixel farm={selectedFarm} />}
                        {selectedFeature === MATURITYCURVE && <MaturityCurve farm={selectedFarm} organization={selectedOrganization} />}
                        {selectedFeature === DISEASE && <DiseaseMap farm={selectedFarm} organization={selectedOrganization} />}
                        {selectedFeature === SEASONINDICES && <SeasonIndices farm={selectedFarm} organization={selectedOrganization} />}
                      </>
                    )}
                    {selectedFarm && selectedOrganization && selectedType === 'Maintain' && <MaintainImagery farm={selectedFarm} url={imageryURL} feature={selectedFeature} />}
                  </Box>
                </>
              )}
            </FormControl>
          </FormWrapper>
        </Box>
      )}
    </>
  );
};
