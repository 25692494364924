import React from 'react';
import {FaSun, FaCloudSun, FaCloud, FaSmog, FaCloudRain, FaCloudSunRain, FaCloudShowersHeavy, FaSnowflake, FaTemperatureHigh, FaTemperatureLow, FaWind, FaMoon, FaCloudMoonRain} from 'react-icons/fa';

interface Props {
  icon: number;
}

const WeatherIcon = ({icon}: Props) => {
  return (
    <>
      {(function () {
        switch (icon) {
          case 1:
          case 2:
            return <FaSun />;
          case 3:
          case 4:
          case 5:
            return <FaCloudSun />;
          case 6:
          case 7:
          case 8:
          case 36:
          case 37:
          case 38:
            return <FaCloud />;
          case 11:
            return <FaSmog />;
          case 12:
          case 13:
          case 19:
          case 20:
            return <FaCloudRain />;
          case 14:
          case 17:
          case 21:
            return <FaCloudSunRain />;
          case 15:
          case 16:
          case 18:
            return <FaCloudShowersHeavy />;
          case 22:
          case 23:
          case 24:
          case 25:
          case 26:
          case 29:
            return <FaSnowflake />;
          case 30:
            return <FaTemperatureHigh />;
          case 31:
            return <FaTemperatureLow />;
          case 32:
            return <FaWind />;
          case 33:
          case 34:
            return <FaMoon />;
          case 39:
          case 40:
          case 41:
          case 42:
          case 43:
          case 44:
            return <FaCloudMoonRain />;
          default:
            return <FaCloud />;
        }
      })()}
    </>
  );
};

export default WeatherIcon;
