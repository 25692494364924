import {IFarmEntity, IFarmWithOwner, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Position} from '@turf/helpers';
import * as turf from '@turf/turf';
import {IFarm} from '../store/reducers/farm';
import {GetFarmCentre} from '../store/utility';

export const parseFarm = (farm: IFarmWithOwner | IFarmEntity): IFarm => {
  // @ts-ignore
  const features = farm.polygons?.map(polygon => turf.feature(polygon.geoJson.geometry, {Name: polygon.id}));

  // run algorithm to determine the centre
  const centerCoords = farm.polygons?.map(p => p.geoJson.geometry.center);
  const farmCenter = centerCoords ? GetFarmCentre(centerCoords) : null;

  return {
    id: farm.id,
    farmid: farm.id,
    name: farm.name,
    farmCenter,
    polygons: farm.polygons
      ?.map(polygon => ({
        ...polygon,
        hectares: Math.round(turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as Position[][])) / 100) / 100,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
    bbox: features ? turf.bbox(turf.featureCollection(features)) : null,
    // @ts-ignore
    ownerOrganization: farm?.ownerOrganization,
  };
};

export const getOrgUserFarms = (organizations: IOrganizationEntity[], organizationId: string, userName?: string): IFarm[] => {
  if (!organizations) return null;
  if (userName && userName !== 'ALL')
    return organizations // user farms
      .filter(org => org.id === organizationId)
      .filter(({organizationToUsers}) => organizationToUsers.some(({user}) => user.username === userName))
      .map(({organizationToFarms}) =>
        organizationToFarms.map(({farm}) => {
          return parseFarm(farm);
        })
      )
      .flat();
  else
    return organizations // all farms of organization - loads for Public note
      .filter(org => org.id === organizationId)
      .map(({organizationToFarms}) =>
        organizationToFarms.map(({farm}) => {
          return parseFarm(farm);
        })
      )
      .flat();
};
