import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {bbox, center, polygon, Position} from '@turf/turf';
import React from 'react';
import PolygonWithInfo from '../../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import {Map} from '../../../../components/UI/Map/Map';

const containerStyle = {
  width: 'calc(100%)',
  height: '300px',
  margin: '20px 0',
};

interface Props {
  selectedPolygon: IPolygonEntity;
}

const HistoricalYieldMap = ({selectedPolygon}: Props) => {
  const turfPolygon = polygon(selectedPolygon.geoJson.geometry.coordinates as Position[][]);
  const {
    geometry: {coordinates},
  } = center(turfPolygon);
  const mapCenter = {lat: coordinates[1], lng: coordinates[0]};
  const polygonBbox = bbox(turfPolygon);

  return (
    <Map center={mapCenter} bbox={polygonBbox} mapContainerStyle={containerStyle}>
      <PolygonWithInfo key={selectedPolygon.id} polygon={selectedPolygon} />
    </Map>
  );
};

export default HistoricalYieldMap;
