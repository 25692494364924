import React, {useState} from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import PolygonWithInfo from 'apps/web-portal-ui/src/app/components/UI/PolygonWithInfo/PolygonWithInfo';
import {Map, MapProps} from 'apps/web-portal-ui/src/app/components/UI/Map/Map';
import {INutrientTypeImageData, ISoilNutrientImageRes} from './../../../../../../../../libs/api-interfaces/src/lib/soil.type';
import OpacityPanel from 'apps/web-portal-ui/src/app/components/UI/Panel/OpacityPanel';
import Marker from 'apps/web-portal-ui/src/app/components/UI/Marker/Marker';
import {useMarker} from 'apps/web-portal-ui/src/app/hooks/useMarker';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';

import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';
import MapInfoWindow from 'apps/web-portal-ui/src/app/components/UI/PolygonWithInfo/InfoWindow';
import {useSelector} from 'react-redux';
import {markerDeleteSelector} from 'apps/web-portal-ui/src/app/store/selectors';
import {renderURL} from '../../NDVI/DownloadScreenshot';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

const renderImage = (type: string, image: INutrientTypeImageData) => {
  switch (type) {
    case 'current':
      return image.curr_url;
    case 'previous':
      return image.prev_url;
    case 'average':
      return image.avg_url;
    case 'predicted':
      return image.pred_url;
    default:
      return '';
  }
};

const getImageUrl = (type: string, image: INutrientTypeImageData) => {
  const url = renderImage(type, image);
  return url;
};

interface Props extends MapProps {
  farm: IFarm;
  type: string;
  images: ISoilNutrientImageRes['images'];
  nutrientType: string;
  handlePolygonHover?: (id: string) => void;
  takeScreenshot: () => void;
}

const SoilNutrientMap = ({
  farm,
  center,
  images,
  nutrientType,
  type,
  bbox,
  polygons,
  handlePolygonHover,
  takeScreenshot,
  polygonClicked,
  handleOnClickPolygon,
  selectedPolygon,
}: //activeFarm
Props) => {
  const farmBBox = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
  const {t} = useTranslation();
  const [opacity, setOpacity] = useState(100);
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const handleOpacityChange = (event, newValue) => {
    newValue = newValue / 100;
    setOpacity(newValue);
  };

  const handleOnClick = polygon => {
    handleOnClickPolygon(polygon);
  };

  const isLoading = loading || postMarkerLoading || false;

  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
        >
          {images && (
            <Marker
              selectedMarker={selectedMarker}
              farm={farm}
              polygon={markerPolygon}
              featureName={'SOIL'}
              isModalOpen={isModalOpen}
              isOpenModalNoteUpdate={isOpenModalNoteUpdate}
              handleAddPin={handleSelectedPin}
              handleMarkerPostRequest={handleMarkerPostRequest}
              handleMarkerFeatureSelection={handleMarkerFeatureSelection}
              handleCloseModal={handleCloseModal}
              handleMarkerSubmit={handleMarkerSubmit}
              handleNoteUpdateClose={handleNoteUpdateClose}
              handleDeleteMarker={handleDeleteMarker}
              takeScreenshot={takeScreenshot}
            ></Marker>
          )}
          {images && farm && <OpacityPanel opacity={opacity} handleOpacityChange={handleOpacityChange} />}
          {polygons?.map(polygon => {
            const correspondingImage = images?.find(({polygonid}) => polygonid === polygon.id);
            if (correspondingImage) {
              const url = getImageUrl(type, correspondingImage[nutrientType]);
              const bounds = correspondingImage.boundBox
                ? new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(correspondingImage.boundBox[1], correspondingImage.boundBox[0]),
                    new window.google.maps.LatLng(correspondingImage.boundBox[3], correspondingImage.boundBox[2])
                  )
                : farmBBox;
              return (
                <>
                  <GroundOverlay key={nutrientType + type + url} url={renderURL(url)} bounds={bounds} opacity={opacity} />;
                  <PolygonWithInfo key={polygon.id} polygon={polygon} handlePolygonHover={handlePolygonHover} handleOnClick={handleOnClick} handleOnClickMap={handleOnClickMap} />;
                  {mouseOverMarker && isOpenMarkerInfoWindow && (
                    <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                  )}
                </>
              );
            } else {
              return images?.length ? <PolygonWithInfo key={polygon.id} polygon={polygon} secondaryTitle={t('ndvi.planet.cloudy')} defaultOpacity={0.3} /> : null;
            }
          })}
        </Map>
      )}
    </>
  );
};

export default SoilNutrientMap;
