import React, {useCallback, useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {AccordionSummary, AccordionSummaryProps, AccordionDetails, Accordion, AccordionProps, List, Typography} from '@material-ui/core';
import ListItem from './ListItem';
import {vineSignalRoutes, cropSignalRoutes, soilSignalRoutes, adminRoutes, superAdminRoutes} from '../../../utilities/routes';
import {useTranslation} from 'react-i18next';
import {getUserGroups} from '../../authHOC';
import theme from '../../../utilities/theme';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {
  biomass,
  BIOMASS,
  cropSignal,
  harvest,
  HARVEST,
  plant,
  PLANT,
  soilHealth,
  soilSignal,
  SOIL_HEALTH,
  vineHealth,
  vineSignal,
  VINE_HEALTH,
  water,
  WATER,
  Config,
  CONFIG,
  DISEASE,
  disease,
} from '../../../config/const';
import DrawerGroup from './DrawerGroup';
import {useLocation} from 'react-router-dom';

const StyledAccordion = styled(Accordion)<AccordionProps>`
  && {
    border: none;
    box-shadow: none;
    &:not(:last-child) {
      border-bottom: 0;
    }
    &:before {
      display: none;
    }
    ${({expanded}) =>
      expanded &&
      css`
        margin: auto;
      `};
    background: ${() => theme.palette.primary.main};
  }
`;

const ListStyled = styled(List)`
  && {
    width: 100%;
    padding: 0;
    & span {
      text-align: left !important;
    }
  }
`;

const ExpansionPanelSummary = styled(AccordionSummary)<AccordionSummaryProps>`
  && {
    background-color: rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: -1px;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 0;
  }
`;

interface groupState {
  vineOpen: boolean;
  soilOpen: boolean;
  harvestOpen: boolean;
  waterOpen: boolean;
  biomassOpen: boolean;
  plantOpen: boolean;
  configOpen: boolean;
  diseaseOpen: boolean;
}

const CustomizedExpansionPanel = ({user}: WithUserProps) => {
  const groups = getUserGroups(user);
  const {t} = useTranslation();
  const [groupState, setGroupState] = useState<groupState>({
    vineOpen: false,
    soilOpen: false,
    harvestOpen: false,
    waterOpen: false,
    biomassOpen: false,
    plantOpen: false,
    configOpen: false,
    diseaseOpen: false,
  });
  const [group, setGroup] = useState<string>();
  const [groupPath, setGroupPath] = useState<string>();

  const cropSignalIncluded = Object.entries(cropSignalRoutes).filter(key => groups && groups.includes(cropSignalRoutes[key[0]].role));
  const soilSignalIncluded = Object.entries(soilSignalRoutes).filter(key => groups && groups.includes('SOILSIGNAL') && groups.includes(soilSignalRoutes[key[0]].role));
  const vineSignalIncluded = Object.entries(vineSignalRoutes).filter(
    key => groups && groups.includes('VINESIGNAL') && groups.includes(vineSignalRoutes[key[0]].role) && !vineSignalRoutes[key[0]].doNotDisplayInAccordion
  );

  const location = useLocation();

  // extract group names
  const vineAccordianGroups = [
    ...new Set(
      Object.entries(vineSignalIncluded)
        .map(key => key[1][1]?.group && key[1][1].group)
        .filter(k => !!k)
    ),
  ];
  const soilAccordianGroups = [
    ...new Set(
      Object.entries(soilSignalIncluded)
        .map(key => key[1][1]?.group && key[1][1].group)
        .filter(k => !!k)
    ),
  ];

  const superAdminGroups = [
    ...new Set(
      Object.entries(superAdminRoutes)
        .map(k => k[1]?.group && k[1].group)
        .filter(k => !!k)
    ),
  ];

  const vineNonGroupFeatures = Object.entries(vineSignalIncluded).filter(key => !key[1][1]?.group && key[1][0] !== 'DASHBOARD');
  const soilNonGroupFeatures = Object.entries(soilSignalIncluded).filter(key => !key[1][1]?.group && key[1][0] !== 'DASHBOARD');
  const handleClick = (group: string, path: string) => {
    setGroup(group);
    setGroupPath(path);
    handleGroupState(group);
  };
  const superAdminGroupRoutes = Object.entries(superAdminRoutes).filter(key => !!key[1]?.group);
  // hold the selected sider item
  const handleGroupState = useCallback((group: string) => {
    switch (group) {
      case VINE_HEALTH:
        setGroupState(prev => ({...prev, vineOpen: !prev.vineOpen}));
        break;
      case HARVEST:
        setGroupState(prev => ({...prev, harvestOpen: !prev.harvestOpen}));
        break;
      case SOIL_HEALTH:
        setGroupState(prev => ({...prev, soilOpen: !prev.soilOpen}));
        break;
      case WATER:
        setGroupState(prev => ({...prev, waterOpen: !prev.waterOpen}));
        break;
      case BIOMASS:
        setGroupState(prev => ({...prev, biomassOpen: !prev.biomassOpen}));
        break;
      case PLANT:
        setGroupState(prev => ({...prev, plantOpen: !prev.plantOpen}));
        break;
      case CONFIG:
        setGroupState(prev => ({...prev, configOpen: !prev.configOpen}));
        break;
      case DISEASE:
        setGroupState(prev => ({...prev, diseaseOpen: !prev.diseaseOpen}));
        break;
      default:
        break;
    }
  }, []);

  const getGroupState = (group: string) => {
    switch (group) {
      case VINE_HEALTH:
        return groupState.vineOpen;
      case SOIL_HEALTH:
        return groupState.soilOpen;
      case HARVEST:
        return groupState.harvestOpen;
      case WATER:
        return groupState.waterOpen;
      case BIOMASS:
        return groupState.biomassOpen;
      case PLANT:
        return groupState.plantOpen;
      case CONFIG:
        return groupState.configOpen;
      case DISEASE:
        return groupState.diseaseOpen;
    }
  };

  const getGroupName = (group: string) => {
    switch (group) {
      case VINE_HEALTH:
        return vineHealth;
      case SOIL_HEALTH:
        return soilHealth;
      case HARVEST:
        return harvest;
      case WATER:
        return water;
      case BIOMASS:
        return biomass;
      case PLANT:
        return plant;
      case CONFIG:
        return Config;
      case DISEASE:
        return disease;
    }
  };

  useEffect(() => {
    // @ts-ignore
    const groupSelected = location.state?.group;
    // capture the current path
    const path = location?.pathname.includes(vineSignal) ? vineSignal : location?.pathname.includes(soilSignal) ? soilSignal : cropSignal;
    // update the state of a group and current path
    if (!group && groupSelected) {
      setGroup(groupSelected);
      // handles group expand and collapse
      handleGroupState(groupSelected);
    }
    if (!groupPath) setGroupPath(path);
  }, [groupPath, group, location.state, location.pathname, handleGroupState]);

  return (
    <div>
      {groups && (groups.includes('ADMIN') || groups.includes('SUPERADMIN')) && (
        <StyledAccordion square expanded>
          <ExpansionPanelSummary>
            <Typography>Admin</Typography>
          </ExpansionPanelSummary>
          <StyledAccordionDetails>
            <ListStyled>
              {groups.includes('ADMIN') &&
                Object.entries(adminRoutes).map(key => {
                  return <ListItem key={key[0]} slug={key[1].slug} title={t(key[1].title)} />;
                })}
              {groups.includes('SUPERADMIN') &&
                superAdminGroups.map((group, idx) => {
                  const open: boolean = getGroupState(group);
                  const groupName = getGroupName(group);
                  return <DrawerGroup key={group + idx} group={group} groupName={groupName} path={vineSignal} open={open} items={superAdminGroupRoutes} handleClick={handleClick} />;
                })}
              {groups.includes('SUPERADMIN') &&
                Object.entries(superAdminRoutes)
                  .filter(key => !key[1]?.group)
                  .map(key => {
                    return <ListItem key={key[0]} slug={key[1].slug} title={t(key[1].title)} />;
                  })}
            </ListStyled>
          </StyledAccordionDetails>
        </StyledAccordion>
      )}

      {cropSignalIncluded.length ? (
        <StyledAccordion square expanded>
          <ExpansionPanelSummary>
            <Typography>Plant Signal</Typography>
          </ExpansionPanelSummary>
          <StyledAccordionDetails>
            <ListStyled>
              {cropSignalIncluded.map(key => {
                return <ListItem key={key[0]} slug={key[1].slug} title={t(key[1].title)} />;
              })}
            </ListStyled>
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : null}
      {vineSignalIncluded.length ? (
        <StyledAccordion square expanded>
          <ExpansionPanelSummary>
            <Typography>{t('navigation.accordion.vine')}</Typography>
          </ExpansionPanelSummary>
          <StyledAccordionDetails>
            <ListStyled>
              {vineSignalIncluded
                .filter(key => key[0] === 'DASHBOARD')
                .map(key => {
                  return <ListItem key={key[0]} slug={key[1].slug} title={t(key[1].title)} />;
                })}
            </ListStyled>
          </StyledAccordionDetails>
          <StyledAccordionDetails style={{textAlign: 'left'}}>
            <ListStyled>
              {vineAccordianGroups.map((group, idx) => {
                let open: boolean = getGroupState(group);
                open = groupPath !== vineSignal ? false : open;
                const groupName = getGroupName(group);
                return <DrawerGroup key={group + idx} group={group} groupName={groupName} path={vineSignal} open={open} items={vineSignalIncluded} handleClick={handleClick} />;
              })}
            </ListStyled>
          </StyledAccordionDetails>
          <StyledAccordionDetails>
            <ListStyled>
              {vineNonGroupFeatures.map(key => {
                return <ListItem key={key[0]} slug={key[1][1].slug} title={t(key[1][1].title)} />;
              })}
            </ListStyled>
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : null}
      {soilSignalIncluded.length ? (
        <StyledAccordion square expanded>
          <ExpansionPanelSummary>
            <Typography>{t('navigation.accordion.soil')}</Typography>
          </ExpansionPanelSummary>
          <StyledAccordionDetails>
            <ListStyled>
              {soilSignalIncluded
                .filter(key => key[0] === 'DASHBOARD')
                .map(key => {
                  return <ListItem key={key[0]} slug={key[1].slug} title={t(key[1].title)} />;
                })}
            </ListStyled>
          </StyledAccordionDetails>
          <StyledAccordionDetails>
            <ListStyled>
              {soilAccordianGroups.map((group, idx) => {
                let open: boolean = getGroupState(group);
                open = groupPath !== soilSignal ? false : open;
                const groupName = getGroupName(group);
                return <DrawerGroup key={group + idx} group={group} groupName={groupName} path={soilSignal} open={open} items={soilSignalIncluded} handleClick={handleClick} />;
              })}
            </ListStyled>
          </StyledAccordionDetails>
          <StyledAccordionDetails>
            <ListStyled>
              {soilNonGroupFeatures.map(key => {
                return <ListItem key={key[0]} slug={key[1][1].slug} title={t(key[1][1].title)} />;
              })}
            </ListStyled>
          </StyledAccordionDetails>
        </StyledAccordion>
      ) : null}
    </div>
  );
};

export default withUser(CustomizedExpansionPanel);
