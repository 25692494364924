import React, {memo, useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import {createStyles, Theme, useTheme} from '@material-ui/core';
import Accordion from './Accordion';
import logo from '../../../../assets/dp-white.png';
import logoTitle from '../../../../assets/deep_planet_.png';
import {vineSignalRoutes} from '../../../utilities/routes';
import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = 200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      background: '#253647',
      overflow: 'hidden',
    },
  })
);

const LogoTitleBar = styled(Toolbar)`
  && {
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 12px;
    & img {
      width: 160px;
    }
  }
`;

const DrawerLogoContainer = styled.div`
  z-index: -1;
  width: ${drawerWidth}px;
  bottom: 0;
  left: 0;
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Logo = styled.img`
  z-index: 1000 !important;
  height: 125px;
`;

const DrawerStyled = styled(Drawer)`
  && * {
    color: white;
    text-align: right;
  }
`;

const ListContainer = styled.div`
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

interface Props {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar = ({mobileOpen, handleDrawerToggle}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  // Holds reference of sidebar component
  const sidebarRef = useRef(null);

  // On scroll, stores the value of the current sidebar position
  const handleScroll = () => {
    window.localStorage.setItem('sidebarPosition', sidebarRef.current.scrollTop);
  };

  useEffect(() => {
    // Checks when user has refreshed or exits the page
    window.addEventListener('beforeunload', function (event) {
      if (event) {
        // Deletes sidebar position value => returns to the top
        window.localStorage.setItem('sidebarPosition', null);
      }
    });
    // Checks storage for sidebar position
    const sidebarPosition = window.localStorage.getItem('sidebarPosition');
    if (sidebarPosition) {
      // Sets reference to current sidebar position
      sidebarRef.current.scrollTop = sidebarPosition;
    }
  }, []);

  const TitleLogo = memo(function TitleLogo() {
    return (
      <LogoTitleBar>
        <Link to={vineSignalRoutes.DASHBOARD.slug}>
          <img src={logoTitle} alt="deep_planet_ logo" />
        </Link>
      </LogoTitleBar>
    );
  });

  const FooterLogo = memo(function FooterLogo() {
    return (
      <DrawerLogoContainer>
        <Link to={vineSignalRoutes.DASHBOARD.slug}>
          <Logo src={logo} alt="Deep Planet Logo (white)" />
        </Link>
      </DrawerLogoContainer>
    );
  });

  const drawer = (
    <ListContainer ref={sidebarRef} onScroll={handleScroll}>
      <TitleLogo />
      <div style={{zIndex: 20}}>
        <Accordion />
      </div>
      <FooterLogo />
    </ListContainer>
  );

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden mdUp implementation="css">
        <DrawerStyled
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </DrawerStyled>
      </Hidden>
      <Hidden smDown implementation="css">
        <DrawerStyled
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </DrawerStyled>
      </Hidden>
    </nav>
  );
};

export default React.memo(Sidebar);
