import React from 'react';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
interface Props {
  farmType: string;
  handleFarmType: (value: string) => void;
  loading: boolean;
}
export const FarmType = ({farmType, handleFarmType, loading}: Props) => {
  return (
    <FormControl style={{width: '100%'}}>
      <InputLabel id="farm-type-selection">Farm Type</InputLabel>
      <Select
        labelId="farm-type-selection"
        value={farmType}
        onChange={e => handleFarmType(e.target.value as string)}
        displayEmpty
        name="farm-type"
        style={{width: '100%'}}
        fullWidth
        disabled={loading}
      >
        {['VINEYARD', 'FARMLAND'].map(farmType => (
          <MenuItem key={farmType} value={farmType}>
            {farmType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
