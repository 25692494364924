import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/soil-moisture';
import {updateObject} from '../utility';

export const postMoistureSensorsSelector = (state: SoilState) => state.postMoistureSensors;

export interface SoilState {
  data: unknown;
  moisturesensordata: unknown;
  getSoil: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  getMoistureSensors: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  postMoistureSensors: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
}

const initialState: SoilState = {
  data: null,
  moisturesensordata: null,
  getSoil: {
    success: false,
    error: null,
    loading: false,
  },
  getMoistureSensors: {
    success: false,
    error: null,
    loading: false,
  },
  postMoistureSensors: {
    success: false,
    error: null,
    loading: false,
  },
};

const reducer = (state: SoilState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SOIL_GET_START:
      return updateObject(state, {
        getSoil: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.SOIL_GET_SUCCESS:
      return updateObject(state, {
        data: action.data,
        getSoil: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.SOIL_GET_FAIL:
      return updateObject(state, {
        getSoil: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.MOISTURESENSORS_GET_SUCCESS:
      return updateObject(state, {
        moisturesensordata: action.data,
        getMoistureSensors: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MOISTURESENSORS_GET_START:
      return updateObject(state, {
        getMoistureSensors: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MOISTURESENSORS_GET_FAIL:
      return updateObject(state, {
        getMoistureSensors: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.MOISTURE_SENSORS_POST_START:
      return updateObject(state, {
        postMoistureSensors: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MOISTURE_SENSORS_POST_SUCCESS:
      return updateObject(state, {
        postMoistureSensors: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MOISTURE_SENSORS_POST_FAIL:
      return updateObject(state, {
        postMoistureSensors: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
