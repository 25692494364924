import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/disease';
import {orderBy} from 'lodash';
import {IFarm} from './farm';
import {IGetDiseaseImages} from '@deep-planet/api-interfaces';

export const getDiseaseImagesSelector = (state: IDiseaseState) => state.getdiseaseImages;
export const diseaseImagesSelector = (state: IDiseaseState) => orderBy(state.diseaseImages, i => i.date, 'desc');
export const diseaseActiveFarmSelector = (state: IDiseaseState) => state.activeFarm;
export const diseaseSelectedDateSelector = (state: IDiseaseState) => state.selectedDate;

export interface IDiseaseState {
  getdiseaseImages: {
    error: unknown;
    loading: boolean;
  };
  diseaseImages: IGetDiseaseImages[];
  activeFarm: IFarm;
  selectedDate: number;
}

const initialState: IDiseaseState = {
  getdiseaseImages: {
    error: false,
    loading: false,
  },
  diseaseImages: [],
  activeFarm: null,
  selectedDate: null,
};

const diseaseReducer = (state: IDiseaseState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.DISEASE_PER_PIXEL_GET_START:
      return updateObject(state, {
        getdiseaseImages: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.DISEASE_PER_PIXEL_GET_SUCCESS:
      return updateObject(state, {
        diseaseImages: action.images,
        activeFarm: action.activeFarm,
        getdiseaseImages: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.DISEASE_PER_PIXEL_GET_FAIL:
      return updateObject(state, {
        getdiseaseImages: {
          error: action.error,
          loading: false,
        },
      });

    case ActionTypes.DISEASE_PER_PIXEL_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default diseaseReducer;
