import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/snackbar';
import {OptionsObject} from 'notistack';

export const notificationsSelector = (state: SnackbarState) => state.notifications;

export interface SnackbarState {
  notifications: INotification[];
}

export interface IEnqueuedNotification {
  message: string;
  options: OptionsObject;
}

export interface INotification extends IEnqueuedNotification {
  key: number | string;
  dismissed?: boolean;
}

const initialState: SnackbarState = {
  notifications: [],
};

export default (state: SnackbarState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };

    case ActionTypes.CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification => (action.dismissAll || notification.key === action.key ? {...notification, dismissed: true} : {...notification})),
      };

    case ActionTypes.REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(notification => notification.key !== action.key),
      };

    default:
      return state;
  }
};
