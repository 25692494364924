import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/crop';
import {IVarietyEntity} from '@deep-planet/api-interfaces';

export const getVarietiesSelector = (state: CropState) => state.getVarieties;
export const varietiesSelector = (state: CropState) => state.varieties;

export interface CropState {
  getVarieties: {
    error: unknown;
    loading: boolean;
  };
  varieties: IVarietyEntity[];
}

const initialState: CropState = {
  getVarieties: {
    error: false,
    loading: false,
  },
  varieties: null,
};

const reducer = (state: CropState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.VARIETIES_GET_START:
      return updateObject(state, {
        getVarieties: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.VARIETIES_GET_SUCCESS:
      return updateObject(state, {
        varieties: action.varieties,
        getVarieties: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.VARIETIES_GET_FAIL:
      return updateObject(state, {
        getVarieties: {
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
