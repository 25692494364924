import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getNDVIPlanet} from '../store/actions';
import {IFarm} from '../store/reducers/farm';
import {ndviImageTypeSelector, ndviPlanetImageTypeSelector, ndviPlanetSelectedDateSelector, planetDataSelector, planetGetSelector, selectedOrganizationSelector} from '../store/selectors';
import {CURRENT} from './../config/const';
import {ndviPlanetActiveDate} from './../store/actions/ndvi';

export function usePlanetImage() {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<number>();
  const {loading: loadingGetImages} = useSelector(planetGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const images = useSelector(planetDataSelector);
  const ndviImageType = useSelector(ndviPlanetImageTypeSelector);
  const planetSelectedDate = useSelector(ndviPlanetSelectedDateSelector);
  const [imageType, setImageType] = useState<string>();

  const handleDateChange = (date: number, type: string) => {
    setSelectedDate(date);
    setImageType(type || CURRENT);
    // image type for the planet is not availble in the design yet.
    dispatch(ndviPlanetActiveDate(date, type || CURRENT));
  };

  useEffect(() => {
    if (!imageType && ndviImageType) setImageType(ndviImageType);
    if (!selectedDate && planetSelectedDate) setSelectedDate(planetSelectedDate);
  }, [imageType, ndviImageType, planetSelectedDate, selectedDate]);

  const onGetPlanetImages = (selectedFarm: IFarm, startDate: Date, endDate: Date) => {
    dispatch(getNDVIPlanet(selectedFarm, startDate, endDate, selectedOrganization?.id));
  };

  return {
    images,
    selectedDate,
    setSelectedDate,
    loadingGetImages,
    onGetPlanetImages,
    handleDateChange,
    imageType,
  };
}
