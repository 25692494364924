import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import {useDispatch, useSelector} from 'react-redux';
import {
  markerDeleteSelector,
  markerPostSelector,
  markersSelector,
  noteAttachmentDeleteSelector,
  noteAttachmentsAddSelector,
  noteDeleteSelector,
  notePutSelector,
  userNamesSelector,
} from '../../../store/selectors';
import CreateNoteModal from '../../../containers/pages/Notes/CreateNoteModal';
import {baseApiUrl} from '../../../config/const';
import {CreateNoteDTO, ICreateNoteDTO, IMarker, INoteEntity, INoteTypeEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {useHttp} from '../../../hooks/http';
import {ISelectedFile} from '../../../containers/pages/Notes/withEdit';
import {updateNote} from '../../../store/actions';
import NoteDetails from '../../../containers/pages/Notes/NoteDetails';
import useEdit from '../../../containers/pages/Notes/withEdit';
import Modal from '../Modal/Modal';
import {ConfirmationDialog} from '../Dialog/ConfirmationDialog';
import {usePrevious} from '../../../hooks/usePrevious';
interface Props {
  farm: IFarm;
  polygon: IPolygonEntity;
  isModalOpen: boolean;
  isOpenModalNoteUpdate: boolean;
  selectedMarker: IMarker;
  handleCloseModal: () => void;
  handleNoteSubmit: (params: ICreateNoteDTO, files: ISelectedFile[]) => void;
  handleNoteUpdateClose: () => void;
  handleDeleteMarker: (value: string) => void;
}

const MarkerNote = ({farm, polygon, isModalOpen, selectedMarker, isOpenModalNoteUpdate, handleCloseModal, handleNoteSubmit, handleNoteUpdateClose, handleDeleteMarker}: Props) => {
  const {t} = useTranslation();
  const markers = useSelector(markersSelector);
  const [isTypesLoading, types] = useHttp<INoteTypeEntity[]>(`${baseApiUrl}/note/types`);
  const {loading: isMarkerPostLoading} = useSelector(markerPostSelector);
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const userNames = useSelector(userNamesSelector);
  const {loading} = useMarker(farm);
  //@ts-ignore
  const note: INoteEntity = selectedMarker?.note || null;
  const {
    summary,
    location,
    description,
    errors,
    selectedType,
    selectedDate,
    selectedFarm,
    selectedPolygon,
    setErrors,
    selectedNote,
    selectedFiles,
    setSelectedFiles,
    selectedAssignee,
    selectedVisibility,
    selectedUserName,
    selectedOrganization,
    handleChangeSummary,
    handleChangeLocation,
    handleChangeDescription,
    handleTypeSelection,
    handleDateSelection,
    handleFarmSelection,
    handlePolygonSelection,
    handleNoteAttachmentChange,
    handleNoteAttachmentCancel,
    handleNoteSelection: setSelectedNote,
    handleAssigneeSelection,
    handleOrganizationSelection,
    handleUserNameSelection,
  } = useEdit(types, [farm], userNames, note);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isActionsOpen, setActionsOpen] = useState(false);
  const [isShareActionsOpen, setSetActionsOpen] = useState(false);
  const {loading: isDeleteLoading} = useSelector(noteDeleteSelector);
  const {loading: isUpdateLoading} = useSelector(notePutSelector);
  const noteAttachmentDeletes = useSelector(noteAttachmentDeleteSelector);
  const {loading: isAttachmentUploadLoading} = useSelector(noteAttachmentsAddSelector);
  const dispatch = useDispatch();
  const handleDetailsClosing = useCallback(() => setSelectedNote(null), [setSelectedNote]);
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const handleOpenShareModal = () => setShareModalOpen(true);
  const handleCloseShareModal = () => setShareModalOpen(false);
  const handleOpenAction = () => setActionsOpen(true);
  const handleCloseAction = () => setActionsOpen(false);
  const handleOpenShareAction = () => setSetActionsOpen(true);
  const handleCloseShareAction = () => setSetActionsOpen(false);
  const prevNoteAttachmentDeletes = usePrevious(noteAttachmentDeletes);

  // update marker note
  const handleNoteUpdate = (noteId: string, params: CreateNoteDTO, files: ISelectedFile[]) => {
    dispatch(updateNote(noteId, params, files, selectedMarker, true));
  };
  const previousNote = usePrevious(note);

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>();

  useEffect(() => {
    // update note when note attachment is deleted
    if (selectedNote && noteAttachmentDeletes.length !== prevNoteAttachmentDeletes?.length) {
      const noteAttachmentDelete = prevNoteAttachmentDeletes?.find(({noteId}) => selectedNote?.id);
      if (noteAttachmentDelete) {
        //@ts-ignore
        const {note} = markers.find(({note}) => noteAttachmentDelete.noteId === note.id);
        //@ts-ignore - even after note is deleted keep changed values remain active
        setSelectedNote({...note, description: description, summary: summary, type: selectedType, location: location});
      }
    }

    // intialize the modal screen with marker note
    if (!selectedNote) setSelectedNote(note);

    // change note when clicks on a differnt marker
    if (selectedNote && note && selectedNote.id !== note.id) {
      setSelectedNote(note);
      // reset file cache
      setSelectedFiles([]);
    }

    // update note with new attachments
    if (selectedFiles.length && selectedNote && selectedMarker) {
      // get updated marker note
      const marker = markers.find(m => m.id === selectedMarker.id);
      // check if new attachment is added
      if (marker && selectedNote.attachments !== marker.note.attachments) {
        //@ts-ignore
        setSelectedNote(marker.note);
        // reset file cache;
        setSelectedFiles([]);
      }
    }
  }, [
    description,
    location,
    markers,
    note,
    noteAttachmentDeletes,
    prevNoteAttachmentDeletes,
    previousNote,
    selectedFiles,
    selectedMarker,
    selectedNote,
    selectedType,
    setSelectedFiles,
    setSelectedNote,
    summary,
  ]);

  const handleDeleteConfirmation = () => {
    setIsOpenDialog(true);
  };

  const handleDialogClose = () => {
    setIsOpenDialog(false);
  };

  const handleNoteDelete = () => {
    // not needed;
  };

  const deleteMarker = () => {
    handleDeleteMarker(selectedMarker.id);
    setIsOpenDialog(false);
  };

  const submitLoading = loading || isDeleteLoading || isUpdateLoading || isAttachmentUploadLoading;

  return (
    <>
      {/*
        creata a new marker note
       */}
      {isModalOpen && (
        <CreateNoteModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          types={types}
          farms={[farm]}
          handleSubmit={handleNoteSubmit}
          submitLoading={isMarkerPostLoading}
          userNames={userNames}
          modalTitle={t('note.modal.create.note.marker')}
          markerNote={true}
          markerPolygon={polygon}
        />
      )}
      {/*
        update existing marker note
       */}
      {isOpenModalNoteUpdate && selectedNote && (
        <Modal
          title={t('note.modal.update.note.marker')}
          isOpen={isOpenModalNoteUpdate}
          isSubmitButtonDisabled={submitLoading}
          isLoading={submitLoading}
          handleClose={handleNoteUpdateClose}
          modalWidth={'492px'} // used only for marker note update
        >
          <>
            <NoteDetails
              selectedFiles={selectedFiles}
              handleNoteAttachmentCancel={handleNoteAttachmentCancel}
              handleNoteAttachmentChange={handleNoteAttachmentChange}
              errors={errors}
              summary={summary}
              location={location}
              description={description}
              selectedDate={selectedDate}
              selectedFarm={selectedFarm}
              selectedPolygon={selectedPolygon}
              selectedType={selectedType}
              setErrors={setErrors}
              handleChangeDescription={handleChangeDescription}
              handleChangeLocation={handleChangeLocation}
              handleChangeSummary={handleChangeSummary}
              handleDateSelection={handleDateSelection}
              handleFarmSelection={handleFarmSelection}
              handlePolygonSelection={handlePolygonSelection}
              handleTypeSelection={handleTypeSelection}
              //@ts-ignore
              note={selectedNote}
              types={types}
              farms={[farm]}
              closeDetails={handleDetailsClosing}
              isDialogOpen={isDialogOpen}
              isShareModalOpen={isShareModalOpen}
              handleOpenShareModal={handleOpenShareModal}
              handleCloseShareModal={handleCloseShareModal}
              isActionsOpen={isActionsOpen}
              isShareActionsOpen={isShareActionsOpen}
              isDeleteLoading={isDeleteLoading}
              handleOpenDialog={handleOpenDialog}
              handleCloseDialog={handleCloseDialog}
              handleOpenAction={handleOpenAction}
              handleOpenShareAction={handleOpenShareAction}
              handleCloseShareAction={handleCloseShareAction}
              handleCloseAction={handleCloseAction}
              handleNoteUpdate={handleNoteUpdate}
              selectedAssignee={selectedAssignee}
              handleAssigneeSelection={handleAssigneeSelection}
              userNames={userNames}
              selectedOrganization={selectedOrganization || note?.organization?.id}
              selectedVisibility={selectedVisibility}
              selectedUserName={selectedUserName}
              handleOrganizationSelection={handleOrganizationSelection}
              handleUserNameSelection={handleUserNameSelection}
              padding={0} //used only for marker note update
              marker={{...selectedMarker, note: selectedNote}}
              isMarkerNote={true}
              handleDeleteMarker={handleDeleteConfirmation}
              handleNoteDelete={handleNoteDelete}
              disableDeleteButton={isMarkerDeleteLoading}
            />
            {isOpenDialog && <ConfirmationDialog open={isOpenDialog} handleClose={handleDialogClose} handleSubmit={deleteMarker} title={`${t('marker.delete.confirmation')}`} submitText={'YES'} />}
          </>
        </Modal>
      )}
    </>
  );
};

export default MarkerNote;
