import {IFarmEntity, IOrganizationEntity, SoilNutrientMapDTO} from '@deep-planet/api-interfaces';
import {Box, Button} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../../../../components/UI/Pickers/SimpleDatePicket';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseSoilNutrientUrl, NEW, OLD} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';
import {FarmType} from './FarmType';
import {RadioButton} from 'apps/web-portal-ui/src/app/components/UI/Switch/RadioButton';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
}

export const SoilNutrient = ({farm, organization}: Props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [selectedFarmType, setSelectedFarmType] = useState('FARMLAND');
  const [predictionDate, setPredictionDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<string>('Old');
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 14)));
  const [toDate, setToDate] = useState<Date>(new Date());
  const labels = [
    {name: OLD, value: OLD},
    {name: NEW, value: NEW},
  ];

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload =
        selectedType === OLD
          ? {
              predictionDate: predictionDate,
              farmId: farm.id,
              farmType: selectedFarmType,
            }
          : {
              farmId: farm.id,
              fromDate: fromDate,
              toDate: toDate,
              farmType: selectedFarmType,
            };
      const url = selectedType === 'Old' ? `${baseSoilNutrientUrl}/soil/nutrients/farm` : `${baseSoilNutrientUrl}/soil/nutrients/alerts-and-fertilizer/farm`;
      await axios.post<any>(url, payload);
      dispatch(enqueueSnackbar({message: 'Soil Nutrient images are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  const handleSwitch = async (value: string) => {
    setSelectedType(value);
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            <FarmType farmType={selectedFarmType} handleFarmType={setSelectedFarmType} loading={loading} />
            <RadioButton labels={labels} value={selectedType} handleSwitch={handleSwitch} />
            <Box marginTop="2rem" width="100%">
              {selectedType === 'Old' && <SimpleDatePicker value={predictionDate} onChange={date => setPredictionDate(date)} label={'Prediction date'} disabled={loading} />}
              {selectedType === 'New' && (
                <DatePeriodPicker disableToolbar={false} startDate={fromDate} endDate={toDate} handleStartDateChange={setFromDate} handleEndDateChange={setToDate} disableFuture={true} />
              )}
            </Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
