import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {InfoWindow} from '@react-google-maps/api';
import {List, ListItem, ListItemText} from '@material-ui/core';
import {IPolygonEntity, IIrrigationPredictionUI, IIrrigationHistoricalUI, IDiseaseImages, INoteEntity, IMarkerTypeUI, IMarker} from '@deep-planet/api-interfaces';
import {explode, Polygon as turfPolygon} from '@turf/turf';
import {Plot} from '../../../containers/pages/irrigation/plot';
import CustomModal from '../Modal/CustomModal';
import {capitalize} from 'lodash';
import {PH, TA} from '../../../config/const';

const StyledLi = styled(ListItem)`
  && {
    padding: 0;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

interface Props {
  polygon: IPolygonEntity;
  secondaryTitle?: string;
  secondaryDescription?: string;
  hectares?: number;
  yieldPrediction?: number;
  irrigationPredictions?: IIrrigationPredictionUI[];
  irrigationHistorical?: IIrrigationHistoricalUI[];
  selectedPolygon?: string;
  isBlockName?: boolean;
  disableInfoBox?: boolean;
  autoPan?: boolean;
  isClicked?: boolean;
  avgBaume?: string;
  metric?: string;
  diseasePredictions?: IDiseaseImages;
  markerNote?: IMarker;
  closeGraph?: () => void;
  showVariety?: boolean;
}

const MapInfoWindow = ({
  polygon,
  secondaryDescription,
  secondaryTitle,
  hectares,
  yieldPrediction,
  diseasePredictions,
  irrigationPredictions,
  irrigationHistorical,
  selectedPolygon,
  isBlockName,
  disableInfoBox,
  autoPan,
  isClicked,
  avgBaume,
  metric,
  markerNote,
  closeGraph,
  showVariety,
}: Props) => {
  let avgMetricValueLabel = null;
  const {t} = useTranslation();
  const maxY = Math.max(...explode(polygon.geoJson.geometry as turfPolygon).features.map(feature => feature.geometry.coordinates[1]));
  const {rootStock, clone, datePlanted} = !!secondaryTitle && polygon.geoJson.properties;
  const plantedDate = datePlanted && new Date(datePlanted).toDateString();
  const averageProbability = diseasePredictions?.averageProbability && Number(diseasePredictions.averageProbability * 100).toFixed(2);
  if (avgBaume && metric) {
    avgMetricValueLabel = metric.toUpperCase() === PH ? 'pH' : metric.toUpperCase() === TA ? TA : capitalize(metric);
    avgMetricValueLabel = `${t('average')} ${' '} ${avgMetricValueLabel}`;
  }

  return (
    <div>
      {(irrigationPredictions || irrigationHistorical) && !isBlockName && isClicked && selectedPolygon === polygon.id ? (
        <CustomModal close={closeGraph}>
          <Plot irrigationPredictions={irrigationPredictions} irrigationHistorical={irrigationHistorical} blockName={polygon.name} />
        </CustomModal>
      ) : !disableInfoBox ? (
        <InfoWindow
          position={{
            lat: maxY,
            lng: polygon.geoJson.geometry.center[0],
          }}
          options={{
            disableAutoPan: autoPan ? false : true, // default true
          }}
        >
          <List dense={true}>
            <StyledLi button={true}>
              <ListItemText primary={t('polygon.info.block.name')} secondary={polygon.name} />
            </StyledLi>
            {showVariety && (
              <StyledLi button={true}>
                <ListItemText primary={'Variety'} secondary={polygon.geoJson.properties?.cropType || 'N/A'} />
              </StyledLi>
            )}
            {avgMetricValueLabel && (
              <StyledLi button={true}>
                <ListItemText primary={avgMetricValueLabel} secondary={Number(avgBaume).toFixed(2)} />
              </StyledLi>
            )}
            {!!averageProbability && (
              <StyledLi button={true}>
                <ListItemText primary={t('average.probability')} secondary={averageProbability} />
              </StyledLi>
            )}
            {!!secondaryTitle && (
              <StyledLi button={true}>
                <ListItemText primary={secondaryTitle} secondary={secondaryDescription || null} />
              </StyledLi>
            )}
            {!!rootStock && (
              <StyledLi button={true}>
                <ListItemText primary={t('setting.create.farm.block.root.stock')} secondary={rootStock} />
              </StyledLi>
            )}
            {!!clone && (
              <StyledLi button={true}>
                <ListItemText primary={t('setting.create.farm.block.clone')} secondary={clone} />
              </StyledLi>
            )}
            {!!datePlanted && (
              <StyledLi button={true}>
                <ListItemText primary={t('setting.create.farm.block.date.planted')} secondary={plantedDate} />
              </StyledLi>
            )}
            {!!hectares && (
              <StyledLi button={true}>
                <ListItemText primary={t('polygon.info.hectares')} secondary={hectares || null} />
              </StyledLi>
            )}{' '}
            {!!yieldPrediction && (
              <StyledLi button={true}>
                <ListItemText primary={t('polygon.info.yield')} secondary={yieldPrediction || null} />
              </StyledLi>
            )}
          </List>
        </InfoWindow>
      ) : (
        markerNote?.note && (
          <InfoWindow
            position={{
              lat: markerNote.latitude,
              lng: markerNote.longitude,
            }}
            options={{
              disableAutoPan: false, // default true
              pixelOffset: new google.maps.Size(0, -20),
            }}
          >
            <List dense={true}>
              <StyledLi button={true}>
                <ListItemText secondary={markerNote?.note?.summary} />
              </StyledLi>
            </List>
          </InfoWindow>
        )
      )}
    </div>
  );
};

export default MapInfoWindow;
