import {TableCell, TableRow} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Help from './Help';

interface Props {
  headerName: string;
  blockName?: string;
}

export const TableHeader = ({headerName, blockName}: Props) => {
  const {t} = useTranslation();
  return (
    <TableRow>
      <TableCell style={{padding: 10, fontWeight: 2000, fontSize: '12pt', color: 'white'}} colSpan={3}>
        {`${t(headerName)} ${blockName || ''}`}
      </TableCell>
      <TableCell align="right" style={{flex: 1}} colSpan={3}>
        <Help helpInfoType={headerName} />
      </TableCell>
    </TableRow>
  );
};
