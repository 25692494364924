import React from 'react';
import {Link} from 'react-router-dom';
import EmailVerificationForm from './Form';
import {ButtonAlt, ConfirmMessage} from '../../UI/Form';
import {EmailVerification} from '@deep-planet/react-cognito';
import AuthSplash from '../../UI/AuthSplash';
import {withTranslation} from 'react-i18next';

const VerifyEmail = ({t}) => {
  return (
    <AuthSplash>
      <ConfirmMessage variant="body1">{t('auth.verify.email.title')}</ConfirmMessage>

      <EmailVerification>
        <EmailVerificationForm />
      </EmailVerification>

      <ButtonAlt>
        <Link to="/login">{t('forms.cancel')}</Link>
      </ButtonAlt>
    </AuthSplash>
  );
};

export default withTranslation()(VerifyEmail);
