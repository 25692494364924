import {parseFarm} from '../../utilities/parseFarm';
import {IUserDetails} from '../reducers/user';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {postPolygon} from './polygon';
import {baseApiUrl} from '../../config/const';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {Action as PolygonAction} from './polygon';
import {Action as UserAction} from './user';
import i18n from '../../../i18n';
import {GetFarmCentre} from '../utility';
import * as turf from '@turf/turf';
import {IFarm, Season, SoilValues} from '../reducers/farm';
import {Dispatch} from 'redux';
import {getStringDate} from '../../helpers/dateHelpers';
import {IFarmWithOwner, IFarmResponse, IFarmImageEntity, ISaveSeasonRequest, ISeasonEntity, IUserEntity, SaveFarmRequest, UpdateFarmDTO, Ibatch} from '@deep-planet/api-interfaces';
import {Position} from '@turf/helpers';
import {userPutSuccess} from './user';

////////////////////////////////////
// GET farm
////////////////////////////////////

interface IFarmGetStart {
  type: ActionTypes.FARM_GET_START;
}

export const farmGetStart = (): IFarmGetStart => ({
  type: ActionTypes.FARM_GET_START,
});

interface IFarmGetSuccess {
  type: ActionTypes.FARM_GET_SUCCESS;
  allFarms: IFarm[];
  activeFarmIndex: string;
}

export const farmGetSuccess = (allFarms: IFarm[], activeFarmIndex: string): IFarmGetSuccess => ({
  type: ActionTypes.FARM_GET_SUCCESS,
  allFarms,
  activeFarmIndex,
});

interface IFarmGetFail {
  type: ActionTypes.FARM_GET_FAIL;
  error: unknown;
}

export const farmGetFail = (error): IFarmGetFail => ({
  type: ActionTypes.FARM_GET_FAIL,
  error,
});

/**
 *  AWS lambda has a limit of 6mb on HTTP request payload. .
 *  method getAllFarms process payload in batches if response payload exceeds the size of 5.5MB
 *  processing response
 *     -- serve the response if payload doesn't exceeds the limit size.
 *     -- else
 *     -- serve the response in batches(farm level or polygon level).
 **/
export const getAllFarms = (organizationId?: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(farmGetStart());
    const url = `${baseApiUrl}/farms${organizationId ? `?organizationId=${organizationId}` : ''}`;
    try {
      const allFarmsResponse = await axios.get<IFarmResponse>(url);
      const {farms, batch, ownerOrganization} = allFarmsResponse.data;
      // process batch data if exist
      if (batch && batch.length)
        await Promise.all(
          batch.map(async row => {
            if (row.polygonFetch) {
              //process polygons
              const farm = await processPolygonLevel(row, ownerOrganization);
              farms.push(farm);
            } else {
              //process farms
              const newFarms = await processFarmLevel(row, ownerOrganization);
              farms.push(...newFarms);
            }
          })
        );

      const allFarms = farms.map(farm => parseFarm(farm));
      const activeFarmIndex = farms?.length === 0 ? null : allFarms[0].farmid;
      dispatch(farmGetSuccess(allFarms, activeFarmIndex));
    } catch (e) {
      console.log(e);
      dispatch(farmGetFail(e));
    }
  };
};

const processPolygonLevel = async (batch: Ibatch, ownerOrg: boolean): Promise<IFarmWithOwner> => {
  let endIndex: number = Math.ceil(batch.totalPolygons / batch.noOfRequests);
  let beginIndex = 0;
  let partialFarm: IFarmWithOwner;
  let currentRequest = 1;
  while (currentRequest <= batch.noOfRequests) {
    currentRequest++;
    if (endIndex > batch.totalPolygons) endIndex = batch.totalPolygons;
    //fetch polygon blocks using index - index will track the no.of polygons processed for each request
    const url = `${baseApiUrl}/farms/${batch.farmId[0]}/paging/polygons?beginIndex=${beginIndex}&endIndex=${endIndex}&ownerOrg=${ownerOrg}`;
    beginIndex = endIndex;
    endIndex = endIndex + endIndex;
    const partialData = await axios.get<IFarmWithOwner>(url);
    if (partialFarm) {
      partialFarm.polygons.push(...partialData.data.polygons);
      continue;
    }
    partialFarm = partialData.data;
  }
  return partialFarm;
};

const processFarmLevel = async (batch: Ibatch, ownerOrganizationRequired: boolean) => {
  if (!batch.farmId.length) return;
  try {
    const body = {farmids: batch.farmId, ownerOrg: ownerOrganizationRequired};
    const url = `${baseApiUrl}/farms/multiFarms`;
    const response = await axios.post(url, body);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
////////////////////////////////////
// POST farm
////////////////////////////////////

interface IFarmPostInit {
  type: ActionTypes.FARM_POST_INIT;
}

export const farmPostInit = (): IFarmPostInit => ({
  type: ActionTypes.FARM_POST_INIT,
});

interface IFarmPostStart {
  type: ActionTypes.FARM_POST_START;
}

export const farmPostStart = (): IFarmPostStart => ({
  type: ActionTypes.FARM_POST_START,
});

interface IFarmPostSuccess {
  type: ActionTypes.FARM_POST_SUCCESS;
  farmName: string;
  farmId: string;
  farm: IFarm;
}

export const farmPostSuccess = (farmName: string, farmId: string, farm?: IFarm): IFarmPostSuccess => ({
  type: ActionTypes.FARM_POST_SUCCESS,
  farmName,
  farmId,
  farm,
});

interface IFarmPostFail {
  type: ActionTypes.FARM_POST_FAIL;
  error: unknown;
}

export const farmPostFail = (error): IFarmPostFail => ({
  type: ActionTypes.FARM_POST_FAIL,
  error,
});

export const postFarmFile = (files, organizationId: string) => {
  return (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(farmPostStart());
    const url = `${baseApiUrl}/farm/file`;
    let farmId;
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('organizationId', organizationId);
    axios
      .post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(({data}) => {
        const farmName = data.name;
        farmId = data.id;

        const centerCoords = data.polygons?.map(p => p.geoJson.geometry.center);
        const farmCenter = GetFarmCentre(centerCoords);
        const features = data.polygons.map(polygon => turf.feature(polygon.geoJson.geometry, {Name: polygon.id}));

        const farm: IFarm = {
          id: data.id,
          farmid: data.id,
          name: data.name,
          farmCenter,
          polygons: data.polygons?.map(polygon => ({
            ...polygon,
            hectares: Math.round(turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as Position[][])) / 100) / 100,
          })),
          bbox: turf.bbox(turf.featureCollection(features)),
          ownerOrganization: data?.ownerOrganization,
        };
        dispatch(farmPostSuccess(farmName, farmId, farm));
      })
      .catch(err => {
        console.log(err);
        dispatch(farmPostFail(err));
        if (err.response.data.message === 'FARM_DUPLICATE_BY_NAME') {
          dispatch(
            enqueueSnackbar({
              message: i18n.t('setting.create.farm.step.uploading.error.duplicate'),
              options: {variant: 'error'},
            })
          );
        } else if (err.response.data.message === 'SAME_FARM_BY_BBOX') {
          dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.step.uploading.error.bbox'), options: {variant: 'error'}}));
        } else if (err.response.data.message === 'TOO_BIG_FARM') {
          dispatch(
            enqueueSnackbar({
              message: i18n.t('setting.create.farm.step.uploading.error.too.big'),
              options: {variant: 'error'},
            })
          );
        } else {
          dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.step.uploading.error'), options: {variant: 'error'}}));
        }
      });
  };
};

export const postFarm = (farmPayload: SaveFarmRequest, polyPayload) => {
  return (dispatch: Dispatch<Action | PolygonAction | SnackbarAction>) => {
    dispatch(farmPostStart());
    const url = `${baseApiUrl}/farm`;
    let farmId;
    axios
      .post(url, farmPayload)
      .then(({data}) => {
        const farmName = data.name;
        farmId = data.id;
        dispatch(farmPostSuccess(farmName, farmId));
      })
      .then(() => {
        polyPayload.farmId = farmId;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(postPolygon(polyPayload, true));
        dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.saved'), options: {variant: 'success'}}));
      })
      .catch(err => {
        console.log(err);
        dispatch(farmPostFail(err));
      });
  };
};

////////////////////////////////////
// DELETE farm
////////////////////////////////////

interface IFarmDeleteStart {
  type: ActionTypes.FARM_DELETE_START;
}

export const farmDeleteStart = (): IFarmDeleteStart => ({
  type: ActionTypes.FARM_DELETE_START,
});

interface IFarmDeleteSuccess {
  type: ActionTypes.FARM_DELETE_SUCCESS;
  deletedFarmId: string;
}

export const farmDeleteSuccess = (deletedFarmId: string): IFarmDeleteSuccess => ({
  type: ActionTypes.FARM_DELETE_SUCCESS,
  deletedFarmId,
});

interface IFarmDeleteFail {
  type: ActionTypes.FARM_DELETE_FAIL;
  error: unknown;
}

export const farmDeleteFail = (error): IFarmDeleteFail => ({
  type: ActionTypes.FARM_DELETE_FAIL,
  error: error,
});

export const deleteFarm = (farmId: string, organizationId: string, isSuperUser?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction | UserAction>) => {
    dispatch(farmDeleteStart());
    const url = `${baseApiUrl}/farm/${farmId}?organizationId=${organizationId}`;
    try {
      // TODO: do not switching up id with name
      if (isSuperUser) {
        await axios.delete(url);
      } else {
        const {
          data: {username, firstName, lastName, address, activeFarmId, preferences},
        } = await axios.delete<IUserEntity>(url);
        const userDetails: IUserDetails = {userid: username, firstName, lastName, address, activeFarm: activeFarmId, preferences};
        dispatch(userPutSuccess(userDetails));
      }
      dispatch(farmDeleteSuccess(farmId));
      dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.deleted'), options: {variant: 'success'}}));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.not.deleted'), options: {variant: 'error'}}));
      dispatch(farmDeleteFail(err));
    }
  };
};

////////////////////////////////////
// PUT farm
////////////////////////////////////

interface IFarmUpdateStart {
  type: ActionTypes.FARM_UPDATE_START;
}

export const farmUpdateStart = (): IFarmUpdateStart => ({
  type: ActionTypes.FARM_UPDATE_START,
});

interface IFarmUpdateSuccess {
  type: ActionTypes.FARM_UPDATE_SUCCESS;
  farmId: string;
  payload: any;
}

export const farmUpdateSuccess = (farmId: string, payload: any): IFarmUpdateSuccess => ({
  type: ActionTypes.FARM_UPDATE_SUCCESS,
  farmId,
  payload,
});

interface IFarmUpdateFail {
  type: ActionTypes.FARM_UPDATE_FAIL;
  error: unknown;
}

export const farmUpdateFail = (error): IFarmUpdateFail => ({
  type: ActionTypes.FARM_UPDATE_FAIL,
  error: error,
});

export const updateFarm = (farmId: string, farmName: string, organizationId: string) => {
  return (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(farmUpdateStart());
    const payload: UpdateFarmDTO = {
      name: farmName,
      organizationId,
    };
    const url = `${baseApiUrl}/farm/${farmId}`;
    axios
      .put(url, payload)
      .then(() => {
        dispatch(farmUpdateSuccess(farmId, payload));
        dispatch(enqueueSnackbar({message: i18n.t('setting.create.farm.updated'), options: {variant: 'success'}}));
      })
      .catch(err => {
        dispatch(farmUpdateFail(err));
      });
  };
};

////////////////////////////////////
// Misc
////////////////////////////////////

interface IFarmIdUpdate {
  type: ActionTypes.FARM_UPDATE_INDEX;
  farmId: string;
}

export const farmIdUpdate = (farmId: string): IFarmIdUpdate => ({
  type: ActionTypes.FARM_UPDATE_INDEX,
  farmId,
});

export const updateActiveFarm = (farmId: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(farmIdUpdate(farmId));
  };
};

export const farmReset = () => {
  return {
    type: ActionTypes.FARM_RESET_STATE,
  };
};

////////////////////////////////////
// GET farm soil
////////////////////////////////////

interface IFarmGetSoilStart {
  type: ActionTypes.FARM_GET_SOIL_START;
}

export const farmGetSoilStart = (): IFarmGetSoilStart => ({
  type: ActionTypes.FARM_GET_SOIL_START,
});

interface IFarmGetSoilSuccess {
  type: ActionTypes.FARM_GET_SOIL_SUCCESS;
  payload: SoilValues[];
}

export const farmGetSoilSuccess = (payload): IFarmGetSoilSuccess => ({
  type: ActionTypes.FARM_GET_SOIL_SUCCESS,
  payload,
});

interface IFarmGetSoilFail {
  type: ActionTypes.FARM_GET_SOIL_FAIL;
}

export const farmGetSoilFail = (): IFarmGetSoilFail => ({
  type: ActionTypes.FARM_GET_SOIL_FAIL,
});

export const getFarmSoilValues = (farmId: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(farmGetSoilStart());
    const url = `${baseApiUrl}/farm/${farmId}/soil${organizationId ? `?organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get(url);
      dispatch(farmGetSoilSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(farmGetSoilFail());
    }
  };
};

////////////////////////////////////
// GET farm soils
////////////////////////////////////

interface IFarmGetSoilsStart {
  type: ActionTypes.FARM_GET_SOILS_START;
}

export const farmGetSoilsStart = (): IFarmGetSoilsStart => ({
  type: ActionTypes.FARM_GET_SOILS_START,
});

interface IFarmGetSoilsSuccess {
  type: ActionTypes.FARM_GET_SOILS_SUCCESS;
  payload: SoilValues[];
}

export const farmGetSoilsSuccess = (payload): IFarmGetSoilsSuccess => ({
  type: ActionTypes.FARM_GET_SOILS_SUCCESS,
  payload,
});

interface IFarmGetSoilsFail {
  type: ActionTypes.FARM_GET_SOILS_FAIL;
}

export const farmGetSoilsFail = (): IFarmGetSoilsFail => ({
  type: ActionTypes.FARM_GET_SOILS_FAIL,
});

export const getFarmSoilsValues = (farmId: string, fromDate: Date, toDate: Date, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(farmGetSoilsStart());
    const url = `${baseApiUrl}/farm/${farmId}/soils?fromDate=${getStringDate(fromDate)}&toDate=${getStringDate(toDate)}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get(url);
      dispatch(farmGetSoilsSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(farmGetSoilsFail());
    }
  };
};

////////////////////////////////////
// GET farm image
////////////////////////////////////

interface IFarmGetImageStart {
  type: ActionTypes.FARM_GET_IMAGE_START;
}

export const farmGetImageStart = (): IFarmGetImageStart => ({
  type: ActionTypes.FARM_GET_IMAGE_START,
});

interface IFarmGetImageSuccess {
  type: ActionTypes.FARM_GET_IMAGE_SUCCESS;
  payload: IFarmImageEntity[];
}

export const farmGetImageSuccess = (payload: IFarmImageEntity[]): IFarmGetImageSuccess => ({
  type: ActionTypes.FARM_GET_IMAGE_SUCCESS,
  payload,
});

interface IFarmGetImageFail {
  type: ActionTypes.FARM_GET_IMAGE_FAIL;
  error: unknown;
}

export const farmGetImageFail = (error): IFarmGetImageFail => ({
  type: ActionTypes.FARM_GET_IMAGE_FAIL,
  error,
});

export const getFarmImages = (farmId: string, fromDate: Date, toDate: Date, product: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(farmGetImageStart());
    const url = `${baseApiUrl}/farm_images?farmId=${farmId}&fromDate=${getStringDate(fromDate)}&toDate=${getStringDate(toDate)}&product=${product}${
      organizationId ? `&organizationId=${organizationId}` : ''
    }`;
    try {
      const {data} = await axios.get<IFarmImageEntity[]>(url);
      dispatch(farmGetImageSuccess(data));
      dispatch(farmIdUpdate(farmId));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(farmGetImageFail(err));
    }
  };
};

////////////////////////////////////
// GET Seasons
////////////////////////////////////

interface ISeasonsGetStart {
  type: ActionTypes.SEASONS_GET_START;
}

export const seasonsGetStart = (): ISeasonsGetStart => ({
  type: ActionTypes.SEASONS_GET_START,
});

interface ISeasonsGetSuccess {
  type: ActionTypes.SEASONS_GET_SUCCESS;
  seasons: Season[];
}

export const seasonsGetSuccess = (seasons: Season[]): ISeasonsGetSuccess => ({
  type: ActionTypes.SEASONS_GET_SUCCESS,
  seasons,
});

interface ISeasonsGetFail {
  type: ActionTypes.SEASONS_GET_FAIL;
  error: unknown;
}

export const seasonsGetFail = (error: unknown): ISeasonsGetFail => ({
  type: ActionTypes.SEASONS_GET_FAIL,
  error,
});

export const getSeasons = (organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(seasonsGetStart());
    const url = `${baseApiUrl}/seasons${organizationId ? `?organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<ISeasonEntity[]>(url);
      const seasons = data.map(({id, name, fromDate, toDate, organization}) => ({id, name, fromDate: getStringDate(fromDate), toDate: getStringDate(toDate), organization}));
      dispatch(seasonsGetSuccess(seasons));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(seasonsGetFail(err));
    }
  };
};

////////////////////////////////////
// POST Season
////////////////////////////////////

interface ISeasonPostStart {
  type: ActionTypes.SEASON_POST_START;
}

export const seasonPostStart = (): ISeasonPostStart => ({
  type: ActionTypes.SEASON_POST_START,
});

interface ISeasonPostSuccess {
  type: ActionTypes.SEASON_POST_SUCCESS;
  season: Season;
}

export const seasonPostSuccess = (season: Season): ISeasonPostSuccess => ({
  type: ActionTypes.SEASON_POST_SUCCESS,
  season,
});

interface ISeasonPostFail {
  type: ActionTypes.SEASON_POST_FAIL;
  error: unknown;
}

export const seasonPostFail = (error: unknown): ISeasonPostFail => ({
  type: ActionTypes.SEASON_POST_FAIL,
  error,
});

export const postSeason = (season: ISaveSeasonRequest, callback: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(seasonPostStart());
    const url = `${baseApiUrl}/season`;
    try {
      const {data} = await axios.post<ISeasonEntity>(url, season);
      dispatch(seasonPostSuccess({...data, fromDate: getStringDate(data.fromDate), toDate: getStringDate(data.toDate)}));
      callback();
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(seasonPostFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof farmGetStart>
  | ReturnType<typeof farmGetSuccess>
  | ReturnType<typeof farmGetFail>
  | ReturnType<typeof farmPostInit>
  | ReturnType<typeof farmPostStart>
  | ReturnType<typeof farmPostSuccess>
  | ReturnType<typeof farmPostFail>
  | ReturnType<typeof farmDeleteStart>
  | ReturnType<typeof farmDeleteSuccess>
  | ReturnType<typeof farmDeleteFail>
  | ReturnType<typeof farmUpdateStart>
  | ReturnType<typeof farmUpdateSuccess>
  | ReturnType<typeof farmUpdateFail>
  | ReturnType<typeof farmIdUpdate>
  | ReturnType<typeof farmGetSoilStart>
  | ReturnType<typeof farmGetSoilSuccess>
  | ReturnType<typeof farmGetSoilFail>
  | ReturnType<typeof farmGetSoilsStart>
  | ReturnType<typeof farmGetSoilsSuccess>
  | ReturnType<typeof farmGetSoilsFail>
  | ReturnType<typeof farmGetImageStart>
  | ReturnType<typeof farmGetImageSuccess>
  | ReturnType<typeof farmGetImageFail>
  | ReturnType<typeof seasonsGetStart>
  | ReturnType<typeof seasonsGetSuccess>
  | ReturnType<typeof seasonsGetFail>
  | ReturnType<typeof seasonPostStart>
  | ReturnType<typeof seasonPostSuccess>
  | ReturnType<typeof seasonPostFail>;
