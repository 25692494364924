import React from 'react';
import styled from 'styled-components';
import {changePassword} from '@deep-planet/react-cognito';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../UI/Spinner';
import {withTranslation} from 'react-i18next';
import {withUser} from '../../../hooks/useAuth';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled(TextField)`
  && {
    margin-bottom: 1rem;
  }
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 2rem;
  }
`;

const Message = styled(Typography)`
  && {
    margin-top: 0.5rem;
  }
`;

const INITIAL_STATE = {
  oldPassword: '',
  newPassword1: '',
  newPassword2: '',
};

class ChangePassWord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      showPassword: false,
      passError: '',
      loading: false,
      ...INITIAL_STATE,
    };
  }

  validatePasswordMatch = () => {
    const {t} = this.props;
    let isValid = true;
    if (this.state.newPassword1 === this.state.newPassword2) {
      this.setState({passError: ''});
      return isValid;
    }
    this.setState({passError: t('forms.password.not.match')});
    return (isValid = false);
  };

  onSubmit = event => {
    const {t} = this.props;
    event.preventDefault();
    if (this.validatePasswordMatch()) {
      this.setState({loading: true});
      changePassword(this.props.user, this.state.oldPassword, this.state.newPassword1).then(
        () =>
          this.setState({
            ...INITIAL_STATE,
            message: t('forms.password.changed'),
            loading: false,
          }),
        error => this.setState({message: error, loading: false})
      );
    }
  };

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleClickShowPassword = () => {
    this.setState(state => ({showPassword: !state.showPassword}));
  };

  render() {
    const {oldPassword, newPassword1, newPassword2, message, showPassword, passError} = this.state;
    const {t} = this.props;
    return (
      <Form onSubmit={this.onSubmit}>
        <Title variant="body1">{t('account.account.settings.description')}</Title>
        <Input
          id="oldPassword"
          name="oldPassword"
          variant="outlined"
          placeholder={t('account.account.settings.password.old')}
          label={t('account.account.settings.password.old')}
          type={showPassword ? 'text' : 'password'}
          value={oldPassword}
          onChange={this.onChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label={t('account.account.settings.password.visibility')} onClick={this.handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Input
          id="newPassword1"
          name="newPassword1"
          variant="outlined"
          placeholder={t('account.account.settings.password.new')}
          label={t('account.account.settings.password.new')}
          type={showPassword ? 'text' : 'password'}
          value={newPassword1}
          onChange={this.onChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label={t('account.account.settings.password.visibility')} onClick={this.handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Input
          id="newPassword2"
          name="newPassword2"
          variant="outlined"
          placeholder={t('account.account.settings.password.repeat')}
          label={t('account.account.settings.password.repeat')}
          type={showPassword ? 'text' : 'password'}
          value={newPassword2}
          onChange={this.onChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label={t('account.account.settings.password.visibility')} onClick={this.handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" type="submit" disabled={this.state.loading}>
          {t('account.account.settings.password.change')}
          {this.state.loading && <Spinner size={15} color="primary" />}
        </Button>

        {message && !message.includes('Password must have symbol characters') && <Message variant="subtitle1">{message}</Message>}
        {message && message.includes('Password must have symbol characters') && (
          <>
            <Message variant="subtitle1">{`${message} from the following list:`}</Message>
            <Message variant="subtitle1">{`^ $ * . [ ] { } ( ) ? " ! @ # % & / \\\\ , > < \\' : ; | _ ~ \``}</Message>
          </>
        )}

        {passError && <Message variant="subtitle1">{passError}</Message>}
      </Form>
    );
  }
}

export default withTranslation()(withUser(ChangePassWord));
