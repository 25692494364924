import React, {useEffect, useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Form, InputField, ButtonOutline, Error} from '../../UI/Form';
import {useTranslation} from 'react-i18next';

interface Props {
  error?: unknown;
  onSubmit?: (username: string, password: string) => void;
  clearCache?: () => void;
}

const LoginForm = ({onSubmit, error, clearCache}: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {t} = useTranslation();

  const onClickSubmit = event => {
    event.preventDefault();
    if (onSubmit) onSubmit(username, password);
  };

  const changeUsername = event => {
    setUsername(event.target.value);
  };

  const changePassword = event => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    return () => {
      if (clearCache) {
        clearCache();
      }
    };
  }, [clearCache]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Form onSubmit={onClickSubmit}>
        <InputField id="email" name="email" value={username} placeholder={t('forms.username')} margin="normal" variant="outlined" onChange={changeUsername} />
        <InputField
          id="outlined-adornment-password"
          variant="outlined"
          placeholder={t('forms.password')}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={changePassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label={t('forms.password.visibility')} onClick={handleClickShowPassword}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <ButtonOutline type="submit">{t('forms.sign.in')}</ButtonOutline>
      </Form>
      {error && <Error variant="subtitle1">{error}</Error>}
    </>
  );
};

export default LoginForm;
