import {IAuthGroup, IAuthUser} from '@deep-planet/api-interfaces';
import {Box, MenuItem} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {baseApiUrl} from '../../../../config/const';
import theme from '../../../../utilities/theme';
import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  users: IAuthUser[];
  groups: IAuthGroup[];
}

export const ManageUser = ({users, groups}: Props) => {
  const [checked, setChecked] = React.useState([]);
  const [selectedUserName, setSelectedUsername] = useState('');
  const {t} = useTranslation();
  const [isLoadingUserGroups, setIsLoadingUserGroups] = useState<boolean>(false);
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      axios
        .post(`${baseApiUrl}/group?username=${selectedUserName}&groupname=${value}`)
        .then(() => {
          newChecked.push(value);
          setChecked(newChecked);
        })
        .catch(err => console.warn(err));
    } else {
      axios
        .delete(`${baseApiUrl}/group?username=${selectedUserName}&groupname=${value}`)
        .then(() => {
          newChecked.splice(currentIndex, 1);
          setChecked(newChecked);
        })
        .catch(err => console.warn(err));
    }
  };

  const handleUserSelect = (e: React.ChangeEvent<{name?: string; value: string}>) => {
    const selectedUser = users.find(u => u.username === e.target.value);
    setSelectedUsername(selectedUser.username);
    setIsLoadingUserGroups(true);
    axios
      .get(`${baseApiUrl}/group-names?username=${selectedUser.username}`)
      .then(groupNames => {
        setChecked(groupNames.data);
      })
      .catch(err => console.warn(err));
    setIsLoadingUserGroups(false);
  };

  return (
    <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h3" align="center" gutterBottom>
        {t('admin.permissions.title')}
      </Typography>
      <FormWrapper padding="2rem 0">
        <FormControl fullWidth>
          <FormControl variant="outlined" margin="normal">
            <InputLabel id="users-select">{t('admin.permissions.user')}</InputLabel>
            <Select labelId="org-selection-label" id="org-selection" value={selectedUserName} onChange={handleUserSelect} label={t('admin.permissions.user')}>
              {users?.map(({username}) => (
                <MenuItem key={username} value={username}>
                  {username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <>
            {isLoadingUserGroups && <MapOverlay position="relative" />}
            {!isLoadingUserGroups && (
              <List subheader={<ListSubheader>{t('admin.permissions.groups')}</ListSubheader>}>
                {groups.map(({groupName}) => (
                  <ListItem key={groupName}>
                    <ListItemText id="switch-list-label-wifi" primary={groupName} />
                    <ListItemSecondaryAction>
                      <Switch
                        disabled={setSelectedUsername === null}
                        edge="end"
                        onChange={handleToggle(groupName)}
                        checked={checked.indexOf(groupName) !== -1}
                        inputProps={{
                          'aria-labelledby': 'switch-list-label-wifi',
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </>
        </FormControl>
      </FormWrapper>
    </Box>
  );
};
