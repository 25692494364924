import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {useState} from 'react';

export function useRows(polygons?: IPolygonEntity[]) {
  const [showRows, setShowRows] = useState(false);
  const isThereRows = Boolean(polygons?.some(({rows}) => rows?.length));

  const handleSwitch = () => {
    setShowRows(!showRows);
  };

  return {
    showRows,
    isThereRows,
    handleSwitch,
  };
}
