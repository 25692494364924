import {IVarietyEntity} from '@deep-planet/api-interfaces';
import {Box, FormControl, IconButton, TextField, Typography} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import {Autocomplete} from '@material-ui/lab';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../components/UI/Pickers/SimpleDatePicket';
import {HistoricalSeasonYield, IFormError, NumericProperty, SeasonalYield} from './HistoricalYieldForm';

const StyledTh = styled.th`
  text-align: left;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
`;

const StyledTd = styled.td<{$hidden?: boolean}>`
  text-align: left;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  font-weight: normal;
  display: ${({$hidden}) => ($hidden ? 'none' : 'table-cell')};
`;

interface Props {
  varieties: IVarietyEntity[];
  rows?: SeasonalYield[];
  historicalRows?: HistoricalSeasonYield[];
  handleAddValue: (seasonId: string, harvestId: number, value: number | Date | string, property: NumericProperty | 'harvestDate' | 'variety') => void;
  getRowSpan: (season: SeasonalYield) => number;
  handleAddHarvest: (seasonId: string) => void;
  handleDeleteHarvest: (seasonId: string, harvestId: number) => void;
  getDateValue: (seasonId: string, harvestId: number) => Date;
  getVarietyValue: (seasonId: string, harvestId: number) => string;
  getNumericValue: (seasonId: string, harvestId: number, property: NumericProperty) => number | string;
  errors: IFormError[];
  polygonId: string;
}

const SaveHistoricalYieldTable = ({varieties, getVarietyValue, rows, handleAddValue, getRowSpan, handleAddHarvest, handleDeleteHarvest, getDateValue, getNumericValue, errors, polygonId}: Props) => {
  const {t} = useTranslation();
  const getError = (seasonId: string, harvestId: number, property: 'yield' | 'harvestDate' | 'variety') => {
    return !!errors?.find(e => e.seasonId === seasonId && e.harvestId === harvestId && e.error === property)?.error;
  };
  return (
    !!rows?.length && (
      <>
        <Typography variant="h6">{t('yield.historical.save')}</Typography>
        <table style={{borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              <StyledTh>{t('yield.historical.table.season')}</StyledTh>
              <StyledTh>{t('yield.historical.table.crop')}*</StyledTh>
              <StyledTh>{t('yield.historical.table.date')}*</StyledTh>
              <StyledTh>{t('yield.historical.table.yield')}*</StyledTh>
              <StyledTh>{t('yield.historical.table.baume')}</StyledTh>
              <StyledTh>{t('yield.historical.table.ph')}</StyledTh>
              <StyledTh>{t('yield.historical.table.ta')}</StyledTh>
              <StyledTh />
            </tr>
          </thead>
          <tbody>
            {rows?.map(season => (
              <tr key={`${season.id}-${polygonId}-${season.harvestId}`}>
                <StyledTd rowSpan={getRowSpan(season)} $hidden={!!season.harvestId}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">{season.name}</Typography>
                    <Typography variant="caption">{`${season.fromDate} - ${season.toDate}`}</Typography>
                  </Box>
                </StyledTd>
                <StyledTd rowSpan={getRowSpan(season)} $hidden={!!season.harvestId}>
                  <FormControl variant="outlined" style={{minWidth: '200px'}}>
                    <Autocomplete
                      value={getVarietyValue(season.id, season.harvestId)}
                      options={varieties?.map(({name}) => name) || []}
                      onChange={(e, newValue) => handleAddValue(season.id, season.harvestId, newValue, 'variety')}
                      getOptionLabel={variety => variety}
                      renderInput={params => <TextField {...params} label="Variety" variant="outlined" error={getError(season.id, season.harvestId, 'variety')} />}
                    />
                  </FormControl>
                </StyledTd>
                <StyledTd>
                  <SimpleDatePicker
                    error={getError(season.id, season.harvestId, 'harvestDate')}
                    value={getDateValue(season.id, season.harvestId)}
                    onChange={date => handleAddValue(season.id, season.harvestId, date, 'harvestDate')}
                    variant="outlined"
                  />
                </StyledTd>
                <StyledTd>
                  <TextField
                    required
                    type="number"
                    error={getError(season.id, season.harvestId, 'yield')}
                    aria-valuemin={0}
                    placeholder={`${t('yield.historical.table.yield')} (t)`}
                    variant="outlined"
                    onChange={e => handleAddValue(season.id, season.harvestId, Number(e.target.value), 'yield')}
                    value={getNumericValue(season.id, season.harvestId, 'yield')}
                  />
                </StyledTd>
                <StyledTd>
                  <TextField
                    type="number"
                    aria-valuemin={0}
                    placeholder={t('yield.historical.table.baume')}
                    variant="outlined"
                    onChange={e => handleAddValue(season.id, season.harvestId, Number(e.target.value), 'baume')}
                    value={getNumericValue(season.id, season.harvestId, 'baume')}
                  />
                </StyledTd>
                <StyledTd>
                  <TextField
                    type="number"
                    aria-valuemin={0}
                    placeholder={t('yield.historical.table.ph')}
                    variant="outlined"
                    onChange={e => handleAddValue(season.id, season.harvestId, Number(e.target.value), 'pH')}
                    value={getNumericValue(season.id, season.harvestId, 'pH')}
                  />
                </StyledTd>
                <StyledTd>
                  <TextField
                    type="number"
                    aria-valuemin={0}
                    placeholder="TA"
                    variant="outlined"
                    onChange={e => handleAddValue(season.id, season.harvestId, Number(e.target.value), 'titratableAcid')}
                    value={getNumericValue(season.id, season.harvestId, 'titratableAcid')}
                  />
                </StyledTd>
                <StyledTd>
                  <Box display="flex">
                    <IconButton onClick={() => handleAddHarvest(season.id)}>
                      <AddCircleIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteHarvest(season.id, season.harvestId)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </StyledTd>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  );
};

export default SaveHistoricalYieldTable;
