import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {ConfirmationDialog} from '../../../../../components/UI/Dialog/ConfirmationDialog';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import {baseApiUrl} from '../../../../../config/const';
import {useHttp} from '../../../../../hooks/http';
import {enqueueSnackbar} from '../../../../../store/actions';
import theme from '../../../../../utilities/theme';
import {ChangeOrganizationDetails} from './ChangeOrganizationDetails';
import {sortBy} from 'lodash';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const ManageOrganization = () => {
  const [isLoadingOrganizations, organizations, setOrganizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);

  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleOrganizationSelect = (organization: IOrganizationEntity) => {
    setSelectedOrganization(organization);
    setOrganizationName(organization.name);
  };

  const handleClickOnDelete = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleOrganizationDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${baseApiUrl}/organization/${selectedOrganization.id}`);
      dispatch(enqueueSnackbar({message: 'Organization successfully deleted', options: {variant: 'success'}}));
      setIsDeleteDialogOpen(false);
      handleSuccessfulOrganizationDelete(selectedOrganization.id);
      setSelectedOrganization(null);
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameChange = (name: string) => {
    setOrganizationName(name);
  };

  const handleOrganizationUpdate = async () => {
    try {
      setIsLoading(true);
      await axios.put(`${baseApiUrl}/organization/${selectedOrganization.id}`, {name: organizationName});
      dispatch(enqueueSnackbar({message: 'Organization successfully updated', options: {variant: 'success'}}));
      handleSuccessfulOrganizationUpdate(selectedOrganization.id, organizationName);
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccessfulOrganizationDelete = (id: string) => {
    setOrganizations(organizations.filter(o => o.id !== id));
  };

  const handleSuccessfulOrganizationUpdate = (id: string, name: string) => {
    setOrganizations(
      organizations.map(org => {
        if (id === org.id)
          return {
            ...org,
            name,
          };
        return org;
      })
    );
  };

  return (
    <>
      {isLoadingOrganizations && <MapOverlay />}
      {!isLoadingOrganizations && (
        <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3" align="center" gutterBottom>
            Manage Organization Details
          </Typography>

          <FormWrapper padding="2rem 0">
            <FormControl fullWidth variant="outlined">
              <FormControl variant="outlined" style={{minWidth: '200px'}}>
                <Autocomplete
                  value={selectedOrganization}
                  options={sortBy(organizations, o => o.name.toLowerCase())}
                  onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                  getOptionLabel={({name}) => name}
                  renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
                />
              </FormControl>

              {selectedOrganization && <ChangeOrganizationDetails organizationName={organizationName} handleNameChange={handleNameChange} />}

              <Box display="flex" justifyContent="flex-end" padding="2rem 0">
                <Button onClick={handleClickOnDelete} variant="contained" color="secondary" disabled={!selectedOrganization || isLoading}>
                  Delete
                </Button>
                <Box padding="0 0 0 1rem">
                  <Button onClick={handleOrganizationUpdate} variant="contained" color="primary" disabled={!selectedOrganization || isLoading}>
                    Save
                  </Button>
                </Box>
              </Box>
            </FormControl>
          </FormWrapper>
        </Box>
      )}

      <ConfirmationDialog
        open={isDeleteDialogOpen}
        handleClose={() => setIsDeleteDialogOpen(false)}
        isLoading={isLoading}
        handleSubmit={handleOrganizationDelete}
        submitText={'Delete'}
        title={"You're about to permanently delete the selected organization with its every users and farms"}
      />
    </>
  );
};
