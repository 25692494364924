import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/organization';
import {updateObject} from '../utility';

export const getOrganizationsSelector = (state: OrganizationState) => state.getOrganizations;
export const organizationsSelector = (state: OrganizationState) => state.organizations;
export const selectedOrganizationSelector = (state: OrganizationState) => state.selectedOrganization;

export interface OrganizationState {
  getOrganizations: {
    loading: boolean;
    error: unknown;
  };
  organizations: IOrganizationEntity[];
  selectedOrganization: IOrganizationEntity;
}

const initialState: OrganizationState = {
  getOrganizations: {
    loading: false,
    error: null,
  },
  organizations: null,
  selectedOrganization: null,
};

const reducer = (state: OrganizationState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.ORGANIZATIONS_GET_START:
      return updateObject(state, {
        getOrganizations: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.ORGANIZATIONS_GET_SUCCESS:
      return updateObject(state, {
        getOrganizations: {
          loading: false,
          error: null,
        },
        organizations: action.organizations.sort((o1, o2) => {
          if (o1.name.toLowerCase() < o2.name.toLowerCase()) return -1;
          if (o1.name.toLowerCase() > o2.name.toLowerCase()) return 1;
          return 0;
        }),
        selectedOrganization: action.organizations.find(organization => organization.organizationToUsers.find(otu => otu.default)),
      });
    case ActionTypes.ORGANIZATIONS_GET_FAIL:
      return updateObject(state, {
        getOrganizations: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.SET_SELECTED_ORGANIZATION:
      return updateObject(state, {
        selectedOrganization: action.organization,
      });
    default:
      return state;
  }
};

export default reducer;
