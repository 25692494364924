import {Typography} from '@material-ui/core';
import {chain} from 'lodash';
import React from 'react';
import {useDropzone} from 'react-dropzone';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {ISelectedFile} from '../../../containers/pages/Notes/withEdit';
import {enqueueSnackbar} from '../../../store/actions';
import theme from '../../../utilities/theme';

export const DropArea = styled.div`
  border: 1px dashed rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: auto;
  width: 100%;
  padding: 18.5px 14px;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    height: 100%;
    width: 100%;
  }
`;

interface Props {
  title?: string;
  disabled?: boolean;
  acceptedExtensions: string[];
  maxSize: number;
  maxFiles: number;
  onDrop: (acceptedFiles: ISelectedFile[]) => void;
  onDropRejected?: (e: {errors: {message: string}[]}[]) => void;
}

const FileUpload = ({onDrop, title, maxSize, acceptedExtensions, maxFiles, disabled = false, onDropRejected}: Props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleOnDropRejected = (e: {errors: {message: string}[]}[]) => {
    const errorsArray = e.map(({errors}) => errors.map(({message}) => message));
    const errors = chain(errorsArray).flatten().uniq().value();
    for (const error of errors) {
      dispatch(enqueueSnackbar({message: error, options: {variant: 'error'}}));
    }
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDropRejected: onDropRejected || handleOnDropRejected,
    onDrop,
    accept: acceptedExtensions,
    maxSize: maxSize,
    maxFiles: maxFiles,
    disabled: disabled,
  });

  return (
    <DropArea {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <Typography>{t('file.upload.drop')}</Typography> : <Typography>{title || t('file.upload.drag.n.drop')}</Typography>}
    </DropArea>
  );
};

export default FileUpload;
