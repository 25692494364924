import React from 'react';
import {MenuItem} from '@material-ui/core';
import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {ALL} from './getUserFarms';

export const getUserNamesForOrganization = (organizations: IOrganizationEntity[], organizationId: string) => {
  const elements = [];
  elements.push(
    <MenuItem key={ALL} value={ALL}>
      {ALL}
    </MenuItem>
  );
  organizations &&
    organizations
      .filter(o => o.id === organizationId)
      .map(o => {
        o.organizationToUsers.map(ou => {
          elements.push(
            <MenuItem key={ou.user.username} value={ou.user.username}>
              {ou.user.username}
            </MenuItem>
          );
        });
      });
  return elements;
};
