import React from 'react';
import BasePage from '../index';
import ColorSchemeLegend from '../../../../components/UI/Legend/ColorSchemeLegend';
import {primaryColorGradient} from '../../../../utilities/theme';

const GrasslandBiomass = () => {
  return (
    <BasePage
      product={'GrasslandBiomass'}
      infoSecondaryTitle={'Grassland Biomass (kg/ha)'}
      soilSignalValueName={'Grassland Biomass'}
      legend={<ColorSchemeLegend title="Grassland Biomass (kg/ha)" scale={['2100', '2200+']} gradient={primaryColorGradient} />}
    />
  );
};

export default GrasslandBiomass;
