import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import Layout from '../../Layout';
import Sidebar from './Sidebar';
import Map from './Map';
import pathHOC from '../../pathHOC';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import SingleColorLegend from '../../../components/UI/Legend/SingleColorLegend';
import {withUser} from '../../../hooks/useAuth';

const DEFAULT_STATE = {
  snapshot: false,
  defaultCenter: {lat: 38.29321210263598, lng: -122.12588788095093},
  saveCenter: false,
  imagesList: null,
  dateIndex: 0,
  imageToDisplay: null,
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;
class Segmentation extends React.Component {
  state = {
    ...DEFAULT_STATE,
  };

  handleSnapshotTaken = () => {
    this.setState({snapshot: !this.state.snapshot});
  };

  handleCenterSaved = () => {
    this.setState({saveCenter: true});
  };

  handleCenterReset = () => {
    this.setState({saveCenter: false});
  };

  handleImageListReset = () => {
    this.setState({imagesList: []});
  };

  handlepassCenterUpstate = center => {
    this.setState({center: center});
  };

  handleUpdateDateIndex = idx => {
    this.setState({dateIndex: idx});
  };

  componentDidMount = () => {
    this.props.onGetSegment(this.props.user.username);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.segment !== this.props.segment && this.props.segment !== null && this.props.segment !== '') {
      if (this.props.segment.processedImages && this.props.segment.processedImages.length > 0) {
        this.setState({
          defaultCenter: {
            lat: this.props.segment.centerPoint.lat,
            lng: this.props.segment.centerPoint.long,
          },

          imageToDisplay: this.props.segment.processedImages[0].imageUrl,
          imagesList: this.props.segment.processedImages.reverse(),
        });
      }
    }

    if (prevState.saveCenter !== this.state.saveCenter && this.state.saveCenter === false) {
      this.setState({center: null});
    }

    if (this.state.dateIndex !== prevState.dateIndex) {
      this.setState({
        imageToDisplay: null,
      });
    }

    if (this.state.imageToDisplay !== prevState.imageToDisplay && this.state.imageToDisplay === null) {
      this.setState({
        imageToDisplay: this.props.segment.processedImages[this.state.dateIndex].imageUrl,
      });
    }
  };

  render() {
    const {defaultCenter, center, saveCenter, snapshot, imageToDisplay, imagesList} = this.state;
    const {user, segment, onPostSegment, onResetSegment, loadingPostSegment, loadingGetSegment, t} = this.props;

    return (
      <Layout>
        <Container>
          <SingleColorLegend legends={[{color: '#b22222', label: t('segmentation.legend')}]} />
          <ContentLeftSidebar
            wider={true}
            sidebar={
              <Sidebar
                handleCenterSaved={this.handleCenterSaved}
                handleCenterReset={this.handleCenterReset}
                handleImageListReset={this.handleImageListReset}
                handleSnapshotTaken={this.handleSnapshotTaken}
                handleUpdateDateIndex={this.handleUpdateDateIndex}
                centerCoords={center}
                user={user}
                segment={segment}
                imagesList={imagesList}
                onPostSegment={onPostSegment}
                onResetSegment={onResetSegment}
                loadingPostSegment={loadingPostSegment}
                loadingGetSegment={loadingGetSegment}
              />
            }
            content={
              <>
                <Box m={2} ml={5} alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    {t('segmentation.title')}
                  </Typography>
                </Box>
                <Map
                  defaultCenter={defaultCenter}
                  imageToDisplay={imageToDisplay}
                  snapshotTaken={snapshot}
                  segment={segment}
                  handlepassCenterUpstate={this.handlepassCenterUpstate}
                  handleCenterReset={this.handleCenterReset}
                  handleImageListReset={this.handleImageListReset}
                  saveCenter={saveCenter}
                  centerCoords={center}
                />
              </>
            }
          />
        </Container>
      </Layout>
    );
  }
}

//  map needs to store the center value in state above whenever it updates or(next step pressed)
//  user clicks take snapshot the request fires
//  show loading
//  pass image and bounds to Map container, which overlays the image over map
//  click reset to start again

const mapStateToProps = state => {
  return {
    segment: state.segmentation.segment,
    loadingGetSegment: state.segmentation.getSegment.loading,
    loadingPostSegment: state.segmentation.postSegment.loading,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetSegment: userId => dispatch(actions.getSegment(userId)),
    onPostSegment: (userId, payload) => dispatch(actions.postSegment(userId, payload)),
    onResetSegment: () => dispatch(actions.segReset()),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(pathHOC(withUser(Segmentation))));
