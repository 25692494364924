import i18n from '../../../i18n';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {baseApiUrl} from '../../config/const';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import * as actions from './index';
import {IUserDetails} from '../reducers/user';
import {IUpdateUserPreferencesRequest, IUserPreferenceEntity, IUserEntity, IUpdateUser, CreateUserDTO} from '@deep-planet/api-interfaces';

//////////////////////////////////////////
// GET user
//////////////////////////////////////////

interface IUserGetStart {
  type: ActionTypes.USER_GET_START;
}

export const userGetStart = (): IUserGetStart => ({
  type: ActionTypes.USER_GET_START,
});

interface IUserGetSuccess {
  type: ActionTypes.USER_GET_SUCCESS;
  payload: any;
}

export const userGetSuccess = (userDetails): IUserGetSuccess => ({
  type: ActionTypes.USER_GET_SUCCESS,
  payload: userDetails,
});

interface IUserGetFail {
  type: ActionTypes.USER_GET_FAIL;
  error: unknown;
}

export const userGetFail = (error): IUserGetFail => ({
  type: ActionTypes.USER_GET_FAIL,
  error: error,
});

export const getUser = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(userGetStart());
    const url = `${baseApiUrl}/user`;
    axios
      .get<IUserEntity>(url)
      .then(({data: {username, firstName, lastName, address, activeFarmId, preferences}}) => {
        // TODO: do not switching up id with name
        const userDetails: IUserDetails = {userid: username, firstName, lastName, address, activeFarm: activeFarmId, preferences};
        dispatch(userGetSuccess(userDetails));
      })
      .catch(err => {
        dispatch(userGetFail(err));
      });
  };
};

//////////////////////////////////////////
// GET users
//////////////////////////////////////////

interface IUsersGetStart {
  type: ActionTypes.USERS_GET_START;
}

export const usersGetStart = (): IUsersGetStart => ({
  type: ActionTypes.USERS_GET_START,
});

interface IUsersGetSuccess {
  type: ActionTypes.USERS_GET_SUCCESS;
  payload: string[];
}

export const usersGetSuccess = (usernames: string[]): IUsersGetSuccess => ({
  type: ActionTypes.USERS_GET_SUCCESS,
  payload: usernames,
});

interface IUsersGetFail {
  type: ActionTypes.USERS_GET_FAIL;
  error: unknown;
}

export const usersGetFail = (error): IUsersGetFail => ({
  type: ActionTypes.USERS_GET_FAIL,
  error: error,
});

export const getUsers = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const url = `${baseApiUrl}/users`;
      dispatch(usersGetStart());
      const {data} = await axios.get<IUserEntity[]>(url);
      dispatch(usersGetSuccess(data.map(({username}) => username)));
    } catch (err) {
      dispatch(usersGetFail(err));
    }
  };
};

export const getOrganizationUsers = (username: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const url = `${baseApiUrl}/organizations/users?username=${encodeURIComponent(username)}`;
      dispatch(usersGetStart());
      const {data} = await axios.get<IUserEntity[]>(url);
      dispatch(usersGetSuccess(data.map(({username}) => username)));
    } catch (err) {
      dispatch(usersGetFail(err));
    }
  };
};

//////////////////////////////////////////
// POST user
//////////////////////////////////////////

interface IUserPostInit {
  type: ActionTypes.USER_POST_INIT;
}

export const userPostInit = (): IUserPostInit => ({
  type: ActionTypes.USER_POST_INIT,
});

interface IUserPostStart {
  type: ActionTypes.USER_POST_START;
}

export const userPostStart = (): IUserPostStart => ({
  type: ActionTypes.USER_POST_START,
});

interface IUserPostSuccess {
  type: ActionTypes.USER_POST_SUCCESS;
}

export const userPostSuccess = (): IUserPostSuccess => ({
  type: ActionTypes.USER_POST_SUCCESS,
});

interface IUserPostFail {
  type: ActionTypes.USER_POST_FAIL;
  error: any;
}

export const userPostFail = (error): IUserPostFail => ({
  type: ActionTypes.USER_POST_FAIL,
  error: error,
});

export const postUser = (payload: CreateUserDTO, callback: (username: string) => void) => {
  return (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(userPostStart());
    const url = `${baseApiUrl}/user`;
    axios
      .post(url, payload)
      .then(() => {
        dispatch(userPostSuccess());
        dispatch(enqueueSnackbar({message: i18n.t('admin.user.added'), options: {variant: 'success'}}));
        callback(payload.username);
      })
      .catch(err => {
        dispatch(userPostFail(err));
        if (err?.response?.data?.message?.includes('Password must have symbol characters')) {
          dispatch(
            enqueueSnackbar({
              message: `${err?.response?.data?.message} from the following list: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \`` || i18n.t('error.http.response'),
              options: {variant: 'error'},
            })
          );
        } else {
          dispatch(enqueueSnackbar({message: err?.response?.data?.message || i18n.t('error.http.response'), options: {variant: 'error'}}));
        }
      });
  };
};

//////////////////////////////////////////
// PUT user
//////////////////////////////////////////

interface IUserPutStart {
  type: ActionTypes.USER_PUT_START;
}

export const userPutStart = (): IUserPutStart => ({
  type: ActionTypes.USER_PUT_START,
});

interface IUserPutSuccess {
  type: ActionTypes.USER_PUT_SUCCESS;
  payload: any;
}

export const userPutSuccess = (userDetails): IUserPutSuccess => ({
  type: ActionTypes.USER_PUT_SUCCESS,
  payload: userDetails,
});

interface IUserPutFail {
  type: ActionTypes.USER_PUT_FAIL;
  error: unknown;
}

export const userPutFail = (error): IUserPutFail => ({
  type: ActionTypes.USER_PUT_FAIL,
  error: error,
});

export const putUser = (payload: IUpdateUser) => {
  return (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(userPutStart());
    const url = `${baseApiUrl}/user`;
    axios
      .put<IUserEntity>(url, payload)
      .then(({data: {username, firstName, lastName, address, activeFarmId, preferences}}) => {
        // TODO: do not switching up id with name
        const userDetails: IUserDetails = {userid: username, firstName, lastName, address, activeFarm: activeFarmId, preferences};
        dispatch(userPutSuccess(userDetails));
        dispatch(
          actions.enqueueSnackbar({
            message: i18n.t('account.details.updated'),
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(err => {
        dispatch(userPutFail(err));
      });
  };
};

//////////////////////////////////////////
// POST user log
//////////////////////////////////////////

export const createUserLog = (event: string) => {
  return async () => {
    const url = `${baseApiUrl}/user/log`;
    let country = '';
    try {
      country = await getCountry();
      axios.post(url, {event, country});
    } catch (e) {
      console.log(e);
    }
  };
};

const getCountry = (): Promise<string> => {
  return new Promise(resolve => {
    if (window?.navigator?.geolocation) {
      window?.navigator?.geolocation?.getCurrentPosition(
        ({coords}) => {
          const geoCoder = new google.maps.Geocoder();
          const latlng = new google.maps.LatLng(coords.latitude, coords.longitude);
          geoCoder.geocode(
            {
              location: latlng,
            },
            function (results, status) {
              if (status === google.maps.GeocoderStatus.OK) {
                if (results.length) {
                  const country = results[results.length - 1]['formatted_address'];
                  return resolve(country);
                }
                return resolve('');
              } else {
                return resolve('');
              }
            }
          );
        },
        () => resolve('')
      );
    } else {
      return resolve('');
    }
  });
};

//////////////////////////////////////////
// PUT user preferences
//////////////////////////////////////////

interface IUserPutPreferencesStart {
  type: ActionTypes.USER_PUT_PREFERENCES_START;
}

export const userPutPreferencesStart = (): IUserPutPreferencesStart => ({
  type: ActionTypes.USER_PUT_PREFERENCES_START,
});

interface IUserPutPreferencesSuccess {
  type: ActionTypes.USER_PUT_PREFERENCES_SUCCESS;
  updatedPreferences: IUserPreferenceEntity[];
}

export const userPutPreferencesSuccess = (updatedPreferences: IUserPreferenceEntity[]): IUserPutPreferencesSuccess => ({
  type: ActionTypes.USER_PUT_PREFERENCES_SUCCESS,
  updatedPreferences,
});

interface IUserPutPreferencesFail {
  type: ActionTypes.USER_PUT_PREFERENCES_FAIL;
  error: unknown;
}

export const userPutPreferencesFail = (error: unknown): IUserPutPreferencesFail => ({
  type: ActionTypes.USER_PUT_PREFERENCES_FAIL,
  error,
});

export const putUserPreferences = (payload: IUpdateUserPreferencesRequest) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(userPutPreferencesStart());
    const url = `${baseApiUrl}/user/preferences`;
    try {
      const {data} = await axios.put<IUserPreferenceEntity[]>(url, payload);
      dispatch(userPutPreferencesSuccess(data));
      dispatch(enqueueSnackbar({message: i18n.t('account.preferences.updated'), options: {variant: 'success'}}));
    } catch (err) {
      dispatch(userPutPreferencesFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

export type Action =
  | ReturnType<typeof userGetStart>
  | ReturnType<typeof userGetSuccess>
  | ReturnType<typeof userGetFail>
  | ReturnType<typeof usersGetStart>
  | ReturnType<typeof usersGetSuccess>
  | ReturnType<typeof usersGetFail>
  | ReturnType<typeof userPostInit>
  | ReturnType<typeof userPostStart>
  | ReturnType<typeof userPostSuccess>
  | ReturnType<typeof userPostFail>
  | ReturnType<typeof userPutStart>
  | ReturnType<typeof userPutSuccess>
  | ReturnType<typeof userPutFail>
  | ReturnType<typeof userPutPreferencesStart>
  | ReturnType<typeof userPutPreferencesSuccess>
  | ReturnType<typeof userPutPreferencesFail>;
