import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ICreateNoteDTO, IMarker, IMarkerColor, IMarkerTypeUI, IPolygonEntity} from '@deep-planet/api-interfaces';
import {MarkerEntity} from 'libs/database/src/lib/modules/marker/marker.entity';
import {IFarm} from '../store/reducers/farm';
import {getMarkerSelector, markerColorSelector, markersSelector} from '../store/selectors/marker';
import {getStringDate} from '../helpers/dateHelpers';
import {deleteMarker, getMarkers, postMarker} from '../store/actions/marker';
import {selectedOrganizationSelector} from '../store/selectors';
import {ISelectedFile} from '../containers/pages/Notes/withEdit';
import {usePrevious} from './usePrevious';
import red_dot from '../../assets/red_dot.svg';
import blue_dot from '../../assets/blue_dot.svg';
import yellow_dot from '../../assets/yellow_dot.svg';
import purple_dot from '../../assets/purple_dot.svg';
import maroon_dot from '../../assets/maroon_dot.svg';
import lime_dot from '../../assets/lime_dot.svg';
import olive_dot from '../../assets/olive_dot.svg';

export function useMarker(farm?: IFarm) {
  const [newMarker, setNewMarker] = useState<IMarker>(null);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedUser, setSelectedUser] = useState<string>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPin, setSelectedPin] = useState<IMarkerColor>(null);
  const [selectedMarkerFeature, setSelectedMarkerFeature] = useState<IMarkerColor | IMarkerTypeUI>(null);
  const [selectedMarker, setSelectedMarker] = useState<IMarker>(null);
  const [markerPolygon, setMarkerPolygon] = useState<IPolygonEntity>(null);
  const [mouseOverMarker, setMouseOverMarker] = useState<IMarker>(null);
  const [isOpenMarkerInfoWindow, setIsOpenMarkerInfoWindow] = useState<boolean>(null);
  const [isOpenModalNoteUpdate, setIsOpenModalNoteUpdate] = useState<boolean>(false);
  const {loading} = useSelector(getMarkerSelector);
  const organization = useSelector(selectedOrganizationSelector);
  const {loading: postMarkerLoading} = useSelector(getMarkerSelector);
  const markerColors = useSelector(markerColorSelector);
  const markers = useSelector(markersSelector);
  const prevMarkers = usePrevious(markers);
  const dispatch = useDispatch();
  // assign SVG icon to the marker
  const getIcon = (color: string) => {
    return {
      icon: {url: getMarkerIcon(color)},
    };
  };

  //set color of the markers
  const getMarkerIcon = (color: string) => {
    switch (color?.toUpperCase()) {
      case 'BLUE':
        return blue_dot;
      case 'YELLOW':
        return yellow_dot;
      case 'PURPLE':
        return purple_dot;
      case 'MAROON':
        return maroon_dot;
      case 'LIME':
        return lime_dot;
      case 'RED':
        return red_dot;
      default:
        return olive_dot;
    }
  };

  // set start date on marker filter
  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };

  // set end date on marker filter
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  // create a new marker with exact coordinates
  const handleOnClickMap = (latLng: google.maps.LatLng, polygon: IPolygonEntity) => {
    if (selectedPin) {
      setMarkerPolygon(polygon);
      const {icon} = getIcon(selectedPin.color);
      const newMarkerLocation: IMarker = new MarkerEntity();
      const feature = selectedPin.feature;
      newMarkerLocation.farm = {id: farm.id, name: farm.name};
      newMarkerLocation.feature = feature;
      newMarkerLocation.color = selectedPin;
      newMarkerLocation.date = new Date();
      newMarkerLocation.latitude = latLng.lat();
      newMarkerLocation.longitude = latLng.lng();
      newMarkerLocation.icon = icon;
      // there is a possibility of farm hasn't have an owner organization
      newMarkerLocation.organizationId = farm?.ownerOrganization?.id || null;
      setNewMarker(newMarkerLocation);
      setIsModalOpen(true);
    }
  };

  // create a new marker with note
  const handleMarkerSubmit = (form: ICreateNoteDTO, files: ISelectedFile[]) => {
    // some farms may not have owner organization
    const marker = newMarker.organizationId ? newMarker : {...newMarker, organizationId: form.organizationId};
    dispatch(postMarker(form, marker, files, handleCloseModal));
    setSelectedMarkerFeature({color: selectedPin.color, feature: {name: selectedPin.feature.name}});
  };

  // get all markers
  const handleGetMarkers = () => {
    dispatch(getMarkers(farm, getStringDate(startDate), getStringDate(endDate), organization.id, selectedUser));
  };

  // close the modal dialog box
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // selected pin of respective feature to create a new marker
  const handleSelectedPin = m => {
    setSelectedPin(m);
  };

  // Show all markers by default upon get all makers
  const handleMarkerPostRequest = () => {
    setSelectedMarkerFeature({color: 'BLUE', feature: {name: 'SHOW ALL'}});
  };

  // filter markers by feature
  const handleMarkerFeatureSelection = m => {
    setSelectedMarkerFeature(m);
  };

  //when click on marker, opens a note model box
  const handleMarkerClick = (marker: IMarker) => {
    setIsOpenModalNoteUpdate(true);
    setSelectedMarker(marker);
  };

  // show note summary
  const handleMarkerMouseOver = (marker: IMarker) => {
    setIsOpenMarkerInfoWindow(true);
    setMouseOverMarker(marker);
  };

  // disable thesummary info window
  const handleMarkerMouseOut = (marker: IMarker) => {
    setIsOpenMarkerInfoWindow(false);
    setMouseOverMarker(marker);
  };

  // close the marker note's modal dialog box
  const handleNoteUpdateClose = () => {
    setIsOpenModalNoteUpdate(false);
  };

  // delete marker permanently
  const handleDeleteMarker = (id: string) => {
    setIsOpenModalNoteUpdate(false);
    //@ts-ignore
    const noteId = selectedMarker?.note?.id;
    dispatch(deleteMarker(id, noteId));
  };

  // set selected user
  const handleUserSelection = (user: string) => {
    setSelectedUser(user);
  };

  // get marker colors with assigned feature
  useEffect(() => {
    if (selectedMarker && prevMarkers !== markers) {
      const marker = markers.find(m => m.id === selectedMarker.id);
      if (marker) setSelectedMarker(marker);
    }
  }, [dispatch, farm, markers, prevMarkers, selectedMarker]);

  return {
    selectedMarkerFeature,
    markers,
    newMarker,
    loading,
    postMarkerLoading,
    markerColors,
    startDate,
    endDate,
    isModalOpen,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    selectedUser,
    markerPolygon,
    setSelectedPin,
    getMarkerIcon,
    handleOnClickMap,
    handleStartDateSelection,
    handleEndDateSelection,
    handleGetMarkers,
    handleCloseModal,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
    handleUserSelection,
  };
}
