import {CreateFeedbackDTO} from '@deep-planet/api-interfaces';
import {Box, Checkbox, FormControlLabel, FormHelperText, TextField, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import {Rating} from '@material-ui/lab';
import {union, uniqBy} from 'lodash';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import FileUpload from '../../../components/UI/FIleUpload/FileUpload';
import Modal from '../../../components/UI/Modal/Modal';
import {enqueueSnackbar} from '../../../store/actions';
import {createFeedback} from '../../../store/actions/general';
import {feedbackPostSelector} from '../../../store/selectors';
import {ISelectedFile} from '../../pages/Notes/withEdit';

interface IFormError {
  description: {
    value: boolean;
    text: string;
  };
  rating: {
    value: boolean;
    text: string;
  };
}

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
}

const MAX_FILE_SIZE = 1024 * 1024 * 15; // 15mb
const ACCEPTED_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.xls', '.pdf', '.doc', '.docx', '.xlsx'];
const MAX_FILE_COUNT = 10;
const MAX_DESCRIPTION_LENGTH = 2500;

const FeedbackModal = ({isOpen, handleCloseModal}: Props) => {
  const [description, setDescription] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [rating, setRating] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState<ISelectedFile[]>([]);
  const [errors, setErrors] = useState<IFormError>({
    description: {
      value: false,
      text: '',
    },
    rating: {
      value: false,
      text: '',
    },
  });

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {loading} = useSelector(feedbackPostSelector);

  const handleChangeDescription = (currentDescription: string) => {
    setDescription(currentDescription);

    if (!currentDescription.length) {
      setErrors({...errors, description: {value: true, text: t('forms.mandatory.to.fill')}});
    } else if (currentDescription.length > MAX_DESCRIPTION_LENGTH) {
      setErrors({...errors, description: {value: true, text: t('forms.shorter.or.equal', {length: MAX_DESCRIPTION_LENGTH})}});
    } else {
      setErrors({...errors, description: {value: false, text: ''}});
    }
  };

  const handleChangeRating = (currentRating: number) => {
    setRating(currentRating);
    if (!currentRating) {
      setErrors({...errors, rating: {value: true, text: t('forms.mandatory.to.select')}});
    } else {
      setErrors({...errors, rating: {value: false, text: ''}});
    }
  };

  const handleSubmit = () => {
    const currentErrors: IFormError = {
      description: {
        value: false,
        text: '',
      },
      rating: {
        value: false,
        text: '',
      },
    };
    if (!rating) {
      currentErrors.rating.value = true;
      currentErrors.rating.text = t('forms.mandatory.to.select');
    }
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      currentErrors.description.value = true;
      currentErrors.description.text = t('forms.shorter.or.equal', {length: MAX_DESCRIPTION_LENGTH});
    }
    if (!description.length) {
      currentErrors.description.value = true;
      currentErrors.description.text = t('forms.mandatory.to.fill');
    }
    setErrors(currentErrors);
    if (!currentErrors.rating.value && !currentErrors.description.value) {
      const payload: CreateFeedbackDTO = {
        description,
        rating,
        followup: isChecked,
      };
      dispatch(createFeedback(payload, selectedFiles, handleCloseModal));
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: ISelectedFile[]) => {
      const size = [...selectedFiles, ...acceptedFiles].reduce((acc, item) => item.size + acc, 0);
      if (acceptedFiles.length <= MAX_FILE_COUNT && size <= MAX_FILE_SIZE) {
        setSelectedFiles(uniqBy(union(selectedFiles, acceptedFiles), 'name'));
      } else if (size > MAX_FILE_SIZE) {
        dispatch(enqueueSnackbar({message: t('file.max.size'), options: {variant: 'error'}}));
      } else {
        dispatch(enqueueSnackbar({message: t('file.upload.invalid'), options: {variant: 'error'}}));
      }
    },
    [dispatch, selectedFiles, t]
  );

  const handleNoteAttachmentCancel = (nameToCancel: string) => {
    const files = selectedFiles.filter(({name}) => name !== nameToCancel);
    setSelectedFiles(files);
  };

  return (
    <Modal
      title={t('feedback.modal.title')}
      submitText={t('forms.send')}
      isOpen={isOpen}
      isSubmitButtonDisabled={loading}
      isLoading={loading}
      handleClose={handleCloseModal}
      handleSubmit={handleSubmit}
    >
      <form>
        <Box display="display" flexDirection="column">
          <Typography>{t('feedback.subtitle')}</Typography>

          <Box margin="1rem 0">
            <TextField
              required
              multiline
              rows={5}
              fullWidth
              disabled={loading}
              variant="outlined"
              value={description}
              placeholder={t('feedback.description')}
              onChange={({target: {value}}) => handleChangeDescription(value)}
              error={errors.description.value}
              helperText={errors.description.value ? errors.description.text : ''}
            />
          </Box>

          <FileUpload onDrop={onDrop} title={t('feedback.attachments')} maxSize={MAX_FILE_SIZE} acceptedExtensions={ACCEPTED_EXTENSIONS} maxFiles={MAX_FILE_COUNT} />
          <Box display="flex" flexDirection="column" mt={2} mb={2}>
            {selectedFiles.map(file => (
              <Box alignItems="center" display="flex" key={file.name}>
                <Typography>{file.name}</Typography>
                <IconButton disabled={loading} onClick={() => handleNoteAttachmentCancel(file.name)}>
                  <ClearIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box display="display" flexDirection="column" margin="2rem 0">
            <Typography>{t('feedback.recommend')}</Typography>
            <Rating name={'rating'} value={rating} max={10} onChange={(_, rating) => handleChangeRating(rating)} />
            {errors.rating.value && <FormHelperText error={errors.rating.value}>{errors.rating.text}</FormHelperText>}
          </Box>
          <FormControlLabel control={<Checkbox color="primary" checked={isChecked} onChange={() => setIsChecked(!isChecked)} name={'name'} />} label={t('feedback.followup')} />
        </Box>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
