import {GenerateMaturityMapRequest, IFarmEntity, IFarmSettings, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box, Button, FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../../../../components/UI/Pickers/SimpleDatePicket';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseApiUrl, baseMaturityMapUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';
import moment from 'moment';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface MaturityMapResult {
  images: string[];
  image_names: string[];
}

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
  farmSettings: IFarmSettings;
  isLoadingFarmSettings: boolean;
}

export const MaturityMap = ({farm, organization, farmSettings, isLoadingFarmSettings}: Props) => {
  const [selectedSugarType, setSelectedSugarType] = useState('Baume');
  const [seasonStartDate, setSeasonStartDate] = useState<Date>(new Date());
  const [fruitSetDate, setFruitSetDate] = useState<Date>(new Date());
  const [predictionDate, setPredictionDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload: GenerateMaturityMapRequest = {
        sugarType: selectedSugarType,
        seasonStartDate: moment(seasonStartDate).format('YYYY-MM-DD'),
        fruitSetDate: moment(fruitSetDate).format('YYYY-MM-DD'),
        predictionDate: moment(predictionDate).format('YYYY-MM-DD'),
        farmId: farm.id,
      };
      await axios.post<MaturityMapResult>(`${baseMaturityMapUrl}/maturity-map`, payload);
      dispatch(enqueueSnackbar({message: 'Maturity map images are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (farmSettings) {
      setSeasonStartDate(farmSettings.seasonStartDate);
      setFruitSetDate(farmSettings.fruitSetDate);
    }
  }, [farmSettings]);

  const isLoading = isLoadingFarmSettings || loading;

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            <FormControl style={{width: '100%'}}>
              <InputLabel id="sugar-type-selection">Sugar Type</InputLabel>
              <Select
                labelId="sugar-type-selection"
                value={selectedSugarType}
                onChange={e => setSelectedSugarType(e.target.value as string)}
                displayEmpty
                name="sugar-type"
                style={{width: '100%'}}
                fullWidth
                disabled={isLoading}
              >
                {['Baume'].map(sugarType => (
                  <MenuItem key={sugarType} value={sugarType}>
                    {sugarType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={seasonStartDate} onChange={date => setSeasonStartDate(date)} label={'Season start date'} disabled={isLoading} />
            </Box>
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={fruitSetDate} onChange={date => setFruitSetDate(date)} label={'Fruit set date'} disabled={isLoading} />
            </Box>
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={predictionDate} onChange={date => setPredictionDate(date)} label={'Prediction date'} disabled={isLoading} />
            </Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={isLoading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {isLoading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
