import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Layout from '../../../Layout';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import {ManageFeaturePermission} from './Permissions/ManageFeaturePermission';
import {Imagery} from './Imagery';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100% - 112px);
  justify-content: center;
`;

const Mode = {
  PERMISSION: 'Permission',
  IMAGERY: 'Imagery',
};

export const Configuration = () => {
  const [activeMode, setActiveMode] = useState(Mode.PERMISSION);

  return (
    <Layout>
      <Container>
        <ContentLeftSidebar
          sidebar={
            <ButtonsContainer>
              {Object.entries(Mode).map(([key, value]) => (
                <Box m={2} key={key}>
                  <Button variant="contained" color="primary" onClick={() => setActiveMode(Mode[key])} style={{width: '200px'}}>
                    {value}
                  </Button>
                </Box>
              ))}
            </ButtonsContainer>
          }
          content={
            <>
              {activeMode === Mode.IMAGERY && <Imagery />}
              {activeMode === Mode.PERMISSION && <ManageFeaturePermission />}
            </>
          }
        />
      </Container>
    </Layout>
  );
};
