import React from 'react';
import BasePage from '../index';
import ColorSchemeLegend from '../../../../components/UI/Legend/ColorSchemeLegend';
import {primaryDiscreteColorGradient} from '../../../../utilities/theme';

const PlantMature = () => {
  return (
    <BasePage
      product={'PlantMature'}
      infoSecondaryTitle={'Plant Mature'}
      soilSignalValueName={'Plant Mature'}
      legend={
        <ColorSchemeLegend
          title="Plant Mature"
          scale={['0.0', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0']}
          gradient={primaryDiscreteColorGradient}
          justifyContent="space-around"
        />
      }
    />
  );
};

export default PlantMature;
