import React, {useState} from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../../components/UI/Spinner';
import {useTranslation} from 'react-i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useDispatch, useSelector} from 'react-redux';
import {putUserPreferences} from '../../../store/actions/user';
import {putUserPreferencesSelector} from '../../../store/selectors';
import {WithUserProps, withUser} from '../../../hooks/useAuth';
import {getUserGroups} from '../../authHOC';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 2rem;
  }
`;

const Btn = styled(Button)`
  && {
    margin-top: 1rem;
  }
`;

interface Props extends WithUserProps {
  noteNotificationPreference: boolean;
  fileNotificationPreference: boolean;
}

const Notifications = ({noteNotificationPreference, fileNotificationPreference, user}: Props) => {
  const [isCheckedNote, setIsCheckedNote] = useState(noteNotificationPreference);
  const [isCheckedFile, setIsCheckedFile] = useState(fileNotificationPreference);
  const isAdmin = getUserGroups(user).includes('SUPERADMIN') || getUserGroups(user).includes('ADMIN');
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {loading} = useSelector(putUserPreferencesSelector);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(
      putUserPreferences({
        preferences: [
          {
            type: 'NOTE_CREATION_NOTIFICATION',
            value: Number(isCheckedNote) || 0,
          },
          {
            type: 'FILE_CREATION_NOTIFICATION',
            value: Number(isCheckedFile) || 0,
          },
        ],
      })
    );
  };

  const changed = isCheckedNote !== noteNotificationPreference || isCheckedFile !== fileNotificationPreference;
  return (
    <>
      <Title variant="body1">{t('account.preferences.notification.subtitle')}</Title>
      <Form onSubmit={handleSubmit}>
        <FormControlLabel
          style={{padding: '0 1rem'}}
          control={<Checkbox color="primary" checked={isCheckedNote} onChange={() => setIsCheckedNote(!isCheckedNote)} name="note_create_notification_check" />}
          label={t('account.preferences.notification.label')}
        />
        {isAdmin && (
          <FormControlLabel
            style={{padding: '0 1rem'}}
            control={<Checkbox color="primary" checked={isCheckedFile} onChange={() => setIsCheckedFile(!isCheckedFile)} name="file_create_notification_check" />}
            label={t('account.preferences.notification.file.label')}
          />
        )}
        <Btn variant="contained" color="primary" type="submit" disabled={loading || !changed}>
          {t('account.personal.information.save')}
          {loading && <Spinner size={15} color="primary" />}
        </Btn>
      </Form>
    </>
  );
};

export default withUser(Notifications);
