import {Box, IconButton, Typography} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {getStringDate} from '../../../../helpers/dateHelpers';
import {deleteHistoricalYield} from '../../../../store/actions/yield';
import {deleteHistoricalYieldSelector} from '../../../../store/selectors';
import DeleteSeasonDialog from './DeleteSeasonDialog';
import {HistoricalSeasonYield} from './HistoricalYieldForm';

const StyledTh = styled.th`
  text-align: left;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
`;

const StyledTd = styled.td<{$hidden?: boolean}>`
  text-align: left;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  font-weight: normal;
  display: ${({$hidden}) => ($hidden ? 'none' : 'table-cell')};
`;

interface Props {
  historicalRows?: HistoricalSeasonYield[];
}

const HistoricalYieldTable = ({historicalRows}: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const {loading} = useSelector(deleteHistoricalYieldSelector);
  const {t} = useTranslation();

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => setIsDialogOpen(false);

  const handleDeleteHistoricalYield = (id: string) => {
    dispatch(deleteHistoricalYield(id, handleClose));
  };

  return (
    !!historicalRows?.length && (
      <Box mb={5}>
        <Typography variant="h6">{t('yield.historical.table.title')}</Typography>
        <table style={{borderCollapse: 'collapse', width: '100%'}}>
          <thead>
            <tr>
              <StyledTh>{t('yield.historical.table.season')}</StyledTh>
              <StyledTh>{t('yield.historical.table.crop')}</StyledTh>
              <StyledTh>{t('yield.historical.table.date')}</StyledTh>
              <StyledTh>{t('yield.historical.table.yield')}</StyledTh>
              <StyledTh>{t('yield.historical.table.baume')}</StyledTh>
              <StyledTh>{t('yield.historical.table.ph')}</StyledTh>
              <StyledTh>{t('yield.historical.table.ta')}</StyledTh>
              <StyledTh />
            </tr>
          </thead>
          <tbody>
            {historicalRows?.map(season => (
              <tr key={`${season.yieldId}`}>
                <StyledTd>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">{season.name}</Typography>
                    <Typography variant="caption">{`${season.fromDate} - ${season.toDate}`}</Typography>
                  </Box>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{season.variety}</Typography>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{getStringDate(season.harvestDate)}</Typography>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{season.yield}</Typography>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{season.baume || 'N/A'}</Typography>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{season.pH || 'N/A'}</Typography>
                </StyledTd>
                <StyledTd>
                  <Typography variant="subtitle2">{season.titratableAcid || 'N/A'}</Typography>
                </StyledTd>
                <StyledTd>
                  <Box display="flex">
                    <IconButton disabled={loading} onClick={handleOpen}>
                      <DeleteIcon />
                      {isDialogOpen && <DeleteSeasonDialog loading={loading} isOpen={isDialogOpen} handleClose={handleClose} handleSubmit={() => handleDeleteHistoricalYield(season.yieldId)} />}
                    </IconButton>
                  </Box>
                </StyledTd>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    )
  );
};

export default HistoricalYieldTable;
