import React from 'react';
import styled from 'styled-components';
import {Button, Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import PolygonPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/PolygonPicker';
import {IChangeOver, IPolygonEntity} from '@deep-planet/api-interfaces';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
`;

const SideList = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

interface Props {
  activeBlock: IPolygonEntity;
  allBlocks: IPolygonEntity[];
  duration: string;
  handleBlockChangeOnChart: (blockId: string) => void;
  handlePostRequest: () => void;
  changeOver: IChangeOver[];
  handleDurationChangeOver: (duration: string) => void;
}

const GraphSidebar = ({activeBlock, allBlocks, duration, handleBlockChangeOnChart, handlePostRequest, changeOver, handleDurationChangeOver}: Props) => {
  const {t} = useTranslation();

  return (
    <Container>
      <SideList id={'cucc'}>
        <Box width="100%">
          <PolygonPicker
            activeBlock={activeBlock?.id}
            allBlocks={allBlocks}
            handleBlockChangeOnChart={handleBlockChangeOnChart}
            changeOverDuration={changeOver}
            duration={duration}
            handleDurationChangeOver={handleDurationChangeOver}
          />
        </Box>
        <Button variant="contained" color="primary" onClick={handlePostRequest} style={{margin: '1rem 0'}}>
          {t('ndvi.sidebar.update')}
        </Button>
      </SideList>
    </Container>
  );
};

export default GraphSidebar;
