import i18n from '../../../i18n';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {baseApiUrl} from '../../config/const';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {IVarietyEntity} from '@deep-planet/api-interfaces';

/////////////////////////////////////////////
// GET varieties
/////////////////////////////////////////////

interface IVarietiesGetStart {
  type: ActionTypes.VARIETIES_GET_START;
}

export const varietiesGetStart = (): IVarietiesGetStart => ({
  type: ActionTypes.VARIETIES_GET_START,
});

interface IVarietiesGetSuccess {
  type: ActionTypes.VARIETIES_GET_SUCCESS;
  varieties: IVarietyEntity[];
}

export const varietiesGetSuccess = (varieties: IVarietyEntity[]): IVarietiesGetSuccess => {
  return {
    type: ActionTypes.VARIETIES_GET_SUCCESS,
    varieties,
  };
};

interface IVarietiesGetFail {
  type: ActionTypes.VARIETIES_GET_FAIL;
  error: unknown;
}

export const varietiesGetFail = (error: unknown): IVarietiesGetFail => ({
  type: ActionTypes.VARIETIES_GET_FAIL,
  error: error,
});

export const getVarieties = () => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(varietiesGetStart());
    const url = `${baseApiUrl}/varieties`;
    try {
      const {data} = await axios.get<IVarietyEntity[]>(url);
      dispatch(varietiesGetSuccess(data));
    } catch (err) {
      dispatch(varietiesGetFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

export type Action = ReturnType<typeof varietiesGetStart> | ReturnType<typeof varietiesGetSuccess> | ReturnType<typeof varietiesGetFail>;
