import React, {useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {Polygon, InfoWindow} from '@react-google-maps/api';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Spinner from '../../../../../components/UI/Spinner';
import {useTranslation} from 'react-i18next';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, {Moment} from 'moment';
import {getLocalDateString} from '../../../../../helpers/dateHelpers';
import i18n from './../../../../../../i18n';
import {ConfirmationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/ConfirmationDialog';

moment.locale(i18n.language || window.navigator.language);

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return getLocalDateString(date);
  }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledField = styled(TextField)`
  && {
    margin-bottom: 0.5rem;
  }
`;

interface Props {
  polygon: any;
  activeFarmId: string;
  loadingPutPoly: boolean;
  loadingDeletePoly: boolean;
}

const PolygonWithInfo = ({polygon, activeFarmId, loadingPutPoly, loadingDeletePoly}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const initialDatePlanted = moment(polygon.geo_json.properties.datePlanted).toDate();
  const [blockName, setBlockName] = useState(polygon.name);
  const [cropType, setCropType] = useState(polygon.geo_json.properties.cropType);
  const [rootStock, setRootStock] = useState(polygon.geo_json.properties.rootStock);
  const [clone, setClone] = useState(polygon.geo_json.properties.clone);
  const [datePlanted, setDatePlanted] = useState(initialDatePlanted);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [deletePolygon, setDeletePolygon] = useState({farmId: null, polygonId: null});

  const onToggleOpen = () => setIsOpen(!isOpen);
  const onSetBlockName = e => setBlockName(e.target.value);
  const onSetCropType = e => setCropType(e.target.value);
  const onSetRootStock = e => setRootStock(e.target.value);
  const onSetClone = e => setClone(e.target.value);
  const onUpdatePoly = (farmId, polyId, payload) => dispatch(actions.updatePolygon(farmId, polyId, payload));
  const onDeletePoly = (farmId, polyId) => {
    setIsOpenDialog(true);
    setDeletePolygon({farmId: farmId, polygonId: polyId});
  };

  const handleDialogClose = () => {
    setIsOpenDialog(false);
    setDeletePolygon(null);
  };
  const handleDeleteConfirmation = () => {
    setIsOpenDialog(false);
    dispatch(actions.deletePolygon(deletePolygon.farmId, deletePolygon.polygonId));
    setDeletePolygon(null);
  };

  const onSavePolygon = (farmId, polyId, polygon) => {
    const payload = {
      ...polygon,
      name: blockName,
      geo_json: {
        ...polygon.geo_json,
        properties: {
          cropType: cropType,
          rootStock: rootStock,
          clone: clone,
          datePlanted: datePlanted,
        },
      },
    };
    onUpdatePoly(farmId, polyId, payload);
  };

  const onDatePlantedChange = (moment: Moment) => {
    const date = moment?.toDate();
    if (date) setDatePlanted(date);
  };

  const hasUpdated =
    polygon.name === blockName &&
    polygon.geo_json.properties.cropType === cropType &&
    polygon.geo_json.properties.rootStock === rootStock &&
    polygon.geo_json.properties.clone === clone &&
    initialDatePlanted.toString() === datePlanted.toString();

  return (
    <>
      <Polygon
        paths={polygon.geo_json.geometry.renderCoords}
        options={{
          fillColor: 'rgb(37,54,71)',
          fillOpacity: 0.5,
          strokeColor: 'rgb(37,54,71)',
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
        }}
        onClick={onToggleOpen}
      />
      {isOpen && (
        <InfoWindow
          onCloseClick={onToggleOpen}
          position={{
            lat: polygon.geo_json.geometry.center[1],
            lng: polygon.geo_json.geometry.center[0],
          }}
        >
          <Content>
            <StyledField id="blockName" name="blockName" label={t('setting.create.farm.block.name')} value={blockName} onChange={onSetBlockName} margin="dense" />
            <StyledField id="cropType" name="cropType" label={t('setting.create.farm.block.type')} value={cropType} onChange={onSetCropType} margin="dense" />
            <StyledField id="rootStock" name="rootStock" label={t('setting.create.farm.block.root.stock')} value={rootStock} onChange={onSetRootStock} margin="dense" />
            <StyledField id="clone" name="clone" label={t('setting.create.farm.block.clone')} value={clone} onChange={onSetClone} margin="dense" />
            <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language || window.navigator.language}>
              <DatePicker autoOk format="LL" label={t('setting.create.farm.block.date.planted')} value={datePlanted} onChange={onDatePlantedChange} />
            </MuiPickersUtilsProvider>
            <Button onClick={() => onSavePolygon(activeFarmId, polygon.polygonid, polygon)} disabled={hasUpdated}>
              {t('setting.edit.block.save')}
              {loadingPutPoly && <Spinner size={15} color="primary" />}
            </Button>
            <Button onClick={() => onDeletePoly(activeFarmId, polygon.polygonid)}>
              {t('setting.edit.block.delete')}
              {loadingDeletePoly && <Spinner size={15} color="primary" />}
            </Button>
            <ConfirmationDialog
              open={isOpenDialog}
              handleClose={handleDialogClose}
              handleSubmit={handleDeleteConfirmation}
              title={`${t('setting.edit.polygon.delete.confirmation')} ${polygon.name.toUpperCase()}`}
              submitText={'YES'}
            />
          </Content>
        </InfoWindow>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    loadingPutPoly: state.polygon.polygonPut.loading,
    loadingDeletePoly: state.polygon.polygonDelete.loading,
  };
};

export default connect(mapStateToProps)(PolygonWithInfo);
