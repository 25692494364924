import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStringDate} from '../helpers/dateHelpers';
import * as actions from '../store/actions';
import {ndviGetActiveData} from '../store/actions/ndvi';
import {IFarm} from '../store/reducers/farm';
import {ndviDataSelector, ndviFarmIdSelector, ndviGetSelector, ndviImageTypeSelector, ndviSelectedDateSelector, selectedOrganizationSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useNDVIImage(activeFarm: IFarm, startDate: Date, endDate: Date, isPredictionNeeded: boolean, lazy = false) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [imageType, setImageType] = useState<string>();
  const ndviImages = useSelector(ndviDataSelector);
  const {loading: loadingNdvi, error} = useSelector(ndviGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const ndviFarmId = useSelector(ndviFarmIdSelector);
  const prevNdviImages = usePrevious(ndviImages);
  const prevNdviFarmId = usePrevious(ndviFarmId);
  const ndviSelectedDate = useSelector(ndviSelectedDateSelector);
  const ndviImageType = useSelector(ndviImageTypeSelector);

  const handleSelectedDateAndTypeNDVI = (selectedDate: Date, type: string) => {
    setSelectedDate(selectedDate);
    setImageType(type);
    dispatch(ndviGetActiveData(selectedDate, type));
  };

  useEffect(() => {
    if (activeFarm && !loadingNdvi && !ndviImages && !lazy) {
      dispatch(actions.getNDVI(activeFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, loadingNdvi, ndviImages, startDate, isPredictionNeeded, lazy, selectedOrganization]);

  // in case of changing selected organization
  useEffect(() => {
    if (activeFarm && prevNdviFarmId && activeFarm.id !== prevNdviFarmId && !loadingNdvi && !lazy) {
      dispatch(actions.getNDVI(activeFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, isPredictionNeeded, prevNdviFarmId, selectedOrganization, startDate, loadingNdvi, lazy]);

  useEffect(() => {
    if (!selectedDate && ndviSelectedDate) setSelectedDate(ndviSelectedDate);

    if (!imageType && ndviImageType) setImageType(ndviImageType);
  }, [selectedDate, ndviSelectedDate, imageType, ndviImageType]);

  const onGetNDVI = (selectedFarm: IFarm, isPredictionNeeded = false) => {
    dispatch(actions.getNDVI(selectedFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization?.id));
  };

  return {
    loadingNdvi,
    error,
    ndviImages,
    prevNdviImages,
    onGetNDVI,
    selectedDate,
    imageType,
    handleSelectedDateAndTypeNDVI,
  };
}
