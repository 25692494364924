import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {Box, Button, Checkbox, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Typography} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment, {Moment} from 'moment';
import axios from 'axios';
import {useHttp} from 'apps/web-portal-ui/src/app/hooks/http';
import {capitalize, sortBy} from 'lodash';
import styled from 'styled-components';
import {INotificationEntity, IOrganizationEntity, NotificationStatusEnum} from '@deep-planet/api-interfaces';
import Spinner from '../../../../components/UI/Spinner';
import {baseApiUrl} from '../../../../config/const';
import {enqueueSnackbar} from '../../../../store/actions';
import theme from '../../../../utilities/theme';
import {ConfirmationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/ConfirmationDialog';
import Modal from 'apps/web-portal-ui/src/app/components/UI/Modal/Modal';
import {getLocalDateString, getStringDate} from 'apps/web-portal-ui/src/app/helpers/dateHelpers';
import i18n from 'apps/web-portal-ui/src/i18n';
import MapOverlay from 'apps/web-portal-ui/src/app/components/UI/MapOverlay';
import {Email} from '@material-ui/icons';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;
const StyledIconButton = styled(IconButton)`
  padding: 0px 0px;
  margin: 0px opx;
`;

export const ManageNotifications = () => {
  const [isLoadingOrganizations, organizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>();
  const [notificationDescription, setNotificationDescription] = useState<string>();
  const [notificationHeader, setNotificationHeader] = useState<string>();
  const [status, setStatus] = useState<NotificationStatusEnum>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [notifications, setNotifications] = useState<INotificationEntity[]>();
  const [selectedNotification, setSelectedNotification] = useState<INotificationEntity>();
  const [sendEmail, setSendEmail] = useState<boolean>(false);

  // for datepicker
  moment.locale(i18n.language || window.navigator.language);
  class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date) {
      return getLocalDateString(date);
    }
  }

  const onDateChange = (moment: Moment) => {
    const date = moment?.toDate();
    if (date) setSelectedDate(date);
  };

  const handleOrganizationSelect = (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
  };

  const handlevisible = e => {
    setStatus(e.target.value);
  };

  const handleEmail = e => {
    setSendEmail(e.target.checked);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let response = null;
      if (selectedOrganization) response = await axios.get<INotificationEntity>(`${baseApiUrl}/notifications/manage?date=${getStringDate(selectedDate)}&organizationId=${selectedOrganization?.id}`);
      else response = await axios.get<INotificationEntity>(`${baseApiUrl}/notifications/manage?date=${getStringDate(selectedDate)}`);
      setNotifications(response.data);
    } catch (e) {
      console.warn(e);
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      // update notification
      const updatedNotification = {id: selectedNotification.id, header: capitalize(notificationHeader), description: capitalize(notificationDescription), status, sendEmail};
      // update UI with modified notification
      const updatedNotificationUI = notifications.map(ntf => {
        if (ntf.id === updatedNotification.id) return {...ntf, ...updatedNotification};
        return ntf;
      });
      setNotifications(updatedNotificationUI);
      const response = await axios.put(`${baseApiUrl}/notifications/update`, updatedNotification);
      dispatch(enqueueSnackbar({message: 'Notification successfully updated', options: {variant: 'success'}}));
      closeModal();
    } catch (e) {
      console.warn(e);
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  const handleClickOnDelete = async () => {
    try {
      setIsDeleteLoading(true);
      await axios.delete(`${baseApiUrl}/notifications/${selectedNotification.id}`);
      const updatedNotifications = notifications.filter(n => !(n.id === selectedNotification.id));
      setNotifications(updatedNotifications);
      dispatch(enqueueSnackbar({message: 'Notification successfully deleted', options: {variant: 'success'}}));
      closeDialog();
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsDeleteLoading(false);
    }
  };
  const openEditModalDialog = (notification: INotificationEntity) => {
    setIsModalOpen(true);
    setNotificationHeader(notification.header);
    setNotificationDescription(notification.description);
    setStatus(notification.status);
    setSendEmail(false);
    setSelectedNotification(notification);
  };

  const openDeleteDialog = (notification: INotificationEntity) => {
    setSelectedNotification(notification);
    setIsDeleteDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNotificationHeader(null);
    setNotificationDescription(null);
    setStatus(null);
    setSendEmail(false);
  };

  const isLoading = isLoadingOrganizations;

  return (
    <>
      <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
        {isLoading && <MapOverlay />}
        <Typography variant="h3" align="center" gutterBottom>
          Manage Notifications
        </Typography>
        <FormWrapper padding="2rem 0">
          <form>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                options={sortBy(organizations, o => o.name.toLowerCase())}
                onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined"></FormControl>
            <FormControl fullWidth variant="outlined">
              <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language || window.navigator.language}>
                <DatePicker variant="dialog" autoOk format="LL" label={t('Select Date')} value={selectedDate} onChange={onDateChange} />
              </MuiPickersUtilsProvider>
            </FormControl>

            {notifications?.length && (
              <Box margin="2rem 0">
                <Typography>Notification List:</Typography>
                {sortBy(notifications, n => n).map(n => (
                  <Box key={n.id} display="flex" alignItems="center" justifyContent="space-between">
                    <p>{`${n?.toUser?.username ? n?.toUser?.username : n?.organization?.name ? n?.organization?.name : 'ALL'} - ${n.header}`}</p>
                    <Box key={n.id} display="flex" alignItems="center" justifyContent="flex-end">
                      <IconButton edge="end" color="secondary" onClick={() => openDeleteDialog(n)}>
                        <DeleteIcon />
                      </IconButton>
                      <StyledIconButton edge="end" color="secondary" onClick={() => openEditModalDialog(n)}>
                        <EditIcon />
                      </StyledIconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            <FormControl fullWidth>
              <Box padding="1rem 0">
                <Button fullWidth disabled={loading} color="primary" variant="contained" type="submit" onClick={handleSubmit}>
                  Submit
                  {loading && <Spinner size={15} color="primary" />}
                </Button>
              </Box>
            </FormControl>
          </form>
        </FormWrapper>
      </Box>

      {isDeleteDialogOpen && (
        <ConfirmationDialog
          open={isDeleteDialogOpen}
          handleClose={closeDialog}
          isLoading={isDeleteLoading}
          handleSubmit={handleClickOnDelete}
          submitText={'Delete'}
          title={`You're about to permanently delete notification "${selectedNotification?.header}"`}
        />
      )}
      {isModalOpen && (
        <Modal
          title={`Modify notification "${selectedNotification?.header}"`}
          submitText={'Modify'}
          isOpen={isModalOpen}
          isSubmitButtonDisabled={loading}
          isLoading={loading}
          handleClose={closeModal}
          handleSubmit={handleUpdate}
        >
          <Box width="100%" margin="1rem 0">
            <FormControl variant="outlined" margin="normal" fullWidth>
              <TextField
                fullWidth
                id="notificationHeader"
                name="notificationHeader"
                value={notificationHeader}
                placeholder={'Notification Header'}
                margin="normal"
                variant="outlined"
                onChange={event => {
                  setNotificationHeader(event.target.value);
                }}
                inputProps={{
                  maxLength: 150,
                }}
                required
                autoComplete="off"
              />
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <TextField
                multiline
                fullWidth
                rows={4}
                id="notificationDescription"
                name="notificationDescription"
                value={notificationDescription}
                placeholder={'Notification Description'}
                margin="normal"
                variant="outlined"
                onChange={event => {
                  setNotificationDescription(event.target.value);
                }}
                inputProps={{
                  maxLength: 1500,
                }}
                helperText={`${notificationDescription?.length}/1500`}
                required
                autoComplete="off"
              />
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <FormLabel id="demo-controlled-radio-buttons-group">visible</FormLabel>
              <RadioGroup row aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={status} onChange={e => handlevisible(e)}>
                <FormControlLabel value="DRAFT" control={<Radio />} label="Draft" />
                <FormControlLabel value="PUBLISH" control={<Radio />} label="Publish" />
                <FormControlLabel checked={sendEmail} control={<Checkbox checkedIcon={<Email />} icon={<Email />} />} label="Email" onChange={e => handleEmail(e)} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Modal>
      )}
    </>
  );
};
