import React, {useState} from 'react';
import Layout from '../../../Layout';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import Map from './Map';
import MapOverlay from '../../../../components/UI/MapOverlay';
import {Error} from '../../../../components/UI/Error/Error';
import {MapPlaceholderNoPolygons} from '../../../../components/UI/MapPlaceholder';
import MapPlaceholder from '../../../../components/UI/MapPlaceholder';
import {withUser, WithUserProps} from '../../../../hooks/useAuth';
import {useFarmSelection} from '../../../../hooks/useFarmSelection';
import {useSoilNutrientsSelection} from 'apps/web-portal-ui/src/app/hooks/useSoilNutrientImage';
import {ButtonGroupBar, SoilNutrientsList} from './ButtonGroup';
import {useTranslation} from 'react-i18next';
import Legend, {DynamicScaleType} from '../../NDVI/Legend';
import {getSoilNutreintsThresholds} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import InfoSection from '../../NDVI/InfoSection';
import {useMarkerColor} from 'apps/web-portal-ui/src/app/hooks/useMarkerColor';
import {getScreenshot} from '../../NDVI/DownloadScreenshot';
import usePolygon from '../../../../hooks/usePolygon';

import Box from '@material-ui/core/Box';
import {HelpTooltip} from '../../../../components/UI/HelpTooltip';
import useTooltipText from '../../../../hooks/useTooltipText';
import {PageTitle} from 'apps/web-portal-ui/src/app/components/UI/PageTitle';

const SoilNutrientsUI = ({user}: WithUserProps) => {
  //initialize the marker colors
  useMarkerColor();
  const {HelpSoilNutrients} = useTooltipText();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [type, setType] = useState<'current' | 'predicted' | 'previous' | 'average'>('current');
  const {t} = useTranslation();
  const {allFarms, activeFarm, selectedFarm, setSelectedFarm, setActiveFarm, loadingGetFarm: loadingFarms} = useFarmSelection();
  const {polygonClicked, selectedPolygon, handleOnClickPolygon} = usePolygon();
  const {
    soilNutrientImages,
    nutrientType,
    loadingNutrientFarms,
    loadingImages,
    error,
    selectedDate,
    soilNutrientActiveFarm,
    setSoilNutrientActiveFarm,
    setSoilNutrientSelectedFarm,
    handleDateChange,
    handleSoilNutrientType,
    getSoilNutirentTypeImages,
  } = useSoilNutrientsSelection(allFarms, activeFarm, selectedFarm);

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleUpdateDateIndex = (date: number, type: 'current' | 'predicted' | 'previous' | 'average') => {
    setType(type);
    handleDateChange(date);
  };

  const handleClickCompare = type => {
    setType(type);
  };

  const handleGetRequest = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
      setSoilNutrientActiveFarm(selectedFarm);
    }
    getSoilNutirentTypeImages(selectedFarm, startDate, endDate);
  };

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSoilNutrientSelectedFarm(selectedFarm);
    setSelectedFarm(selectedFarm);
  };

  const handlePolygonHover = (id: string) => {
    if (id) {
      const {thresholdLow, thresholdHigh} = getSoilNutreintsThresholds(imagesOnSelectedDate.images, id, nutrientType, type);
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      for (let i = 0; i <= 7; i++) {
        currentDynamicScale.push(Number((thresholdLow + step * i).toFixed(2)));
      }
      setDynamicScale(currentDynamicScale);
    } else {
      setDynamicScale(initialDynamicScale);
    }
  };

  //get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const isLoading = loadingNutrientFarms || loadingFarms || loadingImages;
  const selectedActiveFarm = activeFarm || soilNutrientActiveFarm || (allFarms && allFarms[0]);
  const imagesOnSelectedDate = soilNutrientImages?.find(({date, farmid}) => date === selectedDate && farmid === selectedActiveFarm.id);
  const prevImageUrl = imagesOnSelectedDate?.images.some(i => i[nutrientType].prev_url);
  const nutrientName = nutrientType.charAt(0).toUpperCase() + nutrientType.substring(1, nutrientType.length).toLowerCase();
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedActiveFarm && !selectedActiveFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={selectedActiveFarm.id} />}
      {!isLoading && error && <Error onClick={handleGetRequest} />}
      {!isLoading && selectedActiveFarm && (
        <div style={{overflow: 'hidden', position: 'relative'}} id="layout">
          <Legend
            product={'soil_nutrients'}
            type={type}
            dynamicScale={dynamicScale}
            text={nutrientType === 'nitrogen' ? `${t('soil.nutrients.nitrogen')} (g/kg)` : `${nutrientName === 'Phosphorus' ? t('soil.nutrients.phosphorus') : nutrientName} (mg/kg)`}
          />
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                type={type}
                activeFarm={selectedFarm}
                allFarms={allFarms}
                images={soilNutrientImages}
                startDate={startDate}
                endDate={endDate}
                selectedDate={selectedDate}
                nutrientType={nutrientType}
                error={error}
                handleChange={handleFarmChange}
                handlePostRequest={handleGetRequest}
                handleUpdateDateIndex={handleUpdateDateIndex}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
              />
            }
            content={
              <>
                <ButtonGroupBar list={SoilNutrientsList} handleSoilNutrientType={handleSoilNutrientType} />
                <PageTitle
                  title={
                    <InfoSection
                      selectedDate={selectedDate}
                      type={type}
                      product={nutrientType}
                      description={t('soil.nutrients') + ' - ' + nutrientType.charAt(0).toUpperCase() + nutrientType.substring(1).toLowerCase()}
                      handleClickCompare={handleClickCompare}
                      prevImageUrl={prevImageUrl}
                    />
                  }
                  tooltip={HelpSoilNutrients}
                />
                <Map
                  farm={selectedActiveFarm}
                  center={selectedActiveFarm.farmCenter}
                  images={imagesOnSelectedDate?.images}
                  nutrientType={nutrientType}
                  type={type}
                  bbox={selectedActiveFarm.bbox}
                  polygons={selectedActiveFarm.polygons}
                  handlePolygonHover={handlePolygonHover}
                  takeScreenshot={takeScreenshot}
                  polygonClicked={polygonClicked}
                  selectedPolygon={selectedPolygon}
                  handleOnClickPolygon={handleOnClickPolygon}
                />
              </>
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(SoilNutrientsUI);
