import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import SettingsIcon from '@material-ui/icons/Settings';
import {capitalize} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {navRoutes} from '../../../utilities/routes';
import theme from '../../../utilities/theme';

const NavLink = styled<any>(Button)`
  && {
    ${theme.breakpoints.up('sm')} {
      margin-right: 10px;
    }
  }
`;

const Settings = () => {
  const {t} = useTranslation();

  return (
    <NavLink component={Link} to={navRoutes.SETTINGS.slug} color="inherit">
      <Hidden mdUp implementation="css">
        <Box mt={1}>
          <SettingsIcon />
        </Box>
      </Hidden>
      <Hidden smDown implementation="css">
        {capitalize(t(navRoutes.SETTINGS.title))}
      </Hidden>
    </NavLink>
  );
};

export default React.memo(Settings);
