import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from 'styled-components';
import {Router} from 'react-router-dom';
import {setupCognito, cognito} from '@deep-planet/react-cognito';
import * as serviceWorker from './serviceWorker';
import {SnackbarProvider} from 'notistack';
import './i18n';
import i18n from './i18n';

import App from './router';
import GlobalStyles from './app/utilities/globalStyles';
import theme from './app/utilities/theme';
import config from './app/utilities/cognitoConfig';

import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';

import snackbarReducer from './app/store/reducers/snackbar';
import farmReducer from './app/store/reducers/farm';
import userReducer from './app/store/reducers/user';
import polygonReducer from './app/store/reducers/polygon';
import segmentationReducer from './app/store/reducers/segmentation';
import ndviReducer from './app/store/reducers/ndvi';
import ndwiReducer from './app/store/reducers/ndwi';
import soilReducer from './app/store/reducers/soil-moisture';
import yieldReducer from './app/store/reducers/yield';
import weatherReducer from './app/store/reducers/weather';
import generalReducer from './app/store/reducers/general';
import noteReducer from './app/store/reducers/note';
import cropReducer from './app/store/reducers/crop';
import organizationReducer from './app/store/reducers/organization';
import maturityReducer from './app/store/reducers/maturity';
import soilNutrientReducer from './app/store/reducers/soilNutrient';
import irrigationReducer from './app/store/reducers/irrigation';
import soilOrganicCarbonReducer from './app/store/reducers/soilOrganicCarbon';
import yieldPerPixelReducer from './app/store/reducers/yieldPerPixel';
import markerReducer from './app/store/reducers/marker';
import diseaseReducer from './app/store/reducers/disease';
import fileReducer from './app/store/reducers/file';
import seasonIndicesReducer from './app/store/reducers/seasonIndices';
import {updateCurrentLanguage} from './app/store/actions';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {createBrowserHistory} from 'history';

const rootReducer = combineReducers({
  cognito,
  snackbar: snackbarReducer,
  farm: farmReducer,
  user: userReducer,
  polygon: polygonReducer,
  segmentation: segmentationReducer,
  ndvi: ndviReducer,
  ndwi: ndwiReducer,
  soil: soilReducer,
  yield: yieldReducer,
  weather: weatherReducer,
  general: generalReducer,
  note: noteReducer,
  crop: cropReducer,
  organization: organizationReducer,
  maturity: maturityReducer,
  soilNutrient: soilNutrientReducer,
  irrigation: irrigationReducer,
  soilOrganicCarbon: soilOrganicCarbonReducer,
  yieldPerPixel: yieldPerPixelReducer,
  marker: markerReducer,
  disease: diseaseReducer,
  file: fileReducer,
  seasonIndices: seasonIndicesReducer,
});

// used to enable Redux dev tools in Chrome
const composeEnhancer = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

export const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
store.dispatch(updateCurrentLanguage(i18n.language));

setupCognito(store, config);

const history = createBrowserHistory();
const app = (
  <Provider store={store}>
    <Router history={history}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Router>
  </Provider>
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || process.env.NX_REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV || process.env.NX_REACT_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
});

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
