import React from 'react';
import FileUploadModal, {ISelectedFile} from './FileUploadModal';
import {useTranslation} from 'react-i18next';
import {IFileManagerDTO} from '@deep-planet/api-interfaces';
import FileManagerModal from './FileManagerModal';

interface Props {
  isLoading: boolean;
  isFileUploadModalOpen: boolean;
  isFileManagerModalOpen: boolean;
  handleCloseModal: () => void;
  handleSubmit: (form: IFileManagerDTO, files: ISelectedFile[]) => void;
}

// this a file manager componet holds the file Upload Modal(component to upload files) and File Manager Modal(for file maintenance)
export const FileManager = ({isLoading, isFileUploadModalOpen, isFileManagerModalOpen, handleSubmit, handleCloseModal}: Props) => {
  const {t} = useTranslation();

  return (
    <>
      {isFileUploadModalOpen && <FileUploadModal isOpen={isFileUploadModalOpen} handleClose={handleCloseModal} handleSubmit={handleSubmit} submitLoading={isLoading} modalTitle={t('File Upload')} />}
      {isFileManagerModalOpen && (
        <FileManagerModal isOpen={isFileManagerModalOpen} handleClose={handleCloseModal} handleSubmit={handleSubmit} submitLoading={isLoading} modalTitle={t('File Manager')} />
      )}
    </>
  );
};
