import React, {useEffect, useLayoutEffect} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import * as actions from '../../../../../store/actions';
import Map from './Map';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import {usePrevious} from '../../../../../hooks/usePrevious';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const MapController = ({polygons, farmCenter, activeFarmId, loadingGetPolygons, drawingActive, handlePassPolyCoords, passedFarmId}) => {
  const dispatch = useDispatch();
  const prevActiveFarm = usePrevious(activeFarmId);
  useLayoutEffect(() => {
    if (!!activeFarmId && !passedFarmId) {
      dispatch(actions.getPolygons(activeFarmId));
    }
  }, [activeFarmId, passedFarmId, dispatch]);

  useEffect(() => {
    if (prevActiveFarm !== activeFarmId) {
      // make the call to fetch polygons for current activeFarmId
      dispatch(actions.getPolygons(activeFarmId));
    }
  }, [dispatch, activeFarmId, prevActiveFarm]);

  return (
    <Container>
      <Map polygons={polygons} center={farmCenter} activeFarmId={activeFarmId} drawingActive={drawingActive} handlePassPolyCoords={handlePassPolyCoords} />
      {loadingGetPolygons && <MapOverlay />}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    polygons: state.polygon.polygons,
    farmCenter: state.polygon.farmCenter,
    loadingGetPolygons: state.polygon.polygonGet.loading,
  };
};

export default connect(mapStateToProps)(MapController);
