import React, {useState} from 'react';
import {Polygon} from '@react-google-maps/api';
import {IDiseaseImages, IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import MapInfoWindow from './InfoWindow';

interface Props {
  polygon: IPolygonEntity;
  secondaryTitle?: string;
  secondaryDescription?: string;
  hectares?: number;
  yieldPrediction?: number;
  defaultOpacity?: number;
  selectedPolygon?: polygonInfoWindow;
  disableInfoWindow?: boolean;
  disableInfoBox?: boolean;
  autoPan?: boolean;
  avgBaume?: string;
  metric?: string;
  diseasePredictions?: IDiseaseImages;
  handleSelectedPolygon?: (selectedPolygon: polygonInfoWindow) => void;
  handlePolygonHover?: (id: string) => void;
  handleOnClick?: (polygon: IPolygonEntity) => void;
  handleOnClickMap?: (latLng: google.maps.LatLng, polygon: IPolygonEntity) => void;
  showVariety?: boolean;
  claimedBlocks?: IPolygonEntity[];
}

const PolygonWithInfo = ({
  polygon,
  secondaryDescription,
  secondaryTitle,
  hectares,
  yieldPrediction,
  defaultOpacity = 0,
  selectedPolygon,
  disableInfoWindow,
  disableInfoBox,
  autoPan,
  avgBaume,
  metric,
  diseasePredictions,
  handlePolygonHover,
  handleSelectedPolygon,
  handleOnClick,
  handleOnClickMap,
  showVariety,
  claimedBlocks,
}: Props) => {
  const [isOpen, setIsOpen] = useState(polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.openWindow : false);
  const [opacity, setOpacity] = useState(defaultOpacity);
  const [strokeColor, setStrokeColor] = useState<string>('white');
  const [strokeWeight, setStrokeWeight] = useState(2);
  const handleOnMouseOver = () => {
    // do not handle styles of polygon if info window is disabled
    if (disableInfoWindow) return;
    // close onclick polygon Info-window
    closeOnClickInfoWindow();
    // open/close onmouse-over window
    setIsOpen(!isOpen);
    // setOpacity(0.5);
    setStrokeColor(strokeColor === 'white' ? '#FFEA00' : 'white');
    setStrokeWeight(strokeWeight === 3 ? 2 : 3);
    if (handlePolygonHover) handlePolygonHover(polygon.id);
  };

  const handleOnMouseOut = () => {
    // do not handle styles of polygon if info window is disabled
    if (disableInfoWindow) return;
    // close onclick polygon Info-window
    closeOnClickInfoWindow();
    // open/close onmouse-over window
    setIsOpen(!isOpen);
    setOpacity(defaultOpacity);
    setStrokeColor(strokeColor === 'white' ? '#FFEA00' : 'white');
    setStrokeWeight(strokeWeight === 3 ? 2 : 3);
    if (handlePolygonHover) handlePolygonHover('');
  };

  const closeOnClickInfoWindow = () => {
    if (!!selectedPolygon && selectedPolygon?.polygonId !== polygon.id) {
      handleSelectedPolygon({
        polygonId: selectedPolygon?.polygonId,
        openWindow: false,
      });
    }
  };

  const onClick = latLng => {
    handleOnClickMap(latLng, polygon);
    handleOnClick(polygon);
  };
  // disableInfoWindow - prevents rendering the entire info-window-component
  // disableInfoBox    - just prevents the information on the box but the component
  //                     still renders to provide auto panning feature for the dashboard page

  const redColorStroke = diseasePredictions?.diseased ? '#ff0000' : null;
  const polygonStrokeWeight = (polygon.id === selectedPolygon?.polygonId && selectedPolygon?.strokeWeight) || strokeWeight;
  let polygonStrokeColor = (polygon.id === selectedPolygon?.polygonId && selectedPolygon?.strokeColor) || redColorStroke || strokeColor;
  // if user claimed or selected blocks on the dashboard, stroke them with green to show block is currently selected
  polygonStrokeColor = (claimedBlocks && claimedBlocks.find(mb => mb.id === polygon.id) && 'green') || polygonStrokeColor;
  const isInfoWindowOpen = (polygon.id === selectedPolygon?.polygonId && selectedPolygon?.openWindow) || isOpen;
  return (
    <>
      <Polygon
        paths={polygon.geoJson.geometry.renderCoords}
        options={{
          fillColor: '#C5AA75',
          fillOpacity: opacity,
          strokeColor: polygonStrokeColor,
          strokeOpacity: 1,
          strokeWeight: polygonStrokeWeight,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
          strokePosition: 0,
        }}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        onClick={({latLng}) => onClick(latLng)}
      />
      {!disableInfoWindow && (isInfoWindowOpen || isOpen) && (
        <MapInfoWindow
          polygon={polygon}
          secondaryTitle={secondaryTitle && 'Crop Type'}
          secondaryDescription={(secondaryTitle && polygon?.geoJson?.properties?.cropType) || null}
          hectares={hectares}
          yieldPrediction={yieldPrediction}
          disableInfoBox={disableInfoBox}
          autoPan={autoPan}
          avgBaume={avgBaume}
          metric={metric}
          diseasePredictions={diseasePredictions}
          showVariety={showVariety}
        />
      )}
    </>
  );
};

export default PolygonWithInfo;
