import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Spinner from '../Spinner';

interface DialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  isLoading?: boolean;
  title: string;
  submitText: string;
}

export const ConfirmationDialog = ({open, handleClose, isLoading = false, handleSubmit, title, submitText}: DialogProps) => {
  const {t} = useTranslation();
  return (
    <Dialog fullScreen={false} open={open} onClose={handleClose} aria-labelledby="responsive-dialog">
      <DialogTitle id="dialog-title">{t('note.action.delete.subtitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: 'black'}}>{title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="secondary" disabled={isLoading}>
          {isLoading && <Spinner size={15} color="primary" />}
          {submitText}
        </Button>
        <Button autoFocus onClick={handleClose} color="primary" variant="contained" disabled={isLoading}>
          {t('forms.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
