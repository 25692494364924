import {SaveFarmRequest} from '@deep-planet/api-interfaces';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../store/actions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from 'react-i18next';
import {isEqual} from 'lodash';
import {usePrevious} from '../../../hooks/usePrevious';
import {RootState} from '../../../store/reducers';
import {enqueueSnackbar} from '../../../store/actions';
import {selectedOrganizationSelector} from '../../../store/selectors/organization';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import moment, {Moment} from 'moment';
import i18n from '../../../../i18n';
import {getLocalDateString} from '../../../helpers/dateHelpers';
import MomentUtils from '@date-io/moment';

moment.locale(i18n.language || window.navigator.language);

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return getLocalDateString(date);
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  & input {
    margin-bottom: 1rem;
  }
`;

const Label = styled(Typography)`
  && {
    margin-top: 1rem;
  }
`;

const Btn = styled(Button)`
  && {
    margin: 0.5rem 0;
  }
`;

const SmallProgress = styled(CircularProgress)`
  && {
    margin-left: 1rem;
  }
`;

interface Props {
  farmId: string;
  farmName: string;
  loadingPostPolygon: boolean;
  polygon: any;
  resetPolygonCoords: () => void;
  polygonPostSuccess: boolean;
}

const PolygonManager = ({farmId, farmName, loadingPostPolygon, polygon, resetPolygonCoords, polygonPostSuccess}: Props) => {
  const {t} = useTranslation();
  const [blockName, setBlockName] = useState(farmName);
  const [cropType, setCropType] = useState('Grape');
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const [datePlanted, setDatePlanted] = useState(new Date());
  const [rootStock, setRootStock] = useState('');
  const [clone, setClone] = useState('');

  const onDateChange = (moment: Moment) => {
    const date = moment?.toDate();
    if (date) setDatePlanted(date);
  };

  const handleRootStockChange = e => setRootStock(e.target.value);

  const handleCloneChange = e => setClone(e.target.value);

  const handleBlockNameChange = e => {
    setBlockName(e.target.value);
  };
  const handleCropTypeChange = e => {
    setCropType(e.target.value);
  };
  const {coordinatesOfNewPoly, polygonDrawn} = polygon;
  const prevPolygonPostSuccess = usePrevious(polygonPostSuccess);

  const validatePayload = () => {
    let formIsValid = true;
    if (blockName.length < 1) {
      dispatch(
        enqueueSnackbar({
          message: t('setting.create.farm.block.error.invalid.fields'),
          options: {
            variant: 'warning',
          },
        })
      );
      formIsValid = false;
    }
    if (polygon.coordinatesOfNewPoly.length === 0) {
      dispatch(
        enqueueSnackbar({
          message: t('setting.create.farm.block.error.invalid.draw'),
          options: {
            variant: 'warning',
          },
        })
      );
      formIsValid = false;
    }
    if (coordinatesOfNewPoly.length < 4 || !isEqual(coordinatesOfNewPoly[0], coordinatesOfNewPoly[coordinatesOfNewPoly.length - 1])) {
      dispatch(
        enqueueSnackbar({
          message: t('setting.create.farm.block.error.invalid.polygon'),
          options: {
            variant: 'warning',
          },
        })
      );
      return false;
    }
    return formIsValid;
  };

  const handlePolygonCreate = polyPayload => {
    if (!validatePayload()) return;
    //new farm
    if (!farmId) {
      const farmPayload: SaveFarmRequest = {
        name: farmName,
        organizationId: selectedOrganization.id,
      };
      dispatch(actions.postFarm(farmPayload, polyPayload));
      return;
    }
    //edit farm
    dispatch(actions.postPolygon(polyPayload, false));
  };

  const cancelPolygon = () => {
    polygon?.polygon?.setMap(null);
    resetPolygonCoords();
  };

  useEffect(() => {
    // reset fields and map if polygon was successfully posted
    if (polygonPostSuccess !== prevPolygonPostSuccess && polygonPostSuccess === true) {
      polygon?.polygon?.setMap(null);
      resetPolygonCoords();
      setBlockName('');
      setCropType('');
      setRootStock('');
      setClone('');
    }
  }, [polygon, polygon.polygon, polygonPostSuccess, prevPolygonPostSuccess, resetPolygonCoords]);

  const payload = {
    farmId,
    farmName,
    blockName,
    cropType,
    rootStock,
    clone,
    datePlanted,
    coordinatesOfNewPoly,
  };

  return (
    <Container>
      <Label>{t('setting.create.farm.block.name')}</Label>
      <TextField id="block-name" placeholder="Block Name" margin="dense" name="blockName" value={blockName} onChange={handleBlockNameChange} />
      <Label>{t('setting.create.farm.block.type')}</Label>
      <TextField id="crop-type" placeholder="Crop Type" margin="dense" name="cropType" value={cropType} onChange={handleCropTypeChange} />
      <Label>{t('setting.create.farm.block.root.stock')}</Label>
      <TextField id="rootstock" placeholder="rootstock" margin="dense" name="rootStock" value={rootStock} onChange={handleRootStockChange} />
      <Label>{t('setting.create.farm.block.clone')}</Label>
      <TextField id="clone" placeholder="clone" margin="dense" name="clone" value={clone} onChange={handleCloneChange} />
      <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language || window.navigator.language}>
        <DatePicker autoOk format="LL" label={t('setting.create.farm.block.date.planted')} value={datePlanted} onChange={onDateChange} />
      </MuiPickersUtilsProvider>
      <Btn variant="contained" onClick={() => handlePolygonCreate(payload)}>
        {t('setting.create.farm.block.save')} {loadingPostPolygon && <SmallProgress size={15} />}
      </Btn>
      {polygonDrawn && (
        <Btn variant="contained" onClick={() => cancelPolygon()}>
          {t('setting.create.farm.block.clear')}
        </Btn>
      )}
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loadingPostPolygon: state.polygon.polygonPost.loading,
    polygonPostSuccess: state.polygon.polygonPost.success,
  };
};

export default connect(mapStateToProps)(PolygonManager);
