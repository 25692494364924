import {ISoilOrganicCarbonImageResDTO} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/soilOrganicCarbon';
import {updateObject} from '../utility';
import {IFarm} from './farm';

export const soilOrganicCarbonFarmsSelector = (state: ISoilOrganicCarbonState) => state.soilOrganicCarbonFarms;
export const soilOrganicCarbonImagesSelector = (state: ISoilOrganicCarbonState) => state.soilOrganicCarbonImages;
export const soilOrganicCarbonActiveFarmSelector = (state: ISoilOrganicCarbonState) => state.activeSoilOrganicCarbonFarm;
export const soilOrganicCarbonActiveFarmImageSelector = (state: ISoilOrganicCarbonState) => state.activeFarmImageId;
export const soilOrganicCarbonFarmGetSelector = (state: ISoilOrganicCarbonState) => state.soilOrganicCarbonFarmsGet;
export const getSoilOrganicCarbonImagesGetSelector = (state: ISoilOrganicCarbonState) => state.soilOrganicCarbonImagesGet;
export const soilOrganicCarbonSelectedDateSelector = (state: ISoilOrganicCarbonState) => state.selectedDate;

export interface ISoilOrganicCarbonState {
  soilOrganicCarbonImages: ISoilOrganicCarbonImageResDTO[];
  soilOrganicCarbonFarms: IFarm[];
  activeSoilOrganicCarbonFarm: IFarm;
  activeFarmImageId: string;
  selectedDate: Date;
  soilOrganicCarbonImagesGet: {
    loading: boolean;
    error: unknown;
  };
  soilOrganicCarbonFarmsGet: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: ISoilOrganicCarbonState = {
  soilOrganicCarbonImages: null,
  soilOrganicCarbonFarms: null,
  activeSoilOrganicCarbonFarm: null,
  activeFarmImageId: null,
  selectedDate: null,
  soilOrganicCarbonImagesGet: {
    loading: false,
    error: null,
  },
  soilOrganicCarbonFarmsGet: {
    loading: false,
    error: null,
  },
};

const reducer = (state: ISoilOrganicCarbonState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_START:
      return updateObject(state, {
        soilOrganicCarbonImagesGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_FAIL:
      return updateObject(state, {
        soilOrganicCarbonImagesGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_START:
      return updateObject(state, {
        soilOrganicCarbonFarmsGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_FAIL:
      return updateObject(state, {
        soilOrganicCarbonFarmsGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.SOIL_ORGANIC_CARBON_IMAGE_GET_SUCCESS:
      return updateObject(state, {
        soilOrganicCarbonImages: action.soilOrganicCarbonImages,
        activeFarmImageId: action.activeFarmImageId,
        activeSoilOrganicCarbonFarm: action.activeFarm,
        soilOrganicCarbonImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.SOIL_ORGANIC_CARBON_FARM_GET_SUCCESS:
      return updateObject(state, {
        soilOrganicCarbonFarms: action.soilOrganicCarbonFarms,
        activeSoilOrganicCarbonFarm: action.activeFarm,
        soilOrganicCarbonFarmsGet: {
          loading: false,
          error: null,
        },
      });

    case ActionTypes.SOIL_ORGANIC_CARBON_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default reducer;
