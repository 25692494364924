import React from 'react';
import ActionTypes from './actionTypes';
import {IEnqueuedNotification, INotification} from '../reducers/snackbar';
import {FaTimes} from 'react-icons/fa';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';

const CloseButton = ({notificationKey}: {notificationKey: number | string}) => {
  const dispatch = useDispatch();
  return (
    <Button
      onClick={() => {
        dispatch(closeSnackbar(notificationKey as number));
      }}
      style={{color: 'white'}}
    >
      <FaTimes />
    </Button>
  );
};

interface ICloseSnackBar {
  type: ActionTypes.CLOSE_SNACKBAR;
  key: number;
  dismissAll: boolean;
}

export const closeSnackbar = (key?: number): ICloseSnackBar => ({
  type: ActionTypes.CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

interface IEnqueueSnackBar {
  type: ActionTypes.ENQUEUE_SNACKBAR;
  notification: INotification;
}

export const enqueueSnackbar = (notification: IEnqueuedNotification): IEnqueueSnackBar => {
  const key = new Date().getTime() + Math.random();
  return {
    type: ActionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key,
      options: {
        ...notification.options,
        action: () => <CloseButton notificationKey={key} />,
      },
    },
  };
};

interface IRemoveSnackBar {
  type: ActionTypes.REMOVE_SNACKBAR;
  key: number;
}

export const removeSnackbar = (key: number): IRemoveSnackBar => ({
  type: ActionTypes.REMOVE_SNACKBAR,
  key,
});

export type Action = ReturnType<typeof enqueueSnackbar> | ReturnType<typeof closeSnackbar> | ReturnType<typeof removeSnackbar>;
