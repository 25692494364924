import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import {IFarm} from '../../../../store/reducers/farm';
import {IUserDetails} from '../../../../store/reducers/user';
import ExpansionPanel from './ExpansionPanel';
import MapController from './Map/MapController';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapPlaceholder from '../../../../components/UI/MapPlaceholder';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import {useTranslation} from 'react-i18next';
import {activeFarmIdSelector, farmGetSelector, farmPutSelector, getUserSelector, selectedOrganizationSelector} from '../../../../store/selectors';
import {deleteFarm, resetState, updateActiveFarm, updateFarm} from '../../../../store/actions';
import {usePrevious} from '../../../../hooks/usePrevious';
import {withUser, WithUserProps} from '../../../../hooks/useAuth';
import {getUserGroups} from '../../../authHOC';

const Progress = styled(CircularProgress)`
  && {
    margin: 1rem auto;
  }
`;
const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface Props extends WithUserProps {
  passedFarmId: string;
  allFarms: IFarm[];
  userDetails: IUserDetails;
}

const EditFarm = ({user, passedFarmId, allFarms, userDetails}: Props) => {
  const [isOpenFarmId, setOpenFarmId] = useState('');
  const [expandedPanelId, setExpandedPanelId] = useState<string>(null);
  const [drawingPolygon, setDrawingPolygon] = useState(false);
  const [polygon, setPolygon] = useState({
    polygon: null,
    polygonDrawn: false,
    coordinatesOfNewPoly: [],
  });
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const activeFarmId = useSelector(activeFarmIdSelector);
  const {loading: loadingGetFarms} = useSelector(farmGetSelector);
  const {loading: loadingPutFarms} = useSelector(farmPutSelector);
  const {loading: loadingDeleteFarms} = useSelector(getUserSelector);
  const prevActiveFarmId = usePrevious(activeFarmId);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const groups: string[] = getUserGroups(user);
  const isSuperUser = groups.includes('SUPERADMIN');

  const handleDrawingPolyToggle = (farmId: string) => {
    setExpandedPanelId(farmId);
    setDrawingPolygon(!drawingPolygon);
  };

  const handlePassPolyCoords = (polygon, coordinates) => {
    setPolygon({polygon: polygon, polygonDrawn: true, coordinatesOfNewPoly: coordinates});
  };

  const resetPolygonCoords = () => {
    setPolygon({polygon: null, polygonDrawn: false, coordinatesOfNewPoly: []});
  };

  const onResetState = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const onUpdateFarm = (farmId, farmName) => {
    dispatch(updateFarm(farmId, farmName, selectedOrganization.id));
  };
  const onDeleteFarm = farmId => {
    dispatch(deleteFarm(farmId, selectedOrganization.id, isSuperUser));
  };
  const onUpdateFarmId = farmId => {
    dispatch(updateActiveFarm(farmId));
  };

  useLayoutEffect(() => {
    if (isOpenFarmId === '') {
      setOpenFarmId(activeFarmId);
    }
    if (passedFarmId) {
      setOpenFarmId(passedFarmId);
      onResetState();
    }
  }, [allFarms, dispatch, userDetails, user, isOpenFarmId, passedFarmId, activeFarmId, onResetState]);

  useEffect(() => {
    return () => {
      onResetState();
    };
  }, [onResetState]);

  useEffect(() => {
    if (activeFarmId !== prevActiveFarmId && !!activeFarmId) {
      setOpenFarmId(activeFarmId);
    }
  }, [activeFarmId, prevActiveFarmId]);

  return (
    <div style={{position: 'relative'}}>
      <ContentLeftSidebar
        wider
        sidebar={
          <div
            style={{
              padding: '0 0rem',
              maxHeight: 'calc(100% - 120px)',
              overflowY: 'scroll',
            }}
          >
            <Typography variant="h6" style={{marginTop: '-50px', marginBottom: '50px'}}>
              {t('setting.tab.edit')}
            </Typography>
            {loadingGetFarms && <Progress size={20} />}
            <div>
              {allFarms &&
                allFarms.map(farm => {
                  return (
                    farm?.farmid && (
                      <ExpansionPanel
                        key={farm.farmid}
                        farm={farm}
                        userDetails={userDetails}
                        onUpdateFarm={onUpdateFarm}
                        onDeleteFarm={onDeleteFarm}
                        onUpdateFarmId={onUpdateFarmId}
                        loadingPutFarms={loadingPutFarms}
                        loadingDeleteFarms={loadingDeleteFarms}
                        isOpen={isOpenFarmId === farm.farmid}
                        handleDrawingPolyToggle={handleDrawingPolyToggle}
                        drawingActive={activeFarmId === expandedPanelId && drawingPolygon && activeFarmId === farm.farmid}
                        activeFarmId={activeFarmId || (allFarms?.length && allFarms[0].farmid)}
                        polygon={polygon}
                        resetPolygonCoords={resetPolygonCoords}
                        onResetState={onResetState}
                      />
                    )
                  );
                })}
            </div>
          </div>
        }
        content={
          <MapContainer>
            {!activeFarmId && <MapPlaceholder width={'100%'} height={'100%'} />}
            <MapController
              activeFarmId={activeFarmId}
              drawingActive={activeFarmId === expandedPanelId && drawingPolygon && !polygon.polygonDrawn}
              handlePassPolyCoords={handlePassPolyCoords}
              passedFarmId={passedFarmId}
            />
          </MapContainer>
        }
      />
    </div>
  );
};

export default withUser(EditFarm);
