import {RootState} from '../reducers';
import * as Yield from '../reducers/yield';

export const yieldPredictionsSelector = (state: Pick<RootState, 'yield'>) => Yield.yieldPredictionsSelector(state.yield);
export const getYieldPredictionSelector = (state: Pick<RootState, 'yield'>) => Yield.getYieldPredictionSelector(state.yield);
export const yieldPredictionDatesSelector = (state: Pick<RootState, 'yield'>) => Yield.yieldPredictionDatesSelector(state.yield);
export const latestYieldsSelector = (state: Pick<RootState, 'yield'>) => Yield.latestYieldsSelector(state.yield);
export const getHistoricalYieldSelector = (state: Pick<RootState, 'yield'>) => Yield.getHistoricalYieldSelector(state.yield);
export const historicalYieldSelector = (state: Pick<RootState, 'yield'>) => Yield.historicalYieldSelector(state.yield);
export const postHistoricalYieldSelector = (state: Pick<RootState, 'yield'>) => Yield.postHistoricalYieldSelector(state.yield);
export const deleteHistoricalYieldSelector = (state: Pick<RootState, 'yield'>) => Yield.deleteHistoricalYieldSelector(state.yield);
export const getPredictedMaturitySelector = (state: Pick<RootState, 'yield'>) => Yield.getPredictedMaturitySelector(state.yield);
export const maturityPredictionsSelector = (state: Pick<RootState, 'yield'>) => Yield.maturityPredictionsSelector(state.yield);

export const getHistoricalMaturitySelector = (state: Pick<RootState, 'yield'>) => Yield.getHistoricalMaturitySelector(state.yield);
export const maturityHistoricalSelector = (state: Pick<RootState, 'yield'>) => Yield.maturityHistoricalSelector(state.yield);
