import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePrevious} from '../../../hooks/usePrevious';
import {union, uniqBy} from 'lodash';
import {IFarmEntity, INoteEntity, INoteTypeEntity, IPolygonEntity} from '@deep-planet/api-interfaces';
import {IFarm} from '../../../store/reducers/farm';
import {useSelector} from 'react-redux';
import {organizationsSelector} from '../../../store/selectors';
import {ALL, getOrganizationIdForFarm, getUserFarms, NOTE_PRIVATE, NOTE_PUBLIC} from './getUserFarms';
import {baseApiUrl} from '../../../config/const';
import axios from 'axios';

export interface INoteForm {
  summary: {
    value: boolean;
    text: string;
  };
  description: {
    value: boolean;
    text: string;
  };
  location: {
    value: boolean;
    text: string;
  };
  assignee: {
    value: boolean;
    text: string;
  };
}

export interface ISelectedFile {
  name: string;
  type: string;
  size: number;
}

const MAX_SUMMARY_LENGTH = 150;
const MAX_DESCRIPTION_LENGTH = 1500;
const MAX_LOCATION_LENGTH = 150;

function useEdit(types: INoteTypeEntity[], farms: IFarm[], userNames?: string[], note?: INoteEntity) {
  const [selectedNote, setSelectedNote] = useState<INoteEntity>(note || null);
  const [selectedType, setSelectedType] = useState<INoteTypeEntity>(note?.type || null);
  const [selectedFarm, setSelectedFarm] = useState<IFarm | IFarmEntity>(note?.farm || null);
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity>(note?.polygon || null);
  const [selectedDate, setSelectedDate] = useState<Date>(note?.date || new Date());
  const [summary, setSummary] = useState(note?.summary || '');
  const [description, setDescription] = useState(note?.description || '');
  const [location, setLocation] = useState(note?.location || '');
  const [selectedFiles, setSelectedFiles] = useState<ISelectedFile[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<string>(note?.toUser?.username || '');
  const [selectedOrganization, setSelectedOrganization] = useState<string>(note?.organization?.id || null);
  const [selectedVisibility, setSelectedVisibility] = useState<string>(note?.visible);
  const [selectedUserName, setSelectedUserName] = useState<string>('');
  const organizations = useSelector(organizationsSelector);
  const [errors, setErrors] = useState<INoteForm>({
    summary: {
      value: false,
      text: '',
    },
    description: {
      value: false,
      text: '',
    },
    location: {
      value: false,
      text: '',
    },
    assignee: {
      value: false,
      text: '',
    },
  });
  const prevSelectedFarm = usePrevious<IFarm | IFarmEntity>(selectedFarm);
  const {t} = useTranslation();

  useEffect(() => {
    if (selectedNote && !selectedFarm && selectedNote?.farm) setSelectedFarm(selectedNote.farm);
  }, [selectedFarm, selectedNote]);

  const getFarm = (farmId: string) => {
    return farms.find(({id}) => id === farmId) || getUserFarms(organizations, null, selectedOrganization).find(({id}) => id === farmId);
  };

  const handleChangeSummary = (currentSummary: string) => {
    setSummary(currentSummary);
    if (currentSummary.length > MAX_SUMMARY_LENGTH) {
      setErrors({...errors, summary: {value: true, text: t('forms.shorter.or.equal', {length: MAX_SUMMARY_LENGTH})}});
    } else {
      setErrors({...errors, summary: {value: false, text: ''}});
    }
  };

  const handleChangeLocation = (currentLocation: string) => {
    setLocation(currentLocation);
    if (currentLocation.length > MAX_LOCATION_LENGTH) {
      setErrors({...errors, location: {value: true, text: t('forms.shorter.or.equal', {length: MAX_LOCATION_LENGTH})}});
    } else {
      setErrors({...errors, location: {value: false, text: ''}});
    }
  };

  const handleChangeDescription = (currentDescription: string) => {
    setDescription(currentDescription);
    if (currentDescription.length > MAX_DESCRIPTION_LENGTH) {
      setErrors({...errors, description: {value: true, text: t('forms.shorter.or.equal', {length: MAX_DESCRIPTION_LENGTH})}});
    } else {
      setErrors({...errors, description: {value: false, text: ''}});
    }
  };

  const handleTypeSelection = (value: string) => {
    const type = types.find(({name}) => name === value);
    if (type) setSelectedType(type);
  };

  const handleFarmSelection = async (value: string) => {
    const farm = getFarm(value);
    if (!farm?.polygons) {
      try {
        const response = await axios.get(`${baseApiUrl}/farm/${value}/polygon`);
        farm.polygons = response.data;
      } catch (e) {
        //
      }
    }
    if (farm) setSelectedFarm(farm);
    if (farm !== prevSelectedFarm) setSelectedPolygon(null);
  };

  const handlePolygonSelection = (value: string) => {
    const polygon = selectedFarm?.polygons.find(({id}) => id === value);
    if (polygon) setSelectedPolygon(polygon);
  };

  const handleDateSelection = date => setSelectedDate(date);

  const handleNoteAttachmentChange = (files: ISelectedFile[]) => {
    setSelectedFiles(uniqBy(union(selectedFiles, files), 'name'));
  };

  const handleNoteAttachmentCancel = (nameToCancel: string) => {
    const files = selectedFiles.filter(({name}) => name !== nameToCancel);
    setSelectedFiles(files);
  };

  const handleAssigneeSelection = (username: string) => {
    setSelectedAssignee(username);
  };

  const handleOrganizationSelection = (id: string) => {
    setSelectedOrganization(id);
    const organizationId = organizations.filter(o => o.id === id).map(o => o.organizationToUsers.length && o.organizationToUsers.some(otu => otu?.user?.username === selectedAssignee));
    if (!organizationId) setSelectedAssignee(null);
    setSelectedFarm(null);
    setSelectedPolygon(null);
    setSelectedUserName(null);
    setSelectedVisibility(null);
  };

  const handleUserNameSelection = (userName: string) => {
    setSelectedUserName(userName);
    setSelectedAssignee(userName);
    userName === ALL ? setSelectedVisibility(NOTE_PUBLIC) : setSelectedVisibility(NOTE_PRIVATE);
  };

  const handleNoteSelection = (note: INoteEntity) => {
    setSelectedNote(note);
    setSelectedType(note?.type);
    setSelectedFarm(note?.farm ? farms.find(({id}) => id === note?.farm?.id) : null);
    setSelectedPolygon(note?.polygon);
    setSelectedDate(note?.date);
    setSummary(note?.summary);
    setDescription(note?.description);
    setLocation(note?.location);
    setSelectedAssignee(note?.toUser?.username);
    setSelectedOrganization(note?.organization?.id);
    // visible only for super admin
    if (userNames?.length) {
      const orgId = note?.farm ? getOrganizationIdForFarm(organizations, note?.farm?.id) : null;
      setSelectedOrganization(note?.organization?.id || orgId);
      setSelectedVisibility(note?.visible);
      setSelectedUserName(note?.visible === 'PUBLIC' ? 'ALL' : null);
    }
  };

  return {
    summary,
    description,
    location,
    errors,
    setErrors,
    selectedFarm,
    setSelectedFarm,
    selectedPolygon,
    selectedType,
    selectedDate,
    setSelectedType,
    selectedFiles,
    selectedNote,
    selectedAssignee,
    selectedOrganization,
    selectedVisibility,
    selectedUserName,
    setSelectedFiles,
    setSelectedOrganization,
    setSelectedPolygon,
    handleNoteSelection,
    handleChangeSummary,
    handleChangeLocation,
    handleChangeDescription,
    handleTypeSelection,
    handleFarmSelection,
    handlePolygonSelection,
    handleDateSelection,
    handleNoteAttachmentChange,
    handleNoteAttachmentCancel,
    handleAssigneeSelection,
    handleOrganizationSelection,
    handleUserNameSelection,
  };
}

export default useEdit;
