import {IFarmEntity, IOrganizationEntity, IOrganizationUserFarmPermissionTypeEntity, IUserEntity, OrganizationPermissionsResponse} from '@deep-planet/api-interfaces';
import {Box, MenuItem, TextField} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import Select from '@material-ui/core/Select';
import {Autocomplete} from '@material-ui/lab';
import {sortBy} from 'lodash';
import React from 'react';

interface Props {
  selectedOrganization: IOrganizationEntity;
  selectedFarm: IFarmEntity;
  selectedUser: IUserEntity;
  permissionTypes: IOrganizationUserFarmPermissionTypeEntity[];
  permissions: OrganizationPermissionsResponse[];
  handleFarmSelection: (farm: IFarmEntity) => void;
  handleUserSelection: (user: IUserEntity) => void;
  handlePermissionSelect: (userId: string, permissionId: string, farmId: string) => void;
}

export const AddUserToFarm = ({selectedFarm, selectedUser, handleUserSelection, selectedOrganization, permissions, permissionTypes, handleFarmSelection, handlePermissionSelect}: Props) => {
  const onSelectPermission = (permissionId: string, userId: string, farmId: string) => {
    handlePermissionSelect(userId, permissionId, farmId);
  };

  return (
    <Box padding="2rem 0 0">
      <FormControl fullWidth variant="outlined" margin="normal">
        <Autocomplete
          value={selectedFarm}
          options={sortBy(
            selectedOrganization.organizationToFarms.map(({farm}) => farm),
            ({name}) => name.toLowerCase()
          )}
          onChange={(e, farm) => handleFarmSelection(farm as IFarmEntity)}
          getOptionLabel={({name}) => name}
          renderInput={params => <TextField {...params} label="Select a farm" variant="outlined" />}
        />
      </FormControl>
      <FormControl fullWidth variant="outlined" margin="normal">
        <Autocomplete
          value={selectedUser}
          options={sortBy(
            selectedOrganization.organizationToUsers.map(({user}) => user),
            ({username}) => username.toLowerCase()
          )}
          onChange={(e, user) => handleUserSelection(user as IUserEntity)}
          getOptionLabel={({username}) => username}
          renderInput={params => <TextField {...params} label="Select a user" variant="outlined" />}
        />
      </FormControl>
      {selectedFarm && (
        <List>
          {selectedOrganization.organizationToUsers?.map(({user}) => {
            const currentPermission = permissions.find(p => p.userId === user?.id);
            return (
              <Box key={user.id} display="flex" alignItems="center" justifyContent="space-between">
                {user.username}
                <Box width="50%">
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="permission-selection-label">Select a permission</InputLabel>
                    <Select
                      labelId="permission-selection-label"
                      id="permission-selection"
                      value={currentPermission?.permissionType?.id}
                      onChange={e => onSelectPermission(e.target.value as string, user.id, selectedFarm.id)}
                      label="Select permission"
                    >
                      {permissionTypes.map(({name, id}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </List>
      )}
      {selectedUser && (
        <List>
          {selectedOrganization.organizationToFarms?.map(({farm}) => {
            const currentPermission = permissions.find(p => p.farmId === farm?.id);
            return (
              <Box key={farm.id} display="flex" alignItems="center" justifyContent="space-between">
                {farm.name}
                <Box width="50%">
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="permission-selection-label">Select a permission</InputLabel>
                    <Select
                      labelId="permission-selection-label"
                      id="permission-selection"
                      value={currentPermission?.permissionType?.id}
                      onChange={e => onSelectPermission(e.target.value as string, selectedUser.id, farm.id)}
                      label="Select permission"
                    >
                      {permissionTypes.map(({name, id}) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            );
          })}
        </List>
      )}
    </Box>
  );
};
