import React, {useEffect} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {Organizations} from './app/containers/pages/Admin/Organizations/Organizations';

// Admin routes
import Reports from './app/containers/pages/Admin/Reports';
import Users from './app/containers/pages/Admin/Users';
import MoistureSensors from './app/containers/pages/Admin/MoistureSensors';
import Farms from './app/containers/pages/Admin/Farms';

// Pages
import Dashboard from './app/containers/pages/Dashboard';
import {MaturityMap} from './app/containers/pages/MaturityMap/MaturityMap';
import YieldPrediction from './app/containers/pages/YieldPrediction';
import NDVI from './app/containers/pages/NDVI';
import NDVIPlanet from './app/containers/pages/NDVIPlanet';
import Login from './app/components/Cognito/Login';
import ForgotPassword from './app/components/Cognito/ForgotPassword';
import Segmentation from './app/containers/pages/Segmentation';
import Settings from './app/containers/pages/Settings';
import MyAccount from './app/containers/pages/MyAccount';
import SoilMoisture from './app/containers/pages/SoilMoisture';
import WeatherForecastPage from './app/containers/pages/WeatherForecastPage';
import SeasonIndices from './app/containers/pages/SeasonIndices';
import Video from './app/containers/pages/SoilMoistureInterpolation';
import Timelapse from './app/containers/pages/Timelapse';
import Notes from './app/containers/pages/Notes';
import {Harvest} from './app/containers/pages/Harvest/Harvest';
import {HistoricalHarvest} from './app/containers/pages/HistoricalHarvest/HistoricalHarvest';
import Irrigation from './app/containers/pages/irrigation';
import {YieldPerPixelMap} from './app/containers/pages/YieldPerPixel/YieldPerPixel';

// Soil Signal Routes
import SoilSignalSummary from './app/containers/pages/SoilSignalSummary';
import OvergrazedArea from './app/containers/pages/SoilSignal/OvergrazedArea';
import GrasslandBiomass from './app/containers/pages/SoilSignal/GrasslandBiomass';
import ForestBiomass from './app/containers/pages/SoilSignal/ForestBiomass';
import BareSoil from './app/containers/pages/SoilSignal/BareSoil';
import LeafArea from './app/containers/pages/SoilSignal/LeafArea';
import PlantDensity from './app/containers/pages/SoilSignal/PlantDensity';
import PlantHeight from './app/containers/pages/SoilSignal/PlantHeight';
import PlantMature from './app/containers/pages/SoilSignal/PlantMature';
import PlantYoung from './app/containers/pages/SoilSignal/PlantYoung';
import SoilNutrient from './app/containers/pages/SoilSignal/SoilNutrient';
import Disease from './app/containers/pages/Disease/Disease';

// Routes
import {commonRoutes, vineSignalRoutes, cropSignalRoutes, soilSignalRoutes, navRoutes, authRoutes, adminRoutes, superAdminRoutes} from './app/utilities/routes';

// HOCs & utils
import Notifier from './app/components/UI/Notifier/Notifier';
import authHOC from './app/containers/authHOC';
import {useDispatch} from 'react-redux';
import {userPostInit} from './app/store/actions/user';
import ErrorBoundary from './app/components/UI/ErrorBoundary/ErrorBoundary';
import HistoricalYield from './app/containers/pages/YieldPrediction/HistoricalYield/HistoricalYield';
import {Notifications} from './app/containers/pages/Admin/Notifications/Notifications';
import SoilOrganicCarbon from './app/containers/pages/SoilOrganicCarbon';
import {Configuration} from './app/containers/pages/Admin/Configuration/Configuration';

function App({location}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (location && location.state && location.pathname !== location.state.prevPath) {
      dispatch(userPostInit());
    }
  }, [dispatch, location]);

  return (
    <ErrorBoundary>
      <Notifier />
      <Switch>
        {/* Misc routes */}
        <Route exact path="/" component={authHOC(Dashboard)} />
        <Route exact path={commonRoutes.MY_ACCOUNT.slug} component={authHOC(MyAccount)} />
        {/* Auth routes */}
        <Route exact path={authRoutes.SIGN_IN.slug} component={Login} />
        <Route exact path={authRoutes.RESET_PASSWORD.slug} component={ForgotPassword} />
        {/* Nav routes */}
        <Route exact path={navRoutes.SETTINGS.slug} component={authHOC(Settings)} />
        {/* CROP_SIGNAL_ROUTES */}
        <Route exact path={cropSignalRoutes.CROPDISCOVERY.slug} component={authHOC(Segmentation)} />
        {/* Admin routes */}
        <Route exact path={adminRoutes.MOISTURESENSORS.slug} component={authHOC(MoistureSensors)} />
        <Route exact path={superAdminRoutes.USERS.slug} component={authHOC(Users)} />
        <Route exact path={adminRoutes.FARMS.slug} component={authHOC(Farms)} />
        <Route exact path={superAdminRoutes.REPORTS.slug} component={authHOC(Reports)} />
        <Route exact path={superAdminRoutes.ORGANIZATIONS.slug} component={authHOC(Organizations)} />
        <Route exact path={superAdminRoutes.NOTIFICATIONS.slug} component={authHOC(Notifications)} />
        <Route exact path={superAdminRoutes.ADVANCED_PERMISSIONS.slug} component={authHOC(Configuration)} />
        {/* VINE_SIGNAL_ROUTES */}
        <Route exact path={vineSignalRoutes.DASHBOARD.slug} component={authHOC(Dashboard)} />
        <Route exact path={vineSignalRoutes.NDVI.slug} component={authHOC(NDVI)} />
        <Route exact path={vineSignalRoutes.NDWI.slug} component={authHOC(NDVI)} />
        <Route exact path={vineSignalRoutes.NDVIPLANET.slug} component={authHOC(NDVIPlanet)} />
        <Route exact path={vineSignalRoutes.SOILNUTRIENT.slug} component={authHOC(SoilNutrient)} />
        <Route exact path={vineSignalRoutes.SOIL_ORGANIC_CARBON.slug} component={authHOC(SoilOrganicCarbon)} />
        <Route exact path={vineSignalRoutes.SOILMOISTURE.slug} component={authHOC(SoilMoisture)} />
        <Route exact path={vineSignalRoutes.SOILMOISTUREINTERPOLATION.slug} component={authHOC(Video)} />
        <Route exact path={vineSignalRoutes.YIELD.slug} component={authHOC(YieldPrediction)} />
        <Route exact path={vineSignalRoutes.MY_YIELD.slug} component={authHOC(HistoricalYield)} />
        <Route exact path={vineSignalRoutes.HARVEST.slug} component={authHOC(Harvest)} />
        <Route exact path={vineSignalRoutes.HARVEST_CURVES.slug} component={authHOC(HistoricalHarvest)} />
        <Route exact path={vineSignalRoutes.MATURITY_MAP.slug} component={authHOC(MaturityMap)} />
        <Route exact path={vineSignalRoutes.SEASON_INDICES.slug} component={authHOC(SeasonIndices)} />
        <Route exact path={vineSignalRoutes.WEATHER.slug} component={authHOC(WeatherForecastPage)} />
        <Route exact path={vineSignalRoutes.TIMELAPSE.slug} component={authHOC(Timelapse)} />
        <Route exact path={vineSignalRoutes.NOTES.slug} component={authHOC(Notes)} />
        <Route exact path={vineSignalRoutes.IRRIGATION.slug} component={authHOC(Irrigation)} />
        <Route exact path={vineSignalRoutes.YIELD_PER_PIXEL.slug} component={authHOC(YieldPerPixelMap)} />
        <Route exact path={vineSignalRoutes.DISEASE.slug} component={authHOC(Disease)} />
        {/* Soil Signal routes */}
        <Route exact path={soilSignalRoutes.DASHBOARD.slug} component={authHOC(Dashboard)} />
        <Route exact path={soilSignalRoutes.NDVI.slug} component={authHOC(NDVI)} />
        <Route exact path={soilSignalRoutes.NDWI.slug} component={authHOC(NDVI)} />
        <Route exact path={soilSignalRoutes.NDVIPLANET.slug} component={authHOC(NDVIPlanet)} />
        <Route exact path={soilSignalRoutes.SOILMOISTUREINTERPOLATION.slug} component={authHOC(Video)} />
        <Route exact path={soilSignalRoutes.IRRIGATION.slug} component={authHOC(Irrigation)} />
        <Route exact path={soilSignalRoutes.SEASON_INDICES.slug} component={authHOC(SeasonIndices)} />
        <Route exact path={soilSignalRoutes.WEATHER.slug} component={authHOC(WeatherForecastPage)} />
        <Route exact path={soilSignalRoutes.TIMELAPSE.slug} component={authHOC(Timelapse)} />
        <Route exact path={soilSignalRoutes.SOILNUTRIENT.slug} component={authHOC(SoilNutrient)} />
        <Route exact path={soilSignalRoutes.SOIL_ORGANIC_CARBON.slug} component={authHOC(SoilOrganicCarbon)} />
        <Route exact path={soilSignalRoutes.SUMMARY.slug} component={authHOC(SoilSignalSummary)} />
        <Route exact path={soilSignalRoutes.OVERGRAZED_AREA.slug} component={authHOC(OvergrazedArea)} />
        <Route exact path={soilSignalRoutes.GRASSLAND_BIOMASS.slug} component={authHOC(GrasslandBiomass)} />
        <Route exact path={soilSignalRoutes.FOREST_BIOMASS.slug} component={authHOC(ForestBiomass)} />
        <Route exact path={soilSignalRoutes.BARE_SOIL.slug} component={authHOC(BareSoil)} />
        <Route exact path={soilSignalRoutes.LEAF_AREA.slug} component={authHOC(LeafArea)} />
        <Route exact path={soilSignalRoutes.PLANT_DENSITY.slug} component={authHOC(PlantDensity)} />
        <Route exact path={soilSignalRoutes.PLANT_HEIGHT.slug} component={authHOC(PlantHeight)} />
        <Route exact path={soilSignalRoutes.PLANT_YOUNG.slug} component={authHOC(PlantYoung)} />
        <Route exact path={soilSignalRoutes.PLANT_MATURE.slug} component={authHOC(PlantMature)} />
        <Route exact path={soilSignalRoutes.NOTES.slug} component={authHOC(Notes)} />
        <Route exact path={soilSignalRoutes.DISEASE.slug} component={authHOC(Disease)} />
        <Redirect to={vineSignalRoutes.DASHBOARD.slug} />
      </Switch>
    </ErrorBoundary>
  );
}

export default withRouter(Sentry.withProfiler(App));
