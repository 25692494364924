import React from 'react';
import Plotly from 'react-plotly.js';
import {ISoilOrganicCarbonHistoricalUI, ISoilOrganicCarbonInfoUI} from '@deep-planet/api-interfaces';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

interface Props {
  blockName: string;
  historicalSoc: ISoilOrganicCarbonHistoricalUI[];
  soilOrganicCarbonInfo?: ISoilOrganicCarbonInfoUI;
  handleShowChangeOverScreen?: () => void;
  buttonText?: string;
  showChart?: boolean;
  metric: string;
  conversion: number;
}

const PlotChart = ({blockName, historicalSoc, metric, conversion}: Props) => {
  const {t} = useTranslation();
  let years = [];
  let socValues = [];

  // show SOC yearly average
  if (historicalSoc?.length) {
    const socAveragePerYear = _(historicalSoc)
      .map(hs => ({year: new Date(hs.date).getUTCFullYear(), soc: hs.soc}))
      .groupBy(g => g.year)
      .values()
      .map(values => {
        const year = values[0].year;
        const length = values.length;
        return {
          year: year,
          soc: values.map(v => v.soc).reduce((agg, coll) => Number(agg) + Number(coll)) / length,
        };
      })
      .value();
    years = socAveragePerYear.map(s => s.year);
    socValues = socAveragePerYear.map(s => s.soc * conversion);
  }

  // Show graph range(min_value * 0.9, max_value * 1.1)
  const maxValue = Math.max(...socValues) * 1.1;
  let minValue = Math.min(...socValues);
  if (minValue > 0) minValue = minValue * 0.9;

  const trace1: Plotly.Data = {
    x: years,
    y: socValues,
    type: 'bar',
    name: t('average.soc'),
  };
  const data = [trace1];
  const blockname = blockName || ' ';
  return (
    <Plotly
      data={data}
      style={{height: '100%', position: 'absolute', display: 'flex'}}
      layout={{
        title: t('polygon.info.block.name') + ' - ' + blockname,
        xaxis: {type: 'category'},
        yaxis: {title: t('average.soc'), range: [minValue, maxValue]},
        autosize: true,
        width: 560,
      }}
      useResizeHandler={true}
      config={{responsive: true}}
    />
  );
};

export {PlotChart};
