import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getFarmSoilsValues, getFarmSoilValues} from '../store/actions';
import {IFarm} from '../store/reducers/farm';
import {allSoilValuesSelector, farmGetSoilSelector, selectedOrganizationSelector, soilValuesSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export const useSoil = (activeFarm: IFarm, startDate?: Date, endDate?: Date) => {
  const latestSoilValues = useSelector(soilValuesSelector);
  const soilValues = useSelector(allSoilValuesSelector);
  const {loading: loadingGetFarmSoil} = useSelector(farmGetSoilSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevSoilValues = usePrevious(soilValues);
  const prevLatestSoilValues = usePrevious(latestSoilValues);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeFarm && !loadingGetFarmSoil && !soilValues?.length && !prevLatestSoilValues) {
      dispatch(getFarmSoilValues(activeFarm.farmid, selectedOrganization?.id));
    }
  }, [prevLatestSoilValues, activeFarm, dispatch, loadingGetFarmSoil, soilValues, selectedOrganization]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id) {
      dispatch(getFarmSoilValues(activeFarm.farmid, selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, prevSelectedOrganization, selectedOrganization]);

  useEffect(() => {
    if (activeFarm && !loadingGetFarmSoil && !soilValues?.length && !prevSoilValues && startDate && endDate) {
      dispatch(getFarmSoilsValues(activeFarm.farmid, startDate, endDate));
    }
  }, [activeFarm, dispatch, endDate, loadingGetFarmSoil, prevSoilValues, soilValues, startDate]);

  const onGetSoilValues = (activeFarm: IFarm, startDate: Date, endDate: Date) => {
    dispatch(getFarmSoilsValues(activeFarm.farmid, startDate, endDate));
  };

  return {
    latestSoilValues,
    loadingGetFarmSoil,
    soilValues,
    onGetSoilValues,
  };
};
