import React, {useCallback, useState} from 'react';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {selectedOrganizationSelector} from '../../../../../store/selectors/organization';
import theme from '../../../../../utilities/theme';
import {connect, useDispatch, useSelector} from 'react-redux';
import {farmPostInit, postFarmFile} from '../../../../../store/actions/farm';
import {useTranslation} from 'react-i18next';
import Spinner from '../../../../../components/UI/Spinner';
import {enqueueSnackbar} from '../../../../../store/actions';
import {chain} from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import {Link} from 'react-router-dom';
import {ConfirmationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/ConfirmationDialog';
import {MATURITYCURVE} from 'apps/web-portal-ui/src/app/config/const';
import {IOrganizationEntity} from '@deep-planet/api-interfaces';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModalBody = styled.div`
  position: absolute;
  background-color: white;
  border: 2px solid #000;
  ${theme.breakpoints.down('sm')} {
    height: 70%;
    width: 80%;
  }
`;

const InfoText = styled(Typography)`
  padding: 1rem;
  opacity: 0.5;
  text-align: center;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100% - 160px);
  justify-content: center;
`;

const DropArea = styled.div`
  cursor: pointer;
  display: flex;
  height: 200px;
  width: 500px;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    height: 100%;
    width: auto;
  }
`;

const StyledSuccessBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  width: 500px;
  padding: 2rem;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
`;

const CloseButton = styled(IconButton)`
  height: fit-content;
  position: absolute !important;
  top: 0;
  right: 0;
`;

const StyledButton = styled(Button)`
  width: 180px;
  height: fit-content;
`;

interface Props {
  handleBack: () => void;
  loadingPostFarm: boolean;
  success: boolean;
  newFarmId: string;
  //*** added as part of config feature - maturity curves 27/06/2023
  handleUpload?: (files) => void;
  feature?: string;
  featureOrganization?: IOrganizationEntity;
  loadingFileUpload?: boolean;
  //  ****//
}

const UploadFile = ({handleBack, loadingPostFarm, success, newFarmId, handleUpload, featureOrganization, feature = 'FARM', loadingFileUpload}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropBox, setIsOpenDropBox] = useState(false);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const handleClick = () => {
    setIsOpen(true);
    setIsOpenDropBox(false);
  };

  const handleDropBoxDialog = () => {
    setIsOpenDropBox(!isOpenDropBox);
  };

  const handleClose = () => {
    if (success) {
      dispatch(farmPostInit());
    }
    setIsOpen(false);
  };

  const handleYes = () => {
    if (feature === MATURITYCURVE) {
      handleUpload(acceptedFiles);
    } else {
      onDrop(acceptedFiles);
    }
    setIsOpenDropBox(false);
  };
  const handleClickOnNewFarm = () => dispatch(farmPostInit());
  const onDrop = useCallback(
    acceptedFiles => {
      const names = acceptedFiles.map(file => file.name);
      const isAnyOfItKml = names.some(name => name.endsWith('.kml'));
      const isAnyOfItKmz = names.some(name => name.endsWith('.kmz'));
      const isAnyOfItShp = names.some(name => name.endsWith('.shp'));
      if (acceptedFiles.length === 1 && (isAnyOfItKml || isAnyOfItKmz || isAnyOfItShp)) {
        dispatch(postFarmFile(acceptedFiles, selectedOrganization.id));
      } else if (acceptedFiles.length > 1 && isAnyOfItShp && !isAnyOfItKml && !isAnyOfItKmz) {
        dispatch(postFarmFile(acceptedFiles, selectedOrganization.id));
      } else {
        dispatch(enqueueSnackbar({message: t('setting.create.farm.step.uploading.error.not.valid'), options: {variant: 'error'}}));
      }
    },

    [dispatch, selectedOrganization, t]
  );
  const onDropRejected = (e: {errors: {message: string}[]}[]) => {
    const errorsArray = e.map(({errors}) => errors.map(({message}) => message));
    const errors = chain(errorsArray).flatten().uniq().value();
    for (const error of errors) {
      dispatch(enqueueSnackbar({message: error, options: {variant: 'error'}}));
    }
  };

  const onDropAccepted = () => {
    handleDropBoxDialog();
  };
  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: ['.kml', '.kmz', '.shp', '.dbf', '.prj', '.cpg', '.shx', '.sbn', '.sbx', '.csv'],
    maxSize: 1024 * 1024,
    maxFiles: 7,
    disabled: loadingPostFarm,
  });
  return (
    <>
      {feature !== MATURITYCURVE && (
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <ButtonContainer>
        <Box mt={4}>
          <Button variant="contained" color="primary" onClick={handleClick} style={{width: '200px'}}>
            {t('setting.create.farm.step.uploading.upload')}
            {loadingPostFarm && <Spinner size={15} color="primary" />}
          </Button>
        </Box>
        <InfoText variant="caption" display="block">
          {feature === MATURITYCURVE ? t('setting.create.farm.step.uploading.info.maturity') : t('setting.create.farm.step.uploading.info')}
        </InfoText>
      </ButtonContainer>
      <ConfirmationDialog
        open={isOpenDropBox}
        handleClose={handleDropBoxDialog}
        handleSubmit={handleYes}
        title={`${t('setting.create.farm.uploading.upload.dialog')} ${featureOrganization?.name.toUpperCase() || selectedOrganization?.name.toUpperCase()}`}
        submitText={t('forms.yes')}
      />
      <Box display="flex">
        <StyledModal
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen}>
            <StyledModalBody>
              {success ? (
                <StyledSuccessBox>
                  <Box>
                    <Typography align="center">{t('setting.create.farm.step.uploading.success')}</Typography>
                  </Box>
                  <ButtonWrapper>
                    <StyledButton variant="contained" color="primary" onClick={handleClickOnNewFarm}>
                      {t('setting.create.farm.step.uploading.success.create')}
                    </StyledButton>
                    <StyledButton variant="contained" color="primary" onClick={handleClose}>
                      <Link to={`/?selectedFarm=${newFarmId}`}>{t('setting.create.farm.step.uploading.success.dashboard')}</Link>
                    </StyledButton>
                  </ButtonWrapper>
                </StyledSuccessBox>
              ) : (
                <DropArea {...getRootProps()}>
                  {(loadingPostFarm || loadingFileUpload) && <Spinner size={30} color="primary" />}
                  {!loadingPostFarm && (
                    <>
                      <input {...getInputProps()} />
                      {isDragActive ? <Typography>{t('setting.create.farm.step.uploading.drop')}</Typography> : <Typography>{t('setting.create.farm.step.uploading.click')}</Typography>}
                    </>
                  )}
                </DropArea>
              )}
              <CloseButton onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </StyledModalBody>
          </Fade>
        </StyledModal>
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    loadingPostFarm: state.farm.farmPost.loading,
    success: state.farm.farmPost.success,
    newFarmId: state.farm.farmId,
  };
};

export default connect(mapStateToProps)(UploadFile);
