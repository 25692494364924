import {IHistoricalYieldResponse, IMaturityResponse, ISaveYieldRequest, IYieldEntity, IYieldResponse} from '@deep-planet/api-interfaces';
import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {getStringDate} from '../../helpers/dateHelpers';
import ActionTypes from './actionTypes';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';

/////////////////////////////////////////////
// GET yield prediction
/////////////////////////////////////////////

interface IPredictedYieldGetStart {
  type: ActionTypes.PREDICTED_YIELD_GET_START;
}

export const predictedYieldGetStart = (): IPredictedYieldGetStart => ({
  type: ActionTypes.PREDICTED_YIELD_GET_START,
});

interface IPredictedYieldGetSuccess {
  type: ActionTypes.PREDICTED_YIELD_GET_SUCCESS;
  yieldPredictions: IYieldResponse[];
  yieldPredictionDates: string[];
}

export const predictedYieldGetSuccess = (yieldPredictions: IYieldResponse[], yieldPredictionDates: string[]): IPredictedYieldGetSuccess => {
  return {
    type: ActionTypes.PREDICTED_YIELD_GET_SUCCESS,
    yieldPredictions,
    yieldPredictionDates,
  };
};

interface IPredictedYieldGetFail {
  type: ActionTypes.PREDICTED_YIELD_GET_FAIL;
  error: unknown;
}

export const predictedYieldGetFail = (error: unknown): IPredictedYieldGetFail => ({
  type: ActionTypes.PREDICTED_YIELD_GET_FAIL,
  error: error,
});

export const getPredictedYield = (predictionDate?: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(predictedYieldGetStart());

    const params = {
      predictionDate,
      organizationId,
    };
    Object.keys(params).forEach(key => !params[key] && delete params[key]);
    const queryString = new URLSearchParams(params).toString();

    const url = `${baseApiUrl}/yield${queryString ? `?${queryString}` : ''}`;
    const datesUrl = `${baseApiUrl}/yield/dates${organizationId ? `?organizationId${organizationId}` : ''}`;
    try {
      const [{data}, {data: dates}] = await Promise.all([axios.get<IYieldResponse[]>(url), axios.get<IYieldResponse[]>(datesUrl)]);
      dispatch(
        predictedYieldGetSuccess(
          data,
          dates.map(({predictionDate}) => getStringDate(predictionDate))
        )
      );
    } catch (err) {
      dispatch(predictedYieldGetFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

/////////////////////////////////////////////
// GET historical yield
/////////////////////////////////////////////

interface IYieldGetStart {
  type: ActionTypes.YIELD_GET_START;
}

export const yieldGetStart = (): IYieldGetStart => ({
  type: ActionTypes.YIELD_GET_START,
});

interface IYieldGetSuccess {
  type: ActionTypes.YIELD_GET_SUCCESS;
  historicalYields: IHistoricalYieldResponse[];
}

export const yieldGetSuccess = (historicalYields: IHistoricalYieldResponse[]): IYieldGetSuccess => {
  return {
    type: ActionTypes.YIELD_GET_SUCCESS,
    historicalYields,
  };
};

interface IYieldGetFail {
  type: ActionTypes.YIELD_GET_FAIL;
  error: unknown;
}

export const yieldGetFail = (error: unknown): IYieldGetFail => ({
  type: ActionTypes.YIELD_GET_FAIL,
  error: error,
});

export const getHistoricalYield = (organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(yieldGetStart());
    const url = `${baseApiUrl}/yield/historical${organizationId ? `?organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<IHistoricalYieldResponse[]>(url);
      dispatch(yieldGetSuccess(data));
    } catch (err) {
      dispatch(yieldGetFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

/////////////////////////////////////////////
// POST historical yield
/////////////////////////////////////////////

interface IYieldPostStart {
  type: ActionTypes.YIELD_POST_START;
}

export const yieldPostStart = (): IYieldPostStart => ({
  type: ActionTypes.YIELD_POST_START,
});

interface IYieldPostSuccess {
  type: ActionTypes.YIELD_POST_SUCCESS;
  savedYields: IYieldEntity[];
}

export const yieldPostSuccess = (savedYields: IYieldEntity[]): IYieldPostSuccess => {
  return {
    type: ActionTypes.YIELD_POST_SUCCESS,
    savedYields,
  };
};

interface IYieldPostFail {
  type: ActionTypes.YIELD_POST_FAIL;
  error: unknown;
}

export const yieldPostFail = (error: unknown): IYieldPostFail => ({
  type: ActionTypes.YIELD_POST_FAIL,
  error: error,
});

export const saveYield = (yields: ISaveYieldRequest) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(yieldPostStart());
    const url = `${baseApiUrl}/yield/historical`;
    try {
      const {data} = await axios.post<IYieldEntity[]>(url, yields);
      dispatch(yieldPostSuccess(data));
    } catch (err) {
      dispatch(yieldPostFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

/////////////////////////////////////////////
// DELETE historical yield
/////////////////////////////////////////////

interface IYieldDeleteStart {
  type: ActionTypes.YIELD_DELETE_START;
  id: string;
}

export const yieldDeleteStart = (id: string): IYieldDeleteStart => ({
  type: ActionTypes.YIELD_DELETE_START,
  id,
});

interface IYieldDeleteSuccess {
  type: ActionTypes.YIELD_DELETE_SUCCESS;
  deletedId: string;
}

export const yieldDeleteSuccess = (deletedId: string): IYieldDeleteSuccess => ({
  type: ActionTypes.YIELD_DELETE_SUCCESS,
  deletedId,
});

interface IYieldDeleteFail {
  type: ActionTypes.YIELD_DELETE_FAIL;
  error: unknown;
}

export const yieldDeleteFail = (error: unknown): IYieldDeleteFail => ({
  type: ActionTypes.YIELD_DELETE_FAIL,
  error,
});

export const deleteHistoricalYield = (id: string, callback: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(yieldDeleteStart(id));
    const url = `${baseApiUrl}/yield/historical/${id}`;
    try {
      await axios.delete(url);
      dispatch(yieldDeleteSuccess(id));
      callback();
    } catch (err) {
      dispatch(yieldDeleteFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

/////////////////////////////////////////////
// GET maturity prediction
/////////////////////////////////////////////

interface IGetPredictedMaturityStart {
  type: ActionTypes.PREDICTED_MATURITY_GET_START;
}

export const getPredictedMaturityStart = (): IGetPredictedMaturityStart => ({
  type: ActionTypes.PREDICTED_MATURITY_GET_START,
});

interface IGetPredictedMaturitySuccess {
  type: ActionTypes.PREDICTED_MATURITY_GET_SUCCESS;
  maturityPredictions: IMaturityResponse[];
}

export const getPredictedMaturitySuccess = (maturityPredictions: IMaturityResponse[]): IGetPredictedMaturitySuccess => ({
  type: ActionTypes.PREDICTED_MATURITY_GET_SUCCESS,
  maturityPredictions,
});

interface IGetPredictedMaturityFail {
  type: ActionTypes.PREDICTED_MATURITY_GET_FAIL;
  error: unknown;
}

export const getPredictedMaturityFail = (error: unknown): IGetPredictedMaturityFail => ({
  type: ActionTypes.PREDICTED_MATURITY_GET_FAIL,
  error,
});

/////////////////////////////////////////////
// GET historical maturity
/////////////////////////////////////////////

interface IGetHistoricalMaturityStart {
  type: ActionTypes.HISTORICAL_MATURITY_GET_START;
}

export const getHistoricalMaturityStart = (): IGetHistoricalMaturityStart => ({
  type: ActionTypes.HISTORICAL_MATURITY_GET_START,
});

interface IGetHistoricalMaturitySuccess {
  type: ActionTypes.HISTORICAL_MATURITY_GET_SUCCESS;
  historicalMaturity: [];
}

export const getHistoricalMaturitySuccess = (historicalMaturity): IGetHistoricalMaturitySuccess => ({
  type: ActionTypes.HISTORICAL_MATURITY_GET_SUCCESS,
  historicalMaturity,
});

interface IGetHistoricalMaturityFail {
  type: ActionTypes.HISTORICAL_MATURITY_GET_FAIL;
  error: unknown;
}

export const getHistoricalMaturityFail = (error: unknown): IGetHistoricalMaturityFail => ({
  type: ActionTypes.HISTORICAL_MATURITY_GET_FAIL,
  error,
});

export const getPredictedMaturity = (farmId: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    const url = `${baseApiUrl}/yield/maturity?farmId=${farmId}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    dispatch(getPredictedMaturityStart());
    try {
      const {data} = await axios.get<IMaturityResponse[]>(url);
      const maturityPredictions = data.map(d => ({
        ...d,
        estimates: d.estimates.sort((a, b) => (a.date > b.date ? 1 : -1)),
        maturities: d.maturities.sort((a, b) => (a.sampleDate > b.sampleDate ? 1 : -1)),
      }));
      dispatch(getPredictedMaturitySuccess(maturityPredictions));
    } catch (e) {
      dispatch(getPredictedMaturityFail(e));
    }
  };
};

export const getHistoricalMaturity = (farmId: string, polygonId: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    const url = `${baseApiUrl}/harvest-curves/historical?farmId=${farmId}&polygonId=${polygonId}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    dispatch(getHistoricalMaturityStart());
    try {
      const {data} = await axios.get(url);

      if (data.predictedBaume.length === 0) {
        throw new Error('No data for polygon');
      }

      const predictedBaume = data.predictedBaume.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      const weather = data.weather.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      const maturityBaume = data.maturityBaume.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      const proposedHarvestDate = data.harvestDate[0].proposedHarvestDate;
      const variety = data.maturityBaume[0].variety;

      const seasons: any = {};
      predictedBaume.forEach(day => {
        const year = day.date.split('-')[0];
        if (!(year in seasons)) {
          const season = year;
          seasons[season] = {
            harvestDate: null,
            targetBaume: null,
            predictedBaume: {values: [], dates: []},
            weather: {values: [], dates: []},
            gdd: {values: [], dates: []},
            maturityBaume: {values: [], dates: []},
          };
        }
      });

      predictedBaume.forEach(day => {
        const season = day.date.split('-')[0];
        seasons[season].predictedBaume.values.push(day.baume);
        seasons[season].predictedBaume.dates.push(day.date);
      });

      weather.forEach(day => {
        const year = day.date.split('-')[0];
        if (seasons[year]) {
          seasons[year].weather.values.push(parseFloat(day.rainfall).toString());
          seasons[year].weather.dates.push(day.date);
          seasons[year].gdd.values.push(day.gdd);
          seasons[year].gdd.dates.push(day.date);
        }
      });

      maturityBaume.forEach(day => {
        const season = day.date.split('-')[0];
        seasons[season].maturityBaume.values.push(day.baume);
        seasons[season].maturityBaume.dates.push(day.date);
        if (day.targetBaume) {
          seasons[season].targetBaume = day.targetBaume;
        }
      });

      // Format GDD so that it is cumulative
      for (const season in seasons) {
        const allGdds = seasons[season].gdd.values;
        const gdd: string[] = [];
        let gddTotal = 0;
        allGdds.forEach((value: string) => {
          gddTotal += parseFloat(value) / 100;
          gdd.push(gddTotal.toString());
        });
        seasons[season].gdd.values = gdd;
      }

      let latestSeason = 0;
      for (const season in seasons) {
        if (parseInt(season) > latestSeason) {
          latestSeason = parseInt(season);
        }
      }

      seasons[latestSeason].harvestDate = proposedHarvestDate;

      const formattedData = {variety: variety, seasons: seasons};

      dispatch(getHistoricalMaturitySuccess(formattedData));
      return true;
    } catch (e) {
      dispatch(getHistoricalMaturityFail(e));
      return false;
    }
  };
};

export type Action =
  | ReturnType<typeof predictedYieldGetStart>
  | ReturnType<typeof predictedYieldGetSuccess>
  | ReturnType<typeof predictedYieldGetFail>
  | ReturnType<typeof yieldGetStart>
  | ReturnType<typeof yieldGetSuccess>
  | ReturnType<typeof yieldGetFail>
  | ReturnType<typeof yieldPostStart>
  | ReturnType<typeof yieldPostSuccess>
  | ReturnType<typeof yieldPostFail>
  | ReturnType<typeof yieldDeleteStart>
  | ReturnType<typeof yieldDeleteSuccess>
  | ReturnType<typeof yieldDeleteFail>
  | ReturnType<typeof getPredictedMaturityStart>
  | ReturnType<typeof getPredictedMaturitySuccess>
  | ReturnType<typeof getPredictedMaturityFail>
  | ReturnType<typeof getHistoricalMaturityStart>
  | ReturnType<typeof getHistoricalMaturitySuccess>
  | ReturnType<typeof getHistoricalMaturityFail>;
