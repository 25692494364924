import React from 'react';
import {connect} from 'react-redux';

type SetDifference<A, B> = A extends B ? never : A;
type Diff<T extends unknown, U extends unknown> = Pick<T, SetDifference<keyof T, keyof U>>;

// These props will be injected into the base component
export interface WithUserProps {
  user: {
    username: string;
    signOut: any;
  };
  state: any;
  attributes: any;
}

export const withUser = <BaseProps extends WithUserProps>(BaseComponent: React.ComponentType<BaseProps>) => {
  const mapStateToProps = state => ({
    user: state.cognito.user,
    state: state.cognito.state,
    attributes: state.cognito.attributes,
  });

  class Hoc extends React.Component<ReturnType<typeof mapStateToProps>> {
    render() {
      const {user, ...restProps} = this.props;

      return <BaseComponent user={user} {...(restProps as BaseProps)} />;
    }
  }
  return connect<ReturnType<typeof mapStateToProps>, undefined, Diff<BaseProps, WithUserProps>, any>(mapStateToProps)(Hoc);
};
