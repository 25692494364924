import React from 'react';

export type Order = 'asc' | 'desc';

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((item, index) => [item, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getComparator<T>(order: Order, orderBy: string): (a: T, b: T) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: string) {
  if (typeof a[orderBy] === 'number') {
    const itemA = a[orderBy];
    const itemB = b[orderBy];
    if (itemB < itemA) return -1;
    if (itemB > itemA) return 1;
    return 0;
  } else {
    const itemA = (a[orderBy]?.name || a[orderBy]?.username || a[orderBy] || '-').toLowerCase();
    const itemB = (b[orderBy]?.name || b[orderBy]?.username || b[orderBy] || '-').toLowerCase();
    if (itemB < itemA) return -1;
    if (itemB > itemA) return 1;
    return 0;
  }
}

export function createSortHandler<T>(
  property: keyof T,
  orderBy: keyof T,
  order: Order,
  setOrder: React.Dispatch<React.SetStateAction<Order>>,
  setOrderBy: React.Dispatch<React.SetStateAction<keyof T>>
) {
  return () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
}
