import React, {useRef, useState} from 'react';
import {Box} from '@material-ui/core';
import {usePlanetImage} from '../../../hooks/usePlanetImage';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import Layout from '../../Layout';
import Map from '../NDVI/Map';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import Legend from '../NDVI/Legend';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import Sidebar from '../NDVI/Sidebar';
import InfoSection from '../NDVI/InfoSection';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import usePolygon from '../../../hooks/usePolygon';

import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';
import {PageTitle} from '../../../components/UI/PageTitle';

const PlanetPage = () => {
  //initialize the marker colors
  useMarkerColor();
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const {activeFarm, setActiveFarm, selectedFarm, allFarms, loadingGetFarm, handleFarmChange} = useFarmSelection();
  const {onGetPlanetImages, selectedDate, setSelectedDate, images, loadingGetImages, handleDateChange, imageType} = usePlanetImage();
  const {handleOnClickPolygon, selectedPolygon, polygonClicked} = usePolygon();
  const {HelpNDVIPlanet} = useTooltipText();
  const [type, setType] = useState<'current' | 'predicted' | 'previous' | 'average'>();
  const product = 'ndvi';
  const description = 'ndvi.title';
  const ref = useRef(null);

  const handleImageFetching = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    setSelectedDate(null);
    onGetPlanetImages(selectedFarm, startDate, endDate);
  };

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleFarmSelection = (farmId: string) => {
    handleFarmChange(farmId);
  };

  const handleClickCompare = type => {
    setType(type);
    handleDateChange(selectedDate, type);
  };

  const handleUpdateDateIndex = (date: number, type: 'current' | 'predicted' | 'previous' | 'average') => {
    setSelectedDate(date);
    setType(type);
    handleDateChange(date, type);
  };

  const isLoading = loadingGetFarm || loadingGetImages;
  const filteredNDVIData = images?.find(({date}) => date === selectedDate);
  const prevImageUrl = filteredNDVIData?.images.some(i => i.imagePreviousUrl);
  // check if selected image type in the global scope already
  const selectedImageType = imageType || type;
  // take screenshot
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && activeFarm && (
        <Box>
          <div ref={ref} id="layout">
            <Legend product="ndvi" type={selectedImageType} />
            <ContentLeftSidebar
              sidebar={
                <Sidebar
                  allFarms={allFarms}
                  activeFarm={selectedFarm}
                  images={images}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDate={selectedDate}
                  handleChange={handleFarmSelection}
                  handleUpdateDateIndex={handleUpdateDateIndex}
                  handlePostRequest={handleImageFetching}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                  type={selectedImageType}
                />
              }
              content={
                <>
                  <PageTitle
                    title={
                      <InfoSection
                        selectedDate={selectedDate}
                        type={selectedImageType}
                        product={product}
                        description={description}
                        handleClickCompare={handleClickCompare}
                        prevImageUrl={prevImageUrl}
                      />
                    }
                    tooltip={HelpNDVIPlanet}
                  />
                  <Map
                    farm={activeFarm}
                    center={activeFarm.farmCenter}
                    images={filteredNDVIData?.images}
                    bbox={activeFarm.bbox}
                    polygons={activeFarm.polygons}
                    type={selectedImageType}
                    takeScreenshot={takeScreenshot}
                    handleOnClickPolygon={handleOnClickPolygon}
                    polygonClicked={polygonClicked}
                    selectedPolygon={selectedPolygon}
                  />
                </>
              }
            />
          </div>
        </Box>
      )}
    </Layout>
  );
};

export default PlanetPage;
