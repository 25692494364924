import {ICenter, ICurrentWeather, IForecastWeather} from '@deep-planet/api-interfaces';
import {baseWeatherUrl} from '../../config/const';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {Dispatch} from 'redux';

////////////////////////////////////
// GET Current weather
////////////////////////////////////

interface ICurrWeatherGetStart {
  type: ActionTypes.CURR_WEATHER_GET_START;
}

export const currWeatherGetStart = (): ICurrWeatherGetStart => ({
  type: ActionTypes.CURR_WEATHER_GET_START,
});

interface ICurrWeatherGetSuccess {
  type: ActionTypes.CURR_WEATHER_GET_SUCCESS;
  currentWeather: ICurrentWeather;
}

export const currWeatherGetSuccess = (currentWeather: ICurrentWeather): ICurrWeatherGetSuccess => ({
  type: ActionTypes.CURR_WEATHER_GET_SUCCESS,
  currentWeather,
});

interface ICurrWeatherGetFail {
  type: ActionTypes.CURR_WEATHER_GET_FAIL;
  error: unknown;
}

export const currWeatherGetFail = (error): ICurrWeatherGetFail => ({
  type: ActionTypes.CURR_WEATHER_GET_FAIL,
  error: error,
});

export const getCurrentWeather = (center: ICenter, language: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(currWeatherGetStart());
      const {data} = await axios.get<ICurrentWeather>(`${baseWeatherUrl}/current?lat=${center.lat}&lng=${center.lng}&language=${language}`);
      dispatch(currWeatherGetSuccess(data));
    } catch (err) {
      dispatch(currWeatherGetFail(err));
    }
  };
};

////////////////////////////////////
// GET Current weather
////////////////////////////////////

interface IForecastGetStart {
  type: ActionTypes.FORECAST_GET_START;
}

export const forecastGetStart = (): IForecastGetStart => ({
  type: ActionTypes.FORECAST_GET_START,
});

interface IForecastGetSuccess {
  type: ActionTypes.FORECAST_GET_SUCCESS;
  forecastWeather: IForecastWeather[];
}

export const forecastGetSuccess = (forecastWeather: IForecastWeather[]): IForecastGetSuccess => ({
  type: ActionTypes.FORECAST_GET_SUCCESS,
  forecastWeather,
});

interface IForecastGetFail {
  type: ActionTypes.FORECAST_GET_FAIL;
  error: unknown;
}

export const forecastGetFail = (error): IForecastGetFail => ({
  type: ActionTypes.FORECAST_GET_FAIL,
  error: error,
});

export const getForecastWeather = (center: ICenter, language: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch(forecastGetStart());
      const {data} = await axios.get<IForecastWeather[]>(`${baseWeatherUrl}/forecast?lat=${center.lat}&lng=${center.lng}&language=${language}`);
      dispatch(forecastGetSuccess(data));
    } catch (err) {
      dispatch(forecastGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof currWeatherGetStart>
  | ReturnType<typeof currWeatherGetSuccess>
  | ReturnType<typeof currWeatherGetFail>
  | ReturnType<typeof forecastGetStart>
  | ReturnType<typeof forecastGetSuccess>
  | ReturnType<typeof forecastGetFail>;
