import {IFarmImageEntity} from '@deep-planet/api-interfaces';
import {IImageWithBox} from '../../../../store/reducers/ndvi';

export const imageMapping = (images: IFarmImageEntity[]): IImageWithBox[] => {
  const uniqueDateNumbers = [...new Set(images?.map(image => new Date(image.date).getTime()))];
  const modImages = images.map(image => ({...image, date: new Date(image.date).getTime()}));
  return uniqueDateNumbers.map(number => {
    const images = modImages
      .filter(({date}) => date === number)
      .map(({url, type}) => ({
        polygonid: '',
        imagePredictedUrl: type === 'predicted' ? url : null,
        imagePreviousUrl: type === 'previous' ? url : null,
        imageAverageUrl: type === 'average' ? url : null,
        imageUrl: type === 'current' ? url : null,
      }))
      .reduce((obj, item) => ({
        polygonid: '',
        imagePredictedUrl: obj.imagePredictedUrl || item.imagePredictedUrl,
        imagePreviousUrl: obj.imagePreviousUrl || item.imagePreviousUrl,
        imageAverageUrl: obj.imageAverageUrl || item.imageAverageUrl,
        imageUrl: obj.imageUrl || item.imageUrl,
      }));
    return {
      date: number,
      farmid: '',
      images: [images],
    };
  });
};
