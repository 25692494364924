import React, {useState, useEffect} from 'react';
import {Box, Typography, Fade} from '@material-ui/core';
import {HelpTooltip} from '../HelpTooltip';

interface Props {
  title: any;
  tooltip?: any;
  children?: any;
}

const PageTitle: React.FC<Props> = ({children, title, tooltip = null}) => {
  const [fade, setFade] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFade(true);
    }, 100);
  }, []);
  return (
    <Fade in={fade} timeout={1000}>
      <Box pl={1} alignItems="center" display="flex" style={{minHeight: '65px'}}>
        <Box style={{zIndex: 20}}>{tooltip && <HelpTooltip text={tooltip} icon />}</Box>
        <Typography variant="h6" style={{marginLeft: tooltip ? 5 : 10}}>
          {title}
        </Typography>
        {children}
      </Box>
    </Fade>
  );
};

export {PageTitle};
