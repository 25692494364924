import {ISoilNutrientImageRes, ISoilNutrientImagesUI} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/soilNutrient';
import {updateObject} from '../utility';
import {IFarm} from './farm';

export const soilNutrientFarmsSelector = (state: SoilNutrientState) => state.soilNutrientFarms;
export const soilNutrientImagesSelector = (state: SoilNutrientState) => state.soilNutrientImages;
export const soilNutrientTypeImagesSelector = (state: SoilNutrientState) => state.soilNutrientTypeImages;
export const soilNutrientActiveFarmSelector = (state: SoilNutrientState) => state.activeSoilNutrientFarm;
export const soilNutrientActiveFarmImageSelector = (state: SoilNutrientState) => state.activeFarmImageId;
export const soilNutrientFarmGetSelector = (state: SoilNutrientState) => state.soilNutrientFarmsGet;
export const GetSoilNutrientImagesGetSelector = (state: SoilNutrientState) => state.soilNutrientImagesGet;
export const soilNutrientTypeSelector = (state: SoilNutrientState) => state.selectedNutrientType;
export const soilNutrientSelectedDataSelector = (state: SoilNutrientState) => state.selectedDate;

export interface SoilNutrientState {
  soilNutrientImages: ISoilNutrientImageRes[];
  soilNutrientFarms: IFarm[];
  soilNutrientTypeImages: ISoilNutrientImagesUI[];
  activeSoilNutrientFarm: IFarm;
  activeFarmImageId: string;
  selectedNutrientType: string;
  selectedDate: Date;
  soilNutrientImagesGet: {
    loading: boolean;
    error: unknown;
  };
  soilNutrientFarmsGet: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: SoilNutrientState = {
  soilNutrientImages: null,
  soilNutrientFarms: null,
  soilNutrientTypeImages: null,
  activeSoilNutrientFarm: null,
  activeFarmImageId: null,
  selectedNutrientType: null,
  selectedDate: null,
  soilNutrientImagesGet: {
    loading: false,
    error: null,
  },
  soilNutrientFarmsGet: {
    loading: false,
    error: null,
  },
};

const reducer = (state: SoilNutrientState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SOIL_NUTRIENT_IMAGE_GET_START:
      return updateObject(state, {
        soilNutrientImagesGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_IMAGE_GET_FAIL:
      return updateObject(state, {
        soilNutrientImagesGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_FARM_GET_START:
      return updateObject(state, {
        soilNutrientFarmsGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_FARM_GET_FAIL:
      return updateObject(state, {
        soilNutrientFarmsGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_IMAGE_GET_SUCCESS:
      return updateObject(state, {
        soilNutrientImages: action.soilNutrientImages,
        soilNutrientTypeImages: action?.soilNutrientTypeImages,
        selectedNutrientType: action?.soilNutrientType,
        activeFarmImageId: action.activeFarmImageId,
        activeSoilNutrientFarm: action.activeFarm,
        soilNutrientImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_IMAGE_TYPE_GET_SUCCESS:
      return updateObject(state, {
        soilNutrientTypeImages: action.soilNutrientTypeImages,
        selectedNutrientType: action?.soilNutrientType,
        soilNutrientImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_FARM_GET_SUCCESS:
      return updateObject(state, {
        soilNutrientFarms: action.soilNutrientFarms,
        activeSoilNutrientFarm: action.activeFarm,
        soilNutrientFarmsGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.SOIL_NUTRIENT_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default reducer;
