import {GenerateMaturityMapRequest, IFarmEntity, IOrganizationEntity, ISoilOrganicCarbonMapDTO, IYieldPerPixelForFarmReq, SoilNutrientMapDTO} from '@deep-planet/api-interfaces';
import {Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import SimpleDatePicker from '../../../../../../../components/UI/Pickers/SimpleDatePicket';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseSoilNutrientUrl, baseYieldApiUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
}

export const YieldPerPixel = ({farm}: Props) => {
  const [predictionDate, setPredictionDate] = useState<Date>(new Date());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  // get last 10 years
  let year = new Date().getFullYear() - 10;
  // eslint-disable-next-line prefer-spread
  const testYears = Array.apply(year, Array(11)).map(function () {
    return year++;
  });

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload: IYieldPerPixelForFarmReq = {
        predictionDate: predictionDate,
        testYear: selectedYear,
        farmId: farm.id,
      };
      await axios.post<any>(`${baseYieldApiUrl}/api/yield/yield-per-pixel/farm`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      dispatch(enqueueSnackbar({message: 'Yield per pixel images are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <FormControl style={{width: '100%'}}>
            <InputLabel id="test-year-selection">Test year</InputLabel>
            <Select
              labelId="test-year-selection"
              value={selectedYear}
              onChange={e => setSelectedYear(e.target.value as number)}
              displayEmpty
              name="test-year"
              style={{width: '100%'}}
              fullWidth
              disabled={loading}
            >
              {testYears.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box display="display" flexDirection="column">
            <Box marginTop="2rem" width="100%">
              <SimpleDatePicker value={predictionDate} onChange={date => setPredictionDate(date)} label={'Prediction date'} disabled={loading} />
            </Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
