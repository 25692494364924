import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStringDate} from '../helpers/dateHelpers';
import * as actions from '../store/actions';
import {ndwiGetActiveData} from '../store/actions/ndwi';
import {IFarm} from '../store/reducers/farm';
import {ndwiDataSelector, ndwiFarmIdSelector, ndwiGetSelector, ndwiImageTypeSelector, ndwiSelectedDateSelector, selectedOrganizationSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useNDWIImage(activeFarm: IFarm, startDate: Date, endDate: Date, isPredictionNeeded: boolean, lazy = false) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [imageType, setImageType] = useState<string>();
  const ndwiImages = useSelector(ndwiDataSelector);
  const {loading: loadingNdwi, error} = useSelector(ndwiGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const ndwiFarmId = useSelector(ndwiFarmIdSelector);
  const prevNdwiImages = usePrevious(ndwiImages);
  const prevNdwiFarmId = usePrevious(ndwiFarmId);
  const ndwiSelectedDate = useSelector(ndwiSelectedDateSelector);
  const ndwiSelectedImageType = useSelector(ndwiImageTypeSelector);

  const handleSelectedDateAndTypeNDWI = (selectedDate: Date, type: string) => {
    setSelectedDate(selectedDate);
    setImageType(type);
    dispatch(ndwiGetActiveData(selectedDate, type));
  };

  useEffect(() => {
    if (activeFarm && !loadingNdwi && !ndwiImages && !lazy) {
      dispatch(actions.getNDWI(activeFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, loadingNdwi, ndwiImages, startDate, isPredictionNeeded, lazy, selectedOrganization]);

  // in case of changing selected organization
  useEffect(() => {
    if (activeFarm && prevNdwiFarmId && activeFarm.id !== prevNdwiFarmId && !loadingNdwi && !lazy) {
      dispatch(actions.getNDWI(activeFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, isPredictionNeeded, prevNdwiFarmId, selectedOrganization, startDate, loadingNdwi, lazy]);

  useEffect(() => {
    if (!selectedDate && ndwiSelectedDate) setSelectedDate(ndwiSelectedDate);
    if (!imageType && ndwiSelectedImageType) setImageType(ndwiSelectedImageType);
  }, [selectedDate, ndwiSelectedDate, imageType, ndwiSelectedImageType]);

  const onGetNDWI = (selectedFarm: IFarm, isPredictionNeeded = false) => {
    dispatch(actions.getNDWI(selectedFarm, getStringDate(startDate), getStringDate(endDate), isPredictionNeeded, selectedOrganization?.id));
  };

  return {
    loadingNdwi,
    error,
    ndwiImages,
    prevNdwiImages,
    onGetNDWI,
    selectedDate,
    imageType,
    handleSelectedDateAndTypeNDWI,
  };
}
