import {CreateUserDTO, IOrganizationEntity} from '@deep-planet/api-interfaces';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/InfoOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Autocomplete} from '@material-ui/lab';
import {sortBy} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../components/UI/Spinner';
import {enqueueSnackbar} from '../../../../store/actions';
import {postUserSelector} from '../../../../store/selectors';
import theme from '../../../../utilities/theme';

const SubmitButton = styled(Button)`
  margin-top: 1rem !important;
`;

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface FormErrors {
  username: boolean;
  email: boolean;
  organization: boolean;
  password: boolean;
}

const initialFormErrors: FormErrors = {
  username: false,
  email: false,
  organization: false,
  password: false,
};

interface Props {
  organizations: IOrganizationEntity[];
  handleCreateUser: (payload: CreateUserDTO) => void;
}

export const CreateUser = ({organizations, handleCreateUser}: Props) => {
  const [username, setUserName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [autogenerated, setAutogenerated] = React.useState(false);
  const [selectedOrganizations, setSelectedOrganizations] = useState<IOrganizationEntity[]>([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = useState<FormErrors>(initialFormErrors);

  const {t} = useTranslation();
  const dispatch = useDispatch();
  const postUser = useSelector(postUserSelector);

  const handleOrganizationSelect = (currentlySelectedOrganizations: IOrganizationEntity[]) => {
    setSelectedOrganizations(currentlySelectedOrganizations);
  };

  const isValidEmail = (email: string) => {
    if (!email) return false;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPassword = (password: string) => {
    if (!password) return false;
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  };

  const handleClickOnSubmit = () => {
    if (isValidEmail(email) && isValidPassword(password) && selectedOrganizations.length && username) {
      setErrors(initialFormErrors);
      handleCreateUser({username: username.toLowerCase(), password: autogenerated ? null : password, email, organizationIds: selectedOrganizations.map(({id}) => id)});
    } else {
      if (!isValidEmail(email)) {
        dispatch(enqueueSnackbar({message: t('admin.user.email.invalid'), options: {variant: 'error'}}));
      }

      if (!isValidPassword(password)) {
        if (password.length < 8) dispatch(enqueueSnackbar({message: t('admin.user.password.invalid.length'), options: {variant: 'error'}}));
        else dispatch(enqueueSnackbar({message: t('admin.user.password.invalid'), options: {variant: 'error'}}));
      }
      setErrors({username: !username.length, email: !isValidEmail(email), organization: !selectedOrganizations.length, password: !password.length});
    }
  };

  return (
    <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h3" align="center" gutterBottom>
        {t('admin.user.title')}
      </Typography>
      <FormWrapper padding="2rem 0">
        <FormControl fullWidth>
          <TextField
            id="username"
            name="username"
            value={username}
            error={errors.username}
            placeholder={t('forms.username')}
            margin="normal"
            variant="outlined"
            onChange={event => {
              setUserName(event.target.value);
            }}
            required
            autoComplete="off"
          />
          <TextField
            id="email"
            name="email"
            value={email}
            error={errors.email}
            placeholder={t('forms.email')}
            margin="normal"
            variant="outlined"
            onChange={event => {
              setEmail(event.target.value);
            }}
            required
            autoComplete="off"
          />
          <FormControl required variant="outlined" error={errors.organization} margin="normal">
            <Autocomplete
              multiple
              filterSelectedOptions
              value={selectedOrganizations}
              options={sortBy(organizations, ({name}) => name.toLowerCase())}
              onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity[])}
              getOptionLabel={({name}) => name}
              renderInput={params => <TextField {...params} error={errors.organization} label="Select an organization" variant="outlined" />}
            />
          </FormControl>
          {!autogenerated && (
            <TextField
              disabled={autogenerated}
              id="outlined-adornment-password"
              variant="outlined"
              margin="normal"
              placeholder={t('forms.password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={errors.password}
              onChange={event => setPassword(event.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label={t('forms.password.visibility')} onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          )}

          <FormControlLabel control={<Checkbox color="primary" checked={autogenerated} onChange={() => setAutogenerated(!autogenerated)} name="checkedA" />} label={t('admin.user.autogenerated')} />

          <Box display="flex" alignItems="center" margin="0 0 2rem 0">
            <Info style={{padding: '0 9px 0 0 '}} color="primary" fontSize="large" />
            <Typography>By using an autogenerated password the user will receive a welcome email with a temporary password in it</Typography>
          </Box>

          <SubmitButton disabled={postUser.loading} color="primary" variant="contained" type="submit" onClick={handleClickOnSubmit}>
            {t('admin.user.add')}
            {postUser.loading && <Spinner size={15} color="primary" />}
          </SubmitButton>
        </FormControl>
      </FormWrapper>
    </Box>
  );
};
