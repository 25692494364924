import React, {ChangeEvent} from 'react';
import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {IChangeOver} from './../../../../../../../libs/api-interfaces/src/lib/soilOrganicCarbon.type';
import { useTranslation } from 'react-i18next';

interface Props {
  activeBlock: string;
  allBlocks: IPolygonEntity[];
  handleBlockChangeOnChart: (blockId: string) => void;
  changeOverDuration?: IChangeOver[];
  duration?: string;
  handleDurationChangeOver?: (duration: string) => void;
}

const PolygonPicker = ({activeBlock, allBlocks, handleBlockChangeOnChart, changeOverDuration, duration, handleDurationChangeOver}: Props) => {
  const onChange = (event: ChangeEvent<{name?: string; value: string}>) => handleBlockChangeOnChart(event.target.value);
  const onDurationChangeOver = (event: ChangeEvent<{name?: string; value: string}>) => handleDurationChangeOver(event.target.value);
  const {t} = useTranslation();
  return (
    <>
      <FormControl style={{width: '100%'}}>
        <InputLabel id="block-selection">{t('polygon.info.block.name')}</InputLabel>
        <Select labelId="block-selection" value={activeBlock} onChange={onChange} displayEmpty name="activeBlock" style={{width: '100%'}} fullWidth>
          {!!allBlocks &&
            allBlocks.map(({name, id}, idx) => {
              return (
                <MenuItem key={idx} value={id}>
                  {name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      {changeOverDuration ? (
        <FormControl style={{width: '100%'}}>
          <InputLabel id="change-over">{t('change.over')}</InputLabel>
          <Select labelId="change-over" value={duration} onChange={onDurationChangeOver} displayEmpty name="changeOver" style={{width: '100%'}} fullWidth>
            {changeOverDuration.map(({duration, span}, idx) => {
              return (
                <MenuItem key={idx + span + duration} value={duration}>
                  {`${duration.slice(0, 1)} ${span}`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};

export default PolygonPicker;
