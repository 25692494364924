import React, {useState} from 'react';
import {Polygon} from '@react-google-maps/api';
import {IPolygonEntity, polygonInfoWindow, IIrrigationPredictionUI, IIrrigationHistorical, IIrrigationHistoricalUI} from '@deep-planet/api-interfaces';
import MapInfoWindow from './InfoWindow';

interface Props {
  polygon: IPolygonEntity;
  hectares?: number;
  secondaryTitle?: string;
  defaultOpacity?: number;
  selectedPolygonBlock?: string;
  selectedPolygon?: polygonInfoWindow;
  irrigationPredictions?: IIrrigationPredictionUI[];
  irrigationHistorical?: IIrrigationHistoricalUI[];
  disableInfoWindow?: boolean; // disable info window for soc
  handleOnClick?: (polygon: IPolygonEntity) => void;
  handlePolygonHover?: (id: string) => void;
  handleOnClickMap?: (latLng: google.maps.LatLng, polygon: IPolygonEntity) => void;
  closeGraph?: () => void;
  showGraph?: boolean;
}

const PolygonWithInfoChart = ({
  polygon,
  hectares,
  secondaryTitle,
  defaultOpacity = 0,
  selectedPolygon,
  irrigationPredictions,
  irrigationHistorical,
  disableInfoWindow,
  handlePolygonHover,
  handleOnClick,
  handleOnClickMap,
  showGraph,
  closeGraph,
}: Props) => {
  const [isOpen, setIsOpen] = useState(polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.openWindow : false);
  const [opacity, setOpacity] = useState(defaultOpacity);
  const [strokeColor, setStrokeColor] = useState(polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.strokeColor : 'white');
  const [strokeWeight, setStrokeWeight] = useState(polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.strokeWeight : 2);
  const [isBlockName, setIsBlockName] = useState(false);

  const handleOnMouseOver = () => {
    // To show block name while hover on polygons
    setIsBlockName(true);
    setStrokeColor('#FFEA00');
    setStrokeWeight(3);
    if (handlePolygonHover) handlePolygonHover(polygon.id);
  };

  const handleOnMouseOut = () => {
    setIsBlockName(false);
    setStrokeColor('white');
    setStrokeWeight(2);
    if (handlePolygonHover) handlePolygonHover('');
  };

  const onClick = latLng => {
    // handled at index page - controls info window and focus
    handleOnClick(polygon);
    setIsBlockName(false);
    setIsOpen(!isOpen);
    setStrokeWeight(strokeWeight === 3 ? 2 : 3);
    // handles threshold values on index page
    if (handlePolygonHover) handlePolygonHover(polygon.id);
    handleOnClickMap(latLng, polygon);
  };

  return (
    <>
      <Polygon
        paths={polygon.geoJson.geometry.renderCoords}
        options={{
          fillColor: '#C5AA75',
          fillOpacity: opacity,
          strokeColor: polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.strokeColor : strokeColor,
          strokeOpacity: 1,
          strokeWeight: polygon.id === selectedPolygon?.polygonId ? selectedPolygon?.strokeWeight : 2,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
          strokePosition: 0,
        }}
        onClick={({latLng}) => onClick(latLng)}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      />

      {(polygon.id === selectedPolygon?.polygonId || isBlockName) && !disableInfoWindow && (
        <MapInfoWindow
          polygon={polygon}
          hectares={hectares}
          secondaryTitle={secondaryTitle}
          irrigationPredictions={irrigationPredictions}
          irrigationHistorical={irrigationHistorical}
          selectedPolygon={selectedPolygon?.polygonId}
          isBlockName={isBlockName}
          isClicked={showGraph}
          closeGraph={closeGraph}
        />
      )}
    </>
  );
};

export default PolygonWithInfoChart;
