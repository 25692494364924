import i18n from '../../../i18n';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {baseApiUrl} from '../../config/const';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {IOrganizationEntity} from '@deep-planet/api-interfaces';

/////////////////////////////////////////////
// GET organizations
/////////////////////////////////////////////

interface IOrganizationsGetStart {
  type: ActionTypes.ORGANIZATIONS_GET_START;
}

export const organizationsGetStart = (): IOrganizationsGetStart => ({
  type: ActionTypes.ORGANIZATIONS_GET_START,
});

interface IOrganizationsGetSuccess {
  type: ActionTypes.ORGANIZATIONS_GET_SUCCESS;
  organizations: IOrganizationEntity[];
}

export const organizationsGetSuccess = (organizations: IOrganizationEntity[]): IOrganizationsGetSuccess => {
  return {
    type: ActionTypes.ORGANIZATIONS_GET_SUCCESS,
    organizations,
  };
};

interface IOrganizationsGetFail {
  type: ActionTypes.ORGANIZATIONS_GET_FAIL;
  error: unknown;
}

export const organizationsGetFail = (error: unknown): IOrganizationsGetFail => ({
  type: ActionTypes.ORGANIZATIONS_GET_FAIL,
  error: error,
});

export const getOrganizations = () => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(organizationsGetStart());
    const url = `${baseApiUrl}/organizations`;
    try {
      const {data} = await axios.get<IOrganizationEntity[]>(url);
      dispatch(organizationsGetSuccess(data));
    } catch (err) {
      dispatch(organizationsGetFail(err));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

/////////////////////////////////////////////
// set selected organization
/////////////////////////////////////////////

interface ISetSelectedOrganization {
  type: ActionTypes.SET_SELECTED_ORGANIZATION;
  organization: IOrganizationEntity;
}

export const changeSelectedOrganization = (organization: IOrganizationEntity): ISetSelectedOrganization => ({
  type: ActionTypes.SET_SELECTED_ORGANIZATION,
  organization,
});

export const setSelectedOrganization = (organization: IOrganizationEntity) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(changeSelectedOrganization(organization));
  };
};

export type Action = ReturnType<typeof organizationsGetStart> | ReturnType<typeof organizationsGetSuccess> | ReturnType<typeof organizationsGetFail> | ReturnType<typeof changeSelectedOrganization>;
