import React, {useState} from 'react';
import Sidebar from './Sidebar';
import Map from './Map';
import moment from 'moment';
import {Box} from '@material-ui/core';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import useTooltipText from '../../../hooks/useTooltipText';
import usePolygon from '../../../hooks/usePolygon';
import Layout from '../../Layout';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useMaturityImages} from '../../../hooks/useMaturityImages';
import Legend, {DynamicScaleType} from '../NDVI/Legend';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '../../../components/UI/PageTitle';
import {ButtonGroupBar} from './ButtonGroupBar';
import {BAUME, PH, SUGAR, TA, baume, brix, sugar} from '../../../config/const';
import _ from 'lodash';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

export const MaturityMap = () => {
  //initialize the marker colors
  useMarkerColor();
  const {t} = useTranslation();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const {sugarUnit} = useMeasurementConversion();
  const [metric, setMetric] = useState<string>(sugarUnit || baume);

  const {selectedFarm, activeFarm, allFarms, loadingGetFarm, loadingGetUser, handleFarmChange, setActiveFarm} = useFarmSelection();
  const {maturityImages, selectedDate, handleDateChange, onGetMaturityImages, loading: loadingGetImages} = useMaturityImages(activeFarm, startDate, endDate);
  const {handleOnClickPolygon, selectedPolygon, polygonClicked} = usePolygon();
  const [maturityType, setMaturityType] = useState(BAUME);

  const {HelpMaturityMap} = useTooltipText();

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleImageFetching = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    onGetMaturityImages(selectedFarm);
  };

  const handleFarmSelection = (farmId: string) => {
    handleFarmChange(farmId);
  };

  const handlePolygonClick = (polygon: IPolygonEntity) => {
    handleOnClickPolygon(polygon);
  };

  const handlePolygonHover = (id: string) => {
    const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
    if (id && maturityImages) {
      // read imagery thresholds
      const imagery = maturityImages
        .find(({date}) => moment(date).isSame(moment(selectedDate), 'date'))
        .images.find(({type, polygonId}) => polygonId === id && type.toUpperCase() === maturityType.toUpperCase());

      if (imagery) {
        let thresholdLow = imagery.thresholdLow;
        let thresholdHigh = imagery.thresholdHigh;
        if (metric !== 'baume') {
          thresholdLow = baumeToBrixToSugar(thresholdLow);
          thresholdHigh = baumeToBrixToSugar(thresholdHigh);
        }
        const step = (thresholdHigh - thresholdLow) / 7;
        // @ts-ignore
        let currentDynamicScale: DynamicScaleType = [];
        for (let i = 0; i <= 7; i++) {
          currentDynamicScale.push(Number((thresholdLow + step * i).toFixed(2)));
        }
        if (metric.toUpperCase() === TA) {
          currentDynamicScale = _.reverse(currentDynamicScale);
        }
        setDynamicScale(currentDynamicScale);
      } else {
        setDynamicScale(initialDynamicScale);
      }
    } else {
      setDynamicScale(initialDynamicScale);
    }
  };

  const baumeToBrixToSugar = (baume: number) => {
    if (metric === brix) return baume * 1.8;
    if (metric === sugar) return baume * 18;
    return baume;
  };

  const handleSugarMeasureSwitch = (value: string) => {
    setMetric(value);
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  // handle the buttons "BAUME", "PH" or "TA" on title bar
  const handleMaturityType = (value: string) => {
    setMaturityType(value);
    // metric also controls the label of "Average Baume" on polygon Info block "components->ui->PolygonWithInfo->infoWindow.tsx"
    // when switch from PH or TA to BAUME set default metric radio button to 'baume'
    if (value.toUpperCase() === BAUME) setMetric(baume);
    else setMetric(value);
  };

  const isLoading = loadingGetFarm || loadingGetUser || loadingGetImages;
  const filteredImages = maturityImages?.find(({date}) => moment(date).isSame(moment(selectedDate), 'date'));
  const isCalibrated = filteredImages && !!filteredImages?.images[0]?.thresholdLow;
  const legendText = metric.toUpperCase() === PH ? '(pH)' : metric.toUpperCase() === TA ? `${TA} (g/L)` : `(${metric.charAt(0).toUpperCase() + metric.substring(1, metric.length).toLowerCase()})`;

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && activeFarm && (
        <Box>
          <div id="layout">
            <Legend product="maturity_map" type="current" isCalibrated={isCalibrated} dynamicScale={dynamicScale} text={legendText} />
            <ContentLeftSidebar
              sidebar={
                <Sidebar
                  allFarms={allFarms}
                  activeFarm={selectedFarm}
                  images={maturityImages}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDate={selectedDate}
                  handleFarmChange={handleFarmSelection}
                  handleDateChange={handleDateChange}
                  handleImageFetching={handleImageFetching}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                  loading={isLoading}
                />
              }
              content={
                <>
                  <ButtonGroupBar handleMaturityType={handleMaturityType} />
                  <PageTitle title={t('maturity.map')} tooltip={HelpMaturityMap} />
                  <Map
                    farm={activeFarm}
                    center={activeFarm.farmCenter}
                    images={filteredImages?.images}
                    bbox={activeFarm.bbox}
                    polygons={activeFarm.polygons}
                    handlePolygonHover={handlePolygonHover}
                    handlePolygonClick={handlePolygonClick}
                    polygonClicked={polygonClicked}
                    metric={metric} // for both to handel metirc radio button and label "Average ${metric}" on Info window
                    handleSugarSwitch={handleSugarMeasureSwitch}
                    scaleType={SUGAR}
                    takeScreenshot={takeScreenshot}
                    selectedPolygon={selectedPolygon}
                    isCalibrated={isCalibrated}
                    maturityType={maturityType}
                    baumeToBrixToSugar={baumeToBrixToSugar}
                  />
                </>
              }
            />
          </div>
        </Box>
      )}
    </Layout>
  );
};
