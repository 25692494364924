import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import AccountButton from './AccountButton';
import Feedback from './Feedback';
import Help from './Help';
import LanguageSelector from './LanguageSelector';
import theme from '../../../utilities/theme';
import OrganizationSelector from './OrganizationSelector';
import Settings from './Settings';
import {organizationsSelector} from '../../../store/selectors';
import NotificationButton from './Notification/NotificationButton';

const drawerWidth = 200;

const NavBar = styled(AppBar)`
  && {
    z-index: 1201;
    background: ${() => theme.palette.primary.main};

    @media screen and (min-width: 960px) {
      width: calc(100% - ${drawerWidth}px);
      margin-left: ${drawerWidth}px;
    }
  }
`;

const MenuButton = styled(IconButton)`
  && {
    margin-right: 20px;
    @media screen and (min-width: 960px) {
      display: none;
    }
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Navigation = ({handleDrawerToggle}: {handleDrawerToggle: () => void}) => {
  const organizations = useSelector(organizationsSelector);
  return (
    <NavBar position="fixed">
      <Toolbar>
        <MenuButton color="inherit" aria-label="Open drawer" onClick={handleDrawerToggle}>
          <MenuIcon />
        </MenuButton>
        <Settings />
        <Help />
        <FlexGrow />
        {organizations?.length > 1 && <OrganizationSelector organizations={organizations} />}
        <Feedback />
        <LanguageSelector />
        <NotificationButton />
        <AccountButton />
      </Toolbar>
    </NavBar>
  );
};

export default React.memo(Navigation);
