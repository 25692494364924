import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/yield';
import {IHistoricalYieldResponse, IMaturityResponse, IYieldResponse} from '@deep-planet/api-interfaces';
import {orderBy} from 'lodash';
import area from '@turf/area';
import {polygon} from '@turf/turf';
import {Position} from '@turf/helpers';
import {getStringDate} from '../../helpers/dateHelpers';

export const yieldPredictionsSelector = (state: YieldState): ISimplifiedYield[] => {
  return orderBy(
    state.yieldPredictions?.map(y => {
      const blockArea = Math.round(Number(area(polygon(y.geojson.geometry.coordinates as Position[][])) / 10000) * 100) / 100;
      return {
        farmName: y.farmName,
        polygonName: y.polygonName,
        baume: Number(y.baume),
        pH: Number(y.pH),
        titratableAcid: Number(y.titratableAcid),
        yield: Number(y.yield),
        yield_per_ha: Number((Number(y.yield) / blockArea).toFixed(2)),
        predictionDate: getStringDate(y.predictionDate),
        variety: y.geojson.properties.cropType,
        area: blockArea,
        organizationName: y.organizationName,
        avg_yield: Number(y.avg_yield),
        avg_yield_per_ha: Number((Number(y.avg_yield) / blockArea).toFixed(2)),
        polygonId: y.polygonId,
        prevYield: y.prevYield,
        blockArea: blockArea,
      };
    }),
    ['farmName', 'polygonName']
  );
};
export const getYieldPredictionSelector = (state: YieldState) => state.getPredictedYield;
export const yieldPredictionDatesSelector = (state: YieldState) => state.yieldPredictionDates?.sort((a, b) => (a < b ? 1 : -1));
export const latestYieldsSelector = (state: YieldState) => {
  if (state.yieldPredictionDates && state.yieldPredictions) {
    const maxDate = state.yieldPredictionDates.sort((a, b) => (a < b ? 1 : -1))[0];
    return state.yieldPredictions.filter(y => getStringDate(y.predictionDate) === maxDate).map(({polygonId, yield: yieldPrediction}) => ({polygonId, yieldPrediction}));
  }
  return null;
};
export const getHistoricalYieldSelector = (state: YieldState) => state.getHistoricalYield;
export const historicalYieldSelector = (state: YieldState) => state.historicalYields;
export const postHistoricalYieldSelector = (state: YieldState) => state.postHistoricalYield;
export const deleteHistoricalYieldSelector = (state: YieldState) => state.deleteHistoricalYield;
export const getPredictedMaturitySelector = (state: YieldState) => state.getPredictedMaturity;
export const maturityPredictionsSelector = (state: YieldState) => state.maturityPredictions;

export const getHistoricalMaturitySelector = (state: YieldState) => state.getHistoricalMaturity;
export const maturityHistoricalSelector = (state: YieldState) => state.historicalMaturity;

export interface ISimplifiedYield {
  farmName: string;
  polygonName: string;
  baume: number;
  pH: number;
  yield: number;
  yield_per_ha: number;
  titratableAcid: number;
  predictionDate: string;
  variety: string;
  area: number;
  organizationName: string;
  avg_yield: number;
  avg_yield_per_ha: number;
  polygonId?: string;
  prevYield?: boolean;
  blockArea?: number;
}

export interface YieldState {
  yieldPredictions: IYieldResponse[];
  getPredictedYield: {
    error: unknown;
    loading: boolean;
  };
  yieldPredictionDates: string[];
  historicalYields: IHistoricalYieldResponse[];
  getHistoricalYield: {
    error: unknown;
    loading: boolean;
  };
  postHistoricalYield: {
    error: unknown;
    loading: boolean;
  };
  deleteHistoricalYield: {
    error: unknown;
    loading: boolean;
  };
  getPredictedMaturity: {
    error: unknown;
    loading: boolean;
  };
  maturityPredictions: IMaturityResponse[];
  getHistoricalMaturity: {
    error: unknown;
    loading: boolean;
  };
  historicalMaturity: [];
}

const initialState: YieldState = {
  yieldPredictions: null,
  getPredictedYield: {
    error: null,
    loading: false,
  },
  yieldPredictionDates: null,
  historicalYields: null,
  getHistoricalYield: {
    error: null,
    loading: false,
  },
  postHistoricalYield: {
    error: null,
    loading: false,
  },
  deleteHistoricalYield: {
    error: null,
    loading: false,
  },
  getPredictedMaturity: {
    error: null,
    loading: false,
  },
  maturityPredictions: null,
  getHistoricalMaturity: {
    error: null,
    loading: false,
  },
  historicalMaturity: null,
};

const reducer = (state: YieldState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.PREDICTED_YIELD_GET_START:
      return updateObject(state, {
        getPredictedYield: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.PREDICTED_YIELD_GET_SUCCESS:
      return updateObject(state, {
        yieldPredictions: action.yieldPredictions,
        yieldPredictionDates: action.yieldPredictionDates,
        getPredictedYield: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.PREDICTED_YIELD_GET_FAIL:
      return updateObject(state, {
        getPredictedYield: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.YIELD_GET_START:
      return updateObject(state, {
        getHistoricalYield: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.YIELD_GET_SUCCESS:
      return updateObject(state, {
        historicalYields: action.historicalYields,
        getHistoricalYield: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.YIELD_GET_FAIL:
      return updateObject(state, {
        getHistoricalYield: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.YIELD_POST_START:
      return updateObject(state, {
        postHistoricalYield: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.YIELD_POST_SUCCESS:
      return updateObject(state, {
        historicalYields: [
          ...state.historicalYields,
          ...action.savedYields.map(y => ({
            id: y.id,
            baume: y.baume,
            pH: y.pH,
            titratableAcid: y.titratableAcid,
            yield: y.yield,
            harvestDate: y.harvestDate,
            polygonId: y.polygon.id,
            farmId: y.polygon.farm?.id,
            varietyId: y.variety.id,
            varietyName: y.variety.name,
            seasonId: y.season.id,
            seasonName: y.season.name,
          })),
        ],
        postHistoricalYield: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.YIELD_POST_FAIL:
      return updateObject(state, {
        postHistoricalYield: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.YIELD_DELETE_START:
      return updateObject(state, {
        deleteHistoricalYield: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.YIELD_DELETE_SUCCESS:
      return updateObject(state, {
        deleteHistoricalYield: {
          error: false,
          loading: false,
        },
        historicalYields: [...state.historicalYields.filter(y => y.id !== action.deletedId)],
      });
    case ActionTypes.YIELD_DELETE_FAIL:
      return updateObject(state, {
        deleteHistoricalYield: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.PREDICTED_MATURITY_GET_START:
      return updateObject(state, {
        getPredictedMaturity: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.PREDICTED_MATURITY_GET_SUCCESS:
      return updateObject(state, {
        getPredictedMaturity: {
          error: false,
          loading: false,
        },
        maturityPredictions: action.maturityPredictions,
      });
    case ActionTypes.PREDICTED_MATURITY_GET_FAIL:
      return updateObject(state, {
        getPredictedMaturity: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.HISTORICAL_MATURITY_GET_START:
      return updateObject(state, {
        getHistoricalMaturity: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.HISTORICAL_MATURITY_GET_SUCCESS:
      return updateObject(state, {
        getHistoricalMaturity: {
          error: false,
          loading: false,
        },
        historicalMaturity: action.historicalMaturity,
      });
    case ActionTypes.HISTORICAL_MATURITY_GET_FAIL:
      return updateObject(state, {
        getHistoricalMaturity: {
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
