import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../../store/actions';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import {IFarm} from '../../../../store/reducers/farm';
import {selectedOrganizationSelector} from '../../../../store/selectors';
import CreateFarmStepper from './CreateFarmStepper';
import Map from './Map';
import {useTranslation} from 'react-i18next';
import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UploadFile from './CreateFarmStepper/UploadFile';

const MapContainer = styled.div`
  min-width: 100%;
  height: 100%;
  position: relative;
`;

const ButtonsContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100% - 112px);
  justify-content: center;
`;

const Steps = {
  CHOOSE_STEP: 'choose_step',
  DRAWING: 'drawing',
  UPLOADING: 'uploading',
};

interface Props {
  polygons: any[];
  farmId: string;
  postPolySuccess: boolean;
  loadingDeleteFarm: boolean;
  allFarms: IFarm[];
}

const CreateFarm = ({polygons, farmId, postPolySuccess, loadingDeleteFarm, allFarms}: Props) => {
  const [drawingActiveStep, setDrawingActiveStep] = useState(0);
  const [creationActiveStep, setCreationActiveStep] = useState(Steps.CHOOSE_STEP);
  const [polygon, setPolygon] = useState({
    polygon: null,
    polygonDrawn: false,
    coordinatesOfNewPoly: [],
  });
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  // CreateFarmStepper Handlers
  const handleNextStep = () => setDrawingActiveStep(drawingActiveStep + 1);
  const handleResetStep = () => setDrawingActiveStep(0);
  const handleBack = () => setCreationActiveStep(Steps.CHOOSE_STEP);

  const onDeleteFarm = (farmId: string) => dispatch(actions.deleteFarm(farmId, selectedOrganization.id));
  const onResetState = () => dispatch(actions.resetState());
  const onResetFarmState = () => dispatch(actions.farmReset());

  // Polygon Handlers
  const handlePassPolyCoords = (polygon, coordinates) => {
    setPolygon({
      polygon: polygon,
      polygonDrawn: true,
      coordinatesOfNewPoly: coordinates,
    });
  };
  const resetPolygonCoords = () => {
    setPolygon({
      polygon: null,
      polygonDrawn: false,
      coordinatesOfNewPoly: [],
    });
  };

  const getHeaderText = () => {
    switch (creationActiveStep) {
      case Steps.CHOOSE_STEP:
        return t('setting.create.farm.step.choosing.title');
      case Steps.DRAWING:
        return t('setting.create.farm.step.drawing.title');
      case Steps.UPLOADING:
        return t('setting.create.farm.step.uploading.title');
      default:
        return '';
    }
  };

  useEffect(() => {
    return () => dispatch(actions.resetState());
  }, [dispatch]);

  return (
    <div style={{position: 'relative'}}>
      <ContentLeftSidebar
        headerText={getHeaderText()}
        wider
        sidebar={
          <>
            {creationActiveStep === Steps.CHOOSE_STEP && (
              <ButtonsContainer>
                <Box m={2}>
                  <Button variant="contained" color="primary" onClick={() => setCreationActiveStep(Steps.DRAWING)} style={{width: '200px'}}>
                    {t('setting.create.farm.step.choosing.draw')}
                  </Button>
                </Box>
                <Box m={2}>
                  <Button variant="contained" color="primary" onClick={() => setCreationActiveStep(Steps.UPLOADING)} style={{width: '200px'}}>
                    {t('setting.create.farm.step.choosing.upload')}
                  </Button>
                </Box>
              </ButtonsContainer>
            )}
            {creationActiveStep === Steps.DRAWING && (
              <CreateFarmStepper
                activeStep={drawingActiveStep}
                farmId={farmId}
                polygon={polygon}
                handleNextStep={handleNextStep}
                handleResetStep={handleResetStep}
                resetPolygonCoords={resetPolygonCoords}
                postPolySuccess={postPolySuccess}
                onDeleteFarm={onDeleteFarm}
                loadingDeleteFarm={loadingDeleteFarm}
                onResetState={onResetState}
                onResetFarmState={onResetFarmState}
                allFarms={allFarms}
                handleBack={handleBack}
              />
            )}
            {creationActiveStep === Steps.UPLOADING && <UploadFile handleBack={handleBack} />}
          </>
        }
        content={
          <MapContainer>
            <Map polygons={polygons} drawingActive={drawingActiveStep === 1 && !polygon.polygonDrawn} handlePassPolyCoords={handlePassPolyCoords} />
          </MapContainer>
        }
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    farmId: state.farm.farmId,
    polygons: state.polygon.polygons,
    loadingDeleteFarm: state.farm.farmDelete.loading,
  };
};

export default connect(mapStateToProps)(CreateFarm);
