import {RootState} from '../reducers';
import * as soilNutrient from '../reducers/soilNutrient';
export const soilNutrientFarmsSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientFarmsSelector(state.soilNutrient);
export const soilNutrientImagesSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientImagesSelector(state.soilNutrient);
export const soilNutrientTypeImagesSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientTypeImagesSelector(state.soilNutrient);
export const soilNutrientActiveFarmSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientActiveFarmSelector(state.soilNutrient);
export const soilNutrientActiveFarmImageSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientActiveFarmImageSelector(state.soilNutrient);
export const soilNutrientFarmGetSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientFarmGetSelector(state.soilNutrient);
export const soilNutrientImagesGetSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.GetSoilNutrientImagesGetSelector(state.soilNutrient);
export const soilNutrientTypeSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientTypeSelector(state.soilNutrient);
export const soilNutrientSelectedDataSelector = (state: Pick<RootState, 'soilNutrient'>) => soilNutrient.soilNutrientSelectedDataSelector(state.soilNutrient);
