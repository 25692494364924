import {Entity, Column, OneToMany, Index, PrimaryGeneratedColumn, CreateDateColumn, UpdateDateColumn} from 'typeorm';
import {IFarmEntity, IFarmImageEntity, IOrganizationToFarmEntity, IPolygonEntity} from '@deep-planet/api-interfaces';

@Entity('farm')
export class FarmEntity implements IFarmEntity {
  @Index({unique: true})
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column('text')
  name: string;

  @Column('text', {nullable: true})
  code: string;

  @OneToMany('OrganizationToFarmEntity', (organizationToFarm: IOrganizationToFarmEntity) => organizationToFarm.farm)
  organizationToFarms: IOrganizationToFarmEntity[];

  @OneToMany('PolygonEntity', (polygon: IPolygonEntity) => polygon.farm, {cascade: true})
  polygons: IPolygonEntity[];

  @OneToMany('FarmImageEntity', (image: IFarmImageEntity) => image.farm)
  images: IFarmImageEntity[];

  @CreateDateColumn({type: 'timestamp'})
  createdAt: Date;

  @UpdateDateColumn({type: 'timestamp'})
  updatedAt: Date;

  @Column('boolean', {default: true})
  downloadImages: boolean;
}
