import {ICurrentWeather, IForecastWeather} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/weather';

export const currentWeatherSelector = (state: WeatherState) => state.currentWeather;
export const forecastWeatherSelector = (state: WeatherState) => state.forecastWeather;
export const getCurrentWeatherSelector = (state: WeatherState) => state.getCurrentWeather;
export const getForecastWeatherSelector = (state: WeatherState) => state.getForecastWeather;

export interface WeatherState {
  currentWeather: ICurrentWeather;
  forecastWeather: IForecastWeather[];
  getCurrentWeather: {
    error: unknown;
    loading: boolean;
  };
  getForecastWeather: {
    error: unknown;
    loading: boolean;
  };
}

const initialState: WeatherState = {
  currentWeather: null,
  forecastWeather: null,
  getCurrentWeather: {
    error: null,
    loading: false,
  },
  getForecastWeather: {
    error: null,
    loading: false,
  },
};

const reducer = (state: WeatherState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.CURR_WEATHER_GET_START:
      return updateObject(state, {
        getCurrentWeather: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.CURR_WEATHER_GET_SUCCESS:
      return updateObject(state, {
        currentWeather: action.currentWeather,
        getCurrentWeather: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.CURR_WEATHER_GET_FAIL:
      return updateObject(state, {
        getCurrentWeather: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.FORECAST_GET_START:
      return updateObject(state, {
        getForecastWeather: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.FORECAST_GET_SUCCESS:
      return updateObject(state, {
        forecastWeather: action.forecastWeather,
        getForecastWeather: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.FORECAST_GET_FAIL:
      return updateObject(state, {
        getForecastWeather: {
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
