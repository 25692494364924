import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/file';
import {updateObject} from '../utility';
import {IFileInfoEntity, IFileTypeEntity} from '@deep-planet/api-interfaces';

export const filePostSelector = (state: FileState) => state.filePost;
export const filesSelector = (state: FileState) => state.files;
export const filesGetSelector = (state: FileState) => state.filesGet;
export const fileDeleteSelector = (state: FileState) => state.fileDelete;
export const filePutSelector = (state: FileState) => state.filePut;
export const fileShareSelector = (state: FileState) => state.fileShare;
export const fileTypesGetSelector = (state: FileState) => state.fileTypeGet;
export const fileTypesSelector = (state: FileState) => state.fileTypes;

export interface FileState {
  files: IFileInfoEntity[];
  fileTypes: IFileTypeEntity[];
  filesGet: {
    loading: boolean;
    error: boolean;
  };
  filePost: {
    loading: boolean;
    error: boolean;
  };
  filePut: {
    loading: boolean;
    error: boolean;
  };
  fileDelete: {
    loading: boolean;
    error: boolean;
  };
  fileShare: {
    loading: boolean;
    error: boolean;
  };
  fileTypeGet: {
    loading: boolean;
    error: boolean;
  };
}

const initialState: FileState = {
  files: [],
  fileTypes: [],
  filesGet: {
    loading: false,
    error: false,
  },
  filePost: {
    loading: false,
    error: false,
  },
  filePut: {
    loading: false,
    error: false,
  },
  fileDelete: {
    loading: false,
    error: false,
  },
  fileShare: {
    loading: false,
    error: false,
  },
  fileTypeGet: {
    loading: false,
    error: false,
  },
};

const fileReducer = (state: FileState = initialState, action: Action): FileState => {
  switch (action.type) {
    case ActionTypes.FILE_GET_START:
      return updateObject(state, {
        filesGet: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.FILE_GET_SUCCESS:
      return updateObject(state, {
        files: new Array(...action.payload),
        filesGet: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.FILE_GET_FAIL:
      return updateObject(state, {
        filesGet: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.FILE_POST_START:
      return updateObject(state, {
        filePost: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.FILE_POST_SUCCESS:
      return updateObject(state, {
        files: [action.payload, ...state.files],
        filePost: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.FILE_POST_FAIL:
      return updateObject(state, {
        filePost: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.FILE_DELETE_START:
      return updateObject(state, {
        fileDelete: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.FILE_DELETE_SUCCESS:
      return updateObject(state, {
        files: state.files.map(fileInfo => {
          if (fileInfo.id === action.payload.fileInfoId)
            return {
              ...fileInfo,
              files: fileInfo.files.filter(file => file.id !== action.payload.fileId),
            };
          return fileInfo;
        }),
        fileDelete: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.FILE_DELETE_FAIL:
      return updateObject(state, {
        fileDelete: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.FILE_SHARE_START:
      return updateObject(state, {
        fileShare: {
          loading: true,
          error: false,
        },
      });
    case ActionTypes.FILE_SHARE_SUCCESS:
      return updateObject(state, {
        fileShare: {
          loading: false,
          error: false,
        },
      });
    case ActionTypes.FILE_SHARE_FAIL:
      return updateObject(state, {
        fileShare: {
          loading: false,
          error: true,
        },
      });
    case ActionTypes.FILE_TYPE_GET_START:
      return updateObject(state, {
        fileTypeGet: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.FILE_TYPE_GET_SUCCESS:
      return updateObject(state, {
        fileTypes: new Array(...action.payload),
        fileTypeGet: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.FILE_TYPE_GET_FAIL:
      return updateObject(state, {
        fileTypeGet: {
          error: true,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default fileReducer;
