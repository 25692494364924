import {Typography, Mark} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from 'react-i18next';
import styled, {createGlobalStyle} from 'styled-components';
import Slider from '../../../components/UI/Slider/Slider';
import {getLocalDateString} from '../../../helpers/dateHelpers';
import {usePrevious} from '../../../hooks/usePrevious';
import {IFarm} from '../../../store/reducers/farm';
import {IImageWithBox} from '../../../store/reducers/ndvi';
import theme from '../../../utilities/theme';
import Map from './Map';

const StyledMarkersStyled = createGlobalStyle`
  .MuiSlider-markLabel {
    transform: translateY(100%) translateX(-80%) rotate(-40deg) !important
  }
`;

const MobileSlider = styled(Slider)`
  && {
    ${theme.breakpoints.up('sm')} {
      display: none;
    }
  }
`;

const DesktopSlider = styled(Slider)`
  && {
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0 12px;
  }
`;

interface Props {
  imagesToDisplay: IImageWithBox[];
  activeFarm: IFarm;
}

export const TimelapsePlayer = ({imagesToDisplay, activeFarm}: Props) => {
  const [images, setImages] = useState<IImageWithBox['images']>();
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showSlider, setShowSlider] = useState(false);
  const [marks, setMarks] = useState<Mark[]>([]);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [minDateIndex, setMinDateIndex] = useState<number>(0);
  const [maxDateIndex, setMaxDateIndex] = useState<number>();

  const {t} = useTranslation();
  const prevCurrentDateIndex = usePrevious(currentDateIndex);
  const prevImagesToDisplay = usePrevious(imagesToDisplay);
  const intervalId = useRef(null);

  const setStateVariables = useCallback(() => {
    if (imagesToDisplay) {
      setImages(imagesToDisplay[currentDateIndex]?.images);
      setShowSlider(!!imagesToDisplay?.length);
    } else {
      setShowSlider(false);
    }
  }, [currentDateIndex, imagesToDisplay]);

  const stopClicked = () => {
    setShowPlayButton(true);
    clearInterval(intervalId.current);
  };

  const playClicked = () => {
    if (currentDateIndex === maxDateIndex) {
      setCurrentDateIndex(0);
    }
    setShowPlayButton(false);
  };

  const handleSliderChange = (event, newValue) => {
    setCurrentDateIndex(newValue);
  };

  const getMarkerFrequency = useCallback(() => {
    if (imagesToDisplay?.length > 50) return 5;
    if (imagesToDisplay?.length > 15) return 3;
    return 1;
  }, [imagesToDisplay]);

  useEffect(() => {
    if (imagesToDisplay !== prevImagesToDisplay && imagesToDisplay) {
      setStateVariables();
      const images = imagesToDisplay;
      const n = getMarkerFrequency();
      const newMarks: Mark[] = images?.map(({date}, idx) => {
        return {
          value: idx,
          label: idx === 0 || idx === images?.length - 1 || idx % n === 0 ? getLocalDateString(date, 'l') : '',
        };
      });

      const newMaxDate = _.last(newMarks)?.value;
      const newMinDate = _.first(newMarks)?.value;
      setCurrentDateIndex(0);
      setMarks(newMarks);
      setMinDateIndex(newMinDate);
      setMaxDateIndex(newMaxDate);
    }
  }, [getMarkerFrequency, imagesToDisplay, prevImagesToDisplay, setStateVariables]);

  useEffect(() => {
    if (!showPlayButton) {
      intervalId.current = setInterval(() => {
        if (currentDateIndex > maxDateIndex - 1) {
          setShowPlayButton(true);
          clearInterval(intervalId.current);
          return;
        }

        setCurrentDateIndex(currentDateIndex + 1);
      }, 2000);
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [currentDateIndex, maxDateIndex, showPlayButton, setStateVariables]);

  useEffect(() => {
    if (currentDateIndex !== prevCurrentDateIndex) {
      setStateVariables();
    }
  }, [currentDateIndex, prevCurrentDateIndex, setStateVariables]);

  return (
    <>
      <Grid>
        <Map center={activeFarm.farmCenter} images={images} polygons={activeFarm?.polygons} bbox={activeFarm?.bbox} />
        <Box display="flex" justifyContent="center" margin="1rem 0">
          {imagesToDisplay[currentDateIndex] && <Typography style={{fontWeight: 'bold'}}>{getLocalDateString(imagesToDisplay[currentDateIndex]?.date)}</Typography>}
        </Box>
      </Grid>
      {showSlider ? (
        <Box display="flex" alignItems="flex-start" padding="1rem" marginBottom="1rem">
          {showPlayButton ? (
            <StyledIconButton color="primary" aria-label="play timelapse" onClick={playClicked}>
              <PlayCircleOutlineIcon style={{fontSize: 40}} />
            </StyledIconButton>
          ) : (
            <StyledIconButton color="primary" aria-label="stop timelapse" onClick={stopClicked}>
              <PauseCircleOutlineIcon style={{fontSize: 40}} />
            </StyledIconButton>
          )}
          <Box width="100%" padding="5px 2rem">
            <StyledMarkersStyled />
            <MobileSlider min={minDateIndex} max={maxDateIndex} valueLabelDisplay="off" value={currentDateIndex} marks onChange={handleSliderChange} />
            <DesktopSlider min={minDateIndex} max={maxDateIndex} valueLabelDisplay="off" value={currentDateIndex} marks={marks} onChange={handleSliderChange} />
          </Box>
        </Box>
      ) : (
        <Box mt={2}>
          <Alert severity="warning">{t('timelapse.no.images')}</Alert>
        </Box>
      )}
    </>
  );
};

export default TimelapsePlayer;
