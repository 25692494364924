import {IRowEntity} from '@deep-planet/api-interfaces';
import {Box, Switch, Typography} from '@material-ui/core';
import {Polyline} from '@react-google-maps/api';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface RowProps {
  row: IRowEntity;
}

export const Row = ({row}: RowProps) => {
  const coordinates = row.geometry.coordinates.map(coordinate => ({lat: coordinate[1], lng: coordinate[0]}));
  return (
    <Polyline
      path={coordinates}
      options={{
        strokeColor: 'white',
        strokeOpacity: 1,
        strokeWeight: 2,
      }}
    />
  );
};

interface ShowRowSwitcherProps {
  showRows: boolean;
  handleSwitch: () => void;
}

export const ShowRowSwitcher = ({showRows, handleSwitch}: ShowRowSwitcherProps) => {
  const {t} = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        backgroundColor: 'white',
        padding: `1px 14px`,
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        position: 'absolute',
        left: '183px',
        top: '9.5px',
      }}
    >
      <Switch checked={showRows} onChange={handleSwitch} />
      <Typography>{t('map.show.rows.switcher')}</Typography>
    </Box>
  );
};
