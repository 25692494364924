import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getOrganizations} from '../store/actions/organization';
import {IFarm} from '../store/reducers/farm';
import {
  activeFarmIdSelector,
  allFarmsSelector,
  farmGetSelector,
  getOrganizationsSelector,
  getUserSelector,
  organizationsSelector,
  selectedOrganizationSelector,
  userDetailsSelector,
} from '../store/selectors';
import {usePrevious} from './usePrevious';
import {getAllFarms, getUser} from '../store/actions';
import {isEqual} from 'lodash';

export function useFarmSelection() {
  const [activeFarm, setActiveFarm] = useState<IFarm>();
  const [selectedFarm, setSelectedFarm] = useState<IFarm>();
  const allFarms = useSelector(allFarmsSelector);
  const activeFarmId = useSelector(activeFarmIdSelector);
  const userDetails = useSelector(userDetailsSelector);
  const organizations = useSelector(organizationsSelector);
  const {loading: loadingGetFarm} = useSelector(farmGetSelector);
  const {loading: loadingGetUser} = useSelector(getUserSelector);
  const {loading: loadingGetOrganizations} = useSelector(getOrganizationsSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevAllFarms = usePrevious(allFarms);
  const prevUserDetails = usePrevious(userDetails);
  const prevOrganizations = usePrevious(organizations);
  const prevSelectedOrganization = usePrevious(selectedOrganization);

  const dispatch = useDispatch();

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setSelectedFarm(selectedFarm);
  };

  useEffect(() => {
    if (!allFarms && !loadingGetFarm && !prevAllFarms) {
      dispatch(getAllFarms());
    }
    if (!userDetails && !loadingGetUser && !prevUserDetails) {
      dispatch(getUser());
    }
    if (!organizations?.length && !loadingGetOrganizations && !prevOrganizations) {
      dispatch(getOrganizations());
    }
  }, [allFarms, dispatch, loadingGetFarm, loadingGetOrganizations, loadingGetUser, organizations, prevAllFarms, prevOrganizations, prevUserDetails, userDetails]);

  useEffect(() => {
    // set default selected farm across all the features on the portal
    // (ndvi, ndwi, planet, maturity, harvest, timelapse, soil nutrient, soil organic carbon and irrigation )
    // on intialization of feature screen, first farm from the list of farms is a default selected
    // farm on sidebar
    if (allFarms?.length > 0 && !activeFarm && !loadingGetUser && userDetails) {
      const activeSelectedFarm = activeFarmId || allFarms[0].farmid;
      let selectedFarm = allFarms.find(({farmid}) => farmid === activeSelectedFarm);
      if (!selectedFarm) selectedFarm = allFarms[0];
      setActiveFarm(selectedFarm); // to get map and images
      setSelectedFarm(selectedFarm); // farm on sidebar
    }
  }, [activeFarm, activeFarmId, allFarms, loadingGetUser, prevAllFarms, selectedFarm, userDetails]);

  useEffect(() => {
    if (allFarms && prevAllFarms && !isEqual(allFarms, prevAllFarms)) {
      const selectedFarm = allFarms.find(({farmid}) => farmid === allFarms[0].farmid);
      setActiveFarm(selectedFarm);
      setSelectedFarm(selectedFarm);
    }
  }, [allFarms, prevAllFarms, selectedFarm]);

  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id) {
      dispatch(getAllFarms(selectedOrganization.id));
    }
  }, [dispatch, prevSelectedOrganization, selectedOrganization]);

  return {
    handleFarmChange,
    activeFarm,
    setActiveFarm,
    selectedFarm,
    setSelectedFarm,
    allFarms,
    loadingGetFarm,
    loadingGetUser,
    prevUserDetails,
    prevAllFarms,
    userDetails,
    organizations,
    loadingGetOrganizations,
    isLoading: loadingGetFarm || loadingGetUser || loadingGetOrganizations,
  };
}
