import React from 'react';
import {Link} from 'react-router-dom';
import ConfirmForm from './Form';
import {ButtonAlt, ConfirmMessage} from '../../UI/Form';
import {Confirm} from '@deep-planet/react-cognito';
import AuthSplash from '../../UI/AuthSplash';
import {withTranslation} from 'react-i18next';

const LoginPage = ({t}) => {
  return (
    <AuthSplash>
      <ConfirmMessage variant="body1">{t('auth.confirmation.title')}</ConfirmMessage>

      <Confirm>
        <ConfirmForm />
      </Confirm>

      <ButtonAlt>
        <Link to="/login">{t('auth.confirmation.button')}</Link>
      </ButtonAlt>
    </AuthSplash>
  );
};

export default withTranslation()(LoginPage);
