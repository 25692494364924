import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/ndvi';
import {IImage} from '@deep-planet/api-interfaces';
import * as turf from '@turf/turf';
import {orderBy} from 'lodash';
import {IFarm} from './farm';

export const planetGetSelector = (state: NDVIState) => state.planetImagesGet;
export const planetDataSelector = (state: NDVIState) => orderBy(state.planetImages?.images, i => i.date, 'desc');
export const ndviDataSelector = (state: NDVIState) => orderBy(state.esaImages?.images, i => i.date, 'desc');
export const ndviGetSelector = (state: NDVIState) => state.esaImagesGet;
export const ndviFarmIdSelector = (state: NDVIState) => state.esaImages?.farmId;
export const ndviActiveFarmSelector = (state: NDVIState) => state.activeFarm;
export const ndviPlanetActiveFarmSelector = (state: NDVIState) => state.activePlanetFarm;
export const ndviSelectedDateSelector = (state: NDVIState) => state.ndviSelectedDate;
export const ndviImageTypeSelector = (state: NDVIState) => state.imageType;
export const ndviPlanetSelectedDateSelector = (state: NDVIState) => state.planetSelectedDate;
export const ndviPlanetImageTypeSelector = (state: NDVIState) => state.planetImageType;

export interface IImageWithBox extends IImage {
  images: {
    imageAverageUrl: string;
    imagePredictedUrl: string;
    imagePreviousUrl: string;
    imageUrl: string;
    polygonid: string;
    boundBox?: turf.helpers.BBox;
  }[];
}

export interface NDXIImages {
  farmId: string;
  images: IImageWithBox[];
}

export interface NDVIState {
  esaImages: NDXIImages;
  activeFarm: IFarm;
  activePlanetFarm: IFarm;
  ndviSelectedDate: Date;
  imageType: string;
  planetSelectedDate: number;
  planetImageType: string;
  esaImagesGet: {
    loading: boolean;
    error: unknown;
  };
  planetImages: NDXIImages;
  planetImagesGet: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: NDVIState = {
  esaImages: null,
  planetImages: null,
  activeFarm: null,
  activePlanetFarm: null,
  ndviSelectedDate: null,
  imageType: null,
  planetSelectedDate: null,
  planetImageType: null,
  esaImagesGet: {
    loading: false,
    error: null,
  },
  planetImagesGet: {
    loading: false,
    error: null,
  },
};

const reducer = (state: NDVIState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.NDVI_GET_START:
      return updateObject(state, {
        esaImages: {
          farmId: null,
          images: null,
        },
        esaImagesGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.NDVI_GET_SUCCESS:
      return updateObject(state, {
        esaImages: {
          farmId: action.farmId,
          images: new Array(...action.images.reverse()),
        },
        activeFarm: action.activeFarm,
        esaImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.NDVI_GET_FAIL:
      return updateObject(state, {
        esaImages: {},
        esaImagesGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.NDVI_GET_ACTIVE_DATA:
      return updateObject(state, {
        ndviSelectedDate: action.selectedDate,
        imageType: action.imageType,
      });
    case ActionTypes.NDVI_PLANET_GET_START:
      return updateObject(state, {
        planetImages: {
          farmId: null,
          images: null,
        },
        planetImagesGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.NDVI_PLANET_GET_SUCCESS:
      return updateObject(state, {
        planetImages: {
          farmId: action.farmId,
          images: new Array(...action.images.reverse()),
        },
        activePlanetFarm: action.activeFarm,
        planetImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.NDVI_PLANET_GET_FAIL:
      return updateObject(state, {
        planetImages: {},
        planetImagesGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.NDVI_PLANET_ACTIVE_DATE:
      return updateObject(state, {
        planetSelectedDate: action.selectedDate,
        planetImageType: action.imageType,
      });
    default:
      return state;
  }
};

export default reducer;
