import React from 'react';
import {useDispatch} from 'react-redux';
import {CognitoState} from '@deep-planet/react-cognito';
import Login from '../../components/Cognito/Login';
import Confirmation from '../../components/Cognito/Confirmation';
import NewPassword from '../../components/Cognito/NewPassword';
import EmailVerification from '../../components/Cognito/EmailVerification';
import Loader from '../../components/UI/AuthSplash/Loader';
import axios from 'axios';
import jwtdecode from 'jwt-decode';
import {CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import cognitoConfig from '../../utilities/cognitoConfig';
import {usePrevious} from '../../hooks/usePrevious';
import {createUserLog} from '../../store/actions';
import {withUser} from '../../hooks/useAuth';

export default function (ComposedComponent) {
  const BaseDashboard = ({state, user, attributes}) => {
    const dispatch = useDispatch();
    const prevState = usePrevious(state);
    switch (state) {
      case CognitoState.LOGGED_IN:
        // eslint-disable-next-line no-case-declarations
        const accessToken = window.localStorage.getItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.accessToken`);
        // eslint-disable-next-line no-case-declarations
        const idToken = window.localStorage.getItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.idToken`);
        if (!accessToken) {
          return <Login />;
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + idToken;
        axios.interceptors.request.use(async config => {
          const token = await refreshToken(user);
          if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
          }
          return config;
        });
        if (state === CognitoState.LOGGED_IN && prevState === CognitoState.LOGGING_IN) {
          dispatch(createUserLog('login'));
        }

        return <ComposedComponent user={user} attributes={attributes} />;
      case CognitoState.AUTHENTICATED:
      case CognitoState.LOGGING_IN:
        return <Loader />;
      case CognitoState.LOGGED_OUT:
      case CognitoState.LOGIN_FAILURE:
        return <Login />;
      case CognitoState.MFA_REQUIRED:
        return <div>MFA Page</div>;
      case CognitoState.NEW_PASSWORD_REQUIRED:
        return <NewPassword />;
      case CognitoState.EMAIL_VERIFICATION_REQUIRED:
        return <EmailVerification />;
      case CognitoState.CONFIRMATION_REQUIRED:
        return <Confirmation />;
      default:
        return (
          <div>
            <p>Unrecognised cognito state</p>
          </div>
        );
    }
  };

  const refreshToken = async user => {
    return new Promise(resolve => {
      const expiredToken = new Date(1000 * user.signInUserSession.accessToken.payload.exp) < new Date();
      if (expiredToken && user) {
        const poolData = {
          UserPoolId: cognitoConfig.userPool,
          ClientId: cognitoConfig.clientId,
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
          Username: user.username,
          Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.getSession((err, session) => {
          const refreshToken = session.getRefreshToken();
          cognitoUser.refreshSession(refreshToken, (err, res) => {
            if (err) {
              resolve();
            }
            const idToken = res.idToken.jwtToken;
            const accessToken = res.accessToken.jwtToken;
            const refreshToken = res.refreshToken.token;

            window.localStorage.setItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.idToken`, idToken);
            window.localStorage.setItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.accessToken`, accessToken);
            window.localStorage.setItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.refreshToken`, refreshToken);
            resolve(idToken);
          });
        });
      } else {
        return resolve();
      }
    });
  };

  return withUser(BaseDashboard);
}

export const getUserGroups = user => {
  try {
    const token = window.localStorage.getItem(`CognitoIdentityServiceProvider.${cognitoConfig.clientId}.${user.username}.accessToken`);
    let decoded = jwtdecode(token);
    return decoded ? decoded['cognito:groups'] || [] : [];
  } catch (e) {
    return [];
  }
};

export const hasUserRole = (user, role) => {
  return getUserGroups(user).includes(role);
};
