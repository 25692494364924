import {IGetMaturityImages} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/maturity';
import {orderBy} from 'lodash';
import {IFarm} from './farm';

export const getMaturityImagesSelector = (state: MaturityState) => state.getMaturityImages;
export const maturityImagesSelector = (state: MaturityState) => orderBy(state.maturityImages, i => i.date, 'desc');
export const maturityActiveFarmSelector = (state: MaturityState) => state.activeFarm;
export const maturitySelectedDateSelector = (state: MaturityState) => state.selectedDate;

export interface MaturityState {
  getMaturityImages: {
    error: unknown;
    loading: boolean;
  };
  maturityImages: IGetMaturityImages[];
  activeFarm: IFarm;
  selectedDate: number;
}

const initialState: MaturityState = {
  getMaturityImages: {
    error: false,
    loading: false,
  },
  maturityImages: null,
  activeFarm: null,
  selectedDate: null,
};

const reducer = (state: MaturityState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.MATURITY_IMAGE_GET_START:
      return updateObject(state, {
        getMaturityImages: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MATURITY_IMAGE_GET_SUCCESS:
      return updateObject(state, {
        maturityImages: action.images,
        activeFarm: action.activeFarm,
        getMaturityImages: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MATURITY_IMAGE_GET_FAIL:
      return updateObject(state, {
        getMaturityImages: {
          error: action.error,
          loading: false,
        },
      });

    case ActionTypes.MATURITY_IMAGE_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default reducer;
