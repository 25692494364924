import {useLocation} from 'react-router-dom';
import {IFarm} from '../store/reducers/farm';
import {useFarmNDVIImage} from './useFarmNDVIImage';
import {useFarmNDWIImage} from './useFarmNDWIImage';
import {useNDVIImage} from './useNDVIImage';
import {useNDWIImage} from './useNDWIImage';

export const useImages = (activeFarm: IFarm, startDate: Date, endDate: Date, isPredictionNeeded = false) => {
  const {pathname} = useLocation();
  const product = pathname.includes('ndvi') ? 'ndvi' : 'ndwi';
  const isVineSignal = pathname.includes('vine-signal');

  const {
    ndviImages: vineNdviImages,
    onGetNDVI: vineOnGetNDVI,
    loadingNdvi: vineLoadingNdvi,
    error: vineNdviError,
    selectedDate: ndviSelectedDate,
    imageType: ndviSelectedImageType,
    handleSelectedDateAndTypeNDVI,
  } = useNDVIImage(activeFarm, startDate, endDate, isPredictionNeeded, !(product === 'ndvi' && isVineSignal));
  const {
    ndwiImages: vineNdwiImages,
    onGetNDWI: vineOnGetNDWI,
    loadingNdwi: vineLoadingNdwi,
    error: vineNdwiError,
    selectedDate: ndwiSelectedDate,
    imageType: ndwiSelectedImageType,
    handleSelectedDateAndTypeNDWI,
  } = useNDWIImage(activeFarm, startDate, endDate, isPredictionNeeded, !(product === 'ndwi' && isVineSignal));
  const {ndviImages: soilNdviImages, onGetNDVI: soilOnGetNDVI, loadingNdvi: soilLoadingNdvi, error: soilNdviError} = useFarmNDVIImage(
    activeFarm,
    startDate,
    endDate,
    !(product === 'ndvi' && !isVineSignal)
  );
  const {ndwiImages: soilNdwiImages, onGetNDWI: soilOnGetNDWI, loadingNdwi: soilLoadingNdwi, error: soilNdwiError} = useFarmNDWIImage(
    activeFarm,
    startDate,
    endDate,
    !(product === 'ndwi' && !isVineSignal)
  );

  return isVineSignal
    ? {
        error: product === 'ndvi' ? vineNdviError : vineNdwiError,
        loadingGetImages: product === 'ndvi' ? vineLoadingNdvi : vineLoadingNdwi,
        images: product === 'ndvi' ? vineNdviImages : vineNdwiImages,
        description: product === 'ndvi' ? 'ndvi.title' : 'ndwi.title',
        product: product.toLowerCase(),
        variant: product,
        ndviSelectedDate,
        ndviSelectedImageType,
        ndwiSelectedDate,
        ndwiSelectedImageType,
        onGetImages: product === 'ndvi' ? vineOnGetNDVI : vineOnGetNDWI,
        handleSelectedDateAndTypeNDVI,
        handleSelectedDateAndTypeNDWI,
      }
    : {
        error: product === 'ndvi' ? soilNdviError : soilNdwiError,
        loadingGetImages: product === 'ndvi' ? soilLoadingNdvi : soilLoadingNdwi,
        images: product === 'ndvi' ? soilNdviImages : soilNdwiImages,
        description: product === 'ndvi' ? 'ndvi.title' : 'ndwi.title',
        product: product.toLowerCase(),
        variant: product,
        onGetImages: product === 'ndvi' ? soilOnGetNDVI : soilOnGetNDWI,
      };
};
