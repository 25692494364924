import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Box, Button, Tooltip, makeStyles} from '@material-ui/core';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import FarmSelectionSidebar from '../../../components/UI/Sidebar/FarmSelection';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import SugarMeasureSwitcher from '../../../components/UI/Switch/SugarMeasureSwitch';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useMaturityPrediction} from '../../../hooks/useMaturityPrediction';
import Layout from '../../Layout';
import {Plot} from './Plot';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const StyledBox = styled(Box)`
  margin-left: 0;
  margin-right: 0;
  background-color: white;
  padding-top: 20px;
  justify-content: start;
`;

const Harvest = () => {
  const {sugarUnit} = useMeasurementConversion();
  const [metric, setMetric] = useState<string>(sugarUnit === 'brix' ? 'brix' : 'baume');
  const [multiplier, setMultiplier] = useState<number>(1);
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity>(null);
  const {allFarms, loadingGetUser, loadingGetFarm, selectedFarm, handleFarmChange} = useFarmSelection();
  const {maturityPredictions, loadingGetMaturity, prevMaturityPredictions} = useMaturityPrediction(selectedFarm);
  const {HelpHarvest} = useTooltipText();

  const [maturityPrediction, setMaturityPrediction] = useState(maturityPredictions?.find(({polygon}) => selectedFarm?.polygons[0].id));

  const useStyles = makeStyles(theme => ({
    screenshot: {
      position: 'absolute',
      width: 40,
      height: 40,
      backgroundColor: 'white',
      borderRadius: '2.5px',
      paddingInline: '20px',
      zIndex: 5,
      top: '11px',
      right: '12px',
      [theme.breakpoints.down('md')]: {
        top: '2px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '7px',
        width: 20,
        right: '2px',
      },
    },
  }));

  const classes = useStyles();

  const handlePolygonChange = (polygon: IPolygonEntity) => {
    setSelectedPolygon(polygon);
  };

  const handleSugarSwitch = value => {
    setMetric(value);
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const isLoading = loadingGetFarm || loadingGetUser || loadingGetMaturity;

  useEffect(() => {
    setMaturityPrediction(maturityPredictions?.find(({polygon}) => polygon.id === selectedPolygon?.id));
  }, [selectedPolygon, maturityPredictions]);

  useEffect(() => {
    if (Boolean(maturityPredictions) && Boolean(prevMaturityPredictions) && maturityPredictions !== prevMaturityPredictions) {
      setSelectedPolygon(selectedFarm.polygons[0]);
    }
  }, [maturityPredictions, prevMaturityPredictions, selectedFarm]);

  useEffect(() => {
    if (metric === 'baume') {
      setMultiplier(1);
    } else if (metric === 'brix') {
      setMultiplier(1.8);
    }
  }, [metric]);

  return (
    <StyledBox>
      <Layout>
        {isLoading && <MapOverlay />}
        {!isLoading && selectedFarm?.polygons?.length === 0 && <MapPlaceholderNoPolygons activeFarm={selectedFarm.farmid} />}
        {!isLoading && !allFarms?.length && <MapPlaceholder />}

        {!isLoading && selectedFarm && (
          <div id="layout">
            <ContentLeftSidebar
              sidebar={
                <FarmSelectionSidebar
                  polygons={selectedFarm?.polygons}
                  allFarms={allFarms}
                  activeFarm={selectedFarm.farmid}
                  handleFarmChange={handleFarmChange}
                  loadingGetFarm={loadingGetFarm}
                  handlePolygonChange={handlePolygonChange}
                />
              }
              content={
                <>
                  <Box className={classes.screenshot} boxShadow={1}>
                    <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Tooltip title={'screenshot'} placement={'left'} arrow>
                        <Button aria-label={'screenshot'} onClick={takeScreenshot} style={{bottom: '3px', color: 'secondary'}}>
                          <ControlCameraIcon color="secondary" fontSize="large" style={{transform: 'rotate(45deg)'}} />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                  {maturityPrediction && (
                    <>
                      <SugarMeasureSwitcher withSugar={false} metric={metric} handleSwitch={handleSugarSwitch} />
                      <Box style={{position: 'absolute', left: 245, top: 18, zIndex: 12}}>
                        <HelpTooltip text={HelpHarvest} icon />
                      </Box>
                    </>
                  )}

                  <Plot maturityPrediction={maturityPrediction} metric={metric} sugarTypeMultiplier={multiplier} />
                </>
              }
            />
          </div>
        )}
      </Layout>
    </StyledBox>
  );
};

export {Harvest};
