import moment, {LongDateFormatKey} from 'moment';
import i18n from '../../i18n';

export const handleDateFormat = (aDate: string | number | Date, isYearNeeded = true) => {
  moment.locale(i18n.language || window.navigator.language);
  if (isYearNeeded) {
    return moment(aDate).format('ll');
  }
  return moment(aDate).format('MM-DD');
};

export const getStringDate = (aDate: Date | string | number) => {
  const date = moment(aDate);
  return date.utc().format('YYYY-MM-DD');
};

export const getLocalDateString = (aDate: Date | string | number, format: LongDateFormatKey = 'LL') => {
  moment.locale(i18n.language || window.navigator.language);
  return moment(aDate).format(format);
};

export const getDateString = (aDate: Date | string | number) => {
  const date = moment(aDate);
  return date.format('DD-MM-YYYY');
};
