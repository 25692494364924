import {
  IFarmEntity,
  IOrganizationEntity,
  IOrganizationUserFarmPermissionTypeEntity,
  IUserEntity,
  OrganizationPermissionsResponse,
  UpsertOrganizationPermissionRequest,
} from '@deep-planet/api-interfaces';
import {Box, Button, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import {sortBy} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import Spinner from '../../../../../components/UI/Spinner';
import {baseApiUrl} from '../../../../../config/const';
import {useHttp} from '../../../../../hooks/http';
import {enqueueSnackbar} from '../../../../../store/actions';
import theme from '../../../../../utilities/theme';
import {AddUserToFarm} from './AddUserToFarm';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const ManageOrganizationPermission = () => {
  const [isLoadingOrganizations, organizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const [isLoadingPermissions, permissions, setPermissions] = useHttp<OrganizationPermissionsResponse[]>(`${baseApiUrl}/organizations/permissions`);
  const [isLoadingPermissionTypes, permissionTypes] = useHttp<IOrganizationUserFarmPermissionTypeEntity[]>(`${baseApiUrl}/organizations/permission-types`);

  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>();
  const [selectedFarm, setSelectedFarm] = useState<IFarmEntity>(null);
  const [selectedUser, setSelectedUser] = useState<IUserEntity>(null);
  const [filteredPermissions, setFilteredPermissions] = useState<OrganizationPermissionsResponse[]>([]);
  const [isLoadingSavePermissions, setIsLoadingSavePermissions] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handlePermissionSelect = (userId: string, permissionId: string, farmId: string) => {
    const permission = permissionTypes.find(({id}) => id === permissionId);
    const newFilteredPermissions = filteredPermissions.find(p => p.userId === userId && p.farmId === farmId && p.organizationId === selectedOrganization.id)
      ? filteredPermissions.map(p => {
          if (p.userId === userId && p.farmId === farmId && p.organizationId === selectedOrganization.id)
            return {
              ...p,
              permissionType: {
                name: permission.name,
                id: permission.id,
              },
            };
          return p;
        })
      : [
          ...filteredPermissions,
          {
            permissionId: '',
            userId,
            organizationId: selectedOrganization.id,
            farmId,
            permissionType: {
              name: permission.name,
              id: permission.id,
            },
          },
        ];
    setFilteredPermissions(newFilteredPermissions);
  };

  const handleOrganizationSelect = (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
  };

  const handleFarmSelection = (currentlySelectedFarm: IFarmEntity) => {
    setSelectedFarm(currentlySelectedFarm);
    setSelectedUser(null);
    setFilteredPermissions(permissions.filter(p => p.farmId === currentlySelectedFarm.id && p && p.organizationId === selectedOrganization.id));
  };

  const handleUserSelection = (currentlySelectedUser: IUserEntity) => {
    setSelectedUser(currentlySelectedUser);
    setSelectedFarm(null);
    setFilteredPermissions(permissions.filter(p => p.userId === currentlySelectedUser.id && p && p.organizationId === selectedOrganization.id));
  };

  const handlePermissionsSave = async () => {
    const url = `${baseApiUrl}/organization/${selectedOrganization.id}/permission`;
    try {
      setIsLoadingSavePermissions(true);
      const payload: UpsertOrganizationPermissionRequest = {
        permissions: filteredPermissions.map(({userId, permissionId, farmId, permissionType: {id}}) => {
          return {
            farmId,
            userId,
            permissionId,
            permissionTypeId: id,
          };
        }),
      };
      const {data} = await axios.post<OrganizationPermissionsResponse[]>(url, payload);
      setPermissions(data);
      dispatch(enqueueSnackbar({message: 'Organization permissions successfully updated', options: {variant: 'success'}}));
    } catch (e) {
      console.error(e);
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsLoadingSavePermissions(false);
    }
  };

  const isLoading = isLoadingOrganizations || isLoadingPermissions || isLoadingPermissionTypes;
  return (
    <>
      {isLoading && <MapOverlay />}
      {!isLoading && (
        <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3" align="center" gutterBottom>
            Manage Organization Permissions
          </Typography>

          <FormWrapper padding="2rem 0">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedOrganization}
                options={sortBy(organizations, o => o.name.toLowerCase())}
                onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
              />

              {selectedOrganization && (
                <AddUserToFarm
                  selectedOrganization={selectedOrganization}
                  selectedFarm={selectedFarm}
                  selectedUser={selectedUser}
                  permissionTypes={permissionTypes}
                  permissions={filteredPermissions}
                  handleFarmSelection={handleFarmSelection}
                  handleUserSelection={handleUserSelection}
                  handlePermissionSelect={handlePermissionSelect}
                />
              )}
            </FormControl>
            <Box padding="1rem 0">
              <Button fullWidth disabled={isLoadingSavePermissions || !filteredPermissions?.length} color="primary" variant="contained" type="submit" onClick={handlePermissionsSave}>
                Submit
                {isLoadingSavePermissions && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </FormWrapper>
        </Box>
      )}
    </>
  );
};
