import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPredictedYield} from '../store/actions';
import {getYieldPredictionSelector, latestYieldsSelector, selectedOrganizationSelector, yieldPredictionDatesSelector, yieldPredictionsSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useYieldPrediction(userHasPermission = true) {
  const dispatch = useDispatch();

  const latestYields = useSelector(latestYieldsSelector);
  const yieldPredictions = useSelector(yieldPredictionsSelector);
  const {loading: loadingGetYield} = useSelector(getYieldPredictionSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const prevLatestYields = usePrevious(latestYields);
  const prevYieldPredictions = usePrevious(yieldPredictions);
  const yieldPredictionDates = useSelector(yieldPredictionDatesSelector);

  useEffect(() => {
    if (!latestYields && !loadingGetYield && !prevLatestYields && userHasPermission) {
      dispatch(getPredictedYield());
    }
  }, [dispatch, latestYields, loadingGetYield, prevLatestYields, userHasPermission]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id) {
      dispatch(getPredictedYield(null, selectedOrganization.id));
    }
  }, [dispatch, prevSelectedOrganization, selectedOrganization]);

  const onGetPredictedYield = (predictionDate: string) => {
    dispatch(getPredictedYield(predictionDate, selectedOrganization?.id));
  };

  return {
    latestYields,
    yieldPredictions,
    prevYieldPredictions,
    loadingGetYield,
    yieldPredictionDates,
    onGetPredictedYield,
  };
}
