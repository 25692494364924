import React, {useState} from 'react';
import {MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker} from '@material-ui/pickers';
import {useTranslation} from 'react-i18next';
import {Box, FormControl} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import moment, {Moment} from 'moment';
import i18n from '../../../../i18n';
import {getLocalDateString} from '../../../helpers/dateHelpers';

moment.locale(i18n.language || window.navigator.language);

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return getLocalDateString(date);
  }
}

interface Props {
  startDate: Date;
  endDate: Date;
  handleStartDateChange: (date: Date) => void;
  handleEndDateChange: (date: Date) => void;
  disableFuture?: boolean;
  disableToolbar?: boolean;
  variant?: 'inline' | 'outlined';
  direction?: 'row' | 'column';
  width?: string;
}

const DatePeriodPicker = ({
  startDate: initStartDate,
  endDate: initEndDate,
  handleStartDateChange,
  handleEndDateChange,
  disableFuture = false,
  disableToolbar = true,
  variant = 'inline',
  direction = 'column',
  width = '100%',
}: Props) => {
  const [startDate, setStartDate] = useState<Date>(initStartDate);
  const [endDate, setEndDate] = useState<Date>(initEndDate);
  const {t} = useTranslation();

  const onStartDateChange = (moment: Moment) => {
    const date = moment?.toDate();
    if (date) {
      setStartDate(date);
      handleStartDateChange(date);
    }
  };

  const onEndDateChange = (moment: Moment) => {
    const date = moment?.toDate();
    if (date) {
      setEndDate(date);
      handleEndDateChange(date);
    }
  };

  return (
    <Box display="flex" width={width} flexDirection={direction} margin={direction === 'column' ? '16px 0' : '0'}>
      <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={i18n.language || window.navigator.language}>
        <Box width="100%" margin={direction === 'column' ? '0 0 16px 0' : '0 16px 0 0'}>
          <FormControl style={{display: 'flex'}}>
            {variant === 'inline' ? (
              <DatePicker
                disableToolbar={disableToolbar}
                autoOk
                format="LL"
                label={t('date.picker.start.date')}
                value={startDate}
                onChange={onStartDateChange}
                maxDate={endDate}
                disableFuture={disableFuture}
              />
            ) : (
              <KeyboardDatePicker
                disableToolbar={disableToolbar}
                autoOk
                emptyLabel="Select date"
                label={t('date.picker.start.date')}
                variant="inline"
                inputVariant="outlined"
                format="LL"
                value={startDate}
                maxDate={endDate}
                disableFuture={disableFuture}
                InputAdornmentProps={{position: 'end'}}
                onChange={date => onStartDateChange(date)}
              />
            )}
          </FormControl>
        </Box>
        {variant === 'inline' ? (
          <DatePicker
            disableToolbar={disableToolbar}
            autoOk
            format="LL"
            label={t('date.picker.end.date')}
            value={endDate}
            onChange={onEndDateChange}
            minDate={startDate}
            disableFuture={disableFuture}
          />
        ) : (
          <KeyboardDatePicker
            disableToolbar={disableToolbar}
            autoOk
            emptyLabel="Select date"
            label={t('date.picker.end.date')}
            variant="inline"
            inputVariant="outlined"
            format="LL"
            value={endDate}
            minDate={startDate}
            disableFuture={disableFuture}
            InputAdornmentProps={{position: 'end'}}
            onChange={date => onEndDateChange(date)}
          />
        )}
      </MuiPickersUtilsProvider>
    </Box>
  );
};

export default DatePeriodPicker;
