import {Box} from '@material-ui/core';
import React, {useMemo, useState} from 'react';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder, {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useYieldPerPixel} from '../../../hooks/useYieldPerPixel';
import Layout from '../../Layout';
import Legend, {DynamicScaleType} from '../NDVI/Legend';
import Sidebar from './../MaturityMap/Sidebar';
import moment from 'moment';
import Map from './../MaturityMap/Map';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import usePolygon from '../../../hooks/usePolygon';
import {useTranslation} from 'react-i18next';
import {PageTitle} from '../../../components/UI/PageTitle';

export const YieldPerPixelMap = () => {
  //initialize the marker colors
  useMarkerColor();
  const {t} = useTranslation();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const {selectedFarm, activeFarm, allFarms, loadingGetFarm, loadingGetUser, handleFarmChange, setActiveFarm} = useFarmSelection();
  const {yieldPerPixelImages, selectedDate, handleDateChange, onGetYieldPerPixelImages, loading: loadingGetImages} = useYieldPerPixel(activeFarm, startDate, endDate);
  const [showTonnes, setShowTonnes] = useState<boolean>(true);
  const {handleOnClickPolygon, selectedPolygon, polygonClicked} = usePolygon();

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleImageFetching = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    onGetYieldPerPixelImages(selectedFarm);
  };

  const handleFarmSelection = (farmId: string) => {
    handleFarmChange(farmId);
  };

  const handleSelectedDate = (date: number) => {
    handleDateChange(date);

    handleDynamicScale(yieldPerPixelImages, new Date(date), showTonnes);
  };

  const handleSwitch = () => {
    const tonnes = !showTonnes;
    setShowTonnes(!showTonnes);
    //@ts-ignore
    handleDynamicScale(yieldPerPixelImages, selectedDate, tonnes);
  };

  const convertTonneToKg = (value: number) => {
    if (!value || value === 0) return;
    return (value * 1000).toFixed(2);
  };

  const convertKgToTonne = (value: number) => {
    if (!value || value === 0) return;
    return (value / 1000).toFixed(2);
  };

  // TODO: this may required to remove in the future if thresholds are same across the farm
  const handleDynamicScale = (yieldImages, dateSelected, showTonnes) => {
    const images = yieldImages?.find(({date}) => moment(date).isSame(moment(dateSelected), 'date'))?.images;
    if (images) {
      let {thresholdLow, thresholdHigh} = images[0];
      if (!showTonnes) {
        thresholdLow = convertTonneToKg(thresholdLow);
        thresholdHigh = convertTonneToKg(thresholdHigh);
      }
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      for (let i = 0; i <= 7; i++) {
        const value = parseFloat(thresholdLow + step * i).toFixed(2);
        currentDynamicScale.push(Number(value));
      }
      setDynamicScale(currentDynamicScale);
    }
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };

  const isLoading = loadingGetFarm || loadingGetUser || loadingGetImages;
  const filteredImages = useMemo(() => yieldPerPixelImages?.find(({date}) => moment(date).isSame(moment(selectedDate), 'date')), [yieldPerPixelImages, selectedDate]);
  const metricType = showTonnes ? 'Tonne' : 'Kg';
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && activeFarm && (
        <Box>
          <div id="layout">
            <Legend product="yield_per_pixel" type="current" dynamicScale={dynamicScale} text={metricType} />
            <ContentLeftSidebar
              sidebar={
                <Sidebar
                  allFarms={allFarms}
                  activeFarm={selectedFarm}
                  images={yieldPerPixelImages}
                  startDate={startDate}
                  endDate={endDate}
                  selectedDate={selectedDate}
                  handleFarmChange={handleFarmSelection}
                  handleDateChange={handleSelectedDate}
                  handleImageFetching={handleImageFetching}
                  handleStartDateSelection={handleStartDateSelection}
                  handleEndDateSelection={handleEndDateSelection}
                  loading={isLoading}
                />
              }
              content={
                <>
                  <PageTitle title={t('yield.per.pixel')} />
                  <Map
                    farm={activeFarm}
                    center={activeFarm.farmCenter}
                    images={filteredImages?.images}
                    bbox={activeFarm.bbox}
                    polygons={activeFarm.polygons}
                    showTonnes={showTonnes}
                    scaleType={'TONNES'}
                    handleSwitch={handleSwitch}
                    takeScreenshot={takeScreenshot}
                    handlePolygonClick={handleOnClickPolygon}
                    selectedPolygon={selectedPolygon}
                    polygonClicked={polygonClicked}
                  />
                </>
              }
            />
          </div>
        </Box>
      )}
    </Layout>
  );
};
