import React from 'react';
import TableCell from '@material-ui/core/TableCell/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow';
import Forward from '@material-ui/icons/Forward';

interface Props {
  label: string;
  value: unknown;
  label2?: string;
  value2?: unknown;
}
export const TableCellElement = ({label, value, label2, value2}: Props) => {
  // textAlign: label2 ? 'left' : 'unset',
  const labelProp = {padding: '0px 13px'};
  const label2Prop = {
    padding: '0px 13px',
    textAlign: 'left',
    fontSize: '9pt',
    fontWeight: '1000',
  };

  const StyledTableCell = withStyles(theme => ({
    root: label2 ? label2Prop : labelProp,
  }))(TableCell);
  return (
    <TableRow>
      <StyledTableCell>{label}</StyledTableCell>
      <StyledTableCell align="right">{value}</StyledTableCell>
      {label2 && <StyledTableCell align="right">{label2}</StyledTableCell>}
      {value2 && <StyledTableCell align="right">{value2}</StyledTableCell>}
    </TableRow>
  );
};
