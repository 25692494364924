import {useState, useEffect} from 'react';
import axios from 'axios';
import i18n from '../../i18n';
import {enqueueSnackbar} from '../store/actions';
import {useDispatch} from 'react-redux';
import React from 'react';

export function useHttp<T>(url: string): [boolean, T, React.Dispatch<React.SetStateAction<T>>, boolean] {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [fetchedData, setFetchedData] = useState<T>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (url) {
      setIsLoading(true);
      axios
        .get(url)
        .then(response => {
          setFetchedData(response.data);
          setIsLoading(false);
        })
        .catch(e => {
          setError(true);
          dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
          console.error(`Failed to fetch ${url}`, e);
        });
    }
  }, [url, dispatch, error]);

  return [isLoading, fetchedData, setFetchedData, error];
}
