// Import necessary interfaces and modules
import {CreateFileInfoDTO, IMarker, IFileEntity, IShareFileBody, IFileTypeEntity, IFileInfoEntity} from '@deep-planet/api-interfaces';
import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import ActionTypes from './actionTypes';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {getFormData, uploadFile} from './utils';
import {markerPutSuccess} from './marker';
import {ISelectedFile} from '../../components/UI/FileManager/FileUploadModal';

////////////////////////////////////
// GET FILE TYPES
////////////////////////////////////

// Interface for GET_FILE_TYPE_START action
interface IFileTypeGetStart {
  type: ActionTypes.FILE_TYPE_GET_START;
}

// Action creator for GET_FILE_TYPE_START action
export const fileTypeGetStart = (): IFileTypeGetStart => ({
  type: ActionTypes.FILE_TYPE_GET_START,
});

// Interface for GET_FILE_TYPE_SUCCESS action
interface IFileTypeGetSuccess {
  type: ActionTypes.FILE_TYPE_GET_SUCCESS;
  payload: IFileTypeEntity[];
}

// Action creator for GET_FILE_TYPE_SUCCESS action
export const fileTypeGetSuccess = (payload: IFileTypeEntity[]): IFileTypeGetSuccess => ({
  type: ActionTypes.FILE_TYPE_GET_SUCCESS,
  payload,
});

// Interface for GET_FILE_TYPE_FAIL action
interface IFileTypeGetFail {
  type: ActionTypes.FILE_TYPE_GET_FAIL;
}

// Action creator for GET_FILE_TYPE_FAIL action
export const fileTypeGetFail = (): IFileTypeGetFail => ({
  type: ActionTypes.FILE_TYPE_GET_FAIL,
});

// Function to get files
export const getFileTypes = () => {
  // Return a function that takes dispatch as an argument
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    // Dispatch GET_FILES_START action
    dispatch(fileTypeGetStart());
    // Construct API URL
    const url = `${baseApiUrl}/file-manager/file-types`;
    try {
      // Make GET request to API
      const response = await axios.get<IFileTypeEntity[]>(url);
      // Dispatch GET_FILES_SUCCESS action with response data
      dispatch(fileTypeGetSuccess(response.data));
    } catch (e) {
      // Catch any errors and dispatch GET_FILES_FAIL action
      console.log(e);
      dispatch(fileTypeGetFail());
      // Display error message using snackbar
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// GET FILES
////////////////////////////////////

// Interface for GET_FILES_START action
interface IFilesGetStart {
  type: ActionTypes.FILE_GET_START;
}

// Action creator for GET_FILES_START action
export const filesGetStart = (): IFilesGetStart => ({
  type: ActionTypes.FILE_GET_START,
});

// Interface for GET_FILES_SUCCESS action
interface IFilesGetSuccess {
  type: ActionTypes.FILE_GET_SUCCESS;
  payload: IFileEntity[];
}

// Action creator for GET_FILES_SUCCESS action
export const filesGetSuccess = (payload: IFileEntity[]): IFilesGetSuccess => ({
  type: ActionTypes.FILE_GET_SUCCESS,
  payload,
});

// Interface for GET_FILES_FAIL action
interface IFilesGetFail {
  type: ActionTypes.FILE_GET_FAIL;
}

// Action creator for GET_FILES_FAIL action
export const filesGetFail = (): IFilesGetFail => ({
  type: ActionTypes.FILE_GET_FAIL,
});

// Function to get files
export const getFiles = (organizationId: string, fromDate: Date, toDate: Date) => {
  // Return a function that takes dispatch as an argument
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    // Dispatch GET_FILES_START action
    dispatch(filesGetStart());
    // Construct API URL
    const url = `${baseApiUrl}/file-manager/files?organizationId=${organizationId}&fromDate=${fromDate}&toDate=${toDate}`;
    try {
      // Make GET request to API
      const response = await axios.get<IFileEntity[]>(url);
      // Dispatch GET_FILES_SUCCESS action with response data
      dispatch(filesGetSuccess(response.data));
    } catch (e) {
      // Catch any errors and dispatch GET_FILES_FAIL action
      console.log(e);
      dispatch(filesGetFail());
      // Display error message using snackbar
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// POST FILE
////////////////////////////////////

// Interface for FILE_POST_START action
interface IFilePostStart {
  type: ActionTypes.FILE_POST_START;
}

// Action creator for FILE_POST_START action
export const filePostStart = (): IFilePostStart => ({
  type: ActionTypes.FILE_POST_START,
});

// Interface for FILE_POST_SUCCESS action
interface IFilePostSuccess {
  type: ActionTypes.FILE_POST_SUCCESS;
  payload: IFileEntity;
}

// Action creator for FILE_POST_SUCCESS action
export const filePostSuccess = (payload: IFileEntity): IFilePostSuccess => ({
  type: ActionTypes.FILE_POST_SUCCESS,
  payload,
});

// Interface for FILE_POST_FAIL action
interface IFilePostFail {
  type: ActionTypes.FILE_POST_FAIL;
}

// Action creator for FILE_POST_FAIL action
export const filePostFail = (): IFilePostFail => ({
  type: ActionTypes.FILE_POST_FAIL,
});

// Function to create a new file
export const createFile = (params: CreateFileInfoDTO, files: ISelectedFile[], closeModal: () => void) => {
  // Return a function that takes dispatch as an argument
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    // Dispatch FILE_POST_START action
    dispatch(filePostStart());
    try {
      // Process files and upload them to API
      const data = await Promise.all(
        files.map(async file =>
          getFormData(
            {
              name: file.name,
              contentType: file.type,
              organizationId: params.organizationId,
              farmId: params.farmId,
            },
            file,
            `${baseApiUrl}/file-manager/file/upload-file`
          )
        )
      );
      // upload file to S3 bucket
      await Promise.all(data.map(({bucketUrl, formData}) => uploadFile(bucketUrl, formData)));
      // Update params with uploaded file data
      params.files = data.map(({name, url, size, uploadedAt}) => ({name, url, size, uploadedAt}));
      // Construct API URL
      const url = `${baseApiUrl}/file-manager/file`;
      // Make POST request to API
      const response = await axios.post<IFileEntity>(url, params);
      // Dispatch FILE_POST_SUCCESS action with response data
      dispatch(filePostSuccess(response.data));
      // Close modal
      closeModal();
      // Display success message using snackbar
      dispatch(enqueueSnackbar({message: i18n.t('file.saved'), options: {variant: 'success'}}));
    } catch (e) {
      // Catch any errors{
      dispatch(filePostFail());
      const msg = e?.message || i18n.t('error.http.response');
      dispatch(enqueueSnackbar({message: msg, options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// DELETE FILE
////////////////////////////////////

interface IFileDeleteStart {
  type: ActionTypes.FILE_DELETE_START;
}

export const fileDeleteStart = (): IFileDeleteStart => ({
  type: ActionTypes.FILE_DELETE_START,
});

interface IFileDeleteSuccess {
  type: ActionTypes.FILE_DELETE_SUCCESS;
  payload: {fileInfoId: string; fileId: string};
}

export const fileDeleteSuccess = (fileInfoId, fileId: string): IFileDeleteSuccess => ({
  type: ActionTypes.FILE_DELETE_SUCCESS,
  payload: {fileInfoId, fileId},
});

interface IFileDeleteFail {
  type: ActionTypes.FILE_DELETE_FAIL;
}

export const fileDeleteFail = (): IFileDeleteFail => ({
  type: ActionTypes.FILE_DELETE_FAIL,
});

export const deleteFile = (fileInfoId: string, fileId: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(fileDeleteStart());
    const url = `${baseApiUrl}/file-manager/file/${fileId}`;
    try {
      await axios.delete(url);
      dispatch(fileDeleteSuccess(fileInfoId, fileId));
      dispatch(enqueueSnackbar({message: i18n.t('file.deleted'), options: {variant: 'success'}}));
    } catch (e) {
      console.log(e);
      dispatch(fileDeleteFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// FILE SHARE
////////////////////////////////////

interface IFileShareStart {
  type: ActionTypes.FILE_SHARE_START;
}

export const fileShareStart = (): IFileShareStart => ({
  type: ActionTypes.FILE_SHARE_START,
});

interface IFileShareSuccess {
  type: ActionTypes.FILE_SHARE_SUCCESS;
}

export const fileShareSuccess = (): IFileShareSuccess => ({
  type: ActionTypes.FILE_SHARE_SUCCESS,
});

interface IFileShareFail {
  type: ActionTypes.FILE_SHARE_FAIL;
}

export const fileShareFail = (): IFileShareFail => ({
  type: ActionTypes.FILE_SHARE_FAIL,
});

export const shareFile = (fileId: string, body: IShareFileBody, closeModal: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(fileShareStart());
    const url = `${baseApiUrl}/file/${fileId}/email`;
    try {
      await axios.post(url, body);
      dispatch(fileShareSuccess());
      dispatch(enqueueSnackbar({message: i18n.t('file.sent'), options: {variant: 'success'}}));
      closeModal();
    } catch (e) {
      console.log(e);
      dispatch(fileShareFail());
      if (e.response.data.message === 'PAYLOAD_TOO_LARGE') {
        dispatch(
          enqueueSnackbar({
            message: i18n.t('file.attachment.too.many'),
            options: {variant: 'error'},
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: i18n.t('error.http.response'),
            options: {variant: 'error'},
          })
        );
      }
    }
  };
};

export type Action =
  | ReturnType<typeof fileTypeGetStart>
  | ReturnType<typeof fileTypeGetSuccess>
  | ReturnType<typeof fileTypeGetFail>
  | ReturnType<typeof filesGetStart>
  | ReturnType<typeof filesGetSuccess>
  | ReturnType<typeof filesGetFail>
  | ReturnType<typeof filePostStart>
  | ReturnType<typeof filePostSuccess>
  | ReturnType<typeof filePostFail>
  | ReturnType<typeof fileDeleteStart>
  | ReturnType<typeof fileDeleteSuccess>
  | ReturnType<typeof fileDeleteFail>
  | ReturnType<typeof fileShareStart>
  | ReturnType<typeof fileShareSuccess>
  | ReturnType<typeof fileShareFail>
  | ReturnType<typeof markerPutSuccess>;
