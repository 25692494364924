import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  button {
    margin-top: 1rem;
  }
`;

export const InputField = styled(TextField)`
  && {
    * {
      margin-top: 0;
    }
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50px;
    overflow: hidden;
    color: white !important;
    flex-basis: 200;
    margin: 0;
    margin-bottom: 0.5rem;
  }
`;

export const ButtonOutline = styled(Button)`
  && {
    width: 80%;
    padding: 0.25rem;
    border: 2px white solid;
    border-radius: 50px;
    font-size: 125%;
    color: white;
  }
`;

export const Error = styled(Typography)`
  && {
    margin-top: 1rem;
    color: white;
  }
`;

export const ButtonAlt = styled(Button)`
  && {
    color: white;
    margin-top: 1rem;
    text-decoration: underline;
  }
`;

export const ConfirmMessage = styled(Typography)`
  && {
    color: white;
    padding-bottom: 0.5rem;
  }
`;
