import React, {useEffect, useState} from 'react';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useNDVIImage} from '../../../hooks/useNDVIImage';
import {useNDWIImage} from '../../../hooks/useNDWIImage';
import {IImageWithBox} from '../../../store/reducers/ndvi';
import Layout from '../../Layout';
import MapOverlay from '../../../components/UI/MapOverlay';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import {hasUserRole} from '../../authHOC';
import {getStringDate} from '../../../helpers/dateHelpers';
import Sidebar from './Sidebar';
import TimelapsePlayer from './TimelapsePlayer';
import _ from 'lodash';

const PREVIOUS_MONTH_FIRST_DAY = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

export type TimelapseProduct = 'ndvi' | 'ndwi';

const Timelapse = ({user}: WithUserProps) => {
  const [imagesToDisplay, setImagesToDisplay] = useState<IImageWithBox[]>(null);
  const [startDate, setStartDate] = useState<Date>(PREVIOUS_MONTH_FIRST_DAY);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [selectedProduct, setSelectedProduct] = useState<TimelapseProduct>('ndvi');

  const {loadingGetFarm, loadingGetUser, activeFarm, setActiveFarm, allFarms, selectedFarm, setSelectedFarm} = useFarmSelection();
  const {loadingNdvi, ndviImages, prevNdviImages, onGetNDVI} = useNDVIImage(activeFarm, startDate, endDate, hasUserRole(user, 'PREDICTIONS'));
  const {onGetNDWI, loadingNdwi, ndwiImages, prevNdwiImages} = useNDWIImage(activeFarm, startDate, endDate, hasUserRole(user, 'PREDICTIONS'), true);
  const isLoading = loadingNdvi || loadingNdwi || loadingGetFarm || loadingGetUser;

  const handleStartDateSelection = (date: Date) => setStartDate(date);

  const handleEndDateSelection = (date: Date) => setEndDate(date);

  const handleProductChange = (product: TimelapseProduct) => {
    setSelectedProduct(product);
  };

  const handleFarmChange = (farmId: string) => {
    const farm = allFarms.find(farm => farm.farmid === farmId);
    setSelectedFarm(farm);
  };

  const handlePostRequest = () => {
    setImagesToDisplay(null);
    const isPastSearch = getStringDate(endDate) !== getStringDate(new Date());
    const isPredictionNeeded = !isPastSearch && hasUserRole(user, 'PREDICTIONS');
    if (selectedProduct === 'ndvi') {
      onGetNDVI(selectedFarm, isPredictionNeeded);
    } else {
      onGetNDWI(selectedFarm, isPredictionNeeded);
    }
    setActiveFarm(selectedFarm);
  };

  useEffect(() => {
    if (ndviImages !== prevNdviImages || ndwiImages !== prevNdwiImages) {
      if (selectedProduct === 'ndvi') {
        setImagesToDisplay(ndviImages);
      } else {
        setImagesToDisplay(ndwiImages);
      }
    }
  }, [ndviImages, ndwiImages, prevNdviImages, prevNdwiImages, selectedProduct]);

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!loadingGetFarm && !!allFarms?.length && !activeFarm?.polygons?.length && <MapPlaceholderNoPolygons activeFarm={activeFarm?.farmid} />}
      {!loadingGetFarm && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedFarm && activeFarm && imagesToDisplay && (
        <div style={{overflow: 'hidden', position: 'relative', height: 'calc(100% - 64px)'}}>
          <ContentLeftSidebar
            height="100%"
            sidebar={
              <Sidebar
                activeFarm={selectedFarm}
                allFarms={allFarms}
                startDate={startDate}
                endDate={endDate}
                selectedProduct={selectedProduct}
                handleFarmChange={handleFarmChange}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
                handleProductChange={handleProductChange}
                handlePostRequest={handlePostRequest}
              />
            }
            content={<TimelapsePlayer imagesToDisplay={_.sortBy(imagesToDisplay, o => new Date(o.date))} activeFarm={activeFarm} />}
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(Timelapse);
