import React, {Component} from 'react';
import * as actions from '../../../store/actions';
import Layout from '../../Layout';
import Map from './Map';
import SoilMoistureGraph from './SoilMoistureGraph';
import pathHOC from '../../pathHOC';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import * as turf from '@turf/turf';
import MapOverlay from '../../../components/UI/MapOverlay';
import Box from '@material-ui/core/Box';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';

class SoilMoisture extends Component {
  state = {
    dataArray: [],
    moistureSensors: [],
    center: [],
  };

  componentDidMount = () => {
    this.props.onGetSoilData('ABCShiraz');
    this.props.getMoistureSensors();
  };

  componentDidUpdate = prevProps => {
    if (this.props.moisturesensors !== prevProps.moisturesensors && this.props.moisturesensors) {
      const features = turf.featureCollection(this.props.moisturesensors.features);
      const center = turf.center(features);
      this.setState({moistureSensors: this.props.moisturesensors});
      this.setState({center: center});
    }

    if (this.props.data !== prevProps.data && !!this.props.data) {
      const headers = Object.keys(this.props.data[0]);
      headers.shift();
      headers.pop();
      const formatData = [[]];
      headers.forEach(h => {
        formatData[0].push(h);
        formatData[0].push({role: 'certainty', type: 'boolean'});
      });

      this.props.data.forEach(point => {
        const pointArray = [];
        pointArray.push(point['Date']);
        pointArray.push(parseInt(point.field1) === 6 ? 'Last probe' : null);

        headers.forEach(header => {
          pointArray.push(parseFloat(point[header]));
          const isPrediction = parseInt(point.field1) >= 7;
          pointArray.push(!isPrediction);
        });

        formatData.push(pointArray);
      });
      formatData[0].unshift({role: 'annotation', type: 'string'});
      formatData[0].unshift('Date');

      this.setState({dataArray: formatData});
    }
  };

  render() {
    const {dataArray, moistureSensors, center} = this.state;
    const {moistureloading, loadingGet} = this.props;

    return (
      <Layout>
        {!loadingGet && !moistureloading && dataArray.length === 0 && <MapPlaceholderNoData />}
        {(moistureloading || loadingGet) && <MapOverlay />}
        <Grid container alignItems="flex-start">
          <Grid item xs={12}>
            <Box m={3}>
              <Typography variant="h6">{moistureSensors && moistureSensors.properties ? moistureSensors.properties.name : 'No Name'}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box mt={4}>
              <Map handleMarkerClicked={name => this.props.onGetSoilData(name)} sensors={moistureSensors} center={center} />
            </Box>
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoilMoistureGraph dataArray={dataArray} />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.soil.data,
    moisturesensors: state.soil.moisturesensordata,
    moistureloading: state.soil.getMoistureSensors.loading,
    loadingGet: state.soil.getSoil.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSoilData: sensorName => dispatch(actions.getSoil(sensorName)),
    getMoistureSensors: () => dispatch(actions.getSoilMoistureSensors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(pathHOC(SoilMoisture));
