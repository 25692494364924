import React from 'react';
import {GroundOverlay} from '@react-google-maps/api';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import {IImageWithBox} from '../../../store/reducers/ndvi';

const containerStyle = {
  width: 'calc(100%)',
  height: '70vh',
};

interface Props extends MapProps {
  images: IImageWithBox['images'];
}

const TimelapseMap = ({images, center, polygons, bbox}: Props) => {
  return (
    <Map center={center} mapContainerStyle={containerStyle} bbox={bbox} polygons={polygons} displayRows>
      {images
        ? images.map(image => {
            const sw = new window.google.maps.LatLng(image.boundBox[1], image.boundBox[0]);
            const ne = new window.google.maps.LatLng(image.boundBox[3], image.boundBox[2]);
            const bounds = new window.google.maps.LatLngBounds(sw, ne);
            const url = image.imageUrl || image.imagePredictedUrl;
            return <GroundOverlay key={image.imageUrl || image.imagePredictedUrl} url={url} bounds={bounds} />;
          })
        : null}
      {polygons?.map((polygon, idx) => {
        return <PolygonWithInfo key={idx} polygon={polygon} />;
      })}
    </Map>
  );
};

export default TimelapseMap;
