import {CreateNoteDTO, IMarker, INoteEntity, IShareNoteBody} from '@deep-planet/api-interfaces';
import axios from 'axios';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {ISelectedFile} from '../../containers/pages/Notes/withEdit';
import ActionTypes from './actionTypes';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {getFormData, uploadFile} from './utils';
import {markerPutSuccess} from './marker';

////////////////////////////////////
// GET NOTES
////////////////////////////////////

interface INotesGetStart {
  type: ActionTypes.NOTES_GET_START;
}

export const notesGetStart = (): INotesGetStart => ({
  type: ActionTypes.NOTES_GET_START,
});

interface INotesGetSuccess {
  type: ActionTypes.NOTES_GET_SUCCESS;
  payload: INoteEntity[];
}

export const notesGetSuccess = (payload: INoteEntity[]): INotesGetSuccess => ({
  type: ActionTypes.NOTES_GET_SUCCESS,
  payload,
});

interface INotesGetFail {
  type: ActionTypes.NOTES_GET_FAIL;
}

export const notesGetFail = (): INotesGetFail => ({
  type: ActionTypes.NOTES_GET_FAIL,
});

export const getNotes = () => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(notesGetStart());
    const url = `${baseApiUrl}/notes`;
    try {
      const response = await axios.get<INoteEntity[]>(url);
      dispatch(notesGetSuccess(response.data));
    } catch (e) {
      console.log(e);
      dispatch(notesGetFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// POST NOTE
////////////////////////////////////

interface INotePostStart {
  type: ActionTypes.NOTE_POST_START;
}

export const notePostStart = (): INotePostStart => ({
  type: ActionTypes.NOTE_POST_START,
});

interface INotePostSuccess {
  type: ActionTypes.NOTE_POST_SUCCESS;
  payload: INoteEntity;
}

export const notePostSuccess = (payload: INoteEntity): INotePostSuccess => ({
  type: ActionTypes.NOTE_POST_SUCCESS,
  payload,
});

interface INotePostFail {
  type: ActionTypes.NOTE_POST_FAIL;
}

export const notePostFail = (): INotePostFail => ({
  type: ActionTypes.NOTE_POST_FAIL,
});

export const createNote = (params: CreateNoteDTO, files: ISelectedFile[], closeModal: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(notePostStart());
    const data = await Promise.all(
      files.map(async file =>
        getFormData(
          {
            name: file.name,
            contentType: file.type,
          },
          file,
          `${baseApiUrl}/note/upload-url`
        )
      )
    );
    await Promise.all(data.map(({bucketUrl, formData}) => uploadFile(bucketUrl, formData)));
    params.attachments = data.map(({name, url, size, uploadedAt}) => ({name, url, size, uploadedAt}));
    const url = `${baseApiUrl}/note`;
    try {
      const response = await axios.post<INoteEntity>(url, params);
      dispatch(notePostSuccess(response.data));
      closeModal();
      dispatch(enqueueSnackbar({message: i18n.t('note.saved'), options: {variant: 'success'}}));
    } catch (e) {
      console.log(e);
      dispatch(notePostFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// PUT NOTE
////////////////////////////////////

interface INotePutStart {
  type: ActionTypes.NOTE_UPDATE_START;
}

export const notePutStart = (): INotePutStart => ({
  type: ActionTypes.NOTE_UPDATE_START,
});

interface INotePutSuccess {
  type: ActionTypes.NOTE_UPDATE_SUCCESS;
  payload: INoteEntity;
}

export const notePutSuccess = (payload: INoteEntity): INotePutSuccess => ({
  type: ActionTypes.NOTE_UPDATE_SUCCESS,
  payload,
});

interface INotePutFail {
  type: ActionTypes.NOTE_UPDATE_FAIL;
}

export const notePutFail = (): INotePutFail => ({
  type: ActionTypes.NOTE_UPDATE_FAIL,
});

export const updateNote = (id: string, params: CreateNoteDTO, files: ISelectedFile[], markerNote?: IMarker, isMarkerNote?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(notePutStart());
    const url = `${baseApiUrl}/note/${id}`;
    try {
      params = {...params, attachments: params.attachments.map(a => ({...a, size: Number(a.size)}))};
      const data = await Promise.all(
        files.map(async file =>
          getFormData(
            {
              name: file.name,
              contentType: file.type,
            },
            file,
            `${baseApiUrl}/note/upload-url`
          )
        )
      );
      await Promise.all(data.map(({bucketUrl, formData}) => uploadFile(bucketUrl, formData)));
      params.attachments = data.map(({name, url, size, uploadedAt}) => ({name, url, size, uploadedAt}));
      const response = await axios.put<INoteEntity>(url, params);
      //@ts-ignore
      const message = response.data?.message;
      if (message) {
        dispatch(notePutFail());
        dispatch(enqueueSnackbar({message: i18n.t(message), options: {variant: 'error'}}));
      } else {
        dispatch(notePutSuccess(response.data));
        // when updated from marker-note - update note and attachments
        isMarkerNote && dispatch(markerPutSuccess({...markerNote, note: response.data}));
        dispatch(enqueueSnackbar({message: i18n.t('note.updated'), options: {variant: 'success'}}));
      }
    } catch (e) {
      console.log(e);
      dispatch(notePutFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// DELETE NOTE
////////////////////////////////////

interface INoteDeleteStart {
  type: ActionTypes.NOTE_DELETE_START;
}

export const noteDeleteStart = (): INoteDeleteStart => ({
  type: ActionTypes.NOTE_DELETE_START,
});

interface INoteDeleteSuccess {
  type: ActionTypes.NOTE_DELETE_SUCCESS;
  payload: string;
}

export const noteDeleteSuccess = (noteId: string): INoteDeleteSuccess => ({
  type: ActionTypes.NOTE_DELETE_SUCCESS,
  payload: noteId,
});

interface INoteDeleteFail {
  type: ActionTypes.NOTE_DELETE_FAIL;
}

export const noteDeleteFail = (): INoteDeleteFail => ({
  type: ActionTypes.NOTE_DELETE_FAIL,
});

export const deleteNote = (noteId: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(noteDeleteStart());
    const url = `${baseApiUrl}/note/${noteId}`;
    try {
      await axios.delete(url);
      dispatch(noteDeleteSuccess(noteId));
      dispatch(enqueueSnackbar({message: i18n.t('note.deleted'), options: {variant: 'success'}}));
    } catch (e) {
      console.log(e);
      dispatch(noteDeleteFail());
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// DELETE NOTE ATTACHMENT
////////////////////////////////////

interface INoteAttachment {
  noteId: string;
  attachmentId: string;
}

interface INoteAttachmentDeleteStart {
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_START;
  payload: INoteAttachment;
}

export const noteAttachmentDeleteStart = (payload: INoteAttachment): INoteAttachmentDeleteStart => ({
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_START,
  payload,
});

interface INoteAttachmentDeleteSuccess {
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_SUCCESS;
  payload: INoteAttachment;
}

export const noteAttachmentDeleteSuccess = (payload: INoteAttachment): INoteAttachmentDeleteSuccess => ({
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_SUCCESS,
  payload,
});

interface INoteAttachmentDeleteFail {
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_FAIL;
  payload: INoteAttachment;
}

export const noteAttachmentDeleteFail = (payload: INoteAttachment): INoteAttachmentDeleteFail => ({
  type: ActionTypes.NOTE_ATTACHMENT_DELETE_FAIL,
  payload,
});

export const deleteNoteAttachment = (noteId: string, attachmentId: string, markerNote?: IMarker, isMarkerNote?: boolean) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(noteAttachmentDeleteStart({noteId, attachmentId}));
    const url = `${baseApiUrl}/note/${noteId}/attachment/${attachmentId}`;
    try {
      await axios.delete(url);
      // @ts-ignore
      isMarkerNote && dispatch(markerPutSuccess({...markerNote, note: {...markerNote.note, attachments: markerNote.note.attachments.filter(a => a.id !== attachmentId)}}));
      dispatch(noteAttachmentDeleteSuccess({noteId, attachmentId}));
      dispatch(
        enqueueSnackbar({
          message: i18n.t('note.attachment.deleted'),
          options: {variant: 'success'},
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(noteAttachmentDeleteFail({noteId, attachmentId}));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

////////////////////////////////////
// NOTE SHARE
////////////////////////////////////

interface INoteShareStart {
  type: ActionTypes.NOTE_SHARE_START;
}

export const noteShareStart = (): INoteShareStart => ({
  type: ActionTypes.NOTE_SHARE_START,
});

interface INoteShareSuccess {
  type: ActionTypes.NOTE_SHARE_SUCCESS;
}

export const noteShareSuccess = (): INoteShareSuccess => ({
  type: ActionTypes.NOTE_SHARE_SUCCESS,
});

interface INoteShareFail {
  type: ActionTypes.NOTE_SHARE_FAIL;
}

export const noteShareFail = (): INoteShareFail => ({
  type: ActionTypes.NOTE_SHARE_FAIL,
});

export const shareNote = (noteId: string, body: IShareNoteBody, closeModal: () => void) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(noteShareStart());
    const url = `${baseApiUrl}/note/${noteId}/email`;
    try {
      await axios.post(url, body);
      dispatch(noteShareSuccess());
      dispatch(enqueueSnackbar({message: i18n.t('note.sent'), options: {variant: 'success'}}));
      closeModal();
    } catch (e) {
      console.log(e);
      dispatch(noteShareFail());
      if (e.response.data.message === 'PAYLOAD_TOO_LARGE') {
        dispatch(
          enqueueSnackbar({
            message: i18n.t('note.attachment.too.many'),
            options: {variant: 'error'},
          })
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: i18n.t('error.http.response'),
            options: {variant: 'error'},
          })
        );
      }
    }
  };
};

export type Action =
  | ReturnType<typeof notesGetStart>
  | ReturnType<typeof notesGetSuccess>
  | ReturnType<typeof notesGetFail>
  | ReturnType<typeof notePostStart>
  | ReturnType<typeof notePostSuccess>
  | ReturnType<typeof notePostFail>
  | ReturnType<typeof notePutStart>
  | ReturnType<typeof notePutSuccess>
  | ReturnType<typeof notePutFail>
  | ReturnType<typeof noteDeleteStart>
  | ReturnType<typeof noteDeleteSuccess>
  | ReturnType<typeof noteDeleteFail>
  | ReturnType<typeof noteAttachmentDeleteStart>
  | ReturnType<typeof noteAttachmentDeleteSuccess>
  | ReturnType<typeof noteAttachmentDeleteFail>
  | ReturnType<typeof noteShareStart>
  | ReturnType<typeof noteShareSuccess>
  | ReturnType<typeof noteShareFail>
  | ReturnType<typeof markerPutSuccess>;
