import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {Form, InputField, ButtonOutline, Error} from '../../UI/Form';
import {withTranslation} from 'react-i18next';

class NewPasswordRequiredForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: props.error,
      password1: '',
      password2: '',
      showPassword: false,
      passError: '',
    };
  }

  validatePasswordMatch = () => {
    const {t} = this.props;
    let isValid = true;
    if (this.state.password1 === this.state.password2) return isValid;
    this.setState({passError: t('forms.password.not.match')});
    return (isValid = false);
  };

  onSubmit = event => {
    event.preventDefault();
    if (this.validatePasswordMatch()) this.props.onSubmit(this.state.password1);
  };

  onChange = e => {
    this.setState({[e.target.name]: e.target.value});
  };

  handleClickShowPassword = () => {
    this.setState(state => ({showPassword: !state.showPassword}));
  };

  render() {
    const {password1, password2, showPassword, passError} = this.state;
    const {error, t} = this.props;

    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <InputField
            id="password1"
            name="password1"
            variant="outlined"
            placeholder={t('forms.password')}
            type={showPassword ? 'text' : 'password'}
            value={password1}
            onChange={this.onChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label={t('forms.password.visibility')} onClick={this.handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <InputField
            id="password2"
            name="password2"
            variant="outlined"
            placeholder={t('forms.password.repeat')}
            type={showPassword ? 'text' : 'password'}
            value={password2}
            onChange={this.onChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label={t('forms.password.visibility')} onClick={this.handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <ButtonOutline type="submit">{t('forms.password.new')}</ButtonOutline>
        </Form>
        {passError && <Error variant="subtitle1">{passError}</Error>}
        {error && !error.includes('Password must have symbol characters') && <Error variant="subtitle1">{error}</Error>}
        {error && error.includes('Password must have symbol characters') && (
          <>
            <Error variant="subtitle1">{`${error} from the following list:`}</Error>
            <Error variant="subtitle1">{`^ $ * . [ ] { } ( ) ? " ! @ # % & / \\\\ , > < \\' : ; | _ ~ \``}</Error>
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(NewPasswordRequiredForm);
