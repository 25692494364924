import React from 'react';
import BasePage from '../index';
import SingleColorLegend from '../../../../components/UI/Legend/SingleColorLegend';

const OvergrazedArea = () => {
  return (
    <BasePage
      product={'OvergrazedArea'}
      infoSecondaryTitle={'Overgrazed Area (m^2)'}
      soilSignalValueName={'Overgrazed Area'}
      legend={<SingleColorLegend legends={[{color: '#f60b00', label: 'Overgrazed Area'}]} />}
    />
  );
};

export default OvergrazedArea;
