import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/note';
import {updateObject} from '../utility';
import {INoteEntity} from '@deep-planet/api-interfaces';

export const notePostSelector = (state: NoteState) => state.notePost;
export const notesSelector = (state: NoteState) => state.notes;
export const notesGetSelector = (state: NoteState) => state.notesGet;
export const noteDeleteSelector = (state: NoteState) => state.noteDelete;
export const notePutSelector = (state: NoteState) => state.notePut;
export const noteAttachmentDeleteSelector = (state: NoteState) => state.noteAttachmentDelete;
export const noteAttachmentsAddSelector = (state: NoteState) => state.noteAttachmentsAdd;
export const noteShareSelector = (state: NoteState) => state.noteShare;

export interface NoteState {
  notes: INoteEntity[];
  notesGet: {
    loading: boolean;
    error: boolean;
  };
  notePost: {
    loading: boolean;
    error: boolean;
  };
  notePut: {
    loading: boolean;
    error: boolean;
  };
  noteDelete: {
    loading: boolean;
    error: boolean;
  };
  noteShare: {
    loading: boolean;
    error: boolean;
  };
  noteAttachmentDelete: {
    noteId: string;
    attachmentId: string;
  }[];
  noteAttachmentsAdd: {
    loading: boolean;
    error: boolean;
  };
}

const initialState: NoteState = {
  notes: [],
  notesGet: {
    loading: false,
    error: false,
  },
  notePost: {
    loading: false,
    error: false,
  },
  notePut: {
    loading: false,
    error: false,
  },
  noteDelete: {
    loading: false,
    error: false,
  },
  noteShare: {
    loading: false,
    error: false,
  },
  noteAttachmentsAdd: {
    loading: false,
    error: false,
  },
  noteAttachmentDelete: [],
};

const reducer = (state: NoteState = initialState, action: Action): NoteState => {
  switch (action.type) {
    case ActionTypes.NOTES_GET_START:
      return updateObject(state, {
        notesGet: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.NOTES_GET_SUCCESS:
      return updateObject(state, {
        notes: new Array(...action.payload),
        notesGet: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.NOTES_GET_FAIL:
      return updateObject(state, {
        notesGet: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.NOTE_POST_START:
      return updateObject(state, {
        notePost: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.NOTE_POST_SUCCESS:
      return updateObject(state, {
        notes: [action.payload, ...state.notes],
        notePost: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.NOTE_POST_FAIL:
      return updateObject(state, {
        notePost: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.NOTE_UPDATE_START:
      return updateObject(state, {
        notePut: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.NOTE_UPDATE_SUCCESS:
      return updateObject(state, {
        notes: state.notes.map(item => {
          if (item.id !== action.payload.id) return item;
          return action.payload;
        }),
        notePut: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.NOTE_UPDATE_FAIL:
      return updateObject(state, {
        notePut: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.NOTE_DELETE_START:
      return updateObject(state, {
        noteDelete: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.NOTE_DELETE_SUCCESS:
      return updateObject(state, {
        notes: state.notes.filter(note => note.id !== action.payload),
        noteDelete: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.NOTE_DELETE_FAIL:
      return updateObject(state, {
        noteDelete: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.NOTE_ATTACHMENT_DELETE_START:
      return updateObject(state, {
        noteAttachmentDelete: [...state.noteAttachmentDelete, {noteId: action.payload.noteId, attachmentId: action.payload.attachmentId}],
      });
    case ActionTypes.NOTE_ATTACHMENT_DELETE_SUCCESS:
      return updateObject(state, {
        noteAttachmentDelete: state.noteAttachmentDelete.filter(({noteId, attachmentId}) => !(noteId === action.payload.noteId && attachmentId === action.payload.attachmentId)),
        notes: state.notes.map(note => {
          if (note.id !== action.payload.noteId) return note;
          return {...note, attachments: note.attachments.filter(({id}) => id !== action.payload.attachmentId)};
        }),
      });
    case ActionTypes.NOTE_ATTACHMENT_DELETE_FAIL:
      return updateObject(state, {
        noteAttachmentDelete: state.noteAttachmentDelete.filter(({noteId, attachmentId}) => !(noteId === action.payload.noteId && attachmentId === action.payload.attachmentId)),
      });
    case ActionTypes.NOTE_SHARE_START:
      return updateObject(state, {
        noteShare: {
          loading: true,
          error: false,
        },
      });
    case ActionTypes.NOTE_SHARE_SUCCESS:
      return updateObject(state, {
        noteShare: {
          loading: false,
          error: false,
        },
      });
    case ActionTypes.NOTE_SHARE_FAIL:
      return updateObject(state, {
        noteShare: {
          loading: false,
          error: true,
        },
      });
    default:
      return state;
  }
};

export default reducer;
