import {IUpdateUser} from '@deep-planet/api-interfaces';
import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import * as actions from '../../../store/actions';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Layout from '../../Layout';
import ChangePassword from '../../../components/Cognito/ChangePassword';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next';
import {fileNotificationSelector, noteNotificationSelector, putUserSelector} from '../../../store/selectors';
import MapOverlay from '../../../components/UI/MapOverlay';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import Notifications from './Notifications';
import PersonalInformation from './PersonalInformation';
import Preferences from './Preferences';
import {PERSONAL, NOTIFICATIONS, ACCOUNT, PREFERENCES} from '../../../config/const';

const Container = styled.div`
  position: relative;
  overflow: hidden;
  height: calc(100% - 64px);
`;

const Card = styled(Paper)`
  && {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 64px);
  }
`;

const DividerGap = styled(Divider)`
  && {
    margin: 1rem 0;
  }
`;

export type SubPage = 'PERSONAL' | 'NOTIFICATIONS' | 'ACCOUNT' | 'PREFERENCES';

const MyAccount = () => {
  const [selectedSubPage, setSelectedSubPage] = useState<SubPage>(PERSONAL);
  const {t} = useTranslation();
  const {allFarms, loadingGetUser, userDetails, loadingGetFarm} = useFarmSelection();
  const {loading: putUserDetailsLoading} = useSelector(putUserSelector);
  const noteNotificationPreference = useSelector(noteNotificationSelector);
  const fileNotificationPreference = useSelector(fileNotificationSelector);
  const dispatch = useDispatch();

  const onPutUserDetails = (payload: IUpdateUser) => {
    dispatch(actions.putUser(payload));
  };

  const handleSubpageSelect = (selected: SubPage) => {
    setSelectedSubPage(selected);
  };

  useLayoutEffect(() => {
    const subPage = new URLSearchParams(window.location.search).get('selected-page');
    if (subPage && subPage.toUpperCase() === NOTIFICATIONS) {
      handleSubpageSelect(NOTIFICATIONS);
    }
  }, []);

  return (
    <Layout>
      {loadingGetUser || loadingGetFarm ? (
        <MapOverlay />
      ) : (
        <Container>
          <ContentLeftSidebar
            content={
              <Grid container>
                <Grid item xs={12}>
                  {selectedSubPage === PERSONAL && (
                    <Card>
                      <Typography align="center" variant="h5" component="h3">
                        {t('account.personal.information')}
                      </Typography>
                      <DividerGap />
                      <PersonalInformation
                        allFarms={allFarms}
                        userDetails={userDetails}
                        onPutUserDetails={onPutUserDetails}
                        putUserDetailsLoading={putUserDetailsLoading}
                        getUserDetailsLoading={loadingGetUser}
                      />
                    </Card>
                  )}
                  {selectedSubPage === NOTIFICATIONS && (
                    <Card>
                      <Typography align="center" variant="h5" component="h3">
                        {t('account.notifications')}
                      </Typography>
                      <DividerGap />
                      <Notifications noteNotificationPreference={noteNotificationPreference} fileNotificationPreference={fileNotificationPreference} />
                    </Card>
                  )}
                  {selectedSubPage === ACCOUNT && (
                    <Card>
                      <Typography align="center" variant="h5" component="h3">
                        {t('account.privacy')}
                      </Typography>
                      <DividerGap />
                      <ChangePassword />
                    </Card>
                  )}
                  {selectedSubPage === PREFERENCES && (
                    <Card>
                      <Typography align="center" variant="h5" component="h3">
                        {t('account.preferences')}
                      </Typography>
                      <DividerGap />
                      <Preferences />
                    </Card>
                  )}
                </Grid>
              </Grid>
            }
            sidebar={<Sidebar handleSubpageSelect={handleSubpageSelect} />}
          />
        </Container>
      )}
    </Layout>
  );
};

export default MyAccount;
