import {IFarmEntity, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {MATURITYCURVE, baseMaturityCurveUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';
import UploadFile from '../../../../../Settings/CreateFarm/CreateFarmStepper/UploadFile';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
}

export const MaturityCurve = ({farm, organization}: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleBack = () => {
    //
  };

  const handleUpload = async acceptedFiles => {
    const names = acceptedFiles.map(file => file.name);
    const isAnyOfItCSV = names.some(name => name.endsWith('.csv'));
    if (acceptedFiles.length === 1 && isAnyOfItCSV) {
      try {
        setLoading(true);
        const formData = new FormData();
        for (const file of acceptedFiles) {
          formData.append('files', file);
        }
        formData.append('organizationId', organization.id);
        await axios.post(`${baseMaturityCurveUrl}/predicted-maturity`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
        dispatch(enqueueSnackbar({message: 'Maturity curves have been generated', options: {variant: 'success'}}));
      } catch (e) {
        const msg = e?.response?.data?.message || e?.response?.data?.message;
        dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
        console.log(e);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(enqueueSnackbar({message: t('setting.create.farm.step.uploading.error.not.valid'), options: {variant: 'error'}}));
    }
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            <UploadFile handleBack={handleBack} handleUpload={handleUpload} feature={MATURITYCURVE} featureOrganization={organization} loadingFileUpload={loading} />
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
