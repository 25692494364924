import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../../../utilities/theme';

const Overlay = styled.div<{height?: string; width?: string; positon?: string}>`
  z-index: 1000;
  height: ${p => (p.height ? p.height : '100%')};
  width: ${p => (p.width ? p.width : '100%')};
  position: ${p => (p.positon ? p.positon : 'absolute')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${() => theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled(CircularProgress)`
  && {
    color: white;
  }
`;

const MapOverlay = ({width, height, position}: {width?: string; height?: string; position?: string}) => {
  return (
    <Overlay width={width} height={height} positon={position}>
      <Spinner size={100} />
    </Overlay>
  );
};

export default MapOverlay;
