import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {IFarm} from '../../../store/reducers/farm';
import {parseFarm} from '../../../utilities/parseFarm';

/**
 * get the farms which belongs to an  organization where the user is MEMBER/VIEWER
 * @param organizations
 * @param userName
 */

export const ALL = 'ALL';
export const NOTE_PRIVATE = 'PRIVATE';
export const NOTE_PUBLIC = 'PUBLIC';

export const getUserFarms = (organizations: IOrganizationEntity[], userName: string, organizationId?: string): IFarm[] => {
  if (organizationId) return getOrgUserFarms(organizations, organizationId, userName);
  if (!organizations) return null;
  return organizations
    .filter(({organizationToUsers}) => organizationToUsers.some(({user}) => user.username === userName))
    .map(({organizationToFarms}) =>
      organizationToFarms.map(({farm}) => {
        return parseFarm(farm);
      })
    )
    .flat();
};

export const getOrgUserFarms = (organizations: IOrganizationEntity[], organizationId: string, userName?: string): IFarm[] => {
  if (!organizations) return null;
  if (userName && userName !== 'ALL')
    return organizations // user farms
      .filter(org => org.id === organizationId)
      .filter(({organizationToUsers}) => organizationToUsers.some(({user}) => user.username === userName))
      .map(({organizationToFarms}) =>
        organizationToFarms.map(({farm}) => {
          return parseFarm(farm);
        })
      )
      .flat();
  else
    return organizations // all farms of organization - loads for Public note
      .filter(org => org.id === organizationId)
      .map(({organizationToFarms}) =>
        organizationToFarms.map(({farm}) => {
          return parseFarm(farm);
        })
      )
      .flat();
};

export const getOrganizationIdForFarm = (organizations: IOrganizationEntity[], farmId: string): string => {
  if (!organizations) return null;
  const orgIds = organizations.filter(({organizationToFarms}) => organizationToFarms && organizationToFarms?.some(otf => otf.farm.id === farmId));
  if (orgIds?.length) return orgIds[0].id;
  else return null;
};

export const getOrganizationFarms = (organizations: IOrganizationEntity[], organizationId: string): IFarm[] => {
  return organizations
    .find(org => org.id === organizationId)
    .organizationToFarms.map(({farm}) => {
      return parseFarm(farm);
    })
    .flat();
};
