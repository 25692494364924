import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import i18n from '../../../../i18n';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {getCurrentWeather, getForecastWeather} from '../../../store/actions';
import {currentWeatherSelector, forecastWeatherSelector, getCurrentWeatherSelector, getForecastWeatherSelector} from '../../../store/selectors';
import Layout from '../../Layout';
import Temperature from './Temperature';
import WeatherForecast from './WeatherForecast';
import {Typography, Grid} from '@material-ui/core';
import MapOverlay from '../../../components/UI/MapOverlay';
import Map from './Map';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';

const TitleBox = styled.div`
  margin-bottom: 1rem;
  display: flex;
`;

const Container = styled.div`
  min-height: calc(100% - 49px - 1rem);
  max-height: 100%;
`;

const Relative = styled.div`
  position: relative;
  min-width: 100%;
  min-height: calc(100vh - 64px);
`;

const Flex = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row;
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;

const TooltipBox = styled.div`
  display: flex;
  margin-right: 5px;
  margin-top: 1px;
`;

const Meta = styled.div`
  margin-right: 1rem;
  margin-top: 0;
  @media screen and (max-width: 700px) {
    margin-right: 0;
    margin-top: 1rem;
  }
`;

const WeatherPage = () => {
  const {loadingGetUser, loadingGetFarm, activeFarm, allFarms} = useFarmSelection();
  const {HelpWeather} = useTooltipText();
  const dispatch = useDispatch();

  const currentWeather = useSelector(currentWeatherSelector);
  const forecastWeather = useSelector(forecastWeatherSelector);
  const {loading: loadingWeather} = useSelector(getCurrentWeatherSelector);
  const {loading: loadingForecast} = useSelector(getForecastWeatherSelector);

  useEffect(() => {
    if (activeFarm?.farmCenter) {
      dispatch(getCurrentWeather(activeFarm.farmCenter, i18n.language || window.navigator.language));
    }
  }, [activeFarm, dispatch]);

  useEffect(() => {
    if (activeFarm?.farmCenter) {
      dispatch(getForecastWeather(activeFarm.farmCenter, i18n.language || window.navigator.language));
    }
  }, [activeFarm, dispatch]);

  const isLoading = loadingWeather || loadingForecast || loadingGetUser || loadingGetFarm;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && (
        <Relative>
          <div style={{flexGrow: 1, padding: '1rem'}}>
            <Grid container alignContent="stretch" alignItems="stretch" spacing={0} style={{maxWidth: '100% !important'}}>
              <Grid item xs={12}>
                <Flex>
                  <Meta>
                    <TitleBox>
                      <TooltipBox>
                        <HelpTooltip text={HelpWeather} icon />
                      </TooltipBox>
                      <Typography variant="h5">{!!currentWeather && handleDateFormat(currentWeather.epochTime * 1000)}</Typography>
                    </TitleBox>
                    <Container>
                      <Temperature currentWeather={currentWeather} />
                    </Container>
                  </Meta>
                  <Map polygons={activeFarm?.polygons} center={activeFarm?.farmCenter} bbox={activeFarm?.bbox} />
                </Flex>
              </Grid>
              <Grid item xs={12}>
                <WeatherForecast forecastWeather={forecastWeather} />
              </Grid>
            </Grid>
          </div>
        </Relative>
      )}
    </Layout>
  );
};

export default WeatherPage;
