import React from 'react';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import Polygon from './Polygon';

import {MAP, HALF, FULL} from '../../../config/const';

interface SeasonIndicesMapProps extends MapProps {
  selectedPolygon: polygonInfoWindow;
  polygonClicked: boolean;
  handleOnClick: (polygon: IPolygonEntity) => void;
  screenMode: string;
  smallScreen: boolean;
  showOverlay: () => void;
}

const SeasonIndicesMap = ({bbox, center, polygons, selectedPolygon, polygonClicked, handleOnClick, screenMode, smallScreen, showOverlay}: SeasonIndicesMapProps) => {
  const containerStyle = {
    width: smallScreen ? '100%' : (screenMode === HALF && '50%') || (screenMode === FULL && '0%') || (screenMode === MAP && '95%'),
    height: smallScreen ? (screenMode === MAP && 'calc(100vh - 190px)') || (screenMode === FULL && '0vh') : 'calc(100vh - 130px)',
    transition: smallScreen ? ' height 0.5s ease' : 'width 0.2s ease',
  };

  return (
    <Map center={center} mapContainerStyle={containerStyle} bbox={bbox} polygons={polygons} selectedPolygon={selectedPolygon} polygonClicked={polygonClicked} displayRows showBlockName={false}>
      {polygons?.map(polygon => {
        return <Polygon key={polygon.id} polygon={polygon} selectedPolygon={selectedPolygon} handleOnClick={handleOnClick} showOverlay={showOverlay} />;
      })}
    </Map>
  );
};

export default SeasonIndicesMap;
