import {IMarker, INoteEntity} from '@deep-planet/api-interfaces';
import {Column, CreateDateColumn, Entity, Index, JoinColumn, ManyToOne, OneToOne, PrimaryGeneratedColumn} from 'typeorm';
import {FarmEntity} from '../farm/farm.entity';
import {MarkerColorEntity} from './markerColor/marker.color.entity';
import {UserEntity} from '../user/user.entity';
import {NoteEntity} from '../note/note.entity';
import {ColumnNumericTransformer} from '../../numeric.transformet';
import FeatureEntity from '../feature/feature.entity';

@Entity('marker')
@Index(['feature', 'farm', 'color', 'user', 'latitude', 'longitude', 'date'], {unique: true})
export class MarkerEntity implements IMarker {
  @Index({unique: true})
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @ManyToOne('FeatureEntity')
  feature: FeatureEntity;

  @ManyToOne('FarmEntity', () => FarmEntity, {onDelete: 'CASCADE'})
  farm: FarmEntity;

  @ManyToOne('UserEntity')
  user: UserEntity;

  @ManyToOne('MarkerColorEntity')
  color: MarkerColorEntity;

  @OneToOne('NoteEntity', {cascade: true})
  @JoinColumn()
  note: INoteEntity;

  @Column({type: 'numeric', default: 0, precision: 20, scale: 15, nullable: false, transformer: new ColumnNumericTransformer()})
  latitude: number;

  @Column({type: 'numeric', default: 0, precision: 20, scale: 15, nullable: false, transformer: new ColumnNumericTransformer()})
  longitude: number;

  @CreateDateColumn({type: 'date'})
  date: Date;

  @CreateDateColumn({type: 'timestamp without time zone'})
  updatedAt: string;
}
