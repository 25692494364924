import Layout from '../../../Layout';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
import {baseApiUrl} from '../../../../config/const';
import {useHttp} from '../../../../hooks/http';

const Reports = () => {
  const [isLoading, url] = useHttp<string>(`${baseApiUrl}/userlogs`);
  return (
    <Layout>
      <Box p={2}>
        <Typography variant="h6" align="center" gutterBottom>
          Reports
        </Typography>
        <Box mt={2}>
          {!isLoading ? (
            <a href={url} target="_blank" rel="noopener noreferrer" download>
              {'User log report'}
            </a>
          ) : (
            <Typography>loading...</Typography>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default Reports;
