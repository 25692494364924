import React from 'react';
import BasePage from '../index';
import ColorSchemeLegend from '../../../../components/UI/Legend/ColorSchemeLegend';
import {primaryColorGradient} from '../../../../utilities/theme';

const ForestBiomass = () => {
  return (
    <BasePage
      product={'ForestBiomass'}
      infoSecondaryTitle={'Forest Biomass (kg/ha)'}
      soilSignalValueName={'Forest Biomass'}
      legend={<ColorSchemeLegend title="Forest Biomass (kg/ha)" scale={['1150', '1200+']} gradient={primaryColorGradient} />}
    />
  );
};

export default ForestBiomass;
