import {RootState} from '../reducers';
import * as soilOrganicCarbon from '../reducers/soilOrganicCarbon';

export const soilOrganicCarbonFarmsSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonFarmsSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonImagesSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonImagesSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonActiveFarmSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonActiveFarmSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonActiveFarmImageSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonActiveFarmImageSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonFarmGetSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonFarmGetSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonImagesGetSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.getSoilOrganicCarbonImagesGetSelector(state.soilOrganicCarbon);
export const soilOrganicCarbonSelectedDateSelector = (state: Pick<RootState, 'soilOrganicCarbon'>) => soilOrganicCarbon.soilOrganicCarbonSelectedDateSelector(state.soilOrganicCarbon);
