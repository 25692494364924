import React from 'react';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';

const containerStyle = {
  width: 'calc(100%)',
  height: '45vh',
};

const WeatherMap = ({center, polygons, bbox}: MapProps) => {
  return (
    <Map center={center} mapContainerStyle={containerStyle} bbox={bbox} polygons={polygons}>
      {polygons?.map((polygon, idx) => {
        return <PolygonWithInfo key={idx} polygon={polygon} />;
      })}
    </Map>
  );
};

export default WeatherMap;
