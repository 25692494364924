import React from 'react';
import _ from 'lodash';
import {DrawingManager, GoogleMap, StandaloneSearchBox} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import PolygonDrawn from './PolygonDrawn';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 112px)',
};

interface IRefs {
  searchBox: google.maps.places.SearchBox;
  map: google.maps.Map;
}

interface Props {
  polygons: any[];
  drawingActive: boolean;
  handlePassPolyCoords: (polygon: google.maps.Polygon, coordinates: number[][]) => void;
}

interface IState {
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  bounds: google.maps.LatLngBounds;
  map: google.maps.Map;
  markers: any[];
  onBoundsChanged: () => void;
  onMapMounted: (ref: google.maps.Map<Element>) => void;
  onSearchBoxMounted: (ref: google.maps.places.SearchBox) => void;
  onPlacesChanged: () => void;
}

interface WrappedProps {
  polygons: any[];
  drawingActive: boolean;
  handlePassPolyCoords: (polygon: google.maps.Polygon, coordinates: number[][]) => void;
  center: google.maps.LatLng | google.maps.LatLngLiteral;
  bounds: google.maps.LatLngBounds;
  map: google.maps.Map;
  markers: any[];
  onBoundsChanged: () => void;
  onMapMounted: (ref: google.maps.Map<Element>) => void;
  onSearchBoxMounted: (ref: google.maps.places.SearchBox) => void;
  onPlacesChanged: () => void;
}

function withLifecycle(WrappedComponent: React.FunctionComponent<WrappedProps>) {
  return class extends React.Component<Props, IState> {
    componentDidMount() {
      const refs: IRefs = {
        searchBox: null,
        map: null,
      };

      this.setState({
        bounds: null,
        center: {lat: -32.428355117829035, lng: 150.65238741947684},
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            map: refs.map,
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          });
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));

          // Bear this in mind when you start messing around with centering of map
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
          });
          // refs.map.fitBounds(bounds);
        },
      });
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />;
    }
  };
}

const CreateFarmMap = ({center, drawingActive, onBoundsChanged, onMapMounted, bounds, handlePassPolyCoords, onPlacesChanged, onSearchBoxMounted, polygons}: WrappedProps) => {
  const {t} = useTranslation();
  return (
    <GoogleMap onLoad={onMapMounted} zoom={15} center={center} onIdle={onBoundsChanged} mapTypeId={window.google.maps.MapTypeId.HYBRID} mapContainerStyle={containerStyle}>
      <StandaloneSearchBox onLoad={onSearchBoxMounted} bounds={bounds} onPlacesChanged={onPlacesChanged}>
        <input
          type="text"
          placeholder={t('setting.create.farm.search')}
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: 'absolute',
            left: '10px',
            marginTop: '55px',
          }}
        />
      </StandaloneSearchBox>

      {!!polygons &&
        polygons.map(polygon => {
          return <PolygonDrawn key={polygon.polygonid} polygon={polygon} />;
        })}
      {drawingActive && (
        <DrawingManager
          drawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
          }}
          onPolygonComplete={polygon => {
            // identify the polygon coordinates & mutate them in a format that agro monitoring API expects
            const coordinates: number[][] = [];
            const vertices = polygon.getPath();
            for (let i = 0; i < vertices.getLength(); i++) {
              const xy = vertices.getAt(i);
              coordinates.push([xy.lng(), xy.lat()]);
            }
            const xyFirst = vertices.getAt(0);
            coordinates.push([xyFirst.lng(), xyFirst.lat()]);
            // Pass the new polygon's coordinates up to parent component
            handlePassPolyCoords(polygon, coordinates);
          }}
        />
      )}
    </GoogleMap>
  );
};

export default withLifecycle(CreateFarmMap);
