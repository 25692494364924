import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import {Logout} from '@deep-planet/react-cognito';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import {useTranslation} from 'react-i18next';
import {commonRoutes} from '../../../utilities/routes';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {useHistory} from 'react-router-dom';

const LogoutMenuItem = ({handleLogOut}: {handleLogOut: () => void}) => {
  const {t} = useTranslation();
  const logout = () => {
    handleLogOut();
    window.localStorage.clear();
  };

  return (
    <MenuItem onClick={logout}>
      <Typography variant="body1">{t('navigation.logout')}</Typography>
    </MenuItem>
  );
};

const UserName = styled(Typography)`
  && {
    color: white;
    text-transform: none;
  }
`;

const AccountButton = ({user}: WithUserProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const history = useHistory();

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleClick = () => {
    history.push(commonRoutes.MY_ACCOUNT.slug);
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    if (user.signOut) {
      user.signOut();
      window.location.href = '/';
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Button aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={handleMenu} color="inherit">
        <Hidden mdUp implementation="css">
          <Box mt={1}>
            <AccountCircle />
          </Box>
        </Hidden>
        <Hidden smDown implementation="css">
          <UserName>{t('navigation.welcome', {username: user.username})}</UserName>
        </Hidden>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClick}>
          <Typography variant="body1">{t(commonRoutes.MY_ACCOUNT.title)}</Typography>
        </MenuItem>
        <Logout>
          <LogoutMenuItem handleLogOut={handleLogOut} />
        </Logout>
      </Menu>
    </div>
  );
};

export default withUser(AccountButton);
