import React, {useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {Box, Button} from '@material-ui/core';
import {CreateUserDTO, IAuthGroup, IAuthUser, IOrganizationEntity} from '@deep-planet/api-interfaces';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import MapOverlay from '../../../../components/UI/MapOverlay';
import Layout from '../../../Layout';
import {useHttp} from '../../../../hooks/http';
import {baseApiUrl} from '../../../../config/const';
import * as actions from '../../../../store/actions';
import {CreateUser} from './CreateUser';
import {ManageUser} from './ManageUser';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100% - 112px);
  justify-content: center;
`;

const Mode = {
  CREATION: 'creation',
  MANAGE: 'manage',
};

const Users = () => {
  const [activeMode, setActiveMode] = useState(Mode.CREATION);
  const [isLoadingUsers, users, setUsers] = useHttp<IAuthUser[]>(`${baseApiUrl}/auth-users`);
  const [isLoadingGroups, groups] = useHttp<IAuthGroup[]>(`${baseApiUrl}/groups`);
  const [isLoadingOrganizations, organizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);

  const dispatch = useDispatch();

  const handleCreateUser = (payload: CreateUserDTO) => {
    dispatch(actions.postUser(payload, createUserCallback));
  };

  const createUserCallback = (username: string) => {
    // newly created users get a default DASHBOARD permission
    setUsers([...users, {username, groupNames: ['DASHBOARD']}]);
  };

  const isLoading = activeMode === Mode.MANAGE ? isLoadingUsers || isLoadingGroups : isLoadingOrganizations;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && (
        <Container>
          <ContentLeftSidebar
            sidebar={
              <ButtonsContainer>
                <Box m={2}>
                  <Button variant="contained" color="primary" onClick={() => setActiveMode(Mode.CREATION)} style={{width: '200px'}}>
                    Create
                  </Button>
                </Box>
                <Box m={2}>
                  <Button variant="contained" color="primary" onClick={() => setActiveMode(Mode.MANAGE)} style={{width: '200px'}}>
                    Manage
                  </Button>
                </Box>
              </ButtonsContainer>
            }
            content={activeMode === Mode.CREATION ? <CreateUser organizations={organizations} handleCreateUser={handleCreateUser} /> : <ManageUser users={users} groups={groups} />}
          />
        </Container>
      )}
    </Layout>
  );
};

export default Users;
