import ActionTypes from './actionTypes';
import axios from 'axios';
import * as turf from '@turf/turf';
import {baseApiUrl} from '../../config/const';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import i18n from '../../../i18n';
import {Dispatch} from 'redux';
import {IImageWithBox} from '../reducers/ndvi';
import {IImage} from '@deep-planet/api-interfaces';
import {IFarm} from '../reducers/farm';
import {getStringDate} from '../../helpers/dateHelpers';
import {farmIdUpdate} from './farm';

////////////////////////////////////
// GET NDVI
////////////////////////////////////

interface INDVIGetStart {
  type: ActionTypes.NDVI_GET_START;
}

export const ndviGetStart = (): INDVIGetStart => ({
  type: ActionTypes.NDVI_GET_START,
});

interface INDVIGetSuccess {
  type: ActionTypes.NDVI_GET_SUCCESS;
  images: IImageWithBox[];
  farmId: string;
  activeFarm: IFarm;
}

export const ndviGetSuccess = (images: IImageWithBox[], farmId: string, activeFarm: IFarm): INDVIGetSuccess => ({
  type: ActionTypes.NDVI_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface INDVIGetFail {
  type: ActionTypes.NDVI_GET_FAIL;
  error: unknown;
}

export const ndviGetFail = (error): INDVIGetFail => ({
  type: ActionTypes.NDVI_GET_FAIL,
  error,
});

interface INDVIGetActiveData {
  type: ActionTypes.NDVI_GET_ACTIVE_DATA;
  selectedDate: Date;
  imageType: string;
}

export const ndviGetActiveData = (selectedDate: Date, imageType: string): INDVIGetActiveData => ({
  type: ActionTypes.NDVI_GET_ACTIVE_DATA,
  selectedDate,
  imageType,
});

export const getNDVI = (farm: IFarm, startDate: string, endDate: string, prediction: boolean, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(ndviGetStart());

    const ndviGetUrl = `${baseApiUrl}/ndvi/${farm.farmid}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    const ndviPredictionGetUrl = `${baseApiUrl}/predicted_images?farmId=${farm.farmid}&product=ndvi${organizationId ? `&organizationId=${organizationId}` : ''}`;

    try {
      const promises = [getImageRequest<IImage>(ndviGetUrl, dispatch, i18n.t('ndvi.get.failed'))];
      if (prediction) {
        promises.push(getImageRequest<IImage>(ndviPredictionGetUrl, dispatch, i18n.t('ndvi.prediction.get.failed')));
      }
      const response = await Promise.all(promises);
      const ndviWithCoords = response
        .filter(item => !!item)
        .reduce((acc, item) => acc.concat(item), [])
        .sort((a, b) => (a.date > b.date ? 1 : -1));

      ndviWithCoords.forEach(function (date, idx) {
        const mutatedData = date.images;
        (date as IImageWithBox).images.forEach(function (image) {
          const result = farm.polygons.filter(function (poly) {
            return poly.id === image.polygonid;
          });

          const polyCoords = turf.lineString(result[0].geoJson.geometry.coordinates[0]);
          const polyBoundBox = turf.bbox(polyCoords);

          image.boundBox = result[0] !== undefined ? polyBoundBox : null;
        });
        ndviWithCoords[idx].images = mutatedData;
      });
      dispatch(ndviGetSuccess(ndviWithCoords as IImageWithBox[], farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
    } catch (err) {
      console.log(err);
      dispatch(ndviGetFail(err));
    }
  };
};

async function getImageRequest<T>(url: string, dispatch: Dispatch<SnackbarAction>, message: string) {
  try {
    const {data} = await axios.get<T[]>(url);
    return data;
  } catch {
    dispatch(enqueueSnackbar({message: message || 'error', options: {variant: 'error'}}));
    return [] as T[];
  }
}

////////////////////////////////////
// GET NDVI planet
////////////////////////////////////

interface INDVIPlanetGetStart {
  type: ActionTypes.NDVI_PLANET_GET_START;
}

export const ndviPlanetGetStart = (): INDVIPlanetGetStart => ({
  type: ActionTypes.NDVI_PLANET_GET_START,
});

interface INDVIPlanetGetSuccess {
  type: ActionTypes.NDVI_PLANET_GET_SUCCESS;
  images: IImageWithBox[];
  farmId: string;
  activeFarm: IFarm;
}

export const ndviPlanetGetSuccess = (images: IImageWithBox[], farmId: string, activeFarm: IFarm): INDVIPlanetGetSuccess => ({
  type: ActionTypes.NDVI_PLANET_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface INDVIPlanetGetFail {
  type: ActionTypes.NDVI_PLANET_GET_FAIL;
  error: unknown;
}

export const ndviPlanetGetFail = (error): INDVIPlanetGetFail => ({
  type: ActionTypes.NDVI_PLANET_GET_FAIL,
  error,
});

interface INDVIPlanetActiveDate {
  type: ActionTypes.NDVI_PLANET_ACTIVE_DATE;
  selectedDate: number;
  imageType?: string;
}

export const ndviPlanetActiveDate = (selectedDate: number, imageType: string): INDVIPlanetActiveDate => ({
  type: ActionTypes.NDVI_PLANET_ACTIVE_DATE,
  selectedDate,
  imageType,
});

export const getNDVIPlanet = (farm: IFarm, fromDate: Date, toDate: Date, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(ndviPlanetGetStart());

    const url = `${baseApiUrl}/ndvi_planet?farmId=${farm.farmid}&fromDate=${getStringDate(fromDate)}&toDate=${getStringDate(toDate)}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const {data} = await axios.get<IImage[]>(url);
      const ndviWithCoords = data
        .filter(item => !!item)
        .reduce((acc, item) => acc.concat(item), [])
        .sort((a, b) => (a.date > b.date ? 1 : -1));

      ndviWithCoords.forEach(function (date, idx) {
        const mutatedData = date.images;
        (date as IImageWithBox).images.forEach(function (image) {
          const result = farm.polygons.filter(function (poly) {
            return poly.id === image.polygonid;
          });

          const polyCoords = turf.lineString(result[0].geoJson.geometry.coordinates[0]);
          const polyBoundBox = turf.bbox(polyCoords);

          image.boundBox = result[0] !== undefined ? polyBoundBox : null;
        });
        ndviWithCoords[idx].images = mutatedData;
      });
      dispatch(ndviPlanetGetSuccess(ndviWithCoords, farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
    } catch (err) {
      console.log(err);
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
      dispatch(ndviPlanetGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof ndviGetStart>
  | ReturnType<typeof ndviGetSuccess>
  | ReturnType<typeof ndviGetFail>
  | ReturnType<typeof ndviGetFail>
  | ReturnType<typeof ndviPlanetGetStart>
  | ReturnType<typeof ndviPlanetGetSuccess>
  | ReturnType<typeof ndviPlanetGetFail>
  | ReturnType<typeof ndviPlanetActiveDate>
  | ReturnType<typeof ndviGetActiveData>
  | ReturnType<typeof farmIdUpdate>;
