import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStringDate} from '../helpers/dateHelpers';
import {getYieldPerPixelImages, yieldPerPixelImageSetSelectedDate} from '../store/actions/yieldPerPixel';
import {IFarm} from '../store/reducers/farm';
import {selectedOrganizationSelector} from '../store/selectors';
import {getYieldPerPixelImagesSelector, yieldPerPixelActiveFarmSelector, yieldPerPixelImagesSelector, yieldPerPixelSelectedDateSelector} from '../store/selectors/yieldPerPixel';
import {usePrevious} from './usePrevious';

export function useYieldPerPixel(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<number>();
  const yieldPerPixelImages = useSelector(yieldPerPixelImagesSelector);
  const {loading, error} = useSelector(getYieldPerPixelImagesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const yieldPerPixelSelectedFarm = useSelector(yieldPerPixelActiveFarmSelector);
  const prevYieldPerPixelImages = usePrevious(yieldPerPixelImages);
  const prevFarm = usePrevious(yieldPerPixelSelectedFarm);
  const activeSelectedDate = useSelector(yieldPerPixelSelectedDateSelector);

  const handleDateChange = (date: number) => {
    const selectedDate = date;
    setSelectedDate(selectedDate);
    dispatch(yieldPerPixelImageSetSelectedDate(date));
  };

  // this will get the images for the first time when screen is initialized or selected organization changes
  useEffect(() => {
    if (activeFarm && !loading && !yieldPerPixelImages && !lazy) {
      dispatch(getYieldPerPixelImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, lazy, loading, yieldPerPixelImages, selectedOrganization, startDate]);

  // in case of selected organization is changed
  useEffect(() => {
    if (activeFarm && prevFarm && activeFarm.id !== prevFarm.id && !loading && !lazy) {
      dispatch(getYieldPerPixelImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, prevFarm, selectedOrganization, startDate, loading, lazy]);

  useEffect(() => {
    if (!selectedDate && activeSelectedDate) setSelectedDate(activeSelectedDate);
  }, [activeSelectedDate, selectedDate]);
  // get images on the selected farm
  const onGetYieldPerPixelImages = (selectedFarm: IFarm) => {
    dispatch(getYieldPerPixelImages(selectedFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
  };

  return {
    loading,
    error,
    yieldPerPixelImages,
    prevYieldPerPixelImages,
    selectedDate,
    onGetYieldPerPixelImages,
    handleDateChange,
  };
}
