import {IFarmEntity, IOrganizationEntity, IOrganizationToFarmEntity} from '@deep-planet/api-interfaces';
import {Box, Button, IconButton, TextField, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {Autocomplete} from '@material-ui/lab';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {ConfirmationDialog} from '../../../../../components/UI/Dialog/ConfirmationDialog';
import MapOverlay from '../../../../../components/UI/MapOverlay';
import Modal from '../../../../../components/UI/Modal/Modal';
import {baseApiUrl} from '../../../../../config/const';
import {useHttp} from '../../../../../hooks/http';
import {enqueueSnackbar} from '../../../../../store/actions';
import theme from '../../../../../utilities/theme';
import DeleteIcon from '@material-ui/icons/Delete';
import {sortBy} from 'lodash';
import {useFarmSelection} from 'apps/web-portal-ui/src/app/hooks/useFarmSelection';
import {IFarm} from 'apps/web-portal-ui/src/app/store/reducers/farm';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const ManageOrganizationFarms = () => {
  const [isLoadingOrganizations, organizations, setOrganizations] = useHttp<IOrganizationEntity[]>(`${baseApiUrl}/organizations`);
  const {loadingGetFarm: isLoadingFarms, allFarms: farms}: {loadingGetFarm: boolean; allFarms: IFarm[]} = useFarmSelection();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizationEntity>();
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [selectedFarm, setSelectedFarm] = useState<IFarm | IFarmEntity>(null);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleOrganizationSelect = (currentlySelectedOrganization: IOrganizationEntity) => {
    setSelectedOrganization(currentlySelectedOrganization);
  };

  const handleClickOnDelete = async () => {
    try {
      setIsDeleteLoading(true);
      await axios.delete(`${baseApiUrl}/organization/${selectedOrganization.id}/farms/${selectedFarm.id}`);
      dispatch(enqueueSnackbar({message: 'Farm successfully deleted from the organization', options: {variant: 'success'}}));
      handleDeleteFarmFromOrganization(selectedOrganization.id, selectedFarm.id);
      closeDialog();
    } catch (e) {
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const handleClickOnAdd = async () => {
    try {
      setIsAddLoading(true);
      const {data} = await axios.post<IOrganizationToFarmEntity>(`${baseApiUrl}/organization/${selectedOrganization.id}/farms/${selectedFarm.id}`);
      dispatch(enqueueSnackbar({message: 'Farm successfully added to the organization', options: {variant: 'success'}}));
      handleAddFarmToOrganization(data);
      closeModal();
    } catch (e) {
      console.error(e);
      dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
    } finally {
      setIsAddLoading(false);
    }
  };

  const openDialog = (farm: IFarmEntity) => {
    setIsDialogOpen(true);
    setSelectedFarm(farm);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedFarm(null);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFarm(null);
  };

  const handleFarmSelect = (currentlySelectedFarm: IFarm) => {
    setSelectedFarm(currentlySelectedFarm);
  };

  useEffect(() => {
    if (
      organizations?.length &&
      selectedOrganization &&
      organizations?.find(o => o.id === selectedOrganization.id)?.organizationToFarms?.length !== selectedOrganization?.organizationToFarms?.length
    ) {
      setSelectedOrganization(organizations.find(o => o.id === selectedOrganization.id));
    }
  }, [organizations, selectedOrganization]);

  const handleDeleteFarmFromOrganization = (organizationId: string, farmId: string) => {
    const newOrganization = sortBy(
      [
        ...organizations.map(org => {
          if (org.id !== organizationId) return org;
          return {...org, organizationToFarms: org.organizationToFarms.filter(({farm}) => farm.id !== farmId)};
        }),
      ],
      o => o.name.toLowerCase()
    );
    setOrganizations(newOrganization);
  };

  const handleAddFarmToOrganization = (organizationToFarm: IOrganizationToFarmEntity) => {
    const newOrganizations = sortBy([
      ...organizations.map(org => {
        if (org.id !== organizationToFarm.organization.id) return org;
        return {
          ...org,
          organizationToFarms: [...org.organizationToFarms, organizationToFarm],
        };
      }),
    ]);
    setOrganizations(newOrganizations);
  };

  const isLoading = isLoadingOrganizations || isLoadingFarms;
  return (
    <>
      {isLoading && <MapOverlay />}
      {!isLoading && (
        <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
          <Typography variant="h3" align="center" gutterBottom>
            Manage Organization Farms
          </Typography>

          <FormWrapper padding="2rem 0">
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                value={selectedOrganization}
                options={sortBy(organizations, o => o.name.toLowerCase())}
                onChange={(e, org) => handleOrganizationSelect(org as IOrganizationEntity)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Select an organization" variant="outlined" />}
              />
            </FormControl>
            {selectedOrganization && (
              <Box margin="2rem 0">
                <Typography>Current Farms:</Typography>
                {sortBy(selectedOrganization.organizationToFarms, ({farm}) => farm.name).map(({farm}) => (
                  <Box key={farm.id} display="flex" alignItems="center" justifyContent="space-between">
                    <p>{farm.name}</p>
                    <IconButton color="secondary" onClick={() => openDialog(farm)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
            <Box padding="1rem 0">
              <Button fullWidth disabled={!selectedOrganization} color="primary" variant="contained" type="submit" onClick={() => setIsModalOpen(true)}>
                Add farm to Organization
              </Button>
            </Box>
          </FormWrapper>
        </Box>
      )}

      {selectedFarm && isDialogOpen && (
        <ConfirmationDialog
          open={isDialogOpen}
          handleClose={closeDialog}
          isLoading={isDeleteLoading}
          handleSubmit={handleClickOnDelete}
          submitText={'Delete'}
          title={`You're about to permanently delete "${selectedFarm.name}" farm from "${selectedOrganization.name}" organization`}
        />
      )}
      {isModalOpen && (
        <Modal
          title={`Add farm to "${selectedOrganization.name}" organization`}
          submitText={'Add'}
          isOpen={isModalOpen}
          isSubmitButtonDisabled={isAddLoading || !selectedFarm}
          isLoading={isAddLoading}
          handleClose={closeModal}
          handleSubmit={handleClickOnAdd}
        >
          <Box width="100%" margin="1rem 0">
            <FormControl variant="outlined" margin="normal" fullWidth>
              <Autocomplete
                value={selectedFarm}
                options={sortBy(farms, o => o.name.toLowerCase())?.filter(({id}) => !selectedOrganization.organizationToFarms.find(({farm}) => farm.id === id))}
                onChange={(e, farm) => handleFarmSelect(farm as IFarm)}
                getOptionLabel={({name}) => name}
                renderInput={params => <TextField {...params} label="Select farm" variant="outlined" />}
              />
            </FormControl>
          </Box>
        </Modal>
      )}
    </>
  );
};
