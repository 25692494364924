import React from 'react';
import {title, headerTitle, paragraph, points} from './TextElements';

const getText = (key: string, value: any) => {
  const textType = key.includes('headerTitle') ? 'HEADERTITLE' : key.includes('title') ? 'TITLE' : key.includes('text') ? 'TEXT' : key;
  switch (textType) {
    case 'TITLE':
      return title(value);
    case 'HEADERTITLE':
      return headerTitle(value);
    case 'TEXT':
      return paragraph(value);
    case 'ul':
      return points(value);
    default:
      return null;
  }
};

// Text function takes objects or nested string objects and converts text into desired format that
// passed as input(ex: title, header title, paragraph, bullet Points)
const Text = (props: Record<string, any>) => {
  const {item} = props;
  return (
    <div>
      {Object.entries(item).map((key, index) => (
        <div key={key[0]}>{getText(key[0], key[1])}</div>
      ))}
    </div>
  );
};

export default Text;
