import {IMaturityResponse} from '@deep-planet/api-interfaces';
import {Box} from '@material-ui/core';
import {Shape} from 'plotly.js';
import styled from 'styled-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Plotly from 'react-plotly.js';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';
import {getLocalDateString} from '../../../helpers/dateHelpers';

interface Props {
  maturityPrediction: IMaturityResponse;
  sugarTypeMultiplier: number;
  metric: string;
}

const StyledBox = styled(Box)`
  min-width: 650px;
  background-color: white;
  width: 100%;
  padding: 10px;
`;

const Plot = ({maturityPrediction, sugarTypeMultiplier, metric}: Props) => {
  const {t} = useTranslation();
  let maxValue = 0;
  let minValue = 0;
  const dates = maturityPrediction?.estimates?.map(({date}) => date);

  const textMetric = metric.charAt(0).toUpperCase() + metric.slice(1);

  const filterAndSort = items => {
    if (!items) return;
    return items.filter(m => !!m).sort((a, b) => (a > b ? 1 : -1));
  };

  // find the min and max value to provide the y-axis range
  const baume = filterAndSort(maturityPrediction?.maturities?.map(i => i.baume));
  const bestEstimate = filterAndSort(maturityPrediction?.estimates?.map(({bestEstimate}) => bestEstimate));
  const medianEstimate = filterAndSort(maturityPrediction?.estimates?.map(({medianEstimate}) => medianEstimate));
  const upperEstimate = filterAndSort(maturityPrediction?.estimates?.map(({upperEstimate}) => upperEstimate));
  const lowerEstimate = filterAndSort(maturityPrediction?.estimates?.map(({lowerEstimate}) => lowerEstimate));
  const targetBaume = maturityPrediction?.targetBaume;

  if (baume && bestEstimate && medianEstimate && lowerEstimate && upperEstimate && targetBaume) {
    // maximum value of all types of readings
    maxValue =
      Math.max(
        ...[
          baume[baume?.length - 1],
          bestEstimate[bestEstimate?.length - 1],
          medianEstimate[medianEstimate?.length - 1],
          lowerEstimate[lowerEstimate?.length - 1],
          upperEstimate[upperEstimate?.length - 1],
          targetBaume,
        ]
      ) * sugarTypeMultiplier;
    // minimum value of all types of readings
    minValue = Math.min(...[baume[0], bestEstimate[0], medianEstimate[0], lowerEstimate[0], upperEstimate[0], targetBaume]) * sugarTypeMultiplier;
  }

  // Best harvest estimate
  const trace1: Plotly.Data = {
    x: dates,
    y: maturityPrediction?.estimates?.map(({bestEstimate}) => bestEstimate * sugarTypeMultiplier),
    type: 'scatter',
    name: t('harvest.best.estimate'),
  };

  // Median harvest estimate
  const trace2: Plotly.Data = {
    x: dates,
    y: maturityPrediction?.estimates?.map(({medianEstimate}) => medianEstimate * sugarTypeMultiplier),
    type: 'scatter',
    name: t('harvest.median.estimate'),
  };

  // Harvest upper estimate
  const trace3: Plotly.Data = {
    x: dates,
    y: maturityPrediction?.estimates?.map(({upperEstimate}) => upperEstimate * sugarTypeMultiplier),
    type: 'scatter',
    name: t('harvest.upper.estimate'),
  };

  // Harvest lower estimate
  const trace4: Plotly.Data = {
    x: dates,
    y: maturityPrediction?.estimates?.map(({lowerEstimate}) => lowerEstimate * sugarTypeMultiplier),
    type: 'scatter',
    name: t('harvest.lower.estimate'),
  };

  // Target baume/brix value
  const trace5: Plotly.Data = {
    x: [maturityPrediction?.maturities.map(i => i.sampleDate)[0]],
    y: [maturityPrediction?.targetBaume * sugarTypeMultiplier],
    text: 'Target ' + textMetric + maturityPrediction?.targetBaume * sugarTypeMultiplier, // [t('harvest.target.baume', {target: maturityPrediction?.targetBaume * sugarTypeMultiplier}) as string]
    mode: 'text',
    textposition: 'top right',
    showlegend: false,
  };

  // Baume/Brix readings value
  const trace6: Plotly.Data = {
    x: maturityPrediction?.maturities.map(i => i.sampleDate),
    y: maturityPrediction?.maturities.map(i => i.baume * sugarTypeMultiplier),
    mode: 'lines+markers',
    name: textMetric + ' Reading', // [t('harvest.reading.braume')
    marker: {
      color: 'rgb(219, 64, 82)',
      size: 12,
    },
  };

  const trace7: Plotly.Data = {
    x: [maturityPrediction?.proposedHarvestDate],
    y: [9],
    text: [t('harvest.proposed.date', {date: getLocalDateString(maturityPrediction?.proposedHarvestDate)}) as string],
    mode: 'text',
    textposition: 'top left',
    showlegend: false,
  };

  const data = [trace1, trace2, trace3, trace4, trace5, trace6];
  const shapes: Array<Partial<Shape>> = [
    {
      type: 'line',
      xref: 'paper',
      x0: 0,
      y0: maturityPrediction?.targetBaume * sugarTypeMultiplier,
      x1: 1,
      y1: maturityPrediction?.targetBaume * sugarTypeMultiplier,
      line: {
        color: 'rgb(255, 0, 0)',
        width: 2,
        dash: 'dot',
      },
    },
  ];
  if (maturityPrediction?.proposedHarvestDate) {
    data.push(trace7);
    shapes.push({
      type: 'line',
      name: 'harvest date',
      yref: 'paper',
      x0: maturityPrediction?.proposedHarvestDate,
      y0: 0,
      x1: maturityPrediction?.proposedHarvestDate,
      y1: 1,
      line: {
        color: 'rgb(255, 0, 0)',
        width: 2,
        dash: 'dot',
      },
    });
  }

  return (
    <StyledBox display="flex" alignItems="center">
      {!maturityPrediction && <MapPlaceholderNoData light left="241px" width="calc(100% - 241px)" title={t('harvest.no.data')} />}
      {maturityPrediction && (
        <Plotly
          style={{display: 'flex', width: 'calc(100% - 100px)', height: 'calc(100vh - 100px)', padding: '10px'}}
          useResizeHandler
          data={data}
          layout={{
            autosize: true,
            title: t('harvest.title', {date: getLocalDateString(maturityPrediction?.createdAt)}),
            xaxis: {type: 'date'},
            shapes: shapes,
            yaxis: {range: [Math.floor(minValue), Math.ceil(maxValue)]},
          }}
        />
      )}
    </StyledBox>
  );
};

export {Plot};
