import {uniqBy, sortBy} from 'lodash';

export interface FilterValue {
  label: string;
  selected: boolean;
}

export interface IColumn {
  id: string;
  label: string;
  type: string;
  values?: FilterValue[];
}

export function filterTableItems<T>(items: T[], columns: IColumn[]): T[] {
  // if every filter is unselected
  const columnsWithFilters = columns?.filter(columns => !!columns.values?.length);
  if (columnsWithFilters.every(column => column.values.every(value => !value.selected))) {
    return [];
  }
  // if some filter is unselected
  if (columnsWithFilters.some(column => !column.values.every(value => value.selected))) {
    // check line by line every corresponding filter value
    const filterKeys = columnsWithFilters.map(column => column.id);
    return items.filter(item => {
      // iterating through the columns of the current line
      return filterKeys.every(filterKey => {
        // if any of the filter value is not selected then filter out the whole line
        return !!columnsWithFilters.find(column => column.id === filterKey).values.find(value => value.label === item[filterKey])?.selected;
      });
    });
  }
  return items;
}

export function getColumnsOnClearAll(columnId: string, columns: IColumn[]): IColumn[] {
  return columns.map(column => {
    if (column.values && column.id === columnId) {
      return {
        ...column,
        values: column.values.map(value => ({...value, selected: false})),
      };
    }
    if (column.values && column.id !== columnId) {
      return {...column, values: []};
    }
    return column;
  });
}

export function getColumnsOnSelectAll<T>(columnId: string, columns: IColumn[], items: T[]): IColumn[] {
  return columns.map(column => {
    if (column.values && column.id === columnId) {
      return {
        ...column,
        values: column.values.map(value => ({...value, selected: true})),
      };
    }
    if (column.values && column.id !== columnId) {
      const previousUnselectedFilters = columns.find(({id}) => column.id === id).values.filter(({selected}) => !selected);
      const previousUnselectedFiltersLabel = previousUnselectedFilters.map(({label}) => label);

      const values = sortBy(
        uniqBy([...items?.filter(item => !previousUnselectedFiltersLabel.includes(item[column.id])).map(item => ({label: item[column.id], selected: true})), ...previousUnselectedFilters], 'label'),
        'label'
      );
      return {...column, values};
    }
    return column;
  });
}
