import html2canvas from 'html2canvas';
import {getStringDate} from '../../../helpers/dateHelpers';

export const getScreenshot = async screenshotLayout => {
  return await html2canvas(screenshotLayout, {
    allowTaint: false,
    useCORS: true,
  }).then(canvas => {
    const screenshot = canvas.toDataURL('img/png');
    downloadImage(screenshot, 'new image.png');
  });
};

const downloadImage = (screenshot, fileName) => {
  const imageLink = window.document.createElement('a');
  imageLink.download = fileName;
  imageLink.href = screenshot;
  document.body.appendChild(imageLink);
  imageLink.click();
  document.body.removeChild(imageLink);
  imageLink.remove();
};

// screenshot feature -  chrome blocks image download due to CORS and cache issue hence use crossorigin=anonymous
// attach timestamp of current date to get fresh images
export const renderURL = (url: string) => {
  let imageUrl = null;
  const img = new Image();
  img.crossOrigin = 'anonymous';
  imageUrl = img.src = url + `?t=${getStringDate(new Date())}`;
  return imageUrl;
};
