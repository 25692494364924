import * as Note from '../reducers/note';
import {RootState} from '../reducers';

export const notePostSelector = (state: Pick<RootState, 'note'>) => Note.notePostSelector(state.note);
export const notesSelector = (state: Pick<RootState, 'note'>) => Note.notesSelector(state.note);
export const notesGetSelector = (state: Pick<RootState, 'note'>) => Note.notesGetSelector(state.note);
export const noteDeleteSelector = (state: Pick<RootState, 'note'>) => Note.noteDeleteSelector(state.note);
export const notePutSelector = (state: Pick<RootState, 'note'>) => Note.notePutSelector(state.note);
export const noteAttachmentDeleteSelector = (state: Pick<RootState, 'note'>) => Note.noteAttachmentDeleteSelector(state.note);
export const noteAttachmentsAddSelector = (state: Pick<RootState, 'note'>) => Note.noteAttachmentsAddSelector(state.note);
export const noteShareSelector = (state: Pick<RootState, 'note'>) => Note.noteShareSelector(state.note);
