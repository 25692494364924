import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import {FaTimes} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {withUser, WithUserProps} from '../../../../hooks/useAuth';
import {getUserGroups} from '../../../authHOC';
import Text from '../../../../components/UI/Text/Text';
import {IconButton} from '@material-ui/core';
import {Help as HelpIcon} from '@material-ui/icons';

const CloseButton = styled(Button)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
  }
`;

const HelpContent = styled.div``;

const dashboardDescription = (t: TFunction) => [
  {
    headerTitle: t('navigation.help.dashboard.title'),
    text: [t('navigation.help.dashboard.description')],
    rules: ['DASHBOARD'],
  },
];

const helpTextBlockHealth = (t: TFunction) => [
  ...dashboardDescription(t),
  {
    title: t('dashboard.help.ndvi.change'),
    text: [t('dashboard.help.ndvi.change.description')],
    title2: t('dashboard.help.ndwi.change'),
    text2: [t('dashboard.help.ndwi.change.description')],
    rules: ['NDVI', 'SOILSIGNAL'],
  },
];

const helpTextClimateFeatures = (t: TFunction) => [
  ...dashboardDescription(t),
  {
    title: t('dashboard.help.gdd'),
    text: [t('dashboard.help.gdd.description')],
    title2: t('dashboard.help.change.in.gdd.yoy'),
    text2: [t('dashboard.help.change.in.gdd.yoy.description')],
    title3: t('dashboard.help.rainfall'),
    text3: [t('dashboard.help.rainfall.description')],
    title4: t('dashboard.help.change.in.rainfall.yoy'),
    text4: [t('dashboard.help.change.in.rainfall.yoy.description')],
    title5: t('dashboard.help.frostshock'),
    text5: [t('dashboard.help.frostshock.description')],
    title6: t('dashboard.help.heatshock'),
    text6: [t('dashboard.help.heatshock.description')],
    rules: ['NDVI'],
  },
];

const helpTextYieldAndHarvest = (t: TFunction) => [
  ...dashboardDescription(t),
  {
    title: t('dashboard.help.yield.prediction'),
    text: [t('dashboard.help.yield.prediction.description')],
    title2: t('dashboard.help.change.in.yield.yoy.prediction'),
    text2: [t('dashboard.help.change.in.yield.prediction.yoy.description')],
    title3: t('dashboard.help.average.baume'),
    text3: [t('dashboard.help.average.baume.description')],

    rules: ['NDVI', 'YIELD'],
  },

  {
    title: t('dashboard.help.target.baume'),
    text: [t('dashboard.help.target.baume.description')],
    title2: t('dashboard.help.estimated.harvest.date'),
    text2: [t('dashboard.help.estimated.harvest.date.description')],
    title3: t('dashboard.help.change.in.estimated.harvest.date'),
    text3: [t('dashboard.help.change.in.estimated.harvest.date.description')],
    rules: ['NDVI', 'HARVEST'],
  },
];

interface Props extends WithUserProps {
  helpInfoType: string;
}

const Help = ({user, helpInfoType}: Props) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = (helpType: string) => setOpen(true);
  const handleClose = () => setOpen(false);
  const {t} = useTranslation();
  const groups: string[] = getUserGroups(user);

  let helpText = helpTextBlockHealth(t);
  if (helpInfoType.toUpperCase().includes('CLIMATE FEATURES')) helpText = helpTextClimateFeatures(t);
  if (helpInfoType.toUpperCase().includes('YIELD AND HARVEST')) helpText = helpTextYieldAndHarvest(t);

  return (
    <>
      <IconButton style={{padding: 5}} onClick={() => handleClickOpen(helpInfoType)}>
        <HelpIcon fontSize="small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('navigation.help.title')}</DialogTitle>
        <DialogContent>
          <HelpContent>
            <List dense={false}>
              {helpText.map((item, idx) => {
                const isSectionNeeded = item.rules ? item.rules.some(rule => groups.includes(rule)) : true;
                return (
                  isSectionNeeded && (
                    <div key={idx} style={{marginBottom: '2rem'}}>
                      <Text item={item} />
                    </div>
                  )
                );
              })}
            </List>
          </HelpContent>
        </DialogContent>
        <CloseButton onClick={handleClose} color="primary" autoFocus>
          <FaTimes />
        </CloseButton>
      </Dialog>
    </>
  );
};

export default withUser(Help);
