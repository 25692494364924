import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {imageMapping} from '../containers/pages/SoilSignal/NDVI/utils';
import {getFarmImages} from '../store/actions';
import {IFarm} from '../store/reducers/farm';
import {farmGetImagesSelector, farmImagesSelector, selectedOrganizationSelector} from '../store/selectors';
import {usePrevious} from './usePrevious';

export function useFarmNDVIImage(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();
  const farmImages = useSelector(farmImagesSelector);
  const {error, loading: loadingNdvi} = useSelector(farmGetImagesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevNdviImages = usePrevious(farmImages);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const prevStartDate = usePrevious(startDate);
  const prevEndDate = usePrevious(endDate);

  const productImages = farmImages ? farmImages.filter(image => image.product === 'NDVI') : [];
  const ndviImages = productImages && productImages.length ? imageMapping(productImages) : [];

  useEffect(() => {
    if (activeFarm && !loadingNdvi && !ndviImages && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDVI'));
    }
  }, [activeFarm, dispatch, endDate, loadingNdvi, ndviImages, startDate, lazy]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDVI', selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, prevSelectedOrganization, selectedOrganization, startDate, lazy]);

  useEffect(() => {
    if (activeFarm && startDate === prevStartDate && endDate === prevEndDate && !lazy) {
      dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDVI', selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, prevEndDate, prevStartDate, selectedOrganization, startDate, lazy]);

  const onGetNDVI = (activeFarm: IFarm) => {
    dispatch(getFarmImages(activeFarm.farmid, startDate, endDate, 'NDVI', selectedOrganization.id));
  };

  return {
    loadingNdvi,
    error,
    ndviImages,
    prevNdviImages,
    onGetNDVI,
  };
}
