import React from 'react';
import styled from 'styled-components';
import {FaMapSigns} from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import theme from '../../../utilities/theme';

const Container = styled.div<{height?: string; width?: string; left?: string}>`
  z-index: 2;
  height: ${p => (p.height ? p.height : '100%')};
  width: ${p => (p.width ? p.width : '100%')};
  position: absolute;
  top: 0;
  left: ${p => (p.left ? p.left : '0')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${() => theme.palette.primary.main};

  svg {
    width: 100px;
    height: 100px;
    fill: white;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
    left: 0;
  }
`;

const Text = styled(Typography)`
  text-align: center;
  && {
    color: white;
    margin: 2rem 1rem;
  }
`;

const Btn = styled(Button)`
  && {
    color: white;
  }
`;

export const Error = ({width, height, onClick}: {width?: string; height?: string; onClick: () => void}) => {
  const {t} = useTranslation();
  return (
    <Container width={width} height={height}>
      <FaMapSigns />
      <Text variant="h6">{t('error.page.title')}</Text>
      <Btn color="inherit" variant="outlined" onClick={onClick}>
        {t('error.page.button')}
      </Btn>
    </Container>
  );
};
