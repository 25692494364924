import {RootState} from '../reducers';
import * as User from '../reducers/user';

export const userDetailsSelector = (state: Pick<RootState, 'user'>) => User.userDetailsSelector(state.user);
export const getUserSelector = (state: Pick<RootState, 'user'>) => User.getUserSelector(state.user);
export const putUserSelector = (state: Pick<RootState, 'user'>) => User.putUserSelector(state.user);
export const getUsersSelector = (state: Pick<RootState, 'user'>) => User.getUsersSelector(state.user);
export const userNamesSelector = (state: Pick<RootState, 'user'>) => User.userNamesSelector(state.user);
export const noteNotificationSelector = (state: Pick<RootState, 'user'>) => User.noteNotificationSelector(state.user);
export const fileNotificationSelector = (state: Pick<RootState, 'user'>) => User.fileNotificationSelector(state.user);
export const putUserPreferencesSelector = (state: Pick<RootState, 'user'>) => User.putUserPreferencesSelector(state.user);
export const postUserSelector = (state: Pick<RootState, 'user'>) => User.postUserSelector(state.user);
