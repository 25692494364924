import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box, Button, Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../../components/UI/Spinner';
import {baseApiUrl} from '../../../../../config/const';
import {enqueueSnackbar} from '../../../../../store/actions';
import theme from '../../../../../utilities/theme';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const CreateOrganization = () => {
  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();
    if (name.length > 3) {
      setError(false);
      try {
        setLoading(true);
        const {data} = await axios.post<IOrganizationEntity>(`${baseApiUrl}/organization`, {name});
        dispatch(enqueueSnackbar({message: 'Organization successfully created', options: {variant: 'success'}}));
      } catch (e) {
        console.warn(e);
        dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(enqueueSnackbar({message: 'Name should be at least 4 characters', options: {variant: 'error'}}));
      setError(true);
    }
  };

  return (
    <Box padding="4rem 2rem" display="flex" alignItems="center" flexDirection="column">
      <Typography variant="h3" align="center" gutterBottom>
        Create Organization
      </Typography>
      <FormWrapper padding="2rem 0">
        <form>
          <FormControl fullWidth>
            <TextField
              error={error}
              fullWidth
              id="username"
              name="username"
              value={name}
              placeholder={'Organization name'}
              margin="normal"
              variant="outlined"
              onChange={event => {
                setName(event.target.value);
              }}
              required
              autoComplete="off"
            />
            <Box padding="1rem 0">
              <Button fullWidth disabled={loading} color="primary" variant="contained" type="submit" onClick={handleSubmit}>
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </FormControl>
        </form>
      </FormWrapper>
    </Box>
  );
};
