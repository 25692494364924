import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Notifications} from '@material-ui/icons';
import {Hidden, Box, Badge, IconButton, Menu, CircularProgress, List} from '@material-ui/core';
import axios from 'axios';
import {INotificationEntity} from '@deep-planet/api-interfaces';
import {InformationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/InformationDialog';
import {baseApiUrl} from 'apps/web-portal-ui/src/app/config/const';
import {useHttp} from 'apps/web-portal-ui/src/app/hooks/http';
import {withUser, WithUserProps} from 'apps/web-portal-ui/src/app/hooks/useAuth';
import styled from 'styled-components';
import theme from '../../../../utilities/theme';
import NotificationItem from './NotificationItem';

const StyledBox = styled(Box)`
  margin-right: 0px;
  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
`;

const Spinner = styled(CircularProgress)`
  && {
    color: white;
  }
`;

const NotificationButton = ({user}: WithUserProps) => {
  const [isLoadingNotifications, notifications, setNotifications] = useHttp<INotificationEntity[]>(`${baseApiUrl}/notifications/`);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState<boolean>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogContent, setDialogContent] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [unread, setUnread] = useState<number>();

  const handleMenu = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleOpenDialog = (notification: INotificationEntity) => {
    if (!notification.read)
      axios.put(`${baseApiUrl}/notifications/update/read?id=${notification.id}`).catch(e => {
        console.error(`Failed to update notification status`, e);
      });
    setOpenDialog(true);
    setDialogTitle(notification.header);
    setUrl(notification?.url);
    setDialogContent(notification.description);
    const updateNotifications = notifications.map(n => {
      if (n.id === notification.id && notification.read === false) n.read = true;
      return n;
    });
    setUnread(notifications.filter(n => !n.read).length);
    setNotifications(updateNotifications);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (!isLoadingNotifications && notifications) setUnread(notifications.filter(n => !n.read).length);
  }, [setUnread, isLoadingNotifications, notifications]);

  const open = Boolean(anchorEl);
  const isLoading = isLoadingNotifications;
  return (
    <>
      <StyledBox>
        <IconButton color="inherit" onClick={handleMenu} size="medium" aria-label="shownotifications">
          <Hidden mdUp implementation="css">
            <Box display="flex">
              {isLoading && <Spinner size={30} />}
              {!isLoading && (
                <Badge badgeContent={unread} color="error">
                  <Notifications />
                </Badge>
              )}
              <Box ml={1}></Box>
            </Box>
          </Hidden>
          <Hidden smDown implementation="css">
            <Box display="flex">
              {isLoading && <Spinner size={30} />}
              {!isLoading && (
                <Badge badgeContent={unread} color="error">
                  <Notifications />
                </Badge>
              )}
              <Box ml={1}></Box>
            </Box>
          </Hidden>
        </IconButton>
      </StyledBox>
      {notifications && (
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          transformOrigin={{vertical: 'top', horizontal: 'center'}}
          open={open}
          onClose={handleClose}
          style={{maxHeight: 'calc(100% - 160px)', overflow: 'auto', width: '100%', justifyContent: 'center'}}
        >
          <List style={{minWidth: 450, maxWidth: 450, display: 'table', margin: '0 auto'}}>
            {!isLoading && notifications?.map((nl, idx) => <NotificationItem key={idx} username={user.username} notification={nl} handleClick={handleOpenDialog} />)}
          </List>
        </Menu>
      )}
      {openDialog && <InformationDialog title={dialogTitle} content={dialogContent} open={openDialog} url={url} handleClose={handleCloseDialog} />}
    </>
  );
};

export default withUser(NotificationButton);
