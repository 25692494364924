import React from 'react';
import styled from 'styled-components';
import CircularProgress, {CircularProgressProps} from '@material-ui/core/CircularProgress';

const StyledCircularProgress = styled(CircularProgress)`
  && {
    margin-left: 1rem;
  }
`;

const Spinner = ({size, color, style}: CircularProgressProps) => {
  return <StyledCircularProgress size={size} color={color} style={style} />;
};

export default Spinner;
