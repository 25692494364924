import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {usePrevious} from '../../../../hooks/usePrevious';
import {getSeasons} from '../../../../store/actions';
import {seasonsGetSelector, seasonsSelector, selectedOrganizationSelector} from '../../../../store/selectors';
import Layout from '../../../Layout';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import {Box, Button} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {vineSignalRoutes} from '../../../../utilities/routes';
import {useFarmSelection} from '../../../../hooks/useFarmSelection';
import FarmSelectionSidebar from '../../../../components/UI/Sidebar/FarmSelection';
import MapOverlay from '../../../../components/UI/MapOverlay';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import HistoricalYieldForm from './HistoricalYieldForm';

const HistoricalYield = () => {
  const [selectedPolygon, setSelectedPolygon] = useState<IPolygonEntity>();
  const {allFarms, selectedFarm, handleFarmChange, loadingGetFarm, loadingGetUser} = useFarmSelection();
  const dispatch = useDispatch();
  const previousSelectedFarm = usePrevious(selectedFarm);
  const seasons = useSelector(seasonsSelector);
  const {loading: loadingGetSeasons} = useSelector(seasonsGetSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const {t} = useTranslation();

  const handlePolygonChange = (polygon: IPolygonEntity) => {
    setSelectedPolygon(polygon);
  };

  useEffect(() => {
    if (selectedFarm) {
      setSelectedPolygon(selectedFarm.polygons[0]);
    }
  }, [selectedFarm, setSelectedPolygon]);

  useLayoutEffect(() => {
    if (selectedFarm?.ownerOrganization?.id !== previousSelectedFarm?.ownerOrganization?.id) {
      dispatch(getSeasons());
    }
  }, [dispatch, previousSelectedFarm, selectedFarm]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id) {
      dispatch(getSeasons(selectedOrganization.id));
    }
  }, [dispatch, prevSelectedOrganization, selectedOrganization]);

  const isLoading = loadingGetFarm || loadingGetUser || loadingGetSeasons;
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && selectedFarm && selectedPolygon && (
        <ContentLeftSidebar
          height="calc(100% - 64px)"
          content={<HistoricalYieldForm selectedPolygon={selectedPolygon} seasons={seasons.filter(s => selectedFarm?.ownerOrganization?.id === s?.organization?.id)} />}
          sidebar={
            <FarmSelectionSidebar
              polygons={selectedFarm.polygons}
              allFarms={allFarms}
              activeFarm={selectedFarm.farmid}
              handleFarmChange={handleFarmChange}
              loadingGetFarm={loadingGetFarm}
              activePolygon={selectedPolygon}
              handlePolygonChange={handlePolygonChange}
            >
              <Box p={2}>
                <Link to={vineSignalRoutes.YIELD.slug}>
                  <Button fullWidth variant="contained" color="primary">
                    {t('yield.historical.back')}
                  </Button>
                </Link>
              </Box>
            </FarmSelectionSidebar>
          }
        />
      )}
    </Layout>
  );
};

export default HistoricalYield;
