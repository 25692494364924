import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useSoil} from '../../../hooks/useSoil';
import {farmGetImagesSelector, farmImagesSelector} from '../../../store/selectors';
import Layout from '../../Layout';
import React, {useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import Map from './Map';
import {useDispatch, useSelector} from 'react-redux';
import {getFarmImages} from '../../../store/actions';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import moment from 'moment';
interface Props {
  product: string;
  infoSecondaryTitle: string;
  soilSignalValueName: string;
  legend?: JSX.Element;
}

const SoilSignalImagePage = ({product, infoSecondaryTitle, soilSignalValueName, legend}: Props) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const images = useSelector(farmImagesSelector);
  const {loading: loadingGetFarmImages} = useSelector(farmGetImagesSelector);
  const {allFarms, loadingGetFarm, prevAllFarms, activeFarm, setActiveFarm, selectedFarm, handleFarmChange} = useFarmSelection();
  const {soilValues, loadingGetFarmSoil, onGetSoilValues} = useSoil(activeFarm, startDate, endDate);

  const productImages = images?.filter(image => image.product === product);

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleImageFetching = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    dispatch(getFarmImages(selectedFarm.farmid, startDate, endDate, product));
    onGetSoilValues(selectedFarm, startDate, endDate);
  };

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };

  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  useEffect(() => {
    if (!selectedDate && productImages?.length) {
      setSelectedDate(productImages[0].date);
    }
  }, [activeFarm, allFarms, productImages, prevAllFarms, selectedDate]);

  const isLoading = loadingGetFarm || loadingGetFarmImages || loadingGetFarmSoil;
  const filteredValues = soilValues?.filter(soilValue => soilValue.product === soilSignalValueName && moment(soilValue.date).isSame(moment(selectedDate), 'day'));
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && activeFarm && (
        <Box>
          {legend}
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                allFarms={allFarms}
                activeFarm={selectedFarm}
                images={productImages}
                startDate={startDate}
                endDate={endDate}
                selectedDate={selectedDate}
                handleFarmChange={handleFarmChange}
                handleImageFetching={handleImageFetching}
                handleDateChange={handleDateChange}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
              />
            }
            content={
              <Map
                center={activeFarm.farmCenter}
                polygons={activeFarm.polygons}
                image={productImages?.find(({date}) => selectedDate === date)}
                soilValues={filteredValues}
                infoSecondaryTitle={infoSecondaryTitle}
                bbox={activeFarm.bbox}
              />
            }
          />
        </Box>
      )}
    </Layout>
  );
};

export default SoilSignalImagePage;
