import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationDE from './app/locales/de.json';
import translationEN from './app/locales/en.json';
import translationES from './app/locales/es.json';
import translationFR from './app/locales/fr.json';
import translationHU from './app/locales/hu.json';
import translationIT from './app/locales/it.json';
import translationPT from './app/locales/pt.json';

import {store} from './main';
import {updateCurrentLanguage} from './app/store/actions';

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  hu: {
    translation: translationHU,
  },
  it: {
    translation: translationIT,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    initImmediate: true,
    fallbackLng: 'en',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

i18n.on('languageChanged', language => {
  store.dispatch(updateCurrentLanguage(language));
});

export default i18n;
