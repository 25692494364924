import {Box, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React from 'react';

interface Props {
  organizationName: string;
  handleNameChange: (name: string) => void;
}

export const ChangeOrganizationDetails = ({organizationName, handleNameChange}: Props) => {
  return (
    <Box padding="2rem 0 0">
      <Typography variant="subtitle1" align="left">
        Change the organization properties
      </Typography>
      <TextField
        error={null}
        fullWidth
        id="username"
        name="username"
        value={organizationName}
        placeholder={'Organization name'}
        margin="normal"
        variant="outlined"
        onChange={event => {
          handleNameChange(event.target.value);
        }}
        required
        autoComplete="off"
      />
    </Box>
  );
};
