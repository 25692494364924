import React from 'react';
import styled from 'styled-components';
import {createStyles, Theme, makeStyles, Paper, Typography, Hidden} from '@material-ui/core';

const Container = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  padding: 2rem;
`;

const ColorBox = styled(Paper)<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  & > div {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: ${({color}) => color};
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  legends: {
    color: string;
    label: string;
  }[];
}

const SingleColorLegend = ({legends}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Hidden smDown implementation="css">
      <Container>
        <Paper className={classes.root}>
          {legends.map(({color, label}) => (
            <div key={label} style={{display: 'flex'}}>
              <ColorBox color={color}>
                <div />
              </ColorBox>
              <Typography variant="body1">{label}</Typography>
            </div>
          ))}
        </Paper>
      </Container>
    </Hidden>
  );
};

export default SingleColorLegend;
