import React, {useEffect, useState} from 'react';
import {IPolygonEntity} from '@deep-planet/api-interfaces';
import {Box, Button, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {polygon, Position, area} from '@turf/turf';
import Spinner from '../../../../components/UI/Spinner';
import CreateSeasonModal from './CreateSeasonModal';
import {historicalYieldSelector, postHistoricalYieldSelector, selectedOrganizationSelector, varietiesSelector} from '../../../../store/selectors';
import {Season} from '../../../../store/reducers/farm';
import {usePrevious} from '../../../../hooks/usePrevious';
import {getVarieties, saveYield, getHistoricalYield} from '../../../../store/actions';
import HistoricalYieldMap from './HistoricalYieldMap';
import HistoricalYieldTable from './HistoricalYieldTable';
import SaveHistoricalYieldTable from './SaveHistoricalYieldTable';

interface Props {
  selectedPolygon: IPolygonEntity;
  seasons: Season[];
}

export interface SeasonalYield extends Season {
  harvestId: number;
  yield?: number;
  baume?: number;
  pH?: number;
  titratableAcid?: number;
  harvestDate?: Date;
  variety?: string;
}

export interface HistoricalSeasonYield extends SeasonalYield {
  yieldId: string;
}

export type NumericProperty = 'yield' | 'baume' | 'pH' | 'titratableAcid';

export interface IFormError {
  seasonId: string;
  harvestId: number;
  error: 'yield' | 'harvestDate' | 'variety';
}

const HistoricalYieldForm = ({selectedPolygon, seasons}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seasonsToDisplay, setSeasonsToDisplay] = useState<SeasonalYield[]>();
  const [historicalSeasonsToDisplay, setHistoricalSeasonsToDisplay] = useState<HistoricalSeasonYield[]>();
  const [errors, setErrors] = useState<IFormError[]>([]);

  const varieties = useSelector(varietiesSelector);
  const historicalYield = useSelector(historicalYieldSelector);
  const {loading} = useSelector(postHistoricalYieldSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const dispatch = useDispatch();
  const previousSelectedPolygon = usePrevious(selectedPolygon);
  const prevSelectedOrganization = usePrevious(selectedOrganization);
  const {t} = useTranslation();

  const turfPolygon = polygon(selectedPolygon.geoJson.geometry.coordinates as Position[][]);
  const polygonArea = (area(turfPolygon) / 10000).toFixed(2);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteHarvest = (seasonId: string, harvestId: number) => {
    if (harvestId) {
      return setSeasonsToDisplay([...seasonsToDisplay].filter(s => !(s.id === seasonId && s.harvestId === harvestId)));
    }
    let idx = 0;
    const newSeasonsToDisplay = seasonsToDisplay
      .map(s => {
        if (s.id !== seasonId) return s;
        if (s.id === seasonId && s.harvestId === harvestId) return null;
        idx += 1;
        return {
          ...s,
          harvestId: idx - 1,
        };
      })
      .filter(s => !!s);
    setSeasonsToDisplay(newSeasonsToDisplay);
  };

  const handleAddValue = (seasonId: string, harvestId: number, value: number | Date, property: NumericProperty | 'harvestDate' | 'variety') => {
    setSeasonsToDisplay(
      seasonsToDisplay.map(s => {
        if (s.id === seasonId && s.harvestId === harvestId)
          return {
            ...s,
            [property]: value,
          };
        return s;
      })
    );
  };

  const handleAddHarvest = (seasonId: string) => {
    const selectedSeasons = seasonsToDisplay.filter(s => s.id === seasonId);
    const selectedSeason = selectedSeasons[selectedSeasons.length - 1];
    const index = seasonsToDisplay.lastIndexOf(selectedSeason);
    seasonsToDisplay.splice(index + 1, 0, {...selectedSeason, harvestDate: null, baume: 0, pH: 0, titratableAcid: 0, harvestId: selectedSeason.harvestId + 1});
    setSeasonsToDisplay([...seasonsToDisplay]);
  };

  const getRowSpan = (season: SeasonalYield) => {
    return season.harvestId === 0 ? seasonsToDisplay.filter(({id}) => id === season.id).length : 1;
  };

  const getNumericValue = (seasonId: string, harvestId: number, property: NumericProperty) => {
    return seasonsToDisplay.find(s => s.id === seasonId && s.harvestId === harvestId)[property] || '';
  };

  const getVarietyValue = (seasonId: string, harvestId: number) => {
    const defaultVariety = varieties?.find(({name}) => name === selectedPolygon.geoJson.properties.cropType)?.name || '';

    const selectedVariety = seasonsToDisplay.find(s => s.id === seasonId && s.harvestId === harvestId)?.['variety'];
    if (selectedVariety) return selectedVariety;
    return defaultVariety;
  };

  const getDateValue = (seasonId: string, harvestId: number) => {
    return seasonsToDisplay.find(s => s.id === seasonId && s.harvestId === harvestId)['harvestDate'] || null;
  };

  const handleSave = () => {
    const errors: IFormError[] = [];
    for (const {harvestDate, yield: yieldValue, harvestId, id, baume, pH, titratableAcid, variety} of seasonsToDisplay) {
      if (baume || pH || titratableAcid) {
        if (!harvestDate) {
          errors.push({seasonId: id, harvestId, error: 'harvestDate'});
        }
        if (!yieldValue) {
          errors.push({seasonId: id, harvestId, error: 'yield'});
        }
        if (!variety) {
          errors.push({seasonId: id, harvestId, error: 'variety'});
        }
      }
    }
    setErrors(errors);
    if (!errors.length) {
      const historicalYields = seasonsToDisplay
        .map(s => {
          return {
            seasonId: s.id,
            varietyId: varieties.find(v => v.name === s.variety).id,
            harvestDate: s.harvestDate,
            yield: s.yield,
            baume: s.baume,
            pH: s.pH,
            titratableAcid: s.titratableAcid,
          };
        })
        .filter(y => y.harvestDate && y.yield);
      if (historicalYields.length) {
        dispatch(saveYield({polygonId: selectedPolygon.id, organizationId: selectedOrganization.id, historicalYields}));

        const newSeasonsToDisplay = seasonsToDisplay.map(s => {
          const filled = historicalYields.find(y => y.seasonId === s.id && s.harvestDate === y.harvestDate && s.yield === y.yield);
          if (filled)
            return {
              ...s,
              yield: 0,
              baume: 0,
              pH: 0,
              titratableAcid: 0,
              harvestDate: null,
            };
          return {
            ...s,
          };
        });
        setSeasonsToDisplay(newSeasonsToDisplay);
      }
    }
  };

  useEffect(() => {
    dispatch(getVarieties());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHistoricalYield());
  }, [dispatch]);

  // in case of changing selected organization
  useEffect(() => {
    if (selectedOrganization && prevSelectedOrganization && selectedOrganization.id !== prevSelectedOrganization.id) {
      dispatch(getHistoricalYield(selectedOrganization?.id));
    }
  }, [dispatch, prevSelectedOrganization, selectedOrganization]);

  useEffect(() => {
    if ((historicalYield && seasons && seasons.length) || (previousSelectedPolygon && selectedPolygon && previousSelectedPolygon.id !== selectedPolygon.id)) {
      const defaultVariety = varieties?.find(({name}) => name === selectedPolygon.geoJson.properties.cropType)?.name || '';
      setErrors([]);
      const historicalYieldForBlock = historicalYield.filter(y => y.polygonId === selectedPolygon.id);
      if (historicalYieldForBlock.length) {
        const historicalSeasonsToDisplay = seasons
          .map(season => {
            const seasonalYieldData = historicalYieldForBlock.filter(y => y.seasonId === season.id);
            if (seasonalYieldData.length) {
              return seasonalYieldData.map(y => ({
                ...season,
                yieldId: y.id,
                variety: y.varietyName,
                harvestId: 0,
                yield: Number(y.yield),
                baume: Number(y.baume),
                pH: Number(y.pH),
                titratableAcid: Number(y.titratableAcid),
                harvestDate: y.harvestDate,
              }));
            }
          })
          .filter(i => i)
          .flat();
        setHistoricalSeasonsToDisplay(historicalSeasonsToDisplay);
        setSeasonsToDisplay(seasons.map(season => ({...season, variety: defaultVariety, harvestId: 0})));
      } else {
        setSeasonsToDisplay(seasons.map(season => ({...season, variety: defaultVariety, harvestId: 0})));
        setHistoricalSeasonsToDisplay([]);
      }
    }
  }, [historicalYield, previousSelectedPolygon, seasons, selectedPolygon, varieties]);

  return (
    <>
      <Box p={2} height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">{selectedPolygon.name}</Typography>
            <Typography variant="subtitle2">{`${polygonArea} ${t('polygon.info.hectares')}`}</Typography>
          </Box>
          <HistoricalYieldMap selectedPolygon={selectedPolygon} />

          <Box display="flex" flexDirection="column" justifyContent="flex-start" marginTop={4}>
            <HistoricalYieldTable historicalRows={historicalSeasonsToDisplay} />
            <SaveHistoricalYieldTable
              varieties={varieties}
              rows={seasonsToDisplay}
              handleAddValue={handleAddValue}
              getVarietyValue={getVarietyValue}
              handleAddHarvest={handleAddHarvest}
              handleDeleteHarvest={handleDeleteHarvest}
              getDateValue={getDateValue}
              getRowSpan={getRowSpan}
              getNumericValue={getNumericValue}
              errors={errors}
              polygonId={selectedPolygon.id}
            />
            <Box width="200px" marginTop={2}>
              <Button variant="outlined" color="primary" fullWidth onClick={handleOpenModal}>
                {t('yield.season.new')}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" paddingBottom={2}>
          <Box width="200px">
            <Button disabled={loading} variant="contained" color="primary" fullWidth onClick={handleSave}>
              {loading && <Spinner size={15} color="primary" />}
              {t('forms.save')}
            </Button>
          </Box>
        </Box>
      </Box>
      {isModalOpen && <CreateSeasonModal isOpen={isModalOpen} handleClose={handleCloseModal} />}
    </>
  );
};

export default HistoricalYieldForm;
