import {Box, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';

const SugarMeasureSwitcher = ({withSugar = true, metric, handleSwitch, harvest = false}) => {
  const {t} = useTranslation();

  const boxStyle = {
    backgroundColor: harvest ? 'transparent' : 'white',
    padding: '1px 14px',
    borderRadius: '3px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  };

  const formControlLabelStyle = {
    marginRight: '6px',
  };

  return (
    <Box style={boxStyle} display="flex" alignItems="center">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={metric || 'baume'}
        value={metric}
        onChange={m => handleSwitch(m.target.value)}
        style={{flexDirection: 'row'}}
      >
        <FormControlLabel style={formControlLabelStyle} value="baume" control={<Radio />} label="Baume" />
        <FormControlLabel style={formControlLabelStyle} value="brix" control={<Radio />} label="Brix" />
        {withSugar && <FormControlLabel style={formControlLabelStyle} value="sugar" control={<Radio />} label={t('switch.type.sugar')} />}
      </RadioGroup>
    </Box>
  );
};

export default React.memo(SugarMeasureSwitcher);
