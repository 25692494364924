import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {NewPasswordRequired} from '@deep-planet/react-cognito';
import NewPasswordForm from './Form';
import AuthSplash from '../../UI/AuthSplash';
import {ButtonAlt, ConfirmMessage} from '../../UI/Form';
import {withTranslation} from 'react-i18next';

class LoginPage extends Component {
  render() {
    const {t} = this.props;
    return (
      <AuthSplash>
        <ConfirmMessage variant="body1">{t('auth.login.fist.title')}</ConfirmMessage>
        <NewPasswordRequired>
          <NewPasswordForm />
        </NewPasswordRequired>
        <ButtonAlt>
          <Link to="/login">{t('forms.sign.in')}</Link>
        </ButtonAlt>
      </AuthSplash>
    );
  }
}

export default withTranslation()(LoginPage);
