enum ActionTypes {
  // General
  UPDATE_PREV_PATH = 'UPDATE_PREV_PATH',
  UPDATE_CURRENT_LANGUAGE = 'UPDATE_CURRENT_LANGUAGE',

  // Snackbar
  ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR = 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR = 'REMOVE_SNACKBAR',

  //User
  USER_GET_START = 'USER_GET_START',
  USER_GET_SUCCESS = 'USER_GET_SUCCESS',
  USER_GET_FAIL = 'USER_GET_FAIL',
  USERS_GET_START = 'USERS_GET_START',
  USERS_GET_SUCCESS = 'USERS_GET_SUCCESS',
  USERS_GET_FAIL = 'USERS_GET_FAIL',
  USER_POST_INIT = 'USER_POST_INIT',
  USER_POST_START = 'USER_POST_START',
  USER_POST_SUCCESS = 'USER_POST_SUCCESS',
  USER_POST_FAIL = 'USER_POST_FAIL',
  USER_PUT_START = 'USER_PUT_START',
  USER_PUT_SUCCESS = 'USER_PUT_SUCCESS',
  USER_PUT_FAIL = 'USER_PUT_FAIL',
  USER_PUT_PREFERENCES_START = 'USER_PUT_PREFERENCES_START',
  USER_PUT_PREFERENCES_SUCCESS = 'USER_PUT_PREFERENCES_SUCCESS',
  USER_PUT_PREFERENCES_FAIL = 'USER_PUT_PREFERENCES_FAIL',

  // Polygon
  POLYGON_GET_START = 'POLYGON_GET_START',
  POLYGON_GET_SUCCESS = 'POLYGON_GET_SUCCESS',
  POLYGON_GET_FAIL = 'POLYGON_GET_FAIL',
  POLYGON_POST_START = 'POLYGON_POST_START',
  POLYGON_POST_SUCCESS = 'POLYGON_POST_SUCCESS',
  POLYGON_POST_FAIL = 'POLYGON_POST_FAIL',
  POLYGON_DELETE_START = 'POLYGON_DELETE_START',
  POLYGON_DELETE_SUCCESS = 'POLYGON_DELETE_SUCCESS',
  POLYGON_DELETE_FAIL = 'POLYGON_DELETE_FAIL',
  POLYGON_PUT_START = 'POLYGON_PUT_START',
  POLYGON_PUT_SUCCESS = 'POLYGON_PUT_SUCCESS',
  POLYGON_PUT_FAIL = 'POLYGON_PUT_FAIL',
  POLYGON_RESET_STATE = 'POLYGON_RESET_STATE',

  // Farm
  FARM_GET_START = 'FARM_GET_START',
  FARM_GET_SUCCESS = 'FARM_GET_SUCCESS',
  FARM_GET_FAIL = 'FARM_GET_FAIL',
  FARM_POST_INIT = 'FARM_POST_INIT',
  FARM_POST_START = 'FARM_POST_START',
  FARM_POST_SUCCESS = 'FARM_POST_SUCCESS',
  FARM_POST_FAIL = 'FARM_POST_FAIL',
  FARM_DELETE_START = 'FARM_DELETE_START',
  FARM_DELETE_SUCCESS = 'FARM_DELETE_SUCCESS',
  FARM_DELETE_FAIL = 'FARM_DELETE_FAIL',
  FARM_UPDATE_START = 'FARM_UPDATE_START',
  FARM_UPDATE_SUCCESS = 'FARM_UPDATE_SUCCESS',
  FARM_UPDATE_FAIL = 'FARM_UPDATE_FAIL',
  FARM_UPDATE_INDEX = 'FARM_UPDATE_INDEX',
  FARM_RESET_STATE = 'FARM_RESET_STATE',
  FARM_GET_SOIL_START = 'FARM_GET_SOIL_START',
  FARM_GET_SOIL_SUCCESS = 'FARM_GET_SOIL_SUCCESS',
  FARM_GET_SOIL_FAIL = 'FARM_GET_SOIL_FAIL',
  FARM_GET_SOILS_START = 'FARM_GET_SOILS_START',
  FARM_GET_SOILS_SUCCESS = 'FARM_GET_SOILS_SUCCESS',
  FARM_GET_SOILS_FAIL = 'FARM_GET_SOILS_FAIL',
  FARM_GET_IMAGE_START = 'FARM_GET_IMAGE_START',
  FARM_GET_IMAGE_SUCCESS = 'FARM_GET_IMAGE_SUCCESS',
  FARM_GET_IMAGE_FAIL = 'FARM_GET_IMAGE_FAIL',
  SEASONS_GET_START = 'SEASONS_GET_START',
  SEASONS_GET_SUCCESS = 'SEASONS_GET_SUCCESS',
  SEASONS_GET_FAIL = 'SEASONS_GET_FAIL',
  SEASON_POST_START = 'SEASON_POST_START',
  SEASON_POST_SUCCESS = 'SEASON_POST_SUCCESS',
  SEASON_POST_FAIL = 'SEASON_POST_FAIL',

  // SEGMENTATION
  SEG_GET_START = 'SEG_GET_START',
  SEG_GET_SUCCESS = 'SEG_GET_SUCCESS',
  SEG_GET_FAIL = 'SEG_GET_FAIL',
  SEG_POST_START = 'SEG_POST_START',
  SEG_POST_SUCCESS = 'SEG_POST_SUCCESS',
  SEG_POST_FAIL = 'SEG_POST_FAIL',
  SEG_RESET = 'SEG_RESET',

  //NDVI
  NDVI_GET_START = 'NDVI_GET_START',
  NDVI_GET_SUCCESS = 'NDVI_GET_SUCCESS',
  NDVI_GET_FAIL = 'NDVI_GET_FAIL',
  NDVI_GET_ACTIVE_DATA = 'NDVI_GET_ACTIVE_DATA',

  // Planet
  NDVI_PLANET_GET_START = 'NDVI_PLANET_GET_START',
  NDVI_PLANET_GET_SUCCESS = 'NDVI_PLANET_GET_SUCCESS',
  NDVI_PLANET_GET_FAIL = 'NDVI_PLANET_GET_FAIL',
  NDVI_PLANET_ACTIVE_DATE = 'NDVI_PLANET_ACTIVE_DATE',

  //NDWI
  NDWI_GET_START = 'NDWI_GET_START',
  NDWI_GET_SUCCESS = 'NDWI_GET_SUCCESS',
  NDWI_GET_FAIL = 'NDWI_GET_FAIL',
  NDWI_GET_ACTIVE_DATA = 'NDWI_GET_ACTIVE_DATA',

  //SOIL
  SOIL_GET_START = 'SOIL_GET_START',
  SOIL_GET_SUCCESS = 'SOIL_GET_SUCCESS',
  SOIL_GET_FAIL = 'SOIL_GET_FAIL',

  //MOISTURE SENSORS
  MOISTURESENSORS_GET_START = 'MOISTURESENSORS_GET_START',
  MOISTURESENSORS_GET_SUCCESS = 'MOISTURESENSORS_GET_SUCCESS',
  MOISTURESENSORS_GET_FAIL = 'MOISTURESENSORS_GET_FAIL',
  MOISTURE_SENSORS_POST_START = 'MOISTURE_SENSORS_POST_START',
  MOISTURE_SENSORS_POST_SUCCESS = 'MOISTURE_SENSORS_POST_SUCCESS',
  MOISTURE_SENSORS_POST_FAIL = 'MOISTURE_SENSORS_POST_FAIL',

  // YIELD
  PREDICTED_YIELD_GET_START = 'PREDICTED_YIELD_GET_START',
  PREDICTED_YIELD_GET_SUCCESS = 'PREDICTED_YIELD_GET_SUCCESS',
  PREDICTED_YIELD_GET_FAIL = 'PREDICTED_YIELD_GET_FAIL',
  YIELD_GET_START = 'YIELD_GET_START',
  YIELD_GET_SUCCESS = 'YIELD_GET_SUCCESS',
  YIELD_GET_FAIL = 'YIELD_GET_FAIL',
  YIELD_POST_START = 'YIELD_POST_START',
  YIELD_POST_SUCCESS = 'YIELD_POST_SUCCESS',
  YIELD_POST_FAIL = 'YIELD_POST_FAIL',
  YIELD_DELETE_START = 'YIELD_DELETE_START',
  YIELD_DELETE_SUCCESS = 'YIELD_DELETE_SUCCESS',
  YIELD_DELETE_FAIL = 'YIELD_DELETE_FAIL',
  PREDICTED_MATURITY_GET_START = 'MATURITY_GET_START',
  PREDICTED_MATURITY_GET_SUCCESS = 'MATURITY_GET_SUCCESS',
  PREDICTED_MATURITY_GET_FAIL = 'MATURITY_GET_FAIL',

  HISTORICAL_MATURITY_GET_START = 'HISTORICAL_MATURITY_GET_START',
  HISTORICAL_MATURITY_GET_SUCCESS = 'HISTORICAL_MATURITY_GET_SUCCESS',
  HISTORICAL_MATURITY_GET_FAIL = 'HISTORICAL_MATURITY_GET_FAIL',

  // Weather
  CURR_WEATHER_GET_START = 'CURR_WEATHER_GET_START',
  CURR_WEATHER_GET_SUCCESS = 'CURR_WEATHER_GET_SUCCESS',
  CURR_WEATHER_GET_FAIL = 'CURR_WEATHER_GET_FAIL',
  FORECAST_GET_START = 'FORECAST_GET_START',
  FORECAST_GET_SUCCESS = 'FORECAST_GET_SUCCESS',
  FORECAST_GET_FAIL = 'FORECAST_GET_FAIL',

  //  Notes
  NOTES_GET_START = 'NOTES_GET_START',
  NOTES_GET_SUCCESS = 'NOTES_GET_SUCCESS',
  NOTES_GET_FAIL = 'NOTES_GET_FAIL',
  NOTE_POST_START = 'NOTE_POST_START',
  NOTE_POST_SUCCESS = 'NOTE_POST_SUCCESS',
  NOTE_POST_FAIL = 'NOTE_POST_FAIL',
  NOTE_UPDATE_START = 'NOTE_UPDATE_START',
  NOTE_UPDATE_SUCCESS = 'NOTE_UPDATE_SUCCESS',
  NOTE_UPDATE_FAIL = 'NOTE_UPDATE_FAIL',
  NOTE_DELETE_START = 'NOTE_DELETE_START',
  NOTE_DELETE_SUCCESS = 'NOTE_DELETE_SUCCESS',
  NOTE_DELETE_FAIL = 'NOTE_DELETE_FAIL',
  NOTE_ATTACHMENT_DELETE_START = 'NOTE_ATTACHMENT_DELETE_START',
  NOTE_ATTACHMENT_DELETE_SUCCESS = 'NOTE_ATTACHMENT_DELETE_SUCCESS',
  NOTE_ATTACHMENT_DELETE_FAIL = 'NOTE_ATTACHMENT_DELETE_FAIL',
  NOTE_SHARE_START = 'NOTE_SHARE_START',
  NOTE_SHARE_SUCCESS = 'NOTE_SHARE_SUCCESS',
  NOTE_SHARE_FAIL = 'NOTE_SHARE_FAIL',

  // Crop
  VARIETIES_GET_START = 'VARIETIES_GET_START',
  VARIETIES_GET_SUCCESS = 'VARIETIES_GET_SUCCESS',
  VARIETIES_GET_FAIL = 'VARIETIES_GET_FAIL',

  // Feedback
  FEEDBACK_POST_START = 'FEEDBACK_POST_START',
  FEEDBACK_POST_SUCCESS = 'FEEDBACK_POST_SUCCESS',
  FEEDBACK_POST_FAIL = 'FEEDBACK_POST_FAIL',

  // Organization
  ORGANIZATIONS_GET_START = 'ORGANIZATIONS_GET_START',
  ORGANIZATIONS_GET_SUCCESS = 'ORGANIZATIONS_GET_SUCCESS',
  ORGANIZATIONS_GET_FAIL = 'ORGANIZATIONS_GET_FAIL',
  SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION',

  // Maturity
  MATURITY_IMAGE_GET_START = 'MATURITY_IMAGE_GET_START',
  MATURITY_IMAGE_GET_SUCCESS = 'MATURITY_IMAGE_GET_SUCCESS',
  MATURITY_IMAGE_GET_FAIL = 'MATURITY_IMAGE_GET_FAIL',
  MATURITY_IMAGE_SET_SELECTED_DATE = 'MATURITY_IMAGE_SET_SELECTED_DATE',

  // Soil Nutrient farm
  SOIL_NUTRIENT_FARM_GET_SUCCESS = 'SOIL_NUTRIENT_FARM_GET_SUCCESS',
  SOIL_NUTRIENT_FARM_GET_START = 'SOIL_NUTRIENT_FARM_GET_START',
  SOIL_NUTRIENT_FARM_GET_FAIL = 'SOIL_NUTRIENT_FARM_GET_FAIL',

  // Soil nutrient image
  SOIL_NUTRIENT_IMAGE_GET_SUCCESS = 'SOIL_NUTRIENT_IMAGE_GET_SUCCESS',
  SOIL_NUTRIENT_IMAGE_TYPE_GET_SUCCESS = 'SOIL_NUTRIENT_IMAGE_TYPE_GET_SUCCESS',
  SOIL_NUTRIENT_IMAGE_GET_START = 'SOIL_NUTRIENT_IMAGE_GET_START',
  SOIL_NUTRIENT_IMAGE_GET_FAIL = 'SOIL_NUTRIENT_IMAGE_GET_FAIL',
  SOIL_NUTRIENT_SET_SELECTED_DATE = 'SOIL_NUTRIENT_SET_SELECTED_DATE',

  // Irrigation farm
  IRRIGATION_FARM_GET_SUCCESS = 'IRRIGATION_FARM_GET_SUCCESS',
  IRRIGATION_FARM_GET_START = 'IRRIGATION_FARM_GET_START',
  IRRIGATION_FARM_GET_FAIL = 'IRRIGATION_FARM_GET_FAIL',

  // Irrigation image
  IRRIGATION_IMAGE_GET_SUCCESS = 'IRRIGATION_IMAGE_GET_SUCCESS',
  IRRIGATION_IMAGE_TYPE_GET_SUCCESS = 'IRRIGATION_IMAGE_TYPE_GET_SUCCESS',
  IRRIGATION_IMAGE_GET_START = 'IRRIGATION_IMAGE_GET_START',
  IRRIGATION_IMAGE_GET_FAIL = 'IRRIGATION_IMAGE_GET_FAIL',
  IRRIGATION_SET_SELECTED_DATE = 'IRRIGATION_SET_SELECTED_DATE',

  // Soil Organic Carbon farm
  SOIL_ORGANIC_CARBON_FARM_GET_SUCCESS = 'SOIL_ORGANIC_CARBON_FARM_GET_SUCCESS',
  SOIL_ORGANIC_CARBON_FARM_GET_START = 'SOIL_ORGANIC_CARBON_FARM_GET_START',
  SOIL_ORGANIC_CARBON_FARM_GET_FAIL = 'SOIL_ORGANIC_CARBON_FARM_GET_FAIL',

  // Soil Organic Carbon image
  SOIL_ORGANIC_CARBON_IMAGE_GET_SUCCESS = 'SOIL_ORGANIC_CARBON_IMAGE_GET_SUCCESS',
  SOIL_ORGANIC_CARBON_IMAGE_TYPE_GET_SUCCESS = 'SOIL_ORGANIC_CARBON_IMAGE_TYPE_GET_SUCCESS',
  SOIL_ORGANIC_CARBON_IMAGE_GET_START = 'SOIL_ORGANIC_CARBON_IMAGE_GET_START',
  SOIL_ORGANIC_CARBON_IMAGE_GET_FAIL = 'SOIL_ORGANIC_CARBON_IMAGE_GET_FAIL',
  SOIL_ORGANIC_CARBON_SET_SELECTED_DATE = 'SOIL_ORGANIC_CARBON_SET_SELECTED_DATE',

  // Yield Per Pixel
  YIELD_PER_PIXEL_GET_START = 'YIELD_PER_PIXEL_GET_START',
  YIELD_PER_PIXEL_GET_SUCCESS = 'YIELD_PER_PIXEL_GET_SUCCESS',
  YIELD_PER_PIXEL_GET_FAIL = 'YIELD_PER_PIXEL_GET_FAIL',
  YIELD_PER_PIXEL_SET_SELECTED_DATE = 'YIELD_PER_PIXEL_SET_SELECTED_DATE',

  // Markers
  MARKER_GET_START = 'MARKER_GET_START',
  MARKER_GET_SUCCESS = 'MARKER_GET_SUCCESS',
  MARKER_GET_FAIL = 'MARKER_GET_FAIL',
  MARKER_SET_SELECTED_DATE = 'MARKER_SET_SELECTED_DATE',
  MARKER_POST_INIT = 'MARKER_POST_INIT',
  MARKER_POST_START = 'MARKER_POST_START',
  MARKER_POST_SUCCESS = 'MARKER_POST_SUCCESS',
  MARKER_PUT_SUCCESS = 'MARKER_PUT_SUCCESS',
  MARKER_POST_FAIL = 'MARKER_POST_FAIL',
  MARKER_DELETE_START = 'MARKER_DELETE_START',
  MARKER_DELETE_SUCCESS = 'MARKER_DELETE_SUCCESS',
  MARKER_DELETE_FAIL = 'MARKER__DELETE_FAIL',

  // marker colors
  MARKER_COLOR_GET_START = 'MARKER_COLOR_GET_START',
  MARKER_COLOR_GET_SUCCESS = 'MARKER_COLOR_GET_SUCCESS',
  MARKER_COLOR_GET_FAIL = 'MARKER_COLOR_GET_FAIL',

  // Disease Per Pixel
  DISEASE_PER_PIXEL_GET_START = 'DISEASE_PER_PIXEL_GET_START',
  DISEASE_PER_PIXEL_GET_SUCCESS = 'DISEASE_PER_PIXEL_GET_SUCCESS',
  DISEASE_PER_PIXEL_GET_FAIL = 'DISEASE_PER_PIXEL_GET_FAIL',
  DISEASE_PER_PIXEL_SET_SELECTED_DATE = 'DISEASE_PER_PIXEL_SET_SELECTED_DATE',

  // Curves
  CURVE_GET_START = 'CURVE_GET_START',
  CURVE_GET_SUCCESS = 'CURVE_GET_SUCCESS',
  CURVE_GET_FAIL = 'CURVE_GET_FAIL',

  //  FILE
  FILE_GET_START = 'FILES_GET_START',
  FILE_GET_SUCCESS = 'FILES_GET_SUCCESS',
  FILE_GET_FAIL = 'FILES_GET_FAIL',
  FILE_POST_START = 'FILE_POST_START',
  FILE_POST_SUCCESS = 'FILE_POST_SUCCESS',
  FILE_POST_FAIL = 'FILE_POST_FAIL',
  FILE_DELETE_START = 'FILE_DELETE_START',
  FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS',
  FILE_DELETE_FAIL = 'FILE_DELETE_FAIL',
  FILE_SHARE_START = 'FILE_SHARE_START',
  FILE_SHARE_SUCCESS = 'FILE_SHARE_SUCCESS',
  FILE_SHARE_FAIL = 'FILE_SHARE_FAIL',
  FILE_TYPE_GET_START = 'FILE_TYPE_GET_START',
  FILE_TYPE_GET_SUCCESS = 'FILES_TYPE_GET_SUCCESS',
  FILE_TYPE_GET_FAIL = 'FILES_TYPE_GET_FAIL',

  //  SEASON INDICES
  SEASON_INDICES_GET_START = 'SEASON_INDICES_GET_START',
  SEASON_INDICES_GET_SUCCESS = 'SEASON_INDICES_GET_SUCCESS',
  SEASON_INDICES_GET_FAIL = 'SEASON_INDICES_GET_FAIL',
}

export default ActionTypes;
