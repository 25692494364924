export const getStickyColumns = (column, isSuperAdmin) => {
  let stickyColumn = false;
  let width: number;
  if (isSuperAdmin)
    //for admin
    switch (column.id) {
      case 'organizationName':
        stickyColumn = true;
        width = 0; //px
        break;
      case 'farmName':
        stickyColumn = true;
        width = 150; //px
        break;
      case 'polygonName':
        width = 300; //px
        stickyColumn = true;
    }
  // for customers
  else
    switch (column.id) {
      case 'farmName':
        stickyColumn = true;
        width = 0; //px
        break;
      case 'polygonName':
        width = 150; //px
        stickyColumn = true;
    }

  return {stickyColumn, width};
};
