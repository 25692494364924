import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
  getSegment: {
    success: false,
    error: null,
    loading: false,
  },
  postSegment: {
    success: false,
    error: null,
    loading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEG_GET_START:
      return updateObject(state, {
        getSegment: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.SEG_GET_SUCCESS:
      return updateObject(state, {
        segment: action.data,
        getSegment: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.SEG_GET_FAIL:
      return updateObject(state, {
        getSegment: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.SEG_POST_START:
      return updateObject(state, {
        postSegment: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.SEG_POST_SUCCESS:
      return updateObject(state, {
        segment: action.data,
        postSegment: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.SEG_POST_FAIL:
      return updateObject(state, {
        postSegment: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.SEG_RESET:
      return updateObject(state, {
        segment: null,
      });
    default:
      return state;
  }
};

export default reducer;
