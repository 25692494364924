import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Backdrop, Box, Button, Fade, Modal as MaterialModal, ModalProps, Typography} from '@material-ui/core';
import theme from '../../../utilities/theme';
import React from 'react';
import Spinner from '../../../components/UI/Spinner';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = styled(IconButton)`
  height: fit-content;
  position: absolute !important;
  top: 0;
  right: 0;
`;

const StyledModal = styled(MaterialModal)<ModalProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('sm')} {
    display: block;
    overflow: auto;
    height: 100%;
    position: absolute !important;
  }
`;

const StyledModalBody = styled.div<{modalWidth: string}>`
  border-radius: 5px;
  height: auto;
  width: ${({modalWidth}) => modalWidth || '900px'};
  position: absolute;
  background-color: white;
  border: 2px solid #000;
  padding: 20px;
  max-height: 80%;
  overflow: auto;
  ${theme.breakpoints.down('sm')} {
    height: auto;
    min-height: 100%;
    width: 100%;
    max-height: unset;
  }
`;

interface Props {
  title: string;
  submitText?: string;
  isOpen: boolean;
  isSubmitButtonDisabled: boolean;
  isLoading: boolean;
  modalWidth?: string;
  handleClose: () => void;
  handleSubmit?: () => void;
  children: React.ReactElement;
}

const Modal = ({title, submitText, isOpen, modalWidth, handleClose, isLoading, isSubmitButtonDisabled, handleSubmit, children}: Props) => {
  const {t} = useTranslation();
  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <StyledModalBody modalWidth={modalWidth}>
          <Box mb={2}>
            <Typography variant="h4">{title}</Typography>
          </Box>
          {children}
          <Box display="flex" justifyContent="flex-end">
            {submitText && (
              <>
                <Button disabled={isLoading} onClick={handleClose} color="primary" variant="text">
                  {t('forms.cancel')}
                </Button>
                <Button disabled={isSubmitButtonDisabled} color="primary" variant="contained" onClick={handleSubmit}>
                  {submitText}
                  {isLoading && <Spinner size={15} color="primary" />}
                </Button>
              </>
            )}
          </Box>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </StyledModalBody>
      </Fade>
    </StyledModal>
  );
};

export default Modal;
