import {Box, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../utilities/theme';
import {useTranslation} from 'react-i18next';

interface Props {
  withSugar?: boolean;
  metric: string;
  handleSwitch: (value: string) => void;
}

const StyledBox = styled(Box)<{withsugar: boolean}>`
  background-color: white;
  padding: 1px 14px;
  border-radius: 3px;
  position: absolute;
  left: ${i => (i.withsugar ? '183px' : '280px')};
  margin-top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  z-index: 1;

  ${theme.breakpoints.only('sm')} {
    height: calc(100% - 121px);
    flex-direction: column;
    left: ${i => (i.withsugar ? '10px' : '60px')};
    height: 40px;
    margin-top: ${i => (i.withsugar ? '52px' : '7px')};
  }

  ${theme.breakpoints.only('xs')} {
    height: calc(100% - 121px);
    flex-direction: column;
    left: ${i => (i.withsugar ? '10px' : '60px')};
    width: calc(100% - 103px);
    height: 40px;
    margin-top: ${i => (i.withsugar ? '52px' : '7px')};
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`;

const SugarMeasureSwitcher = ({withSugar = true, metric, handleSwitch}: Props) => {
  const {t} = useTranslation();

  return (
    <StyledBox withsugar={withSugar} display="flex" alignItems="center">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        defaultValue={metric ? metric : 'baume'}
        value={metric}
        onChange={m => handleSwitch(m.target.value)}
      >
        <FormControlLabel style={{marginRight: '6px'}} value="baume" control={<Radio />} label="Baume" />
        <FormControlLabel style={{marginRight: '6px'}} value="brix" control={<Radio />} label="Brix" />
        {withSugar && <FormControlLabel style={{marginRight: '6px'}} value="sugar" control={<Radio />} label={t('switch.type.sugar')} />}
      </RadioGroup>
    </StyledBox>
  );
};

export default React.memo(SugarMeasureSwitcher);
