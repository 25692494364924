import {IFarmEntity, IOrganizationEntity, IrrigationMapDTO} from '@deep-planet/api-interfaces';
import {Box, Button} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import Spinner from '../../../../../../../components/UI/Spinner';
import {baseSoilNutrientUrl} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';
import theme from '../../../../../../../utilities/theme';
import {FarmType} from './FarmType';
import DatePeriodPicker from 'apps/web-portal-ui/src/app/components/UI/Pickers/DatePeriodPicker';

const FormWrapper = styled(Box)`
  width: 50%;
  ${theme.breakpoints.down('lg')} {
    width: 75%;
  }
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
}

export const Irrigation = ({farm, organization}: Props) => {
  const [selectedFarmType, setSelectedFarmType] = useState('FARMLAND');
  const [fromDate, setFromDate] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 14)));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload: IrrigationMapDTO = {
        fromDate: fromDate,
        toDate: toDate,
        farmId: farm.id,
      };
      await axios.post<any>(`${baseSoilNutrientUrl}/soil/irrigation/farm`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      dispatch(enqueueSnackbar({message: 'Irrigation images are generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <FormWrapper width="100%">
        <form>
          <Box display="display" flexDirection="column">
            <FarmType farmType={selectedFarmType} handleFarmType={setSelectedFarmType} loading={loading} />
            <Box marginTop="2rem" width="100%">
              <DatePeriodPicker disableToolbar={false} startDate={fromDate} endDate={toDate} handleStartDateChange={setFromDate} handleEndDateChange={setToDate} disableFuture={true} />
            </Box>
            <Box marginTop="2rem" width="100%"></Box>
            <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
                Submit
                {loading && <Spinner size={15} color="primary" />}
              </Button>
            </Box>
          </Box>
        </form>
      </FormWrapper>
    </Box>
  );
};
