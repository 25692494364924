import {IIrrigationHistoricalUI, IIrrigationPredictionUI} from '@deep-planet/api-interfaces';
import {Box, Button, ButtonGroup} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Plotly from 'react-plotly.js';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';

interface Props {
  irrigationPredictions: IIrrigationPredictionUI[];
  irrigationHistorical: IIrrigationHistoricalUI[];
  date?: Date;
  blockName?: string;
}

const Plot = ({irrigationPredictions, irrigationHistorical, date, blockName}: Props) => {
  const {t} = useTranslation();
  const [y1AxisData, setY1AxisData] = useState<number[]>([0]);
  const [y2AxisData, setY2AxisData] = useState<number[]>([0]);
  const [dateRange, setDateRange] = useState<Date[]>();
  const [selectedType, setSelectedType] = useState<'HISTORICAL' | 'FUTURE'>('FUTURE');
  const [y1AxisText, setY1AxisText] = useState<string>();
  const [y2AxisText, setY2AxisText] = useState<string>();
  const [title, setTitle] = useState<string>();

  const handleOnClick = selectedType => {
    setSelectedType(selectedType);
    if (selectedType === 'HISTORICAL') {
      if (irrigationHistorical.length) {
        setY1AxisData(irrigationHistorical?.map(({cumulativeRainfall}) => cumulativeRainfall));
        setY2AxisData(irrigationHistorical?.map(({cumulativeET}) => cumulativeET));
        setDateRange(irrigationHistorical?.map(({date}) => date));
        setY1AxisText(t('irrigation.cumulative.rain'));
        setY2AxisText(t('irrigation.cumulative.et'));
        setTitle('mm');
      }
    } else {
      if (irrigationPredictions.length) {
        setY1AxisData(irrigationPredictions.map(({potentialET}) => potentialET));
        setY2AxisData(irrigationPredictions.map(({cumulativeET}) => cumulativeET));
        setDateRange(irrigationPredictions.map(({futureDate}) => futureDate));
        setY1AxisText(t('irrigation.potential.et'));
        setY2AxisText(t('irrigation.cumulative.et'));
        setTitle(t('irrigation.evaportranspiration'));
      }
    }
  };

  useEffect(() => {
    if (selectedType === 'HISTORICAL') {
      setY1AxisData(irrigationHistorical?.map(({cumulativeRainfall}) => cumulativeRainfall));
      setY2AxisData(irrigationHistorical?.map(({cumulativeET}) => cumulativeET));
      setDateRange(irrigationHistorical?.map(({date}) => date));
      setY1AxisText(t('irrigation.cumulative.rain'));
      setY2AxisText(t('irrigation.cumulative.et'));
      setTitle('mm');
    } else {
      setY1AxisData(irrigationPredictions?.map(({potentialET}) => potentialET));
      setY2AxisData(irrigationPredictions?.map(({cumulativeET}) => cumulativeET));
      setDateRange(irrigationPredictions?.map(({futureDate}) => futureDate));
      setY1AxisText(t('irrigation.potential.et'));
      setY2AxisText(t('irrigation.cumulative.et'));
      setTitle(t('irrigation.evaportranspiration'));
    }
  }, [irrigationHistorical, irrigationPredictions, selectedType, t]);

  const trace1: Plotly.Data = {
    x: dateRange,
    y: y1AxisData,
    type: 'scatter',
    line: {
      color: 'blue',
      width: 3,
      shape: 'spline',
    },
    marker: {
      color: 'red',
    },
    name: y1AxisText,
  };

  const trace2: Plotly.Data = {
    x: dateRange,
    y: y2AxisData,
    type: 'scatter',
    line: {
      color: 'rgb(178, 255, 102)',
      width: 3,
      shape: 'spline',
    },
    marker: {
      color: 'red',
    },
    name: y2AxisText,
    yaxis: selectedType === 'FUTURE' && 'y2',
  };

  const data = [trace1, trace2];

  return (
    <div>
      {!(irrigationPredictions && irrigationHistorical) ? (
        <MapPlaceholderNoData light left="241px" width="calc(100% - 241px)" title={t('harvest.no.data')} />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <ButtonGroup variant="outlined" aria-label="outlined button group" style={{marginBottom: '20px'}}>
            <Button
              variant={selectedType === 'HISTORICAL' ? 'contained' : 'outlined'}
              disabled={!irrigationHistorical.length ? true : false}
              color={selectedType === 'HISTORICAL' ? 'primary' : 'default'}
              onClick={() => handleOnClick('HISTORICAL')}
            >
              Historical
            </Button>
            <Button variant={selectedType === 'FUTURE' ? 'contained' : 'outlined'} color={selectedType === 'FUTURE' ? 'primary' : 'default'} onClick={() => handleOnClick('FUTURE')}>
              Future
            </Button>
          </ButtonGroup>
          <Plotly
            data={data}
            layout={{
              title: t('polygon.info.block.name') + ' ' + blockName,
              xaxis: {type: 'date'},
              yaxis: {title: title},
              yaxis2: {
                titlefont: {color: 'rgb(148, 103, 189)'},
                tickfont: {color: 'rgb(148, 103, 189)'},
                overlaying: 'y',
                side: 'right',
              },
              width: 480,
            }}
          />
        </Box>
      )}
    </div>
  );
};

export {Plot};
