import React, {useState} from 'react';
import Plotly from 'react-plotly.js';
import {Shape} from 'plotly.js';

import {useLayoutConfig} from '../../../hooks/useLayoutConfig';
import {colors} from '../../../config/const';

const Plot = ({maturityPrediction, metric, multiplier, polygonName}) => {
  const [data, setData] = useState(maturityPrediction);

  // Initialise graph values
  const [isSugar, setIsSugar] = useState(false);
  const title = data.variety;
  const years = data.seasons;
  const graphData = [];
  const ghostTraces = [];
  const ghostPlots = [];
  let targetBaume;
  let harvestDate;
  let colorCount = 0;
  // To ensure colors match on different graphs with different seasons
  for (const year in years) colorCount++;
  let finalYear = '';

  const createBaumePlots = (year, name) => {
    // Plot points for baume values
    const groundValues = year.maturityBaume.values;
    const groundDates = year.maturityBaume.dates;
    const plots: Plotly.Data = {
      name: name,
      x: groundDates,
      y: groundValues,
      type: 'scatter',
      showlegend: false,
      mode: 'markers',
      opacity: 1,
      marker: {
        color: colors[colorCount],
        size: 10,
      },
    };
    graphData.push(plots);
    return plots;
  };

  const createBaumeCurve = (year, name) => {
    // Curve points for baume values
    const baumeValues = year.predictedBaume.values;
    const baumeDates = year.predictedBaume.dates;
    const trace: Plotly.Data = {
      name: name,
      x: baumeDates,
      y: baumeValues,
      type: 'scatter',
      opacity: 1,
      marker: {color: colors[colorCount]},
    };
    graphData.push(trace);
    return trace;
  };

  const createBarPlots = (year, latestYear) => {
    // Rain bar charts
    const rainFall = year.weather.values;
    const weatherDates = year.weather.dates;
    const barPlots: Plotly.Data = {
      name: 'Rainfall',
      type: 'bar',
      hoverinfo: 'x+y',
      showlegend: latestYear,
      x: weatherDates,
      y: rainFall,
      yaxis: 'y2',
      marker: {
        color: 'rgba(255, 255, 255, 0)',
        line: {
          color: 'rgba(0, 0, 50, 0.3)',
          width: 2,
        },
      },
    };
    graphData.push(barPlots);
  };

  const createGdd = (year, latestYear) => {
    // Cumilative Gdd curve
    const gddValues = year.gdd.values;
    const gddDates = year.gdd.dates;
    const gddTrace: Plotly.Data = {
      name: 'GDD',
      x: gddDates,
      y: gddValues,
      type: 'scatter',
      showlegend: latestYear,
      hoverinfo: 'x+y',
      line: {
        color: 'rgba(0, 0, 135, 0.05)',
      },
      fill: 'tozeroy',
      fillcolor: 'rgba(0, 0, 135, 0.2)',
    };
    graphData.push(gddTrace);
  };

  const createGhostCurves = (ghosTraces, ghostPlots) => {
    ghostTraces.forEach(trace => {
      const ghostDates: string[] = [];
      const ghostValues = trace.y;
      const markerColor = trace.marker?.color;
      // Adjust all curves in ghostTraces to have a year of 2024
      trace.x?.forEach(date => {
        const datesSplit = date?.toString().split('-').slice(1);
        ghostDates.push(finalYear + '-' + datesSplit?.join('-'));
      });
      // Plot ghost traces
      const ghostTrace: Plotly.Data = {
        name: 'Ghost trace',
        x: ghostDates,
        y: ghostValues,
        showlegend: false,
        type: 'scatter',
        hoverinfo: 'none',
        opacity: 0.2,
        marker: {color: markerColor},
      };
      graphData.push(ghostTrace);
    });

    // Adjust all curves in ghostPltos to have a year of 2024
    ghostPlots.forEach(plot => {
      const ghostDates: string[] = [];
      const ghostValues = plot.y;
      const markerColor = plot.marker?.color;

      plot.x?.forEach(date => {
        const datesSplit = date?.toString().split('-').slice(1);
        ghostDates.push(finalYear + '-' + datesSplit?.join('-'));
      });
      // Plot ghost plots
      const ghostPlots: Plotly.Data = {
        name: 'Ghost plots',
        x: ghostDates,
        y: ghostValues,
        type: 'scatter',
        showlegend: false,
        mode: 'markers',
        hoverinfo: 'none',
        opacity: 0.2,
        marker: {
          color: markerColor,
          size: 10,
        },
      };
      graphData.push(ghostPlots);
    });
  };

  const createAnnotations = (metric, targetBaume, harvestDate) => {
    // Create dotted line for target baume
    const shapes: Array<Partial<Shape>> = [
      {
        type: 'line',
        xref: 'paper',
        x0: 0,
        y0: targetBaume,
        x1: 1,
        y1: targetBaume,
        line: {
          color: 'rgb(255, 0, 0)',
          width: 2,
          dash: 'dot',
        },
      },
    ];

    // Create target baume label
    const annotations: Array<Partial<Plotly.Annotations>> = [
      {
        x: 0.9,
        xref: 'paper',
        y: targetBaume?.toString(),
        yref: 'y',
        text: `Target ${metric}: ${targetBaume}`,
        showarrow: true,
        arrowhead: 1,
        ax: 0,
        ay: -30, // Distance from arrow
        arrowcolor: 'rgb(255, 0, 0)',
        font: {
          size: 12,
          color: 'rgb(255, 0, 0)',
        },
        bgcolor: 'rgba(255,255,255,0.8)',
      },
    ];

    // Create harvest date label
    annotations.push({
      x: 1,
      y: targetBaume?.toString() * 1.5,
      xref: 'paper',
      yref: 'y',
      text: `Harvest Date: ${harvestDate}`,
      showarrow: false,
      font: {
        size: 12,
        color: 'rgb(0, 0, 0)',
      },
      bgcolor: 'rgba(255,255,255,0.8)',
    });

    return {shapes, annotations};
  };

  for (const year in years) {
    let latestYear = false;
    if (years[year].harvestDate) {
      latestYear = true;
      finalYear = year;
      // Set targetBaume and harvestDate
      targetBaume = parseFloat(years[year].targetBaume).toFixed(2);
      harvestDate = years[year].harvestDate.split('T')[0];
    }

    const plots = createBaumePlots(years[year], year);
    const trace = createBaumeCurve(years[year], year);

    // If the year is from previous season
    if (!latestYear) {
      // Add to ghost traces array -> to be used to create faded traces on current season
      ghostTraces.push(trace);
      ghostPlots.push(plots);
    }

    createBarPlots(years[year], latestYear);
    createGdd(years[year], latestYear);

    // Go to next colour
    colorCount--;
  }

  createGhostCurves(ghostTraces, ghostPlots);
  const {shapes, annotations} = createAnnotations(metric, targetBaume, harvestDate);

  // Hook to handle dynamic changes in layout -> user changine metric, season, etc...
  const {layout, handleLegendClick, handleRelayout} = useLayoutConfig(maturityPrediction, data, setData, polygonName, metric, multiplier, isSugar, setIsSugar, shapes, annotations, title, finalYear);

  return <Plotly style={{height: '100%', width: '100%'}} useResizeHandler data={graphData} layout={layout} onLegendClick={handleLegendClick} onRelayout={handleRelayout} />;
};

export default Plot;
