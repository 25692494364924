import React, {useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/Info';
import {makeStyles} from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

interface TooltipProps {
  children?: React.ReactNode;
  text: React.ReactNode;
  position?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
  icon?: boolean;
}

const useStyles = makeStyles(theme => ({
  icon: {
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  box: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  tooltip: {
    padding: 10,
    backgroundColor: 'rgba(49, 64, 84, 0.97)',
    maxWidth: '700px',
    zIndex: 50,
  },
  arrow: {
    color: 'rgba(49, 64, 84, 0.97)',
  },
}));

const CustomTooltip: React.FC<TooltipProps> = ({children, text, position = 'bottom-start', icon = false}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [permanentOpen, setPermanentOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    if (!permanentOpen) {
      setOpen(false);
    }
  };

  const handleTooltipClick = () => {
    setPermanentOpen(true);
    setOpen(true);
  };

  const handleTooltipClickOff = () => {
    setPermanentOpen(false);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClickOff}>
      <Tooltip classes={{tooltip: classes.tooltip, arrow: classes.arrow}} title={text} placement={position} arrow open={open}>
        <Box className={classes.box} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose} onClick={handleTooltipClick}>
          {icon && <InfoIcon className={classes.icon} style={{color: '#3e5069'}} onMouseEnter={handleTooltipOpen} />}
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export {CustomTooltip as HelpTooltip};
