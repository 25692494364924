import {IFarmEntity, IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Box, Button} from '@material-ui/core';
import axios from 'axios';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import Spinner from '../../../../../../../components/UI/Spinner';
import {imageGenerationURL} from '../../../../../../../config/const';
import {enqueueSnackbar} from '../../../../../../../store/actions';

interface Props {
  farm: IFarmEntity;
  organization: IOrganizationEntity;
}

export const SeasonIndices = ({farm, organization}: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await axios.post(`${imageGenerationURL}/images/season-indices/farm/${farm.id}`);
      dispatch(enqueueSnackbar({message: 'Season Indices graphs are successfully generated', options: {variant: 'success'}}));
    } catch (e) {
      const msg = e?.response?.data?.message || e?.response?.data?.message;
      dispatch(enqueueSnackbar({message: msg || 'Sorry! request has been failed', options: {variant: 'error'}}));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%" padding="2rem 0rem" display="flex" flexDirection="column" alignItems="center">
      <Box mt={4} mb={4} display="flex" justifyContent="flex-end" width="100%">
        <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit} style={{height: 'auto'}} fullWidth>
          Generate Season Indices
          {loading && <Spinner size={15} color="primary" />}
        </Button>
      </Box>
    </Box>
  );
};
