import React from 'react';
import classNames from 'classnames';
import {Theme, useTheme, createStyles, makeStyles} from '@material-ui/core/styles';
import {Fab, Divider, Paper, Slide, useMediaQuery, Typography, IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useState, useEffect} from 'react';
import styled from 'styled-components';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      minHeight: 'calc(100vh - 112px)',
      paddingLeft: drawerWidth,
    },
    drawer: {
      zIndex: 4,
      position: 'fixed',
      height: 'calc(100vh - 64px)',
      minHeight: 'calc(100vh - 64px)',
      maxHeight: 'calc(100vh - 64px)',
      width: drawerWidth,
      marginLeft: -drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      background: '#DBDBDB',
    },
    switch: {
      float: 'left',
      marginInline: '8px',
      marginTop: '11px',
    },
    polygon: {
      fill: theme.palette.common.white,
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: '0',
      },
    },
  })
);

const Header = styled.label<{header?: boolean}>`
  justify-content: ${({header}) => (header ? 'space-between' : 'flex-end')};
`;

const Content = ({classes, open, children, wider}) => {
  return (
    <div
      className={classNames(classes.content, {
        [classes.contentShift]: open,
      })}
      style={{marginLeft: !open ? (wider ? '-300px' : '-240px') : '0px', width: '100%', overflow: 'auto'}}
    >
      {children}
    </div>
  );
};

const Sidebar = ({
  classes,
  open,
  handleDrawerClose,
  children,
  wider,
  headerText,
}: {
  classes: any;
  open: boolean;
  handleDrawerClose: () => void;
  children: any;
  wider?: boolean;
  headerText?: string;
}) => {
  return (
    <div>
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <Paper id="drawer-paper" className={classes.drawer} style={wider ? {width: '300px', maxHeight: '100%', marginLeft: '-300px'} : null}>
          <Header header={!!headerText} className={classes.drawerHeader}>
            {headerText && <Typography variant="h6">{headerText}</Typography>}
            <IconButton id="close-drawer" onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </Header>
          <Divider />
          {children}
        </Paper>
      </Slide>
    </div>
  );
};

interface Props {
  classes?: any;
  sidebar: JSX.Element;
  content: JSX.Element;
  wider?: boolean;
  headerText?: string;
  height?: string;
}

const SimpleSlide = ({sidebar, content, wider, headerText, height}: Props) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  const handleDrawerClose = () => setOpen(false);

  return (
    <div id="simple-slide-root" className={classes.root} style={{paddingLeft: wider ? '300px' : '240px', height: height || 'unset'}}>
      <Sidebar classes={classes} open={open} handleDrawerClose={handleDrawerClose} wider={wider} headerText={headerText}>
        {sidebar}
      </Sidebar>
      <Content classes={classes} open={open} wider={wider}>
        {!open && (
          <Fab size="small" aria-label="open drawer" color="primary" id="open-drawer" className={classes.switch} onClick={() => setOpen(true)} style={{zIndex: 3}}>
            <ChevronRightIcon />
          </Fab>
        )}
        {content}
      </Content>
    </div>
  );
};

export default SimpleSlide;
