// helper function to return state with new values without mutating it
import {DeepPartial} from 'redux';

export function updateObject<T>(oldObject: T, updatedValues: DeepPartial<T>): T {
  return {
    ...oldObject,
    ...updatedValues,
  };
}

// Get centroid of non self closing polygons
export const GetPolygonCentroid = arr => {
  let twoTimesSignedArea = 0;
  let cxTimes6SignedArea = 0;
  let cyTimes6SignedArea = 0;

  const length = arr.length;

  const x = function (i) {
    return arr[i % length][0];
  };
  const y = function (i) {
    return arr[i % length][1];
  };

  for (let i = 0; i < arr.length; i++) {
    const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
    twoTimesSignedArea += twoSA;
    cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
    cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
  }
  const sixSignedArea = 3 * twoTimesSignedArea;
  return [cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
};

/**
 * Get a center latitude,longitude from an array of like geo-points
 *
 * For Example:
 * $data = array (lat, lng)
 * (
 *   0 = > array(45.849382, 76.322333),
 *   1 = > array(45.843543, 75.324143),
 *   2 = > array(45.765744, 76.543223),
 *   3 = > array(45.784234, 74.542335)
 * );
 * @param data
 */
export const GetFarmCentre = (data: number[][]) => {
  if (!(data.length > 0)) {
    return null;
  }

  const num_coords = data.length;

  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  let lat;
  let lon;
  for (let i = 0; i < data.length; i++) {
    lat = (data[i][1] * Math.PI) / 180;
    lon = (data[i][0] * Math.PI) / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= num_coords;
  Y /= num_coords;
  Z /= num_coords;

  lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  lat = Math.atan2(Z, hyp);

  const newX = parseFloat(((lat * 180) / Math.PI).toFixed(5));
  const newY = parseFloat(((lon * 180) / Math.PI).toFixed(5));

  return {lat: newX, lng: newY};
};
