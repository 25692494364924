import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import styled from 'styled-components';
import {Button, Box, TableContainer, TableFooter} from '@material-ui/core';
import {ISoilOrganicCarbonInfoUI} from '@deep-planet/api-interfaces';
import {TableCellElement} from '../../../components/UI/Table/TableCell';
import DualSwitch from '../../../components/UI/Switch/DualSwitch';
import {getDateString} from '../../../helpers/dateHelpers';
import {useTranslation} from 'react-i18next';
import {PERCENTAGE} from '../../../config/const';

// container with table data is responsive for interpolation and change over time page
const Container = styled.div<{showChart: boolean; selectedImage: boolean}>`
  z-index: ${({showChart}) => (showChart ? '0' : '1')};
  position: absolute;
  top: ${({showChart, selectedImage}) => (showChart ? '0rem' : selectedImage ? '8.2rem' : '4.2rem')};
  right: ${({showChart}) => (showChart ? '0rem' : '4rem')};
  width: ${({showChart}) => (showChart ? 'calc(30%)' : '330px')};
  height: ${({showChart}) => (showChart ? 'calc(100%)' : 'auto')};
`;
interface Props {
  soilOrganicCarbonInfo: ISoilOrganicCarbonInfoUI;
  blockName: string;
  handleShowChangeOverScreen: () => void;
  buttonText: string;
  selectedImage?: boolean;
  showChart?: boolean;
  metric?: string;
  conversion: number;
  handleMetricChange: () => void;
}

const BlockInfo = ({soilOrganicCarbonInfo, blockName, handleShowChangeOverScreen, buttonText, selectedImage, showChart, metric, conversion, handleMetricChange}: Props) => {
  const baselineDate = soilOrganicCarbonInfo?.baselineDate ? getDateString(soilOrganicCarbonInfo?.baselineDate) : '';
  const {t} = useTranslation();
  return (
    <Container selectedImage={selectedImage} showChart={showChart}>
      {/* this table data shows SOC interpolation block information and change over time, so display flex and height is important */}
      {showChart && (
        <Box style={{margin: '5px 0px 0px 10px'}}>
          <DualSwitch handleChange={handleMetricChange} checked={metric === PERCENTAGE} title1="g/kg" title2="%" />
        </Box>
      )}
      <TableContainer style={showChart ? {display: 'flex', height: 'calc(100%)'} : null} component={Paper}>
        <Table stickyHeader size="small" aria-label="spanning table">
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                {`${t('block.details')} - ${blockName || ''}`}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCellElement label={t('average.soc') + ` (${metric})`} value={(soilOrganicCarbonInfo?.averageSoc * conversion).toFixed(3)} />
            {showChart && (
              <>
                <TableCellElement label={t('soil.organic.carbon.target.soc')} value={(soilOrganicCarbonInfo?.targetSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.one.month.soc')} value={(soilOrganicCarbonInfo?.oneMonthSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.three.months.soc')} value={(soilOrganicCarbonInfo?.threeMonthsSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.six.months.soc')} value={(soilOrganicCarbonInfo?.sixMonthsSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.one.year.soc')} value={(soilOrganicCarbonInfo?.oneYearSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.three.years.soc')} value={(soilOrganicCarbonInfo?.threeYearsSoc * conversion).toFixed(3)} />
                <TableCellElement label={t('soil.organic.carbon.five.years.soc')} value={(soilOrganicCarbonInfo?.fiveYearsSoc * conversion).toFixed(3)} />
              </>
            )}
            <TableCellElement label={t('soil.organic.carbon.baseline.soc')} value={(soilOrganicCarbonInfo?.baselineSoc * conversion).toFixed(3)} />
            <TableCellElement label={t('soil.organic.carbon.baseline.date')} value={baselineDate || ''} />
          </TableBody>

          <TableFooter style={{alignItems: 'center', justifyContent: 'center'}}>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <Button variant="contained" color="secondary" onClick={handleShowChangeOverScreen}>
                  {buttonText}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default BlockInfo;
