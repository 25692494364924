import {Box, Typography, makeStyles} from '@material-ui/core';
import {Slider as MuiSlider} from '@material-ui/core';
import React, {useState, useEffect} from 'react';

interface Props {
  opacity: number;
  handleOpacityChange: (event, value) => void;
}

const OpacityPanel = ({opacity, handleOpacityChange}: Props) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  const useStyles = makeStyles(theme => ({
    boxStyle: {
      position: 'absolute',
      top: 10,
      right: 54,
      height: 40,
      backgroundColor: 'white',
      borderRadius: '2.5px',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingInline: 10,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box className={classes.boxStyle} boxShadow={1}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {!isMobileView && <Typography style={{marginTop: 2}}>Opacity</Typography>}
        <MuiSlider style={{width: isMobileView ? 30 : 80, marginInline: 10}} value={opacity * 100} onChange={handleOpacityChange} defaultValue={100} />
      </div>
    </Box>
  );
};

export default OpacityPanel;
