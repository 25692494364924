import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {handleDateFormat} from '../../../helpers/dateHelpers';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

const StyledChip = styled(Chip)`
  && {
    margin: 5px 0 5px 5px;
  }
`;

interface Props {
  selectedDate: number;
  description: string;
  type: string;
  product: string;
  handleClickCompare: (type: string) => void;
  prevImageUrl?: boolean;
  soilOrganicCarbonEnabled?: boolean;
}

const InfoSection = ({description, selectedDate, type, product, handleClickCompare, prevImageUrl, soilOrganicCarbonEnabled}: Props) => {
  const {t} = useTranslation();
  return (
    <Box display="flex" alignItems="center" style={{flexDirection: 'row'}}>
      <Box m={1}>
        <Typography variant="h6" gutterBottom display="inline">
          {t(description)} {selectedDate ? handleDateFormat(selectedDate) : null}
        </Typography>
      </Box>
      {selectedDate && soilOrganicCarbonEnabled && (
        <Box m={1} alignItems="center">
          <Typography variant="body2" display="inline">
            {type === 'BASELINE' ? t('soil.organic.carbon.selected.baseline', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === 'CURRENT' ? t('ndvi.selected.current', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === '1Y' ? t('soil.organic.carbon.selected.1Y', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === '3Y' ? t('soil.organic.carbon.selected.3Y', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === '5Y' ? t('soil.organic.carbon.selected.5Y', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === 'average' ? t('ndvi.selected.average', {product, date: handleDateFormat(selectedDate)}) : null}
          </Typography>
        </Box>
      )}
      {selectedDate && !soilOrganicCarbonEnabled && (
        <Box m={1} alignItems="center">
          <Typography display="inline" variant="body2">
            {type === 'predicted' ? t('ndvi.selected.predicted', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === 'current' && prevImageUrl ? t('ndvi.selected.current', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === 'previous' ? t('ndvi.selected.previous', {product, date: handleDateFormat(selectedDate)}) : null}
            {type === 'average' ? t('ndvi.selected.average', {product, date: handleDateFormat(selectedDate)}) : null}
          </Typography>
          {type !== 'current' && type !== 'predicted' ? (
            <React.Fragment>
              <Typography display="inline" variant="body2">
                {t('ndvi.selected.comparison', {product})}
              </Typography>
              <StyledChip size="small" color="primary" label={handleDateFormat(selectedDate)} onClick={() => handleClickCompare('current')} />
              {type !== 'predicted' ? (
                <Typography display="inline" variant="body2">
                  {' '}
                  {t('ndvi.selected.comparison.with')}
                </Typography>
              ) : null}
            </React.Fragment>
          ) : null}
          {type === 'current' && prevImageUrl ? (
            <Typography display="inline" variant="body2">
              {t('ndvi.selected.comparison.to')}
            </Typography>
          ) : null}
          {type !== 'previous' && type !== 'predicted' && prevImageUrl ? (
            <StyledChip size="small" color="primary" label={t('ndvi.sidebar.compare.previous')} onClick={() => handleClickCompare('previous')} />
          ) : null}{' '}
          {type !== 'average' && type !== 'predicted' && prevImageUrl ? (
            <StyledChip color="primary" size="small" label={t('ndvi.sidebar.compare.average')} onClick={() => handleClickCompare('average')} />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default InfoSection;
