import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {GroundOverlay} from '@react-google-maps/api';
import {useTranslation} from 'react-i18next';
import {Map, MapProps} from 'apps/web-portal-ui/src/app/components/UI/Map/Map';
import {IIrrigationImageResDTO, polygonInfoWindow, IPolygonEntity, ISoilOrganicCarbonImageResDTO} from '@deep-planet/api-interfaces';
import PolygonWithInfoChart from '../PolygonWithInfo/polygonWithInfoChart';
import OpacityPanel from '../Panel/OpacityPanel';
import Marker from '../Marker/Marker';
import {useMarker} from '../../../hooks/useMarker';
import {IFarm} from '../../../store/reducers/farm';
import MapOverlay from '../MapOverlay';
import MapInfoWindow from '../PolygonWithInfo/InfoWindow';
import {markerDeleteSelector} from '../../../store/selectors';
import {Box} from '@material-ui/core';
import DualSwitch from '../Switch/DualSwitch';
import {renderURL} from '../../../containers/pages/NDVI/DownloadScreenshot';
import {G_PER_KG, PERCENTAGE, SOILORGANICCARBON} from '../../../config/const';
const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 130px)',
};

const renderImage = (type: string, image) => {
  switch (type) {
    case 'current':
      return image.curr_url;
    case 'previous':
      return image.prev_url;
    case 'average':
      return image.avg_url;
    case 'predicted':
      return image.pred_url;
    case 'BASELINE':
      return image.baseline_url;
    case 'CURRENT':
      return image.current_url;
    case '1M':
      return image.oneMonth_url;
    case '6M':
      return image.sixMonths_url;
    case '1Y':
      return image.oneYear_url;
    case '3Y':
      return image.threeYears_url;
    case '5Y':
      return image.fiveYears_url;
    default:
      return '';
  }
};

const getImageUrl = (type: string, image) => {
  return renderImage(type, image);
};

interface Props extends MapProps {
  farm: IFarm;
  type: string;
  images: IIrrigationImageResDTO['images'] | ISoilOrganicCarbonImageResDTO['images'];
  handlePolygonHover?: (id: string) => void;
  handleOnClick?: (polygon: IPolygonEntity) => void;
  handleMetricChange?: () => void;
  selectedPolygon?: polygonInfoWindow;
  disableInfoWindow?: boolean;
  feature?: string;
  takeScreenshot: () => void;
  showGraph?: boolean;
  closeGraph?: () => void;
  metric?: string;
}

const MapContent = ({
  farm,
  center,
  images,
  type,
  bbox,
  polygons,
  handlePolygonHover,
  handleOnClick,
  handleMetricChange,
  selectedPolygon,
  disableInfoWindow,
  feature,
  takeScreenshot,
  polygonClicked,
  irrigation,
  displayGraph,
  showGraph,
  closeGraph,
  metric,
}: Props) => {
  const farmBBox = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
  const {t} = useTranslation();
  const [opacity, setOpacity] = useState(100);
  let leftPosition = 'auto';
  const {loading: isMarkerDeleteLoading} = useSelector(markerDeleteSelector);
  const {
    isModalOpen,
    loading,
    postMarkerLoading,
    selectedMarkerFeature,
    selectedMarker,
    mouseOverMarker,
    isOpenMarkerInfoWindow,
    isOpenModalNoteUpdate,
    markerPolygon,
    handleCloseModal,
    handleOnClickMap,
    handleMarkerSubmit,
    handleSelectedPin,
    handleMarkerPostRequest,
    handleMarkerFeatureSelection,
    handleMarkerClick,
    handleMarkerMouseOver,
    handleMarkerMouseOut,
    handleNoteUpdateClose,
    handleDeleteMarker,
  } = useMarker(farm);

  const handleOpacityChange = (event, newValue) => {
    newValue = newValue / 100;
    setOpacity(newValue);
  };

  if (feature === 'SOILCARBON') leftPosition = '190px';

  const isLoading = loading || postMarkerLoading || false;
  return (
    <>
      {isMarkerDeleteLoading && <MapOverlay position="relative" />}
      {isLoading && <MapOverlay />}
      {!isLoading && !isMarkerDeleteLoading && (
        <Map
          center={center}
          mapContainerStyle={containerStyle}
          bbox={bbox}
          polygons={polygons}
          displayRows
          selectedShowMarker={selectedMarkerFeature}
          handleMarkerClick={handleMarkerClick}
          handleMarkerMouseOver={handleMarkerMouseOver}
          handleMarkerMouseOut={handleMarkerMouseOut}
          polygonClicked={polygonClicked}
          selectedPolygon={selectedPolygon}
          focus
          irrigation={irrigation}
          displayGraph={displayGraph}
        >
          {images && farm && (
            <Marker
              selectedMarker={selectedMarker}
              farm={farm}
              polygon={markerPolygon}
              featureName={'SOIL'}
              isModalOpen={isModalOpen}
              isOpenModalNoteUpdate={isOpenModalNoteUpdate}
              handleAddPin={handleSelectedPin}
              handleMarkerPostRequest={handleMarkerPostRequest}
              handleMarkerFeatureSelection={handleMarkerFeatureSelection}
              handleCloseModal={handleCloseModal}
              handleMarkerSubmit={handleMarkerSubmit}
              handleNoteUpdateClose={handleNoteUpdateClose}
              handleDeleteMarker={handleDeleteMarker}
              takeScreenshot={takeScreenshot}
            />
          )}
          {images && <OpacityPanel opacity={opacity} handleOpacityChange={handleOpacityChange}></OpacityPanel>}
          {polygons?.map(polygon => {
            // @ts-ignore
            const correspondingImage = images?.find(({polygonid}) => polygonid === polygon.id);
            if (correspondingImage) {
              const url = getImageUrl(type, correspondingImage);
              const bounds = correspondingImage.boundBox
                ? new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(correspondingImage.boundBox[1], correspondingImage.boundBox[0]),
                    new window.google.maps.LatLng(correspondingImage.boundBox[3], correspondingImage.boundBox[2])
                  )
                : farmBBox;
              return (
                <>
                  <GroundOverlay key={polygon.id + url} url={renderURL(url)} bounds={bounds} opacity={opacity} />;
                  <PolygonWithInfoChart
                    key={polygon.id}
                    polygon={polygon}
                    // @ts-ignore
                    irrigationPredictions={correspondingImage?.irrigationPrediction}
                    // @ts-ignore
                    irrigationHistorical={correspondingImage?.irrigationHistorical}
                    selectedPolygon={selectedPolygon}
                    disableInfoWindow={disableInfoWindow}
                    handlePolygonHover={handlePolygonHover}
                    handleOnClick={handleOnClick}
                    handleOnClickMap={handleOnClickMap}
                    showGraph={showGraph}
                    closeGraph={closeGraph}
                  />
                  {mouseOverMarker && isOpenMarkerInfoWindow && (
                    <MapInfoWindow key={mouseOverMarker.latitude + mouseOverMarker.longitude} polygon={polygon} disableInfoBox={true} markerNote={mouseOverMarker} />
                  )}
                </>
              );
            } else {
              return images?.length ? (
                <PolygonWithInfoChart key={polygon.id} polygon={polygon} handleOnClick={handleOnClick} secondaryTitle={t('irrigation')} defaultOpacity={0.3} handleOnClickMap={handleOnClickMap} />
              ) : null;
            }
          })}
          {images && feature === 'SOILCARBON' && (
            <Box
              style={{
                position: 'absolute',
                top: 10,
                left: 182,
              }}
            >
              <DualSwitch handleChange={handleMetricChange} checked={metric === PERCENTAGE} title1={G_PER_KG} title2={PERCENTAGE} page={SOILORGANICCARBON} />
            </Box>
          )}
        </Map>
      )}
    </>
  );
};

export default MapContent;
