import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TableHead} from '../../../components/UI/Table';
import {filterTableItems, getColumnsOnClearAll, getColumnsOnSelectAll, IColumn} from '../../../components/UI/Table/filter-utils';
import {getLocalDateString} from '../../../helpers/dateHelpers';
import {useYieldPrediction} from '../../../hooks/useYieldPrediction';
import Layout from '../../Layout';
import {Box, Table, TableBody, TableCell, TableRow, Typography, TableContainer, Paper, IconButton, Tooltip, ListItem, ListItemText, List, Button} from '@material-ui/core';
import MapOverlay from '../../../components/UI/MapOverlay';
import {MapPlaceholderNoData} from '../../../components/UI/MapPlaceholder';
import {getComparator, Order, stableSort} from '../../../components/UI/Table/sort-utils';
import {ISimplifiedYield} from '../../../store/reducers/yield';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import styled from 'styled-components';
import theme from '../../../utilities/theme';
import * as FileSaver from 'file-saver';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {getUserGroups} from '../../authHOC';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import {vineSignalRoutes} from '../../../utilities/routes';
import {Link} from 'react-router-dom';
import {uniqBy, sortBy} from 'lodash';
import {getStickyColumns} from './StickyColumns';
import {SwitchButton} from '../../../components/UI/Switch/switch';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';
import {useMeasurementConversion} from '../../../hooks/useMeasurementConversion';

const DownloadButton = styled(IconButton)`
  position: fixed !important;
  top: 64px;
  right: 20px;
  ${theme.breakpoints.down('sm')} {
    top: 57px;
    right: 1px;
  }
`;

const TitleWrapper = styled(Box)`
  padding: 32px;
  ${theme.breakpoints.down('sm')} {
    padding: 70px 20px 20px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TitleInnerWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const TooltipWrapper = styled(Box)`
  margin: 3px 7px 0 0;
`;

const StyledPaper = styled(Paper)`
  height: calc(100% - 103px);
  ${theme.breakpoints.only('md')} {
    height: calc(100% - 103px);
  }
  ${theme.breakpoints.only('sm')} {
    height: calc(100% - 129px);
  }
  ${theme.breakpoints.only('xs')} {
    height: calc(100% - 121px);
  }
`;

interface Data {
  farmName: string;
  polygonName: string;
  variety: string;
  area: number;
  baume: number;
  pH: number;
  titratableAcid: number;
  yield: number;
  avg_yield: number;
  avg_yield_per_ha: number;
}

const YieldPrediction = ({user}: WithUserProps) => {
  const {areaUnit, weightUnit, convertArea, convertWeight, round} = useMeasurementConversion();

  const defaultColumns = [
    {
      id: 'farmName',
      label: 'yield.historical.table.farm',
      type: 'string',
    },
    {
      id: 'polygonName',
      label: 'yield.historical.table.block',
      type: 'string',
    },
    {
      id: 'variety',
      label: 'yield.historical.table.crop',
      type: 'string',
    },
    {
      id: 'area',
      label: (areaUnit === 'hectares' && 'yield.historical.table.area.ha') || (areaUnit === 'acres' && 'yield.historical.table.area.ac'),
      type: 'number',
    },
    {
      id: 'baume',
      label: 'yield.historical.table.baume',
      type: 'number',
    },
    {
      id: 'pH',
      label: 'yield.historical.table.ph',
      type: 'number',
    },
    {
      id: 'titratableAcid',
      label: 'yield.historical.table.ta',
      type: 'number',
    },
    {
      id: 'yield',
      label: (weightUnit === 'pounds' && 'yield.historical.table.yield.lbs') || (weightUnit === 'kilograms' && 'yield.historical.table.yield.kg') || 'yield.historical.table.yield.t',
      type: 'number',
    },
    {
      id: 'yield_per_ha',
      label: 'yield.historical.table.yield.area',
      type: 'number',
    },
    {
      id: 'avg_yield',
      label: 'yield.historical.table.historical.yield',
      type: 'number',
    },
    {
      id: 'avg_yield_per_ha',
      label: 'yield.historical.table.historical.yield.ha',
      type: 'number',
    },
  ];

  const [columns, setColumns] = useState<IColumn[]>(defaultColumns);
  const {loadingGetYield, yieldPredictions, prevYieldPredictions, yieldPredictionDates, onGetPredictedYield} = useYieldPrediction();
  const {t} = useTranslation();
  const {HelpYield} = useTooltipText();
  const isSuperAdmin = getUserGroups(user).includes('SUPERADMIN');
  const selectedPredictionDate = !loadingGetYield && yieldPredictions[0]?.predictionDate;
  const [showTonnes, setShowTonnes] = useState(true);
  const [showHectares, setShowHectares] = useState(true);

  if (isSuperAdmin && !columns.some(column => column.id === 'organizationName')) {
    columns.unshift({
      id: 'organizationName',
      label: 'yield.historical.table.organization',
      type: 'string',
    });
  }

  useEffect(() => {
    if (yieldPredictions?.length && yieldPredictions !== prevYieldPredictions) {
      const newColumns = columns.map(column => {
        if (column.type === 'string') {
          const values = sortBy(
            uniqBy(
              yieldPredictions?.map(y => ({label: y[column.id], selected: true})),
              'label'
            ),
            'label'
          );
          return {
            ...column,
            values,
          };
        }
        return column;
      });
      setColumns(newColumns);
    }
  }, [columns, prevYieldPredictions, yieldPredictions]);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('farmName');

  const getLocalDate = (date: Date | string) => {
    return getLocalDateString(date);
  };

  const downloadCSV = () => {
    const csv = makeCSV();
    const blob = new Blob(['\ufeff', csv], {type: 'text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, `yield_prediction_${selectedPredictionDate}.csv`);
  };

  const makeCSV = () => {
    const header = [
      t('yield.historical.table.farm'),
      t('yield.historical.table.block'),
      t('yield.historical.table.crop'),
      t((areaUnit === 'hectares' && 'yield.historical.table.area.ha') || (areaUnit === 'acres' && 'yield.historical.table.area.ac')),
      t('yield.historical.table.baume'),
      t('yield.historical.table.ph'),
      t('yield.historical.table.ta'),
      t(
        (weightUnit === 'tonnes' && 'yield.historical.table.yield.t') ||
          (weightUnit === 'pounds' && 'yield.historical.table.yield.lbs') ||
          (weightUnit === 'kilograms' && 'yield.historical.table.yield.kg')
      ),
      t('yield.historical.table.yield.area'),
      t('yield.historical.table.prediction'),
      t('yield.historical.table.historical.yield'),
      t('yield.historical.table.historical.yield.ha'),
    ];
    if (isSuperAdmin) header.unshift(t('yield.historical.table.organization'));
    return [
      header,
      ...filterTableItems(yieldPredictions, columns).map(item => {
        const totalYield = showTonnes ? item.yield : (item.yield * 1000).toFixed(2);
        const yield_per_ha = round(convertWeight(item.yield) / convertArea(item.blockArea), 2);
        const row = [
          item.farmName,
          item.polygonName,
          item.variety,
          convertArea(item.area),
          item.baume,
          item.pH,
          item.titratableAcid,
          item.prevYield ? `*${totalYield}` : totalYield,
          item.prevYield ? `*${yield_per_ha}` : yield_per_ha,
          item.predictionDate,
          item.avg_yield || 'N/A',
          item.avg_yield_per_ha || 'N/A',
        ];
        if (isSuperAdmin) row.unshift(item.organizationName);
        return row;
      }),
    ]
      .map(e => e.join(','))
      .join('\n');
  };

  const handleClick = (predictionDate: string) => {
    if (predictionDate !== selectedPredictionDate) {
      onGetPredictedYield(predictionDate);
    }
  };

  const handleToggleSelection = (label: string, columnId: string, selected: boolean) => {
    const changedColumnValues = columns
      .find(({id}) => id === columnId)
      .values.map(value => {
        if (value.label === label) {
          return {
            ...value,
            selected,
          };
        }
        return value;
      });
    handleFilterSelection(columnId, changedColumnValues);
  };

  const handleFilterSelection = (columnId: string, changedColumnValues: {label: string; selected: boolean}[]) => {
    const changedColumnSelectedValues = changedColumnValues.filter(({selected}) => selected).map(({label}) => label);

    const otherActiveFilters = columns
      .filter(({values}) => !!values?.length)
      .filter(({id}) => id !== columnId)
      .filter(({values}) => values.some(({selected}) => !selected))
      .map(column => ({
        id: column.id,
        values: column.values.filter(({selected}) => selected).map(({label}) => label),
      }));

    const filters = [{id: columnId, values: changedColumnSelectedValues}, ...otherActiveFilters];

    const newColumns: IColumn[] = columns.map(column => {
      if (column.values && column.id === columnId) {
        return {
          ...column,
          values: sortBy(changedColumnValues, 'label'),
        };
      }
      if (column.values && column.id !== columnId) {
        const previousUnselectedFilters = columns.find(({id}) => column.id === id).values.filter(({selected}) => !selected);
        const newValues = sortBy(
          uniqBy(
            [
              ...yieldPredictions
                ?.filter(y => {
                  return filters.every(({id, values}) => values.includes(y[id]));
                })
                .map(y => ({label: y[column.id], selected: true})),
              ...previousUnselectedFilters,
            ],
            'label'
          ),
          'label'
        );
        return {...column, values: newValues};
      }
      return column;
    });
    setColumns(newColumns);
  };

  const handleClearAll = (columnId: string) => {
    const newColumns: IColumn[] = getColumnsOnClearAll(columnId, columns);
    setColumns(newColumns);
  };

  const handleSelectAll = (columnId: string) => {
    const newColumns: IColumn[] = getColumnsOnSelectAll(columnId, columns, yieldPredictions);
    setColumns(newColumns);
  };

  const handleSwitch = () => {
    setShowTonnes(!showTonnes);
  };

  const handleSwitchForArea = () => {
    setShowHectares(!showHectares);
  };

  const convertToKGPerHa = (value: number, blockArea: number) => {
    if (!value || value === 0) return;
    return ((value * 1000) / blockArea).toFixed(2);
  };

  return (
    <Layout>
      {loadingGetYield && <MapOverlay />}
      {!loadingGetYield && yieldPredictions && !yieldPredictions.length && <MapPlaceholderNoData />}
      {!loadingGetYield && yieldPredictions && yieldPredictions?.length && (
        <ContentLeftSidebar
          height="calc(100vh - 64px)"
          sidebar={
            <Box display="flex" flexDirection="column" height="calc(100% - 64px)" justifyContent="space-between">
              <Box overflow="auto">
                <List style={{overflow: 'auto'}}>
                  <ListItem />
                  {yieldPredictionDates.map(date => {
                    return (
                      <ListItem style={{backgroundColor: selectedPredictionDate === date ? '#DBDBDB' : 'unset'}} onClick={() => handleClick(date)} button key={date}>
                        <ListItemText primary={date} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              {isSuperAdmin && (
                <Box p={2}>
                  <Link to={vineSignalRoutes.MY_YIELD.slug}>
                    <Button fullWidth variant="contained" color="primary">
                      {t('yield.historical.button')}
                    </Button>
                  </Link>
                </Box>
              )}
            </Box>
          }
          content={
            <>
              <TitleWrapper>
                <TitleInnerWrapper>
                  <TooltipWrapper>
                    <HelpTooltip text={HelpYield} icon />
                  </TooltipWrapper>
                  <Typography variant="h6" align="center" gutterBottom>
                    {t('yield.prediction.title', {date: getLocalDate(selectedPredictionDate)})}
                  </Typography>
                </TitleInnerWrapper>
                <Tooltip title={t('yield.prediction.download')}>
                  <DownloadButton aria-label={t('yield.prediction.download')} onClick={downloadCSV}>
                    <CloudDownloadIcon color="primary" fontSize="large" />
                  </DownloadButton>
                </Tooltip>
                <Typography variant="body2" align="right" gutterBottom>
                  {t('yield.note.text')}
                </Typography>
              </TitleWrapper>
              {/* <StyledPaper> */}
              <TableContainer style={{overflow: 'auto', maxHeight: 'calc(100vh - 192px)'}}>
                <Table stickyHeader>
                  <TableHead
                    columns={columns}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    orderBy={orderBy}
                    order={order}
                    handleToggleSelection={handleToggleSelection}
                    handleClearAll={handleClearAll}
                    handleSelectAll={handleSelectAll}
                    isSuperAdmin={isSuperAdmin}
                  />
                  <TableBody>
                    {stableSort<ISimplifiedYield>(filterTableItems(yieldPredictions, columns), getComparator<ISimplifiedYield>(order, orderBy)).map((y, idx) => (
                      <TableRow key={`${y.farmName}-${y.polygonName}-${idx}`}>
                        {columns.map(column => {
                          let value = y[column.id];
                          //convert from hectare to acres
                          if (column.id === 'area') value = convertArea(value);
                          // show yield_per_ac in kg's
                          if (column.id === 'yield_per_ha') value = round(convertWeight(y.yield) / convertArea(y.blockArea), 2);
                          //convert from tonne to kg's
                          if (column.id === 'yield') value = convertWeight(value);
                          if ((column.id === 'yield' || column.id === 'yield_per_ha') && y?.prevYield)
                            //if yield is previous yield show with astrix
                            value = `*${value}`;
                          //get sticky columns to freeze on horizontol scrolling
                          const {stickyColumn, width} = getStickyColumns(column, isSuperAdmin);
                          return (
                            <TableCell key={column.id} style={stickyColumn ? {position: 'sticky', left: width, backgroundColor: 'white', zIndex: 1} : {}}>
                              {value || 'N/A'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* </StyledPaper> */}
            </>
          }
        />
      )}
    </Layout>
  );
};

export default withUser(YieldPrediction);
