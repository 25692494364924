import React from 'react';
import styled, {css} from 'styled-components';
import {Link, withRouter} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import {ListItem as MaterialListItem} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

const Item = styled(MaterialListItem)<{isactive: string; group: string}>`
  && {
    ${p =>
      p.isactive === 'true'
        ? css`
            background: #1f2d3b;
          `
        : css`
            background: #253647;
          `}
  }
`;

const Text = styled(ListItemText)<{group: string}>`
  && {
    & span {
      font-size: ${g => (g.group ? '80% !important' : '100% !important')};
    }
  }
`;

const ListItem = ({slug, title, group, location}: {slug: string; title: string; group: string} & any) => {
  const isActive = value => {
    return location.pathname === value;
  };

  return (
    <>
      <Item
        button
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component={Link}
        to={{
          pathname: slug,
          state: {
            prevPath: location.pathname,
            group: group,
          },
        }}
        isactive={isActive(slug).toString()}
        group={group}
        disabled={slug.includes('soil-temperature') || slug.includes('soil-salinity')}
      >
        <Text group={group} primary={title} />
      </Item>
      {group ? <></> : <Divider />}
    </>
  );
};

export default withRouter(ListItem);
