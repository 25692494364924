import ActionTypes from './actionTypes';
import axios from 'axios';
import * as turf from '@turf/turf';
import {baseApiUrl} from '../../config/const';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import i18n from '../../../i18n';
import {Dispatch} from 'redux';
import {IImageWithBox} from '../reducers/ndvi';
import {IImage} from '@deep-planet/api-interfaces';
import {IFarm} from '../reducers/farm';
import {farmIdUpdate} from './farm';

////////////////////////////////////
// GET NDVI
////////////////////////////////////

interface INDWIGetStart {
  type: ActionTypes.NDWI_GET_START;
}

export const ndwiGetStart = (): INDWIGetStart => ({
  type: ActionTypes.NDWI_GET_START,
});

interface INDWIGetSuccess {
  type: ActionTypes.NDWI_GET_SUCCESS;
  images: IImageWithBox[];
  farmId: string;
  activeFarm: IFarm;
}

export const ndwiGetSuccess = (images: IImageWithBox[], farmId: string, activeFarm: IFarm): INDWIGetSuccess => ({
  type: ActionTypes.NDWI_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface INDWIGetFail {
  type: ActionTypes.NDWI_GET_FAIL;
  error: unknown;
}

export const ndwiGetFail = (error): INDWIGetFail => ({
  type: ActionTypes.NDWI_GET_FAIL,
  error,
});

interface INDWIGetActiveData {
  type: ActionTypes.NDWI_GET_ACTIVE_DATA;
  selectedDate: Date;
  imageType: string;
}

export const ndwiGetActiveData = (selectedDate: Date, imageType: string): INDWIGetActiveData => ({
  type: ActionTypes.NDWI_GET_ACTIVE_DATA,
  selectedDate,
  imageType,
});

export const getNDWI = (farm: IFarm, startDate: string, endDate: string, prediction: boolean, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(ndwiGetStart());
    const ndwiGetUrl = `${baseApiUrl}/ndwi/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    const ndwiPredictionGetUrl = `${baseApiUrl}/predicted_images?farmId=${farm.farmid}&product=ndwi${organizationId ? `&organizationId=${organizationId}` : ''}`;

    try {
      const promises = [getImageRequest<IImage>(ndwiGetUrl, dispatch, i18n.t('ndvi.get.failed'))];
      if (prediction) {
        promises.push(getImageRequest<IImage>(ndwiPredictionGetUrl, dispatch, i18n.t('ndvi.prediction.get.failed')));
      }
      const response = await Promise.all(promises);
      const ndviWithCoords = response
        .filter(item => !!item)
        .reduce((acc, item) => acc.concat(item), [])
        .sort((a, b) => (a.date > b.date ? 1 : -1));

      ndviWithCoords.forEach(function (date, idx) {
        const mutatedData = date.images;
        (date as IImageWithBox).images.forEach(function (image) {
          const result = farm.polygons.filter(function (poly) {
            return poly.id === image.polygonid;
          });

          const polyCoords = turf.lineString(result[0].geoJson.geometry.coordinates[0]);
          const polyBoundBox = turf.bbox(polyCoords);

          image.boundBox = result[0] !== undefined ? polyBoundBox : null;
        });
        ndviWithCoords[idx].images = mutatedData;
      });
      dispatch(ndwiGetSuccess(ndviWithCoords as IImageWithBox[], farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
    } catch (err) {
      console.log(err);
      dispatch(ndwiGetFail(err));
    }
  };
};

async function getImageRequest<T>(url: string, dispatch: Dispatch<SnackbarAction>, message: string) {
  try {
    const {data} = await axios.get<T[]>(url);
    return data;
  } catch {
    dispatch(enqueueSnackbar({message: message || 'error', options: {variant: 'error'}}));
    return [] as T[];
  }
}

export type Action = ReturnType<typeof ndwiGetStart> | ReturnType<typeof ndwiGetSuccess> | ReturnType<typeof ndwiGetFail> | ReturnType<typeof farmIdUpdate> | ReturnType<typeof ndwiGetActiveData>;
