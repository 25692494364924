import React from 'react';
import TableCell from '@material-ui/core/TableCell/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import TableRow from '@material-ui/core/TableRow';
import Forward from '@material-ui/icons/Forward';
import {useTranslation} from 'react-i18next';

interface Props {
  label: string;
  value: unknown;
  label2?: string;
  value2?: unknown;
  iconButton?: boolean;
}
export const TableCellElement = ({label, value, label2, value2, iconButton}: Props) => {
  const {t} = useTranslation();
  //@ts-ignore
  const StyledTableCell = withStyles(theme => ({
    root: label2 ? label2Prop : labelProp,
  }))(TableCell);
  const labelProp = {padding: '0px 13px', fontSize: '8.5pt', fontWeight: '1000'};
  const label2Prop = {
    padding: '0px 0px 0px 13px',
    textAlign: 'left',
    fontSize: '8.5pt',
    fontWeight: '1000',
    flex: '1',
  };

  // value and value2 can be icons too
  // if icon is green => rotate 270 - to show upward arrow in the green, else down arrow in the red
  return (
    <TableRow>
      <StyledTableCell>{t(label)}</StyledTableCell>
      <StyledTableCell style={{textAlign: 'center', padding: '0px 0px', width: '25%'}} align="center">
        {iconButton ? (
          value === 'green' ? (
            <Forward style={{color: 'green', transform: 'rotate(270deg)'}} />
          ) : value === 'red' ? (
            <Forward style={{color: 'red', transform: 'rotate(90deg)'}} />
          ) : (
            ''
          )
        ) : (
          value
        )}
      </StyledTableCell>
      {label2 && (
        <StyledTableCell style={{paddingLeft: '0px'}} align="right">
          {label2}
        </StyledTableCell>
      )}
      {value2 && (
        //dispay greenIcon || redIcon || actual value
        <StyledTableCell style={{textAlign: 'center', padding: '0px 0px', width: '25%'}} align="center">
          {iconButton ? (
            value2 === 'green' ? (
              <Forward style={{color: 'green', transform: 'rotate(270deg)'}} />
            ) : value2 === 'red' ? (
              <Forward style={{color: 'red', transform: 'rotate(90deg)'}} />
            ) : (
              ''
            )
          ) : (
            value2
          )}
        </StyledTableCell>
      )}
    </TableRow>
  );
};
