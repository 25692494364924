import React from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Box, ListItem, ListItemText, Button, ListItemAvatar, Avatar, Divider} from '@material-ui/core';
import {INotificationEntity} from '@deep-planet/api-interfaces';

interface props {
  notification: INotificationEntity;
  handleClick: (item: INotificationEntity) => void;
  username: string;
}

const NotificationItem = ({notification, handleClick, username}: props) => {
  const {t} = useTranslation();
  // to categorize read and unread notification
  const color = notification.read ? '' : 'lightgreen';

  return (
    <>
      <Button style={{textTransform: 'none', backgroundColor: color}}>
        <ListItem alignItems="flex-start" onClick={() => handleClick(notification)}>
          <ListItemAvatar>
            <Avatar alt="DeepPlanet" src="/images/" />
          </ListItemAvatar>
          <div
            style={{
              justifyContent: 'space-evenly',
              display: 'flex',
              minWidth: '350px',
              flexDirection: 'row',
              height: '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {notification?.header && (
              <ListItemText
                primary={notification.header}
                secondary={
                  <React.Fragment>
                    <Typography style={{display: 'inline'}} component="span" variant="body2">
                      Deep Planet
                    </Typography>
                    {' — ' + notification.description}
                  </React.Fragment>
                }
              />
            )}
            <Box style={{minWidth: '100px'}} textAlign="right">
              <Typography style={{display: 'inline'}} component="span" variant="body2">
                {new Date(notification.createdAt).toLocaleDateString()}
              </Typography>
            </Box>
          </div>
        </ListItem>
      </Button>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default NotificationItem;
