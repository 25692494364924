import React, {useState} from 'react';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import LanguageIcon from '@material-ui/icons/Language';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import styled from 'styled-components';
import theme from '../../../utilities/theme';
import {useTranslation} from 'react-i18next';

const Title = styled(Typography)`
  color: white;
  text-transform: none !important;
`;

const StyledBox = styled(Box)`
  margin-right: 16px;
  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
`;

const LanguageSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {i18n, t} = useTranslation();

  const handleClick = language => i18n.changeLanguage(language, handleClose);
  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <StyledBox>
      <Button aria-owns={anchorEl ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={handleOpen} color="inherit">
        <Hidden mdUp implementation="css">
          <Box mt={1}>
            <LanguageIcon />
          </Box>
        </Hidden>
        <Hidden smDown implementation="css">
          <Box display="flex">
            <LanguageIcon />
            <Box ml={1}>
              <Title variant="body1">{t('navigation.languages')}</Title>
            </Box>
          </Box>
        </Hidden>
      </Button>
      <Menu
        id="menu-languages"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClick('en')}>
          <Typography variant="body1">English</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('de')}>
          <Typography variant="body1">Deutsche</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('es')}>
          <Typography variant="body1">Español</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('fr')}>
          <Typography variant="body1">Français</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('it')}>
          <Typography variant="body1">Italiano</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('hu')}>
          <Typography variant="body1">Magyar</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleClick('pt')}>
          <Typography variant="body1">Português</Typography>
        </MenuItem>
      </Menu>
    </StyledBox>
  );
};

export default React.memo(LanguageSelector);
