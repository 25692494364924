import {TableHead as MuiTableHead, TableRow} from '@material-ui/core';
import React from 'react';
import {FilterValue} from './filter-utils';
import TableHeaderCell from './TableHeaderCell';
import {Order} from './sort-utils';

interface TableHeadProps {
  columns: {
    id: string;
    label: string;
    type?: string;
    width?: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    values?: FilterValue[];
  }[];
  orderBy?: string;
  order?: 'asc' | 'desc';
  setOrder?: React.Dispatch<React.SetStateAction<Order>>;
  setOrderBy?: React.Dispatch<React.SetStateAction<keyof any>>;
  handleToggleSelection: (label: string, columnId: string, selected: boolean) => void;
  handleClearAll?: (columnId: string) => void;
  handleSelectAll?: (columnId: string) => void;
  isSuperAdmin?: boolean;
}

const TableHead = ({columns, orderBy, order, setOrder, setOrderBy, handleToggleSelection, handleClearAll, handleSelectAll, isSuperAdmin}: TableHeadProps) => {
  return (
    <MuiTableHead>
      <TableRow>
        {columns.map(column => (
          <TableHeaderCell
            key={column.id}
            column={column}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            order={order}
            orderBy={orderBy}
            values={column.values}
            handleToggleSelection={handleToggleSelection}
            handleClearAll={handleClearAll}
            handleSelectAll={handleSelectAll}
            isSuperAdmin={isSuperAdmin}
          />
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export {TableHead};
