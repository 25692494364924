import {RootState} from '../reducers';
import * as File from '../reducers/file';

export const filePostSelector = (state: Pick<RootState, 'file'>) => File.filePostSelector(state.file);
export const filesSelector = (state: Pick<RootState, 'file'>) => File.filesSelector(state.file);
export const filesGetSelector = (state: Pick<RootState, 'file'>) => File.filesGetSelector(state.file);
export const fileDeleteSelector = (state: Pick<RootState, 'file'>) => File.fileDeleteSelector(state.file);
export const filePutSelector = (state: Pick<RootState, 'file'>) => File.filePutSelector(state.file);
export const fileShareSelector = (state: Pick<RootState, 'file'>) => File.fileShareSelector(state.file);
export const fileTypesGetSelector = (state: Pick<RootState, 'file'>) => File.fileTypesGetSelector(state.file);
export const fileTypesSelector = (state: Pick<RootState, 'file'>) => File.fileTypesSelector(state.file);
