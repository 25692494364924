import React, {useEffect, useMemo, useState} from 'react';
import Layout from '../../Layout';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import Map from './Map';
import Legend from './Legend';
import MapOverlay from '../../../components/UI/MapOverlay';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import InfoSection from './InfoSection';
import {useImages} from '../../../hooks/useImages';
import {Error} from '../../../components/UI/Error/Error';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import {getUserGroups} from '../../authHOC';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import usePolygon from '../../../hooks/usePolygon';
import {getScreenshot} from './DownloadScreenshot';

import useTooltipText from '../../../hooks/useTooltipText';
import {PageTitle} from '../../../components/UI/PageTitle';

const NdviUI = ({user}: WithUserProps) => {
  //initialize the marker colors
  useMarkerColor();
  const [selectedDate, setSelectedDate] = useState<number>();
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [type, setType] = useState<'current' | 'predicted' | 'previous' | 'average'>();
  const {selectedFarm, activeFarm, allFarms, loadingGetFarm, loadingGetUser, handleFarmChange, setActiveFarm} = useFarmSelection();
  const {
    images,
    product,
    description,
    variant,
    error,
    loadingGetImages,
    ndviSelectedDate,
    ndviSelectedImageType,
    ndwiSelectedDate,
    ndwiSelectedImageType,
    onGetImages,
    handleSelectedDateAndTypeNDVI,
    handleSelectedDateAndTypeNDWI,
  } = useImages(activeFarm, startDate, endDate, getUserGroups(user).includes('PREDICTIONS'));
  const {polygonClicked, selectedPolygon, handleOnClickPolygon} = usePolygon();
  const {HelpNDVI, HelpNDWI} = useTooltipText();

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleUpdateDateIndex = (date: number, type: 'current' | 'predicted' | 'previous' | 'average') => {
    setSelectedDate(date);
    setType(type);
    product === 'ndvi' ? handleSelectedDateAndTypeNDVI(new Date(date), type) : handleSelectedDateAndTypeNDWI(new Date(date), type);
  };

  const handleClickCompare = type => {
    setType(type);
    product === 'ndvi' ? handleSelectedDateAndTypeNDVI(ndviSelectedDate, type) : handleSelectedDateAndTypeNDWI(ndwiSelectedDate, type);
  };

  const handleGetRequest = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
    }
    const groups = getUserGroups(user);
    onGetImages(selectedFarm, groups.includes('PREDICTIONS'));
  };

  useEffect(() => {
    const activeSelectedDate = product === 'ndvi' ? ndviSelectedDate : ndwiSelectedDate;
    if (!selectedDate && activeSelectedDate) setSelectedDate(Number(new Date(activeSelectedDate).getTime()));
  }, [ndviSelectedDate, ndwiSelectedDate, product, selectedDate]);

  //get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };
  // check if selected image type in the global scope already
  const selectedImageType = (product === 'ndvi' ? ndviSelectedImageType : ndwiSelectedImageType) || type;
  // still loading the images?
  const isLoading = loadingGetFarm || loadingGetUser || loadingGetImages;
  // filter images based on the selected date
  const filteredImages = useMemo(() => images?.find(({date}) => date === selectedDate), [selectedDate, images]);
  const prevImageUrl = filteredImages?.images.some(i => i.imagePreviousUrl);
  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && activeFarm && !activeFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={activeFarm.id} />}
      {!isLoading && error && <Error onClick={handleGetRequest} />}
      {!isLoading && activeFarm && (
        <div style={{overflow: 'hidden', position: 'relative'}} id="layout">
          <Legend product={variant} type={type} />
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                activeFarm={selectedFarm}
                allFarms={allFarms}
                handleChange={handleFarmChange}
                images={images}
                type={selectedImageType}
                handlePostRequest={handleGetRequest}
                handleUpdateDateIndex={handleUpdateDateIndex}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
                startDate={startDate}
                endDate={endDate}
                selectedDate={selectedDate}
              />
            }
            content={
              <>
                <PageTitle
                  title={
                    <InfoSection selectedDate={selectedDate} type={selectedImageType} product={product} description={description} handleClickCompare={handleClickCompare} prevImageUrl={prevImageUrl} />
                  }
                  tooltip={product === 'ndvi' ? HelpNDVI : HelpNDWI}
                />
                <Map
                  farm={activeFarm}
                  center={activeFarm.farmCenter}
                  images={filteredImages?.images}
                  type={selectedImageType}
                  bbox={activeFarm.bbox}
                  polygons={activeFarm.polygons}
                  takeScreenshot={takeScreenshot}
                  polygonClicked={polygonClicked}
                  selectedPolygon={selectedPolygon}
                  handleOnClickPolygon={handleOnClickPolygon}
                />
              </>
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(NdviUI);
