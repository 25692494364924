import Button from '@material-ui/core/Button';
import React, {useState, useEffect} from 'react';

const modalBackgroundStyles: React.CSSProperties = {
  position: 'absolute',
  backgroundColor: 'rgb(94, 94, 94, 0.55)',
  width: '100%',
  height: '100%',
  top: '0px',
  zIndex: 10,
};

interface Props {
  close?: () => void;
  children?: React.ReactNode;
}

const CustomModal = ({close, children}: Props) => {
  const checkSmallerScreen = () => window.innerWidth <= 768 || window.innerHeight < 850;

  const [open, setOpen] = useState(true);
  const [isSmallerScreen, setisSmallerScreen] = useState(checkSmallerScreen());

  const modalContentStyles: React.CSSProperties = {
    backgroundColor: 'white',
    width: isSmallerScreen ? '100%' : '70%',
    height: isSmallerScreen ? '100%' : '85%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 11,
    padding: '3%',
    borderRadius: isSmallerScreen ? '0px' : '7px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: isSmallerScreen ? 'auto' : 'hidden',
  };

  useEffect(() => {
    const handleResize = () => {
      setisSmallerScreen(checkSmallerScreen());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    close();
  };

  const handleContentClick = e => {
    e.stopPropagation();
  };

  return (
    <div>
      {open && (
        <div style={modalBackgroundStyles} onClick={handleClose}>
          <div style={modalContentStyles} onClick={handleContentClick}>
            <div>{children}</div>
            <Button variant="contained" color="primary" style={{width: '25%'}} onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomModal;
