import React, {useState} from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import {FaTimes} from 'react-icons/fa';
import Hidden from '@material-ui/core/Hidden';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {getUserGroups} from '../../authHOC';
import Text from '../../../components/UI/Text/Text';

const CloseButton = styled(Button)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
  }
`;

const HelpContent = styled.div``;

const helpText = (t: TFunction) => [
  {
    headerTitle: t('navigation.help.header.title'),
    text: [t('navigation.help.description')],
    title2: t('navigation.help.instructions.title'),
    text2: [t('navigation.help.instructions.description.1'), t('navigation.help.instructions.description.2')],
    ul: [
      {title: t('navigation.help.instructions.sub.title.1'), description: t('navigation.help.instructions.sub.title.1.description.1')},
      {title: t('navigation.help.instructions.sub.title.2'), description: t('navigation.help.instructions.sub.title.2.description.2')},
      {title: t('navigation.help.instructions.sub.title.3'), description: t('navigation.help.instructions.sub.title.3.description.3')},
      {title: t('navigation.help.instructions.sub.title.4'), description: t('navigation.help.instructions.sub.title.4.description.4')},
      {title: t('navigation.help.instructions.sub.title.5'), description: t('navigation.help.instructions.sub.title.5.description.5')},
    ],
    text3: [t('navigation.help.instructions.description.3')],
    title3: t('navigation.help.dashboard.title'),
    text4: [t('navigation.help.dashboard.description')],
    rules: ['DASHBOARD'],
  },
  {
    title: t('navigation.help.vine.ndvi'),
    title2: ' ',
    title3: t('navigation.help.ndvi.title'),
    text: [t('navigation.help.ndvi.description.1'), t('navigation.help.ndvi.description.2')],
    title4: t('navigation.help.ndvi.esa.title'),
    text2: [t('navigation.help.ndvi.esa.description.1')],
    text3: [t('navigation.help.ndvi.esa.description.2')],
    ul: [t('navigation.help.ndvi.esa.bulletpoint.1'), t('navigation.help.ndvi.esa.bulletpoint.2'), t('navigation.help.ndvi.esa.bulletpoint.3')],
    rules: ['NDVI', 'SOILSIGNAL'],
  },
  {
    title: t('navigation.accordion.vine.ndwi'),
    title2: t('navigation.help.ndwi.title'),
    text: [t('navigation.help.ndwi.description.1'), t('navigation.help.ndwi.description.2'), t('navigation.help.ndwi.description.3')],
    ul: [t('navigation.help.ndwi.bulletpoint.1'), t('navigation.help.ndwi.bulletpoint.2'), t('navigation.help.ndwi.bulletpoint.3')],
    rules: ['NDWI', 'SOILSIGNAL'],
  },
  {
    title: t('navigation.help.ndvi.planet.title'),
    text: [t('navigation.help.ndvi.planet.description.1'), t('navigation.help.ndvi.planet.description.2')],
    rules: ['NDVI'],
  },
  {
    title: t('navigation.accordion.vine.soil.moisture'),
    title2: t('navigation.help.soil.moisture.title'),
    text: [t('navigation.help.soil.moisture.description')],
    title3: t('navigation.help.soil.moisture.interpolation.title'),
    text2: [t('navigation.help.soil.moisture.interpolation.description.1'), t('navigation.help.soil.moisture.interpolation.description.2')],
    rules: ['SOILMOISTURE'],
  },
  {
    title3: t('navigation.help.soil.moisture.interpolation.title'),
    text2: [t('navigation.help.soil.moisture.interpolation.description.1'), t('navigation.help.soil.moisture.interpolation.description.2')],
    rules: ['SOILMOISTUREINTERPOLATION'],
  },
  {
    title: t('navigation.help.yield.title'),
    text: [t('navigation.help.yield.description')],
    rules: ['YIELD'],
  },
  {
    title: t('navigation.help.harvest.title'),
    text: [t('navigation.help.harvest.description.1'), t('navigation.help.harvest.description.2')],
    rules: ['HARVEST'],
  },
  {
    title: t('navigation.help.weather.title'),
    text: [t('navigation.help.weather.description')],
    rules: ['DASHBOARD', 'WEATHER'],
  },
  {
    title: t('navigation.help.timelapse.title'),
    text: [t('navigation.help.timelapse.description')],
    rules: ['TIMELAPSE'],
  },
  {
    title: t('navigation.help.notes.title'),
    text: [t('navigation.help.notes.description.1'), t('navigation.help.notes.description.2'), t('navigation.help.notes.description.3')],
    rules: ['DASHBOARD', 'NOTES'],
  },
];

const Help = ({user}: WithUserProps) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {t} = useTranslation();
  const groups: string[] = getUserGroups(user);

  return (
    <Hidden smDown>
      <Button color="inherit" onClick={handleClickOpen}>
        {t('navigation.help.title')}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('navigation.help.title')}</DialogTitle>
        <DialogContent>
          <HelpContent>
            <List dense={false}>
              {helpText(t).map((item, idx) => {
                const isSectionNeeded = item.rules ? item.rules.some(rule => groups.includes(rule)) : true;
                return (
                  isSectionNeeded && (
                    <div key={idx} style={{marginBottom: '2rem'}}>
                      <Text item={item} />
                    </div>
                  )
                );
              })}
            </List>
          </HelpContent>
        </DialogContent>
        <CloseButton onClick={handleClose} color="primary" autoFocus>
          <FaTimes />
        </CloseButton>
      </Dialog>
    </Hidden>
  );
};

export default withUser(Help);
