import {Box, Button, ButtonGroup} from '@material-ui/core';
import {MAGNESIUM, NITROGEN, PHOSPHORUS, POTASSIUM} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import {mapValues} from 'lodash';
import React, {useState} from 'react';

interface Props {
  list: string[];
  handleSoilNutrientType: (type: string) => void;
}
// button names on UI
export const SoilNutrientsList = ['N', 'P', 'K'];

export const ButtonGroupBar = ({list, handleSoilNutrientType}: Props) => {
  const [nutrientType, setNutrientType] = useState<string>('N');
  const handleSoilNutrientTypeChange = value => {
    let type: string;
    setNutrientType(value);
    switch (value.toUpperCase()) {
      case 'P':
        type = PHOSPHORUS;
        break;
      case 'MG':
        type = MAGNESIUM;
        break;
      case 'K':
        type = POTASSIUM;
        break;
      case 'N':
        type = NITROGEN;
        break;
      default:
        type = PHOSPHORUS;
    }
    handleSoilNutrientType(type);
  };
  return (
    <Box
      m={2}
      ml={2}
      display="flex"
      alignItems="center"
      style={{
        backgroundColor: 'white',
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        position: 'absolute',
        right: '50px',
        marginTop: '20px',
      }}
    >
      <ButtonGroup size="small" aria-label="small contained button group">
        {list.map((item, idx) => (
          <Button key={idx + nutrientType} variant="contained" color={item === nutrientType ? 'secondary' : 'default'} onClick={() => handleSoilNutrientTypeChange(item)}>
            {item}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

// style={{color: item === type ? 'blue' : ''}
