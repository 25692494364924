import React from 'react';
import {Typography, List, ListItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import BulletIcon from '@material-ui/icons/Lens';

const useTooltipText = () => {
  const styles = {
    title: {
      fontSize: 15,
    },
    regularText: {
      fontSize: 15,
    },
    number: {
      fontWeight: 700,
      fontSize: 16,
      marginRight: 5,
    },
    bulletIcon: {
      fontSize: 7,
      marginRight: 3,
      marginBottom: 1,
    },
  };

  const {t} = useTranslation();

  const renderDescription = description => {
    return <Typography style={styles.regularText}>{description}</Typography>;
  };

  const renderList = (listTitle, listArray, bulleted = false) => {
    const instructions = [];
    let index = 1;
    for (const instruction of listArray) {
      const instructionSet = {id: index, instruction: instruction};
      instructions.push(instructionSet);
      index++;
    }
    return (
      <Typography style={styles.regularText}>
        {listTitle}
        <List style={styles.regularText}>
          {instructions.map(instructionSet => (
            <ListItem key={instructionSet.id}>
              <b style={styles.number}>{bulleted ? <BulletIcon style={styles.bulletIcon} /> : `${instructionSet.id}.`} </b>
              {instructionSet.instruction}
            </ListItem>
          ))}
        </List>
      </Typography>
    );
  };

  const HelpDashboard = (
    <>
      <Typography style={styles.title}>
        <b>{t('help.dashboard.title')}</b>
      </Typography>
      <br />
      {renderDescription(t('help.dashboard.description.1'))}
      <br />
      {renderDescription(t('help.dashboard.description.2'))}
    </>
  );

  const HelpNDVIStarter = () => (
    <>
      <Typography style={styles.title}>
        <b>Normalised Difference Vegetation Index (NDVI)</b>
        {t('help.ndvi.title')}
      </Typography>
      <br />
      {renderDescription(t('help.ndvi.starter.description'))}
    </>
  );

  const HelpNDVIEnding = () => (
    <>
      {renderList(t('help.ndvi.ending.li.title'), [t('help.select.date'), t('help.ndvi.ending.li.2')])}
      {renderDescription(t('help.ndvi.ending.description.1'))}
      <br />
      {renderDescription(t('help.ndvi.ending.description.2'))}
    </>
  );

  const HelpNDVI = (
    <>
      <HelpNDVIStarter />
      <br />
      <Typography style={styles.regularText}>
        <b>NDVI ESA</b>
        {t('help.ndvi.esa.description')}
      </Typography>
      <br />
      <HelpNDVIEnding />
    </>
  );

  const HelpNDVIPlanet = (
    <>
      <HelpNDVIStarter />
      <Typography style={styles.regularText}>
        <b>NDVI Planet</b>
        {t('help.ndvi.planet.description')}
      </Typography>
      <br />
      <HelpNDVIEnding />
    </>
  );

  const HelpNDWI = (
    <>
      <Typography style={styles.title}>
        <b>Normalised Difference Water Index (NDWI) </b>
        {t('help.ndwi.title')}
      </Typography>
      <br />
      {renderDescription(t('help.ndwi.description.1'))}
      <br />
      {renderDescription(t('help.ndwi.description.2'))}
      {renderList(t('help.ndwi.li.title'), [t('help.select.date'), t('help.ndwi.li.2')])}
      {renderDescription(t('help.ndwi.description.3'))}
      <br />
      {renderDescription(t('help.ndwi.description.4'))}
    </>
  );

  const HelpSoilNutrients = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('navigation.accordion.soil.nutrient')}</b> {t('help.soil.nutrients.title')}
      </Typography>
      <br />
      {renderDescription(t('help.soil.nutrients.description'))}
      {renderList(t('help.soil.nutrients.li.title'), [t('help.select.date'), t('help.soil.nutrients.li.2'), t('help.soil.nutrients.li.3')])}
    </>
  );

  const HelpSOC = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>Soil Organic Carbon (SOC)</b> {t('help.soc.title')}
      </Typography>
      <br />
      {renderDescription(t('help.soc.description'))}
      <br />
      {renderList(t('help.soc.li.title'), [t('help.select.date'), t('help.soc.li.2'), t('help.soc.li.3'), t('help.soc.li.4')])}
    </>
  );

  const HelpIrrigation = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('help.irrigation')}</b> {t('help.irrigation.title')}
      </Typography>
      <br />
      {renderDescription(t('help.irrigation.description.1'))}
      <br />
      {renderDescription(t('help.irrigation.description.2'))}
      {renderList(t('help.irrigation.li.title'), [t('help.select.date'), t('help.irrigation.li.2'), t('help.irrigation.li.3')])}
    </>
  );

  const HelpSoilMoisture = (
    <>
      <Typography style={styles.title}>
        <b>{t('navigation.accordion.vine.soil.moisture')}</b> {t('help.soil.title')}
      </Typography>
      <br />
      {renderDescription(t('help.soil.description.1'))}
      <br />
      {renderDescription(t('help.soil.description.2'))}
      <br />
      {renderDescription(t('help.soil.description.3'))}
    </>
  );

  const HelpYield = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('dashboard.yield.prediction')}</b> {t('help.yield.title')}
      </Typography>
      <br />
      {renderDescription(t('help.yield.description'))}
    </>
  );

  const HelpHarvest = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('harvest.curves')}</b> {t('help.harvest.title')}
      </Typography>
      <br />
      {renderDescription(t('help.harvest.description.1'))}
      <br />
      {renderDescription(t('help.harvest.description.2'))}
    </>
  );

  const HelpMaturityMap = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('maturity.map')}</b> {t('help.maturity.title')}
      </Typography>
      <br />
      {renderDescription(t('help.maturity.description.1'))}
      <br />
      {renderList(t('help.maturity.li.title.2'), [t('help.maturity.li.4'), t('help.maturity.li.5')], true)}
      {renderDescription(t('help.maturity.description.2'))}
      <br />
      {renderList(t('help.maturity.li.title'), [t('help.select.date'), t('help.maturity.li.2'), t('help.maturity.li.3')])}
    </>
  );

  const HelpDisease = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('disease')} Map</b> {t('help.disease.title')}
      </Typography>
      <br />
      {renderDescription(t('help.disease.description'))}
      {renderList(t('help.disease.li.title'), [t('help.select.date'), t('help.disease.li.2')])}
    </>
  );

  const HelpWeather = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('help.weather')}</b> {t('help.weather.title')}
      </Typography>
      <br />
      {renderDescription(t('weather.tooltip'))}
    </>
  );

  const HelpSeasonIndices = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('help.season.indices')}</b> {t('help.season.indices.title')}
      </Typography>
      {renderList(t('help.season.indices.li.title'), ['NDVI', 'NDWI', 'GDD (Growing Degree Days)', 'Cumulative Rainfall'], true)}
      {renderDescription(t('help.season.indices.description'))}
      <br />
      {renderList(t('help.season.indices.li.title.2'), [t('help.season.indices.li'), t('help.season.indices.li.2'), t('help.season.indices.li.3'), t('help.season.indices.li.4')], true)}
    </>
  );

  const HelpTimelapse = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b>{t('help.timelapse')}</b> {t('help.timelapse.title')}
      </Typography>
      <br />
      {renderDescription(t('help.timelapse.description'))}
    </>
  );

  const HelpNotes = (
    <>
      <Typography style={styles.title}>
        {t('help.the')} <b> {t('help.notes')}</b>
        {t('help.notes.title')}
      </Typography>
      <br />
      {renderDescription(t('help.notes.description.1'))}
      <br />
      {renderDescription(t('help.notes.description.2'))}
    </>
  );

  return {
    HelpDashboard,
    HelpNDVIStarter,
    HelpNDVIEnding,
    HelpNDVI,
    HelpNDVIPlanet,
    HelpNDWI,
    HelpSoilNutrients,
    HelpSOC,
    HelpIrrigation,
    HelpSoilMoisture,
    HelpYield,
    HelpHarvest,
    HelpMaturityMap,
    HelpDisease,
    HelpWeather,
    HelpSeasonIndices,
    HelpTimelapse,
    HelpNotes,
  };
};

export default useTooltipText;
