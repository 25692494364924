import {Box, TableCell, Typography} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {FilterValue} from './filter-utils';
import TableFilterButton from './TableFilterButton';
import TableSortButton from './TableSortButton';
import {createSortHandler, Order} from './sort-utils';
import {getStickyColumns} from '../../../containers/pages/YieldPrediction/StickyColumns';

const StyledTypography = styled(Typography)`
  && {
    cursor: pointer;
  }
`;

export interface Props {
  column: {
    id: string;
    label: string;
    type?: string;
    width?: string;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  };
  orderBy?: string;
  order?: 'asc' | 'desc';
  setOrder?: React.Dispatch<React.SetStateAction<Order>>;
  setOrderBy?: React.Dispatch<React.SetStateAction<keyof any>>;
  values?: FilterValue[];
  handleToggleSelection?: (label: string, columnId: string, selected: boolean) => void;
  handleClearAll?: (columnId: string) => void;
  handleSelectAll?: (columnId: string) => void;
  isSuperAdmin?: boolean;
}

const TableHeaderCell = ({column, setOrder, setOrderBy, orderBy, order, values, handleToggleSelection, handleClearAll, handleSelectAll, isSuperAdmin}: Props) => {
  const {t} = useTranslation();
  //get sticky columns to freeze on horizontol scrolling
  const {stickyColumn, width} = getStickyColumns(column, isSuperAdmin);
  return (
    <TableCell width={column.width} key={column.id} align={column.align || 'left'} style={stickyColumn ? {position: 'sticky', left: width, zIndex: 10} : {}}>
      {order && orderBy && setOrder && setOrderBy && (
        <Box display="flex" alignItems="center">
          <StyledTypography onClick={createSortHandler(column.id, orderBy, order, setOrder, setOrderBy)}>{t(column.label)}</StyledTypography>
          <TableSortButton order={order} orderBy={orderBy} active={orderBy === column.id} column={column} setOrderBy={setOrderBy} setOrder={setOrder} />
          {column.type === 'string' && (
            <TableFilterButton values={values} handleToggleSelection={handleToggleSelection} columnId={column.id} handleClearAll={handleClearAll} handleSelectAll={handleSelectAll} />
          )}
        </Box>
      )}
      {!(order && orderBy && setOrder && setOrderBy) && column.label}
    </TableCell>
  );
};

export default TableHeaderCell;
