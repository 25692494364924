import {IIrrigationImageResDTO} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {Action} from '../actions/irrigation';
import {updateObject} from '../utility';
import {IFarm} from './farm';

export const irrigationFarmsSelector = (state: IIrrigationState) => state.irrigationFarms;
export const irrigationImagesSelector = (state: IIrrigationState) => state.irrigationImages;
export const irrigationActiveFarmSelector = (state: IIrrigationState) => state.activeIrrigationFarm;
export const irrigationActiveFarmImageSelector = (state: IIrrigationState) => state.activeFarmImageId;
export const irrigationFarmGetSelector = (state: IIrrigationState) => state.irrigationFarmsGet;
export const getIrrigationImagesGetSelector = (state: IIrrigationState) => state.irrigationImagesGet;
export const irrigationSelectedDateSelector = (state: IIrrigationState) => state.selectedDate;

export interface IIrrigationState {
  irrigationImages: IIrrigationImageResDTO[];
  irrigationFarms: IFarm[];
  activeIrrigationFarm: IFarm;
  activeFarmImageId: string;
  selectedDate: Date;
  irrigationImagesGet: {
    loading: boolean;
    error: unknown;
  };
  irrigationFarmsGet: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: IIrrigationState = {
  irrigationImages: null,
  irrigationFarms: null,
  activeIrrigationFarm: null,
  activeFarmImageId: null,
  selectedDate: null,
  irrigationImagesGet: {
    loading: false,
    error: null,
  },
  irrigationFarmsGet: {
    loading: false,
    error: null,
  },
};

const reducer = (state: IIrrigationState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.IRRIGATION_IMAGE_GET_START:
      return updateObject(state, {
        irrigationImagesGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.IRRIGATION_IMAGE_GET_FAIL:
      return updateObject(state, {
        irrigationImagesGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.IRRIGATION_FARM_GET_START:
      return updateObject(state, {
        irrigationFarmsGet: {
          loading: true,
          error: null,
        },
      });
    case ActionTypes.IRRIGATION_FARM_GET_FAIL:
      return updateObject(state, {
        irrigationFarmsGet: {
          loading: false,
          error: action.error,
        },
      });
    case ActionTypes.IRRIGATION_IMAGE_GET_SUCCESS:
      return updateObject(state, {
        irrigationImages: action.irrigationImages,
        activeFarmImageId: action.activeFarmImageId,
        activeIrrigationFarm: action.activeFarm,
        irrigationImagesGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.IRRIGATION_FARM_GET_SUCCESS:
      return updateObject(state, {
        irrigationFarms: action.irrigationFarms,
        activeIrrigationFarm: action.activeFarm,
        irrigationFarmsGet: {
          loading: false,
          error: null,
        },
      });
    case ActionTypes.IRRIGATION_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    default:
      return state;
  }
};

export default reducer;
