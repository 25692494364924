import React from 'react';
import PolygonManager from '../../PolygonManager';
import Spinner from '../../../../../components/UI/Spinner';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';

const Btn = styled(Button)`
  && {
    margin: 1rem 1rem 1rem 0;
  }
`;

interface Props {
  farmName: string;
  farmId: string;
  polygon: any;
  onDeleteFarm: (farmId: string) => void;
  loadingDeleteFarm: boolean;
  handleNextStep: () => void;
  postPolySuccess: boolean;
  resetPolygonCoords: () => void;
}

const DrawPolygonsStep = ({farmName, farmId, polygon, onDeleteFarm, loadingDeleteFarm, handleNextStep, postPolySuccess, resetPolygonCoords}: Props) => {
  const {t} = useTranslation();
  return (
    <>
      <PolygonManager polygon={polygon} farmName={farmName} farmId={farmId} resetPolygonCoords={resetPolygonCoords} />
      <div>
        <Btn onClick={() => onDeleteFarm(farmId)}>
          {t('forms.cancel')}
          {loadingDeleteFarm && <Spinner size={15} color="primary" />}
        </Btn>
        <Btn variant="contained" color="primary" onClick={handleNextStep} disabled={!postPolySuccess}>
          {t('setting.create.farm.finish')}
        </Btn>
      </div>
    </>
  );
};

export default DrawPolygonsStep;
