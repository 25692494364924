import {GroundOverlay} from '@react-google-maps/api';
import React from 'react';
import {IFarmImageEntity} from '@deep-planet/api-interfaces';
import {Map, MapProps} from '../../../components/UI/Map/Map';
import {SoilValues} from '../../../store/reducers/farm';
import PolygonWithInfo from '../../../components/UI/PolygonWithInfo/PolygonWithInfo';
import {renderURL} from './../NDVI/DownloadScreenshot';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 64px)',
};

interface Props extends MapProps {
  image: IFarmImageEntity | null;
  soilValues: SoilValues[];
  infoSecondaryTitle: string;
}

const SoilSignalMap = ({center, polygons, image, soilValues, infoSecondaryTitle, bbox}: Props) => {
  const bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(bbox[1], bbox[0]), new window.google.maps.LatLng(bbox[3], bbox[2]));
  return (
    <Map center={center} mapContainerStyle={containerStyle} bbox={bbox}>
      <GroundOverlay key={image?.url} url={renderURL(image?.url)} bounds={bounds} />
      {!!polygons &&
        polygons.length &&
        polygons.map(polygon => {
          return (
            <PolygonWithInfo polygon={polygon} key={polygon.id} secondaryTitle={infoSecondaryTitle} secondaryDescription={soilValues?.find(({polygonName}) => polygonName === polygon.name)?.value} />
          );
        })}
    </Map>
  );
};

export default SoilSignalMap;
