import React, {useState} from 'react';
import {Marker, InfoWindow, MarkerProps} from '@react-google-maps/api';
import Typography from '@material-ui/core/Typography';

interface Props {
  position: MarkerProps['position'];
  name: string;
  handleMarkerClicked: (name: string) => void;
}

const MarkerWithPopup = ({position, name, handleMarkerClicked}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggleOpen = () => setIsOpen(!isOpen);

  return (
    <Marker position={position} onClick={() => handleMarkerClicked(name)}>
      {isOpen && (
        <InfoWindow onCloseClick={onToggleOpen}>
          <Typography variant="body1">
            <strong>{name}</strong>
          </Typography>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerWithPopup;
