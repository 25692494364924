import React from 'react';
import {Map} from '../../../components/UI/Map/Map';
import MarkerWithPopup from './MarkerWithPopup';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(53vh - 96px - 1rem)',
};

const SoilMoistureMap = props => {
  const center = {
    lat: props.center && props.center.geometry ? props.center.geometry.coordinates[1] : 0,
    lng: props.center && props.center.geometry ? props.center.geometry.coordinates[0] : 0,
  };
  return (
    <Map center={center} mapContainerStyle={containerStyle}>
      {props.sensors && props.sensors.features
        ? props.sensors.features.map(f => (
            <MarkerWithPopup
              name={f.properties.name}
              position={{
                lat: f.geometry.coordinates[1],
                lng: f.geometry.coordinates[0],
              }}
              handleMarkerClicked={name => props.handleMarkerClicked(name)}
            />
          ))
        : null}
    </Map>
  );
};

export default SoilMoistureMap;
