import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {List, ListItem, ListItemProps, ListItemText} from '@material-ui/core';
import {SubPage} from './index';
import {PERSONAL, NOTIFICATIONS, ACCOUNT, PREFERENCES} from '../../../config/const';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 64px);
  padding: 16px 0;
`;

const StyledListItem = styled(ListItem)<ListItemProps>`
  &:hover {
    cursor: pointer;
  }
`;

interface Props {
  handleSubpageSelect: (selected: SubPage) => void;
}

const Sidebar = ({handleSubpageSelect}: Props) => {
  const {t} = useTranslation();
  return (
    <Container>
      <List>
        <StyledListItem button onClick={() => handleSubpageSelect(PERSONAL)} title={t('account.personal.information')}>
          <ListItemText primary={t('account.personal.information')} />
        </StyledListItem>
        <StyledListItem button onClick={() => handleSubpageSelect(NOTIFICATIONS)} title={'account.notifications'}>
          <ListItemText primary={t('account.notifications')} />
        </StyledListItem>
        <StyledListItem button onClick={() => handleSubpageSelect(ACCOUNT)} title={'account.privacy'}>
          <ListItemText primary={t('account.privacy')} />
        </StyledListItem>
        <StyledListItem button onClick={() => handleSubpageSelect(PREFERENCES)} title={'account.preferences'}>
          <ListItemText primary={t('account.preferences')} />
        </StyledListItem>
      </List>
    </Container>
  );
};

export default Sidebar;
