import React, {useState} from 'react';
import Layout from '../../Layout';
import ContentLeftSidebar from '../../../components/UI/ContentLeftSidebar';
import Sidebar from './Sidebar';
import MapOverlay from '../../../components/UI/MapOverlay';
import {Error} from '../../../components/UI/Error/Error';
import {MapPlaceholderNoPolygons} from '../../../components/UI/MapPlaceholder';
import MapPlaceholder from '../../../components/UI/MapPlaceholder';
import {withUser, WithUserProps} from '../../../hooks/useAuth';
import {useFarmSelection} from '../../../hooks/useFarmSelection';
import {useTranslation} from 'react-i18next';
import Legend, {DynamicScaleType} from '../NDVI/Legend';
import {getThresholds} from 'apps/web-portal-ui/src/app/helpers/imageTransformers';
import {useIrrigationSelection} from '../../../hooks/useIrrigationImage';
import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';
import InfoSection from '../NDVI/InfoSection';
import MapContent from '../../../components/UI/Map/MapContent';
import {useMarkerColor} from '../../../hooks/useMarkerColor';
import {getScreenshot} from '../NDVI/DownloadScreenshot';
import usePolygon from '../../../hooks/usePolygon';

import Box from '@material-ui/core/Box';
import {HelpTooltip} from '../../../components/UI/HelpTooltip';
import useTooltipText from '../../../hooks/useTooltipText';
import {PageTitle} from '../../../components/UI/PageTitle';

const Irrigation = ({user}: WithUserProps) => {
  //initialize the marker colors
  useMarkerColor();
  const initialDynamicScale: DynamicScaleType = [null, null, null, null, null, null, null, null];
  const [dynamicScale, setDynamicScale] = useState<DynamicScaleType>(initialDynamicScale);
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [type, setType] = useState<'current' | 'predicted' | 'previous' | 'average'>('current');
  const [showGraph, setShowGraph] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {t} = useTranslation();
  const {selectedPolygon, polygonClicked, handleOnClickPolygon} = usePolygon();
  // collect farm data from selector hook
  const {allFarms, selectedFarm, setSelectedFarm, activeFarm, setActiveFarm, loadingGetFarm: loadingFarms} = useFarmSelection();
  // collect the irrigation data from selector hook
  const {
    irrigationImages,
    loadingIrrigationFarms,
    loadingImages,
    irrigationActiveFarm,
    setIrrigationSelectedFarm,
    setIrrigationActiveFarm,
    selectedDate,
    error,
    handleGetImagesForPeriod,
    handleDateChange,
  } = useIrrigationSelection(allFarms, activeFarm, selectedFarm);
  const {HelpIrrigation} = useTooltipText();

  const handleStartDateSelection = (date: Date) => {
    setStartDate(date);
  };
  const handleEndDateSelection = (date: Date) => {
    setEndDate(date);
  };

  const handleUpdateDateIndex = (date: number, type: 'current' | 'predicted' | 'previous' | 'average') => {
    setType(type);
    handleDateChange(date);
  };

  const handleClickCompare = type => {
    setType(type);
  };

  const handleGetRequest = () => {
    if (selectedFarm) {
      setActiveFarm(selectedFarm);
      setIrrigationActiveFarm(selectedFarm);
    }
    handleGetImagesForPeriod(selectedFarm, startDate, endDate);
  };

  const handleOnClick = (polygon: IPolygonEntity) => {
    handleOnClickPolygon(polygon);
  };

  const displayGraph = () => {
    setShowGraph(true);
  };

  const closeGraph = () => {
    setShowGraph(false);
  };

  const handleFarmChange = (farmId: string) => {
    const selectedFarm = allFarms.find(({farmid}) => farmid === farmId);
    setIrrigationSelectedFarm(selectedFarm);
    setSelectedFarm(selectedFarm);
  };

  const handlePolygonHover = (id: string) => {
    if (id) {
      const {thresholdLow, thresholdHigh} = getThresholds(selectedDateImages.images, id, type);
      const step = (thresholdHigh - thresholdLow) / 7;
      // @ts-ignore
      const currentDynamicScale: DynamicScaleType = [];
      // show threshold values on colorbar on map
      for (let i = 0; i <= 7; i++) {
        currentDynamicScale.push(Number((thresholdLow + step * i).toFixed(2)));
      }
      setDynamicScale(currentDynamicScale);
    } else {
      setDynamicScale(initialDynamicScale);
    }
  };

  // get screenshot of the layout
  const takeScreenshot = () => {
    const screenshotLayout = document.getElementById('layout');
    getScreenshot(screenshotLayout);
  };
  const selectedActiveFarm = activeFarm || irrigationActiveFarm || (allFarms && allFarms[0]);
  const isLoading = loadingIrrigationFarms || loadingFarms || loadingImages;
  const selectedDateImages = irrigationImages?.find(({date, farmid}) => date === selectedDate && farmid === selectedActiveFarm.id);
  const prevImageUrl = selectedDateImages?.images.some(i => i.prev_url);

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && !allFarms?.length && <MapPlaceholder />}
      {!isLoading && selectedActiveFarm && !selectedActiveFarm?.polygons && <MapPlaceholderNoPolygons activeFarm={selectedActiveFarm.id} />}
      {!isLoading && error && <Error onClick={handleGetRequest} />}
      {!isLoading && selectedActiveFarm && (
        <div style={{overflow: 'hidden', position: 'relative'}} id="layout">
          <Legend product={'irrigation'} type={type} dynamicScale={dynamicScale} text={'mm'} />
          <ContentLeftSidebar
            sidebar={
              <Sidebar
                type={type}
                activeFarm={selectedFarm}
                allFarms={allFarms}
                images={irrigationImages}
                startDate={startDate}
                endDate={endDate}
                selectedDate={selectedDate}
                error={error}
                handleChange={handleFarmChange}
                handlePostRequest={handleGetRequest}
                handleUpdateDateIndex={handleUpdateDateIndex}
                handleStartDateSelection={handleStartDateSelection}
                handleEndDateSelection={handleEndDateSelection}
              />
            }
            content={
              <>
                <PageTitle
                  title={
                    <InfoSection
                      selectedDate={selectedDate}
                      type={type}
                      product={'Irrigation'}
                      description={t('irrigation.evaportranspiration')}
                      handleClickCompare={handleClickCompare}
                      prevImageUrl={prevImageUrl}
                    />
                  }
                  tooltip={HelpIrrigation}
                />
                <MapContent
                  farm={selectedActiveFarm}
                  center={selectedActiveFarm.farmCenter}
                  images={selectedDateImages?.images}
                  type={type}
                  bbox={selectedActiveFarm.bbox}
                  polygons={selectedActiveFarm.polygons}
                  handlePolygonHover={handlePolygonHover}
                  selectedPolygon={selectedPolygon}
                  handleOnClick={handleOnClick}
                  feature={'IRRIGATION'}
                  takeScreenshot={takeScreenshot}
                  polygonClicked={polygonClicked}
                  irrigation
                  displayGraph={displayGraph}
                  showGraph={showGraph}
                  closeGraph={closeGraph}
                />
              </>
            }
          />
        </div>
      )}
    </Layout>
  );
};

export default withUser(Irrigation);
