import {Dispatch} from 'redux';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {baseApiUrl} from '../../config/const';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import i18n from '../../../i18n';

//////////////////////////////////////////
// GET soil
//////////////////////////////////////////

interface ISoilGetStart {
  type: ActionTypes.SOIL_GET_START;
}

export const soilGetStart = (): ISoilGetStart => ({
  type: ActionTypes.SOIL_GET_START,
});

interface ISoilGetSuccess {
  type: ActionTypes.SOIL_GET_SUCCESS;
  data: unknown;
}

export const soilGetSuccess = (data): ISoilGetSuccess => ({
  type: ActionTypes.SOIL_GET_SUCCESS,
  data: data,
});

interface ISoilGetFail {
  type: ActionTypes.SOIL_GET_FAIL;
  error: unknown;
}

export const soilGetFail = (error: unknown): ISoilGetFail => ({
  type: ActionTypes.SOIL_GET_FAIL,
  error: error,
});

export const getSoil = (sensorName: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(soilGetStart());
    const url = `${baseApiUrl}/soil-moisture/${sensorName}`;
    axios
      .get(url)
      .then(response => {
        const data = response.data;
        dispatch(soilGetSuccess(data));
      })
      .catch(err => {
        console.log(err);
        dispatch(soilGetFail(err));
      });
  };
};

//////////////////////////////////////////
// GET soil moisture sensor
//////////////////////////////////////////

interface ISoilMoistureSensorGetStart {
  type: ActionTypes.MOISTURESENSORS_GET_START;
}
export const moistureSensorGetStart = (): ISoilMoistureSensorGetStart => ({
  type: ActionTypes.MOISTURESENSORS_GET_START,
});

interface ISoilMoistureSensorGetSuccess {
  type: ActionTypes.MOISTURESENSORS_GET_SUCCESS;
  data: unknown;
}

export const moistureSensorGetSuccess = (data: unknown): ISoilMoistureSensorGetSuccess => ({
  type: ActionTypes.MOISTURESENSORS_GET_SUCCESS,
  data,
});

interface ISoilMoistureSensorGetFail {
  type: ActionTypes.MOISTURESENSORS_GET_FAIL;
  error: unknown;
}

export const moistureSensorGetFail = (error: unknown): ISoilMoistureSensorGetFail => ({
  type: ActionTypes.MOISTURESENSORS_GET_FAIL,
  error,
});

export const getSoilMoistureSensors = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(moistureSensorGetStart());
    const url = `${baseApiUrl}/moisture-sensor`;
    axios
      .get(url)
      .then(response => {
        let data = null;
        if (response.data.data) {
          data = JSON.parse(response.data.data);
        }

        dispatch(moistureSensorGetSuccess(data));
      })
      .catch(err => {
        console.log(err);
        dispatch(moistureSensorGetFail(err));
      });
  };
};

//////////////////////////////////////////
// Post soil moisture sensor
//////////////////////////////////////////

interface ISoilMoisturePostStart {
  type: ActionTypes.MOISTURE_SENSORS_POST_START;
}

export const moistureSensorPostStart = (): ISoilMoisturePostStart => ({
  type: ActionTypes.MOISTURE_SENSORS_POST_START,
});

interface ISoilMoisturePostSuccess {
  type: ActionTypes.MOISTURE_SENSORS_POST_SUCCESS;
  data: unknown;
}

export const moistureSensorPostSuccess = (data: unknown): ISoilMoisturePostSuccess => ({
  type: ActionTypes.MOISTURE_SENSORS_POST_SUCCESS,
  data,
});

interface ISoilMoisturePostFail {
  type: ActionTypes.MOISTURE_SENSORS_POST_FAIL;
  error: unknown;
}

export const moistureSensorPostFail = (error: unknown): ISoilMoisturePostFail => ({
  type: ActionTypes.MOISTURE_SENSORS_POST_FAIL,
  error,
});

export const postSoilMoistureSensors = (user: string, payload) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    const url = `${baseApiUrl}/moisture-sensor`;
    try {
      dispatch(moistureSensorPostStart());
      await axios.post(url, payload, {headers: {'Content-Type': 'application/json'}});
      dispatch(moistureSensorPostSuccess({}));
      dispatch(enqueueSnackbar({message: i18n.t('admin.moisture.updated'), options: {variant: 'success'}}));
    } catch (e) {
      dispatch(moistureSensorPostFail(e));
      dispatch(enqueueSnackbar({message: i18n.t('error.http.response'), options: {variant: 'error'}}));
    }
  };
};

export type Action =
  | ReturnType<typeof moistureSensorPostStart>
  | ReturnType<typeof moistureSensorPostSuccess>
  | ReturnType<typeof moistureSensorPostFail>
  | ReturnType<typeof moistureSensorGetStart>
  | ReturnType<typeof moistureSensorGetSuccess>
  | ReturnType<typeof moistureSensorGetFail>
  | ReturnType<typeof soilGetStart>
  | ReturnType<typeof soilGetSuccess>
  | ReturnType<typeof soilGetFail>;
