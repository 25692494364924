import React, {useState, useEffect} from 'react';
import {Polygon} from '@react-google-maps/api';

import {IPolygonEntity, polygonInfoWindow} from '@deep-planet/api-interfaces';

interface PolygonProps {
  polygon: IPolygonEntity;
  selectedPolygon: polygonInfoWindow;
  handleOnClick: (polygon: IPolygonEntity) => void;
  showOverlay: () => void;
}

const CustomPolygon = ({polygon, selectedPolygon, handleOnClick, showOverlay}: PolygonProps) => {
  const [strokeColor, setStrokeColor] = useState<string>('white');
  const [strokeWeight, setStrokeWeight] = useState<number>(2);

  // Increase polygon outline on mouse in
  const handleOnMouseOver = () => {
    setStrokeWeight(strokeWeight === 3 ? 2 : 3);
  };

  // Decrease polygon outline on mouse out
  const handleOnMouseOut = () => {
    setStrokeWeight(strokeWeight === 3 ? 2 : 3);
  };

  const onClick = (latLng: google.maps.LatLng) => {
    showOverlay();
    handleOnClick(polygon);
    setStrokeColor(strokeColor === 'white' ? '#FFEA00' : 'white');
  };

  const polygonStrokeWeight = (polygon.id === selectedPolygon?.polygonId && selectedPolygon?.strokeWeight) || strokeWeight;
  const polygonStrokeColor = (polygon.id === selectedPolygon?.polygonId && selectedPolygon?.strokeColor) || strokeColor;

  useEffect(() => {
    // Reset stroke colour when another polygon is selected that does not match this polygon
    if (selectedPolygon && polygon.id !== selectedPolygon.polygonId) {
      setStrokeColor('white');
    }
  }, [polygon.id, selectedPolygon]);

  return (
    <Polygon
      paths={polygon.geoJson.geometry.renderCoords}
      options={{
        fillColor: '#C5AA75',
        fillOpacity: 0,
        strokeColor: polygonStrokeColor,
        strokeOpacity: 1,
        strokeWeight: polygonStrokeWeight,
        clickable: true,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
        strokePosition: 0,
      }}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
      onClick={({latLng}) => onClick(latLng)}
    />
  );
};

export default CustomPolygon;
