import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getStringDate} from '../helpers/dateHelpers';
import {getdiseaseImages, diseaseImageSetSelectedDate} from '../store/actions/disease';
import {IFarm} from '../store/reducers/farm';
import {selectedOrganizationSelector} from '../store/selectors';
import {getDiseaseImagesSelector, diseaseImagesSelector, diseaseSelectedDateSelector} from '../store/selectors/disease';
import {usePrevious} from './usePrevious';

export function useDisease(activeFarm: IFarm, startDate: Date, endDate: Date, lazy = false) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<number>();
  const diseaseImages = useSelector(diseaseImagesSelector);
  const {loading, error} = useSelector(getDiseaseImagesSelector);
  const selectedOrganization = useSelector(selectedOrganizationSelector);
  const prevdiseaseImages = usePrevious(diseaseImages);
  const prevFarm = usePrevious(activeFarm);
  const activeSelectedDate = useSelector(diseaseSelectedDateSelector);

  const handleDateChange = (date: number) => {
    const selectedDate = date;
    setSelectedDate(selectedDate);
    dispatch(diseaseImageSetSelectedDate(date));
  };

  // this will get the images for the first time when screen is initialized or selected organization changes
  useEffect(() => {
    if (activeFarm && !loading && !diseaseImages) {
      dispatch(getdiseaseImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
    }
  }, [activeFarm, dispatch, endDate, loading, diseaseImages, selectedOrganization, startDate]);

  // // in case of selected organization is changed
  useEffect(() => {
    if (activeFarm && prevFarm && activeFarm.id !== prevFarm.id && !loading) {
      dispatch(getdiseaseImages(activeFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization.id));
    }
  }, [activeFarm, dispatch, endDate, prevFarm, selectedOrganization, startDate, loading]);

  useEffect(() => {
    if (!selectedDate && activeSelectedDate) setSelectedDate(activeSelectedDate);
  }, [activeSelectedDate, selectedDate]);
  // get images on the selected farm
  const onGetDiseaseImages = (selectedFarm: IFarm) => {
    dispatch(getdiseaseImages(selectedFarm, getStringDate(startDate), getStringDate(endDate), selectedOrganization?.id));
  };

  return {
    loading,
    error,
    diseaseImages,
    prevdiseaseImages,
    selectedDate,
    onGetDiseaseImages,
    handleDateChange,
  };
}
