import {useTranslation} from 'react-i18next';
import Layout from '../../../Layout';
import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {baseApiUrl} from '../../../../config/const';
import theme from '../../../../utilities/theme';
import axios from 'axios';
import MapOverlay from '../../../../components/UI/MapOverlay';
import {useDispatch} from 'react-redux';
import {enqueueSnackbar} from '../../../../store/actions';
import {useFarmSelection} from 'apps/web-portal-ui/src/app/hooks/useFarmSelection';

const Form = styled(FormControl)`
  && {
    width: 100%;
  }
`;

const FormWrapper = styled(Box)`
  padding: 0 2rem;
  ${theme.breakpoints.down('sm')} {
    padding: 0;
  }
`;

const ContentWrapper = styled(Grid)`
  padding: 4rem 2rem;
  ${theme.breakpoints.down('sm')} {
    padding: 2rem 1rem;
  }
`;

const Farms = () => {
  const {loadingGetFarm: isLoading, allFarms: farms} = useFarmSelection();
  const [geojson, setGeojson] = useState('');
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const handleFarmSelection = (id: string) => {
    if (id) {
      axios
        .get(`${baseApiUrl}/farm/${id}`)
        .then(({data}) => {
          setGeojson(JSON.stringify(data));
        })
        .catch(() => {
          dispatch(enqueueSnackbar({message: t('error.http.response'), options: {variant: 'error'}}));
        });
    }
  };

  const sortFarmsByName = (farm1, farm2) => {
    if (farm1.name.toLowerCase() < farm2.name.toLowerCase()) return -1;
    if (farm1.name.toLowerCase() > farm2.name.toLowerCase()) return 1;
    return 0;
  };

  return (
    <Layout>
      {isLoading && <MapOverlay />}
      {!isLoading && (
        <Grid container>
          <ContentWrapper sm={12} xs={12} lg={12} xl={12}>
            <Typography variant="h6" align="center" gutterBottom>
              {'Get geojson by farm'}
            </Typography>
            <FormWrapper>
              <Form>
                <Select
                  native
                  onChange={e => handleFarmSelection(e.currentTarget.value as string)}
                  inputProps={{
                    name: 'age',
                    id: 'farms-select',
                  }}
                  placeholder={'Select a farm'}
                >
                  <option aria-label="None" value="">
                    Select a farm
                  </option>
                  {!isLoading && farms
                    ? farms.sort(sortFarmsByName).map(f => (
                        <option value={f.id} key={f.id}>
                          {`${f.name}`}
                        </option>
                      ))
                    : null}
                </Select>
              </Form>
            </FormWrapper>
            <div style={{padding: '4rem'}}>{geojson}</div>
          </ContentWrapper>
        </Grid>
      )}
    </Layout>
  );
};

export default Farms;
