import {Box} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from '../../../Layout';
import ContentLeftSidebar from '../../../../components/UI/ContentLeftSidebar';
import {CreateOrganization} from './CreateOrganization/CreateOrganization';
import {ManageOrganization} from './ManageOrganizationDetails/ManageOrganization';
import {ManageOrganizationFarms} from './ManageOrganizationFarms/ManageOrganizationFarms';
import {ManageOrganizationPermission} from './ManageOrganizationPermissions/ManageOrganizationPermission';
import {ManageOrganizationUsers} from './ManageOrganizationUsers/ManageOrganizationUsers';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const ButtonsContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  height: calc(100% - 112px);
  justify-content: center;
`;

const Mode = {
  CREATION: 'Create',
  MANAGE: 'Manage',
  PERMISSION: 'Permission',
  USERS: 'Users',
  FARMS: 'Farms',
};

export const Organizations = () => {
  const [activeMode, setActiveMode] = useState(Mode.CREATION);

  return (
    <Layout>
      <Container>
        <ContentLeftSidebar
          sidebar={
            <ButtonsContainer>
              {Object.entries(Mode).map(([key, value]) => (
                <Box m={2} key={key}>
                  <Button variant="contained" color="primary" onClick={() => setActiveMode(Mode[key])} style={{width: '200px'}}>
                    {value}
                  </Button>
                </Box>
              ))}
            </ButtonsContainer>
          }
          content={
            <>
              {activeMode === Mode.CREATION && <CreateOrganization />}
              {activeMode === Mode.MANAGE && <ManageOrganization />}
              {activeMode === Mode.PERMISSION && <ManageOrganizationPermission />}
              {activeMode === Mode.USERS && <ManageOrganizationUsers />}
              {activeMode === Mode.FARMS && <ManageOrganizationFarms />}
            </>
          }
        />
      </Container>
    </Layout>
  );
};
