import {PolygonEntity} from '../../../../../../libs/database/src/lib/modules/polygon/polygon.entity';
import ActionTypes from './actionTypes';
import axios from 'axios';
import {GetFarmCentre, GetPolygonCentroid} from '../utility';
import {baseApiUrl} from '../../config/const';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import i18n from '../../../i18n';
import * as turf from '@turf/turf';
import {Position} from '@turf/helpers';
import {IFarm} from '../reducers/farm';
import {farmPostSuccess, farmUpdateSuccess} from './farm';
import {IPolygonEntity} from '@deep-planet/api-interfaces';

////////////////////////////////////
// GET polygon
////////////////////////////////////

interface IPolygonGetStart {
  type: ActionTypes.POLYGON_GET_START;
}

export const polygonGetStart = (): IPolygonGetStart => ({
  type: ActionTypes.POLYGON_GET_START,
});

interface IPolygonGetSuccess {
  type: ActionTypes.POLYGON_GET_SUCCESS;
  farmCenter: any;
  polyData: any;
}

export const polygonGetSuccess = (farmCenter, polyData): IPolygonGetSuccess => ({
  type: ActionTypes.POLYGON_GET_SUCCESS,
  farmCenter: farmCenter,
  polyData: polyData,
});

interface IPolygonGetFail {
  type: ActionTypes.POLYGON_GET_FAIL;
  error: unknown;
}

export const polygonGetFail = (error): IPolygonGetFail => ({
  type: ActionTypes.POLYGON_GET_FAIL,
  error: error,
});

////////////////////////////////////
// POST polygon
////////////////////////////////////

interface IPolygonPostStart {
  type: ActionTypes.POLYGON_POST_START;
}

export const polygonPostStart = (): IPolygonPostStart => ({
  type: ActionTypes.POLYGON_POST_START,
});

interface IPolygonPostSuccess {
  type: ActionTypes.POLYGON_POST_SUCCESS;
  polyData: any;
}

export const polygonPostSuccess = (polyData): IPolygonPostSuccess => ({
  type: ActionTypes.POLYGON_POST_SUCCESS,
  polyData: polyData,
});

interface IPolygonPostFail {
  type: ActionTypes.POLYGON_POST_FAIL;
  error: unknown;
}

export const polygonPostFail = (error): IPolygonPostFail => {
  return {
    type: ActionTypes.POLYGON_POST_FAIL,
    error: error,
  };
};

////////////////////////////////////
// DELETE polygon
////////////////////////////////////

interface IPolygonDeleteStart {
  type: ActionTypes.POLYGON_DELETE_START;
}

export const polygonDeleteStart = (): IPolygonDeleteStart => ({
  type: ActionTypes.POLYGON_DELETE_START,
});

interface IPolygonDeleteSuccess {
  type: ActionTypes.POLYGON_DELETE_SUCCESS;
  polyId: any;
}

export const polygonDeleteSuccess = (polyId): IPolygonDeleteSuccess => ({
  type: ActionTypes.POLYGON_DELETE_SUCCESS,
  polyId: polyId,
});

interface IPolygonDeleteFail {
  type: ActionTypes.POLYGON_DELETE_FAIL;
  error: unknown;
}

export const polygonDeleteFail = (error): IPolygonDeleteFail => ({
  type: ActionTypes.POLYGON_DELETE_FAIL,
  error: error,
});

////////////////////////////////////
// PUT polygon
////////////////////////////////////

interface IPolygonPutStart {
  type: ActionTypes.POLYGON_PUT_START;
}

export const polygonPutStart = (): IPolygonPutStart => ({
  type: ActionTypes.POLYGON_PUT_START,
});

interface IPolygonPutSuccess {
  type: ActionTypes.POLYGON_PUT_SUCCESS;
  polyId: any;
  payload: any;
}

export const polygonPutSuccess = (polyId, payload): IPolygonPutSuccess => ({
  type: ActionTypes.POLYGON_PUT_SUCCESS,
  polyId: polyId,
  payload: payload,
});

interface IPolygonPutFail {
  type: ActionTypes.POLYGON_PUT_FAIL;
  error: unknown;
}

export const polygonPutFail = (error): IPolygonPutFail => ({
  type: ActionTypes.POLYGON_PUT_FAIL,
  error,
});

export const getPolygons = (farmId: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(polygonGetStart());
    const url = `${baseApiUrl}/farm/${farmId}/polygon`;
    axios
      .get(url)
      .then(({data}) => {
        // Extract the center coordinates of the polygons from this reponse
        const centerCoords = [];

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].geoJson.geometry) {
              const center = data[i].geoJson.geometry.center;
              centerCoords.push(center);
            }
          }
        }

        // run algorithm to determine the centre
        const farmCenter = GetFarmCentre(centerCoords);
        const polyData = data.map(p => ({farmid: p.farm.id, polygonid: p.id, name: p.name, geo_json: p.geoJson}));

        dispatch(polygonGetSuccess(farmCenter, polyData));
      })
      .catch(err => {
        dispatch(polygonGetFail(err));
      });
  };
};

export const postPolygon = (payload, isNewFarm) => {
  return (dispatch: Dispatch<Action | farmAction | SnackbarAction>) => {
    dispatch(polygonPostStart());

    // Extract render coordinates for google maps polygon api
    const polyGeoJson = payload.coordinatesOfNewPoly;
    const renderCoords = [];
    for (let i = 0; i < polyGeoJson.length; i++) {
      const object = {
        lat: polyGeoJson[i][1],
        lng: polyGeoJson[i][0],
      };
      renderCoords.push(object);
    }

    // Get center of polygon
    const polyCenter = GetPolygonCentroid(polyGeoJson);

    const url = `${baseApiUrl}/farm/${payload.farmId}/polygon`;
    const data = {
      name: payload.blockName,
      geo_json: {
        type: 'Feature',
        properties: {
          farmName: payload.farmName,
          cropType: payload.cropType,
          rootStock: payload.rootStock,
          clone: payload.clone,
          datePlanted: payload.datePlanted,
        },
        geometry: {
          type: 'Polygon',
          center: polyCenter,
          coordinates: [payload.coordinatesOfNewPoly],
          renderCoords: renderCoords,
        },
      },
    };
    axios
      .post(url, data)
      .then(({data}) => {
        const polyData = {
          polygonid: data.id,
          farmid: payload.farmId,
          geo_json: data.geoJson,
          name: data.name,
        };
        if (isNewFarm) {
          const farm = parseFarm(data, payload.farmName, payload.farmId);
          dispatch(farmPostSuccess(farm.name, farm.id, farm));
        }
        dispatch(polygonPostSuccess(polyData));
        dispatch(enqueueSnackbar({message: i18n.t('setting.create.block.saved'), options: {variant: 'success'}}));
      })
      .catch(err => {
        console.log(err);
        dispatch(polygonPostFail(err));
      });
  };
};

export const parseFarm = (polygon, farmName, farmId) => {
  //only one polygon block coordinates available when polygon is drawn manually
  const farmCenter = {lat: polygon.geoJson.geometry.center[1], lng: polygon.geoJson.geometry.center[0]};
  const features = turf.feature(polygon?.geoJson?.geometry, {Name: polygon.id});
  const farm: IFarm = {
    id: farmId,
    farmid: farmId,
    name: farmName,
    farmCenter: farmCenter,
    polygons: polygon ? [].concat({...polygon, hectares: Math.round(turf.area(turf.polygon(polygon.geoJson.geometry.coordinates as Position[][])) / 100) / 100}) : [],
    bbox: turf.bbox(turf.featureCollection(features ? [].concat(features) : [])),
    ownerOrganization: polygon?.ownerOrganization,
  };
  return farm;
};

export const deletePolygon = (farmId, polyId) => {
  return (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(polygonDeleteStart());
    const url = `${baseApiUrl}/farm/${farmId}/polygon/${polyId}`;
    axios
      .delete(url)
      .then(() => {
        dispatch(polygonDeleteSuccess(polyId));
        dispatch(enqueueSnackbar({message: i18n.t('setting.create.block.deleted'), options: {variant: 'success'}}));
      })
      .catch(err => {
        dispatch(enqueueSnackbar({message: i18n.t('setting.edit.block.not.delete'), options: {variant: 'error'}}));
        dispatch(polygonDeleteFail(err));
      });
  };
};

export const updatePolygon = (farmId, polyId, payload) => {
  return (dispatch: Dispatch<Action | farmAction | SnackbarAction>) => {
    dispatch(polygonPutStart());
    const url = `${baseApiUrl}/farm/${farmId}/polygon/${polyId}`;
    axios
      .put(url, payload)
      .then(() => {
        dispatch(polygonPutSuccess(polyId, payload));
        const polyPayload = {polyId: polyId, name: payload.name, geoJson: payload?.geo_json};
        dispatch(farmUpdateSuccess(farmId, polyPayload));
        dispatch(enqueueSnackbar({message: i18n.t('setting.create.block.updated'), options: {variant: 'success'}}));
      })
      .catch(err => {
        dispatch(polygonPutFail(err));
      });
  };
};

export const resetState = () => {
  return {
    type: ActionTypes.POLYGON_RESET_STATE,
  };
};

export type Action =
  | ReturnType<typeof polygonGetStart>
  | ReturnType<typeof polygonGetSuccess>
  | ReturnType<typeof polygonGetFail>
  | ReturnType<typeof polygonPostStart>
  | ReturnType<typeof polygonPostSuccess>
  | ReturnType<typeof polygonPostFail>
  | ReturnType<typeof polygonDeleteStart>
  | ReturnType<typeof polygonDeleteSuccess>
  | ReturnType<typeof polygonDeleteFail>
  | ReturnType<typeof polygonPutStart>
  | ReturnType<typeof polygonPutSuccess>
  | ReturnType<typeof polygonPutFail>;

export type farmAction = ReturnType<typeof farmPostSuccess> | ReturnType<typeof farmUpdateSuccess>;
