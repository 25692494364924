import React from 'react';
import styled from 'styled-components';
import {createStyles, Theme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import {makeStyles} from '@material-ui/core';

const Container = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 2rem;
  right: 4rem;
  width: 330px;
`;

const ColorScale = styled.div<{gradient: string}>`
  min-width: 100%;
  height: 20px;
  position: relative;
  margin-top: 2rem;
  background: rgb(255, 255, 255);
  background: ${p => `linear-gradient(${p.gradient})`};
`;

const Scale = styled.ul<{left: string; justifyContent: string; width: string}>`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  position: absolute;
  top: -100%;
  left: ${p => p.left};
  justify-content: ${p => p.justifyContent || 'normal'};

  & li {
    min-width: ${p => p.width};
    max-width: ${p => p.width};
    display: flex;
    align-items: center;
    justify-content: center;
    & * {
      font-size: 70% !important;
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  title: string;
  scale: string[];
  gradient: string;
  justifyContent?: string;
}

const Legend = ({title, scale, gradient, justifyContent = 'space-between'}: Props) => {
  const classes = useStyles();
  return (
    <Hidden smDown implementation="css">
      <Container>
        <Paper className={classes.root}>
          <Typography variant="body1">
            <strong>{title}</strong>
          </Typography>
          <ColorScale gradient={gradient}>
            <Scale width={'auto'} left={'0px'} justifyContent={justifyContent}>
              {scale.map((num, idx) => (
                <li key={idx}>
                  <Typography variant="body1">{num}</Typography>
                </li>
              ))}
            </Scale>
          </ColorScale>
        </Paper>
      </Container>
    </Hidden>
  );
};

export default Legend;
