import {IMarker, IMarkerColor} from '@deep-planet/api-interfaces';
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/marker';
import {orderBy} from 'lodash';
import {IFarm} from './farm';

export const getMarkerSelector = (state: IMarkerState) => state.getMarkers;
export const markersSelector = (state: IMarkerState) => orderBy(state.markers, i => i.date, 'desc');
export const markerActiveFarmSelector = (state: IMarkerState) => state.activeFarm;
export const markerSelectedDateSelector = (state: IMarkerState) => state.selectedDate;
export const markerColorSelector = (state: IMarkerState) => state.markerColors;
export const markerPostSelector = (state: IMarkerState) => state.markerPost;
export const markerDeleteSelector = (state: IMarkerState) => state.markerDelete;

export interface IMarkerState {
  getMarkers: {
    error: unknown;
    loading: boolean;
  };
  getMarkerColors: {
    error: unknown;
    loading: boolean;
  };
  markerPost: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  markerDelete: {
    loading: boolean;
    error: boolean;
  };
  markerPut: {
    success: boolean;
    error: unknown;
    loading: boolean;
  };
  markers: IMarker[];
  markerColors: IMarkerColor[];
  activeFarm: IFarm;
  selectedDate: number;
}

const initialState: IMarkerState = {
  getMarkers: {
    error: false,
    loading: false,
  },
  getMarkerColors: {
    error: false,
    loading: false,
  },
  markerPost: {
    success: false,
    error: null,
    loading: false,
  },

  markerDelete: {
    loading: false,
    error: false,
  },

  markerPut: {
    success: false,
    error: null,
    loading: false,
  },
  markers: [],
  activeFarm: null,
  selectedDate: null,
  markerColors: null,
};

const reducer = (state: IMarkerState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.MARKER_GET_START:
      return updateObject(state, {
        getMarkers: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MARKER_GET_SUCCESS:
      return updateObject(state, {
        markers: action.images,
        activeFarm: action.activeFarm,
        getMarkers: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MARKER_GET_FAIL:
      return updateObject(state, {
        getMarkers: {
          error: action.error,
          loading: false,
        },
      });

    case ActionTypes.MARKER_SET_SELECTED_DATE:
      return updateObject(state, {
        selectedDate: action.selectedDate,
      });
    case ActionTypes.MARKER_POST_START:
      return updateObject(state, {
        markerPost: {
          success: false,
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MARKER_POST_SUCCESS:
      return updateObject(state, {
        markers: action.marker ? [...state.markers, action.marker] : state.markers,
        markerPost: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MARKER_PUT_SUCCESS:
      return updateObject(state, {
        markers: state.markers.map(marker => {
          if (marker.id !== action.marker.id) return marker;
          return action.marker;
        }),
        markerPut: {
          success: true,
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MARKER_POST_FAIL:
      return updateObject(state, {
        markerPost: {
          success: false,
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.MARKER_DELETE_START:
      return updateObject(state, {
        markerDelete: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.MARKER_DELETE_SUCCESS:
      return updateObject(state, {
        markers: state.markers.filter(marker => marker.id !== action.payload),
        markerDelete: {
          error: false,
          loading: false,
        },
      });
    case ActionTypes.MARKER_DELETE_FAIL:
      return updateObject(state, {
        markerDelete: {
          error: true,
          loading: false,
        },
      });
    case ActionTypes.MARKER_COLOR_GET_START:
      return updateObject(state, {
        getMarkerColors: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.MARKER_COLOR_GET_SUCCESS:
      return updateObject(state, {
        markerColors: action.markerColors,
        getMarkerColors: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.MARKER_COLOR_GET_FAIL:
      return updateObject(state, {
        getMarkerColors: {
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
