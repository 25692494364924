import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {connect, useDispatch} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useTranslation} from 'react-i18next';
import {IFarm} from '../../../../../store/reducers/farm';
import {enqueueSnackbar} from '../../../../../store/actions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Btn = styled(Button)`
  && {
    margin: 1rem 1rem 1rem 0;
  }
`;

interface Props {
  farmName: string;
  handleChange: (e: any) => void;
  disabled: boolean;
  loadingPostFarm: boolean;
  handleNextStep: () => void;
  allFarms: IFarm[];
}

const NameFarmStep = ({farmName, handleChange, disabled, loadingPostFarm, handleNextStep, allFarms}: Props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const handleNamingFarm = () => {
    if (allFarms.some(farm => farm.name === farmName)) {
      dispatch(
        enqueueSnackbar({
          message: t('setting.create.farm.duplicate'),
          options: {
            variant: 'warning',
          },
        })
      );
      return;
    }
    handleNextStep();
  };

  return (
    <Container>
      <TextField id="name-farm" placeholder={t('setting.create.farm.placeholder')} margin="normal" name="farmName" value={farmName} onChange={handleChange} />
      <Btn variant="contained" color="primary" onClick={handleNamingFarm} disabled={disabled}>
        {t('setting.create.farm.step.next')}
      </Btn>
      {loadingPostFarm && <CircularProgress />}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    loadingPostFarm: state.farm.farmPost.loading,
  };
};

export default connect(mapStateToProps)(NameFarmStep);
