import {IOrganizationEntity} from '@deep-planet/api-interfaces';
import {Button, Typography, Hidden, Box, FormControl, MenuItem} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import GroupIcon from '@material-ui/icons/Group';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Modal from '../../../components/UI/Modal/Modal';
import {setSelectedOrganization} from '../../../store/actions/organization';
import {selectedOrganizationSelector} from '../../../store/selectors/organization';
import theme from '../../../utilities/theme';

const Title = styled(Typography)`
  color: white;
  text-transform: none !important;
`;

const StyledBox = styled(Box)`
  margin-right: 16px;
  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
`;

interface Props {
  organizations: IOrganizationEntity[];
}

const OrganizationSelector = ({organizations}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedOrganization = useSelector(selectedOrganizationSelector);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOrganizationChange = (organization: IOrganizationEntity) => {
    dispatch(setSelectedOrganization(organization));
  };

  return (
    <>
      <StyledBox>
        <Button color="inherit" onClick={handleOpenModal}>
          <Hidden mdUp implementation="css">
            <Box mt={1}>
              <GroupIcon />
            </Box>
          </Hidden>
          <Hidden smDown implementation="css">
            <Box display="flex">
              <GroupIcon />
              <Box ml={1}>
                <Title variant="body1">{selectedOrganization.name}</Title>
              </Box>
            </Box>
          </Hidden>
        </Button>
      </StyledBox>
      {isOpen && (
        <OrganizationSelectorModal
          isOpen={isOpen}
          handleCloseModal={handleCloseModal}
          organizations={organizations}
          selectedOrganization={selectedOrganization}
          handleOrganizationChange={handleOrganizationChange}
        />
      )}
    </>
  );
};

interface OrganizationSelectorModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  organizations: IOrganizationEntity[];
  selectedOrganization: IOrganizationEntity;
  handleOrganizationChange: (organization: IOrganizationEntity) => void;
}

const OrganizationSelectorModal = ({isOpen, handleCloseModal, organizations, selectedOrganization, handleOrganizationChange}: OrganizationSelectorModalProps) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(selectedOrganization.id);
  const {t} = useTranslation();

  const handleSubmit = () => {
    const newlySelectedOrganization = organizations.find(({id}) => selectedOrganizationId === id);
    handleOrganizationChange(newlySelectedOrganization);
    handleCloseModal();
  };

  return (
    <Modal title={t('organization.select')} submitText={'Select'} isOpen={isOpen} isSubmitButtonDisabled={false} isLoading={false} handleClose={handleCloseModal} handleSubmit={handleSubmit}>
      <Box margin="2rem 0">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="org-selection-label">Select an organization</InputLabel>
          <Select labelId="org-selection-label" id="org-selection" value={selectedOrganizationId} onChange={e => setSelectedOrganizationId(e.target.value as string)} label={t('organization.select')}>
            {!!organizations &&
              organizations.map(org => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Modal>
  );
};

export default React.memo(OrganizationSelector);
