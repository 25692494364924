import {orderBy} from 'lodash';
import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {NDXIImages} from './ndvi';
import {Action} from '../actions/ndwi';
import {IFarm} from './farm';

export const ndwiDataSelector = (state: NDWIState) => orderBy(state.esaImages?.images, i => i.date, 'desc');
export const ndwiGetSelector = (state: NDWIState) => state.esaImagesGet;
export const ndwiFarmIdSelector = (state: NDWIState) => state.esaImages?.farmId;
export const ndwiActiveFarmSelector = (state: NDWIState) => state.activeFarm;
export const ndwiSelectedDateSelector = (state: NDWIState) => state.selectedDate;
export const ndwiImageTypeSelector = (state: NDWIState) => state.imageType;

export interface NDWIState {
  esaImages: NDXIImages;
  activeFarm: IFarm;
  selectedDate: Date;
  imageType: string;
  esaImagesGet: {
    loading: boolean;
    error: unknown;
  };
}

const initialState: NDWIState = {
  esaImages: null,
  activeFarm: null,
  selectedDate: null,
  imageType: null,
  esaImagesGet: {
    loading: false,
    error: false,
  },
};

const reducer = (state: NDWIState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.NDWI_GET_START:
      return updateObject(state, {
        esaImages: {
          farmId: null,
          images: null,
        },
        esaImagesGet: {
          error: null,
          loading: true,
        },
      });
    case ActionTypes.NDWI_GET_SUCCESS:
      return updateObject(state, {
        esaImages: {
          farmId: action.farmId,
          images: new Array(...action.images.reverse()),
        },
        activeFarm: action.activeFarm,
        esaImagesGet: {
          error: null,
          loading: false,
        },
      });
    case ActionTypes.NDWI_GET_FAIL:
      return updateObject(state, {
        esaImages: {},
        esaImagesGet: {
          error: action.error,
          loading: false,
        },
      });
    case ActionTypes.NDWI_GET_ACTIVE_DATA:
      return updateObject(state, {
        selectedDate: action.selectedDate,
        imageType: action.imageType,
      });
    default:
      return state;
  }
};

export default reducer;
