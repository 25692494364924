import {IGetFileSignedUrl, IGetSignedUrl} from '@deep-planet/api-interfaces';
import axios from 'axios';
import {Dispatch} from 'redux';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';

export async function getFormData(metadata: IGetSignedUrl | IGetFileSignedUrl, file, getSignedUrl: string) {
  const {
    data: {url, fields},
  } = await axios.post(getSignedUrl, metadata);

  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v as string);
  });
  formData.append('file', file);
  return {
    url: `${url}/${fields.key}`,
    bucketUrl: url,
    formData,
    name: file.name,
    size: Number(file.size),
    uploadedAt: new Date(),
  };
}

export async function uploadFile(url: string, formData: FormData) {
  await fetch(url, {
    method: 'POST',
    body: formData,
    mode: 'no-cors',
  });
}

export async function getImageRequest<T>(url: string, dispatch: Dispatch<SnackbarAction>, message: string) {
  try {
    const {data} = await axios.get<T[]>(url);
    return data;
  } catch {
    dispatch(enqueueSnackbar({message: message || 'error', options: {variant: 'error'}}));
    return [] as T[];
  }
}
