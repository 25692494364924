import ActionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {Action} from '../actions/seasonIndices';
import {ISeasonIndicesResponse} from '@deep-planet/api-interfaces';

export const getSeasonIndicesSelector = (state: SeasonIndicesState) => state.getSeasonIndices;
export const seasonIndicesSelector = (state: SeasonIndicesState) => state.seasonIndices;

export interface SeasonIndicesState {
  getSeasonIndices: {
    error: unknown;
    loading: boolean;
  };
  seasonIndices: ISeasonIndicesResponse[];
}

const initialState: SeasonIndicesState = {
  getSeasonIndices: {
    error: null,
    loading: false,
  },
  seasonIndices: [],
};

const reducer = (state: SeasonIndicesState = initialState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SEASON_INDICES_GET_START:
      return updateObject(state, {
        getSeasonIndices: {
          error: false,
          loading: true,
        },
      });
    case ActionTypes.SEASON_INDICES_GET_SUCCESS:
      return updateObject(state, {
        getSeasonIndices: {
          error: false,
          loading: false,
        },
        seasonIndices: action.seasonIndices,
      });
    case ActionTypes.SEASON_INDICES_GET_FAIL:
      return updateObject(state, {
        getSeasonIndices: {
          error: action.error,
          loading: false,
        },
      });
    default:
      return state;
  }
};

export default reducer;
