import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography, Button} from '@material-ui/core';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {IFarm} from '../../../../store/reducers/farm';
import {polygonInfoWindow} from '@deep-planet/api-interfaces';
import Plots from '../Plots';
import Loader from './Loader';

import {MAP, FULL} from '../../../../config/const';

interface MobileOverlayProps {
  screenMode: string;
  setScreenMode: (mode: string) => void;
  metric: string;
  index: string;
  smallScreen: boolean;
  polygonName: string;
  selectedFarm: IFarm;
  selectedPolygon: polygonInfoWindow;
  seasonIndices: any[];
  loadingSeasonIndices: boolean;
  seasonIndicesError: unknown;
}

const MobileOverlay = ({
  screenMode,
  setScreenMode,
  metric,
  index,
  smallScreen,
  polygonName,
  selectedFarm,
  selectedPolygon,
  seasonIndices,
  loadingSeasonIndices,
  seasonIndicesError,
}: MobileOverlayProps) => {
  const {t} = useTranslation();

  const containerStyle: React.CSSProperties = {
    width: '100%',
    height: (screenMode === MAP && '60px') || (screenMode === FULL && 'calc(100vh - 130px)'),
    position: 'relative',
  };

  const buttonStyle: React.CSSProperties = {marginTop: 10, marginRight: 10, textTransform: 'none', width: 60};

  return (
    <Box style={containerStyle}>
      <Box style={{width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 20}}>
        {/* Loading wheel */}
        {loadingSeasonIndices && screenMode !== MAP && <Loader />}
        {screenMode !== MAP && <div style={{flex: 1}} />}
        {screenMode === FULL && <div style={{width: 40}} />}
        {/* Top bar with buttons */}
        {screenMode !== MAP && (
          <Typography variant="h6" style={{fontWeight: 'bolder', color: '#3f51b5', marginLeft: 5, paddingTop: 10}}>
            {polygonName}
          </Typography>
        )}
        <div style={{flex: 1}} />
        {screenMode === MAP && (
          <Button
            onClick={() => {
              setScreenMode(FULL);
            }}
            style={buttonStyle}
          >
            <ChevronUpIcon style={{fontSize: 30}} />
          </Button>
        )}
        {screenMode === FULL && (
          <Button
            onClick={() => {
              setScreenMode(MAP);
            }}
            style={buttonStyle}
          >
            <ChevronDownIcon style={{fontSize: 30}} />
          </Button>
        )}
      </Box>
      {screenMode !== MAP && (
        <Box style={{height: 'calc(100% - 130px)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginInline: -15}}>
          {/* No graph data instruction */}
          {!polygonName && !loadingSeasonIndices && (
            <Typography variant="h5" style={{marginBottom: 100, textAlign: 'center', color: 'black', opacity: 0.5}}>
              {t('season.indices.overlay.instruction')}
            </Typography>
          )}
          {/* Render plots */}
          {polygonName && screenMode === FULL && (
            <Plots
              metric={metric}
              index={index}
              scale={1.4}
              smallScreen
              selectedFarm={selectedFarm}
              selectedPolygon={selectedPolygon}
              seasonIndices={seasonIndices}
              loadingSeasonIndices={loadingSeasonIndices}
              seasonIndicesError={seasonIndicesError}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default MobileOverlay;
