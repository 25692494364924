import {IGetYieldPerPixelImages} from '@deep-planet/api-interfaces';
import {Dispatch} from 'redux';
import i18n from '../../../i18n';
import {baseApiUrl} from '../../config/const';
import {IFarm} from '../reducers/farm';
import ActionTypes from './actionTypes';
import {farmIdUpdate} from './farm';
import {Action as SnackbarAction, enqueueSnackbar} from './snackbar';
import {getImageRequest} from './utils';
import {flatten} from 'lodash';

/////////////////////////////////////////////
// GET yieldPerPixel images
/////////////////////////////////////////////

interface IYieldPerPixelImageGetStart {
  type: ActionTypes.YIELD_PER_PIXEL_GET_START;
}

export const yieldPerPixelImageGetStart = (): IYieldPerPixelImageGetStart => ({
  type: ActionTypes.YIELD_PER_PIXEL_GET_START,
});

interface IYieldPerPixelImageGetSuccess {
  type: ActionTypes.YIELD_PER_PIXEL_GET_SUCCESS;
  images: IGetYieldPerPixelImages[];
  farmId: string;
  activeFarm: IFarm;
}

export const yieldPerPixelImageGetSuccess = (images: IGetYieldPerPixelImages[], farmId: string, activeFarm: IFarm): IYieldPerPixelImageGetSuccess => ({
  type: ActionTypes.YIELD_PER_PIXEL_GET_SUCCESS,
  images,
  farmId,
  activeFarm,
});

interface IYieldPerPixelImageGetFail {
  type: ActionTypes.YIELD_PER_PIXEL_GET_FAIL;
  error: unknown;
}

export const yieldPerPixelImageGetFail = (error): IYieldPerPixelImageGetFail => ({
  type: ActionTypes.YIELD_PER_PIXEL_GET_FAIL,
  error,
});

interface IYieldPerPixelImageSetSelectedDate {
  type: ActionTypes.YIELD_PER_PIXEL_SET_SELECTED_DATE;
  selectedDate: number;
}

export const yieldPerPixelImageSetSelectedDate = (selectedDate: number): IYieldPerPixelImageSetSelectedDate => ({
  type: ActionTypes.YIELD_PER_PIXEL_SET_SELECTED_DATE,
  selectedDate,
});

export const getYieldPerPixelImages = (farm: IFarm, startDate: string, endDate: string, organizationId?: string) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    dispatch(yieldPerPixelImageGetStart());
    const yieldPerPixelImageGetUrl = `${baseApiUrl}/yield_per_pixel/${farm.id}?fromDate=${startDate}&toDate=${endDate}${organizationId ? `&organizationId=${organizationId}` : ''}`;
    try {
      const response = await getImageRequest<IGetYieldPerPixelImages[]>(yieldPerPixelImageGetUrl, dispatch, i18n.t('error.yield.per.pixel.not.found'));
      const data = flatten(response);
      dispatch(yieldPerPixelImageGetSuccess(data, farm.id, farm));
      dispatch(farmIdUpdate(farm.id));
    } catch (err) {
      dispatch(enqueueSnackbar({message: i18n.t('error.yield.per.pixel.not.found'), options: {variant: 'error'}}));
      dispatch(yieldPerPixelImageGetFail(err));
    }
  };
};

export type Action =
  | ReturnType<typeof yieldPerPixelImageGetStart>
  | ReturnType<typeof yieldPerPixelImageGetSuccess>
  | ReturnType<typeof yieldPerPixelImageGetFail>
  | ReturnType<typeof farmIdUpdate>
  | ReturnType<typeof yieldPerPixelImageSetSelectedDate>;
