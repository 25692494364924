import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import NameFarmStep from './NameFarmStep';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import DrawPolygonsStep from './DrawPolygonsStep';
import {usePrevious} from '../../../../../hooks/usePrevious';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {vineSignalRoutes} from '../../../../../utilities/routes';
import {IFarm} from '../../../../../store/reducers/farm';

const Root = styled.div`
  padding: 0 1rem;
`;

const ActionsContainer = styled.div`
  margin-bottom: 1rem;
`;

const ResetField = styled(Paper)`
  && {
    padding-left: 2rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
  }
`;

interface Props {
  onResetFarmState: () => void;
  handleResetStep: () => void;
  resetPolygonCoords: () => void;
  onResetState: () => void;
  polygon: any;
  farmId: string;
  activeStep: number;
  handleNextStep: () => void;
  postPolySuccess: boolean;
  onDeleteFarm: (farmId: string) => void;
  loadingDeleteFarm: boolean;
  allFarms: IFarm[];
  handleBack: () => void;
}

const CreateFarmStepper = ({
  onResetFarmState,
  handleResetStep,
  resetPolygonCoords,
  onResetState,
  polygon,
  farmId,
  activeStep,
  handleNextStep,
  postPolySuccess,
  onDeleteFarm,
  loadingDeleteFarm,
  allFarms,
  handleBack,
}: Props) => {
  const [farmName, setFarmName] = useState('');
  const handleChange = e => setFarmName(e.target.value);
  const {t} = useTranslation();
  const handleReset = useCallback(() => {
    setFarmName('');
    onResetFarmState();
    handleResetStep();
    resetPolygonCoords();
    onResetState();
  }, [handleResetStep, onResetFarmState, onResetState, resetPolygonCoords]);

  const prevLoadingDeleteFarm = usePrevious(loadingDeleteFarm);
  useEffect(() => {
    if (prevLoadingDeleteFarm !== loadingDeleteFarm && !loadingDeleteFarm) {
      handleReset();
      setFarmName('');
    }
  }, [prevLoadingDeleteFarm, loadingDeleteFarm, handleReset]);

  const steps = [t('setting.create.farm.step.naming'), t('setting.create.farm.step.drawing')];
  return (
    <>
      <IconButton onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Root style={{maxHeight: 'calc(100% - 112px)', overflow: 'auto'}}>
        <Stepper activeStep={activeStep} orientation="vertical" style={{maxHeight: 'calc(100% - 112px)'}}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <ActionsContainer>
                  {index === 0 && (
                    <NameFarmStep farmName={farmName} handleChange={handleChange} handleNextStep={handleNextStep} disabled={activeStep === 0 && farmName.length < 1} allFarms={allFarms} />
                  )}
                  {index === 1 && (
                    <DrawPolygonsStep
                      farmName={farmName}
                      farmId={farmId}
                      polygon={polygon}
                      onDeleteFarm={onDeleteFarm}
                      loadingDeleteFarm={loadingDeleteFarm}
                      handleNextStep={handleNextStep}
                      postPolySuccess={postPolySuccess}
                      resetPolygonCoords={resetPolygonCoords}
                    />
                  )}
                </ActionsContainer>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <ResetField square elevation={0}>
            <Typography>{t('setting.create.farm.created')}</Typography>
            <Button onClick={handleReset}>{t('setting.create.farm.reset')}</Button>
            <Link to={vineSignalRoutes.DASHBOARD.slug}>
              <Button>{t('setting.create.farm.created.link')}</Button>
            </Link>
          </ResetField>
        )}
      </Root>
    </>
  );
};

export default CreateFarmStepper;
