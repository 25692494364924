import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core';

import {IFarm} from '../../../store/reducers/farm';
import FarmPicker from '../../../components/UI/Pickers/FarmPicker';
import {MAP, HALF, FULL} from '../../../config/const';

import {polygonInfoWindow} from '@deep-planet/api-interfaces';

interface Props {
  activeFarm: IFarm;
  allFarms: IFarm[];
  handleChange: (farmId: string) => void;
  index: string;
  setIndex: (value: string) => void;
  selectedPolygon: polygonInfoWindow;
  setSelectedPolygon: (polygon: polygonInfoWindow) => void;
  polygonName: string;
  screenMode: string;
  setScreenMode: (mode: string) => void;
  smallScreen: boolean;
}

const Sidebar: React.FC<Props> = ({activeFarm, allFarms, handleChange, index, setIndex, selectedPolygon, setSelectedPolygon, screenMode, setScreenMode, smallScreen}) => {
  const {t} = useTranslation();
  const formOptions = ['All', 'NDVI', 'NDWI', 'Growing Degree Days', 'Rainfall'];

  const [activePolygonName, setActivePolygonName] = useState<string>('');

  const handlePolygonSelect = event => {
    const polygonId = event.target.value;
    setActivePolygonName(polygonId);
    const polygon = activeFarm.polygons.find(item => item.id === polygonId);
    const formattedPolygon: polygonInfoWindow = {openWindow: true, polygonId: polygon.id, strokeColor: '#FFEA00', strokeWeight: 3};
    if (screenMode === MAP) setScreenMode(smallScreen ? FULL : HALF);
    setSelectedPolygon(formattedPolygon);
  };

  useEffect(() => {
    if (selectedPolygon) {
      setActivePolygonName(selectedPolygon.polygonId);
    } else {
      setActivePolygonName('');
    }
  }, [selectedPolygon]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 64px)'}}>
      <div style={{padding: '1rem', display: 'flex', flexDirection: 'column'}}>
        <Box width="100%">
          <FarmPicker activeFarm={activeFarm?.farmid} allFarms={allFarms} handleChange={handleChange} />

          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Index</InputLabel>
            <Select value={index} onChange={event => setIndex(event.target.value as string)}>
              {formOptions.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText>{t('season.indices.sidebar.switcher.description')}</FormHelperText>
          <FormControl style={{width: '100%', marginTop: 20}}>
            <InputLabel>Block</InputLabel>
            <Select value={activePolygonName} onChange={handlePolygonSelect}>
              {activeFarm.polygons.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText>Select a block from the sidebar or the map</FormHelperText>
        </Box>
      </div>
    </div>
  );
};

export default Sidebar;
