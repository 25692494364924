import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
    body{
        margin: 0;
    }
    a {
        text-shadow: none !important;
        background-image: none !important;
        text-decoration: none !important;
        color: inherit;
      }
`;

export default GlobalStyles;
