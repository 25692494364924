import React, {useLayoutEffect, useState} from 'react';
import styled from 'styled-components';
import {Accordion} from '@material-ui/core';
import {AccordionDetails} from '@material-ui/core';
import {AccordionSummary} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PolygonManager from '../../PolygonManager';
import Spinner from '../../../../../components/UI/Spinner';
import {useTranslation} from 'react-i18next';
import {usePrevious} from '../../../../../hooks/usePrevious';
import {IFarm} from '../../../../../store/reducers/farm';
import {ConfirmationDialog} from 'apps/web-portal-ui/src/app/components/UI/Dialog/ConfirmationDialog';
import {deleteFarm} from 'apps/web-portal-ui/src/app/store/actions';

const Heading = styled(Typography)`
  && {
    font-size: 100%;
  }
`;

const Details = styled(AccordionDetails)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const Gap = styled.div`
  height: 1rem;
`;

const Subtext = styled(Typography)`
  && {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    margin-top: 8px;
    min-height: 1em;
  }
`;
const Btn = styled(Button)`
  && {
    margin-top: 0.5rem;
  }
`;

interface Props {
  farm: IFarm;
  onUpdateFarm;
  onUpdateFarmId;
  loadingPutFarms;
  loadingDeleteFarms;
  isOpen;
  handleDrawingPolyToggle;
  drawingActive;
  activeFarmId;
  polygon;
  onDeleteFarm;
  onResetState;
  userDetails;
  resetPolygonCoords;
}

const ControlledExpansionPanels = ({
  farm,
  onUpdateFarm,
  onUpdateFarmId,
  loadingPutFarms,
  loadingDeleteFarms,
  isOpen,
  handleDrawingPolyToggle,
  drawingActive,
  activeFarmId,
  polygon,
  onDeleteFarm,
  onResetState,
  resetPolygonCoords,
}: Props) => {
  const [farmName, setFarmName] = useState(farm.name);
  const {t} = useTranslation();
  const prevActiveFarmId = usePrevious(activeFarmId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [deleteFarmId, setDeleteFarmId] = useState();

  useLayoutEffect(() => {
    if (activeFarmId !== prevActiveFarmId) {
      onUpdateFarmId(activeFarmId);
    }
  }, [activeFarmId, prevActiveFarmId, onUpdateFarmId]);

  const handleChange = e => setFarmName(e.target.value);
  const handleDeleteFarm = id => {
    setIsOpenDialog(true);
    setDeleteFarmId(id);
  };

  const handleDialogClose = () => {
    setIsOpenDialog(false);
    setDeleteFarmId(null);
  };
  const handleDeleteConfirmation = () => {
    setIsOpenDialog(false);
    onDeleteFarm(deleteFarmId);
    onResetState();
    setDeleteFarmId(null);
  };
  const nameChanged = farm.name === farmName;

  return (
    <Accordion expanded={isOpen} onClick={() => onUpdateFarmId(farm.farmid)}>
      <AccordionSummary>
        <Heading>{farm.name}</Heading>
      </AccordionSummary>
      <Details>
        <Typography>{t('setting.edit.farm.title')}</Typography>
        <TextField
          id="farm-name"
          style={{margin: 8}}
          helperText={t('setting.edit.block.tooltip')}
          fullWidth
          margin="normal"
          value={farmName}
          onChange={handleChange}
          name="farmName"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Gap />
        <Button variant="contained" color="primary" disabled={nameChanged} onClick={() => onUpdateFarm(farm.farmid, farmName)}>
          {t('setting.edit.farm.save')}
          {loadingPutFarms && isOpen && <Spinner size={15} />}
        </Button>
        <Gap />
        <Button variant="contained" color="secondary" onClick={() => handleDeleteFarm(farm.farmid)}>
          {t('setting.edit.farm.delete')}
          {loadingDeleteFarms && (isOpen || isOpenDialog) && <Spinner size={15} />}
        </Button>
        <Gap />
        <Typography>{t('setting.edit.block.title')}</Typography>
        <Subtext>{t('setting.edit.block.description')}</Subtext>
        <Gap />
        {drawingActive && <PolygonManager polygon={polygon} farmName={farm.name} farmId={activeFarmId} resetPolygonCoords={resetPolygonCoords} />}
        <Btn variant="contained" color="primary" onClick={() => handleDrawingPolyToggle(farm.farmid)}>
          {drawingActive ? t('forms.cancel') : t('setting.edit.block.create')}
        </Btn>
      </Details>
      <ConfirmationDialog
        open={isOpenDialog}
        handleClose={handleDialogClose}
        handleSubmit={handleDeleteConfirmation}
        title={`${t('setting.create.farm.delete.confirmation')}  ${farm.name.toUpperCase()}`}
        submitText={'YES'}
      />
    </Accordion>
  );
};

export default ControlledExpansionPanels;
