import React from 'react';
import {Chart} from 'react-google-charts';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';

const ChartWrap = styled.div`
  position: relative;
  padding-bottom: calc(50vh);
  height: 0;
  overflow: hidden;
  margin: 5px;
`;

const LegendTitle = styled.span`
  font-family: Arial;
  font-size: 14;
  font-style: italic;
`;

const SoilMoistureGraph = ({dataArray, t}) => {
  const options = {
    title: t('soil.moisture.graph.title'),
    annotations: {
      style: 'line',
      textStyle: {
        bold: true,
        color: '#000000',
        opacity: 1,
        fontSize: 12,
      },
      alwaysOutside: true,
    },
    chartArea: {top: 5, left: 50, bottom: 250, right: 120, width: '70%', height: '70%'},
    backgroundColor: {fill: 'transparent'},
    hAxis: {
      title: t('soil.moisture.graph.x'),
    },
    vAxis: {
      title: t('soil.moisture.graph.y'),
    },
  };

  return (
    <div>
      <Grid container justify="flex-end" alignItems="flex-start">
        <Hidden smDown implementation="css">
          <Box mr={5}>
            <LegendTitle variant="body1">{t('soil.moisture.graph.depth')}</LegendTitle>
          </Box>
        </Hidden>
      </Grid>
      <ChartWrap>
        <Chart height="500px" chartType="LineChart" data={dataArray} options={options} />
      </ChartWrap>
    </div>
  );
};

export default withTranslation()(SoilMoistureGraph);
