import {Switch, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../utilities/theme';

interface Props {
  option1: string;
  option2: string;
  isMetric: boolean;
  boxNumber: number;
  feature?: string;
  left: string;
  marginTop: string;
  height?: string;
  handleSwitch: () => void;
  shadow?: boolean;
}

// this switch box works for both yield predictions and yield-per-pixel map
// yield predictions: will have two switch boxes so boxNumber indicates 1st or 2nd
const StyledBox = styled.div<{boxNumber: number; feature: string; left: string; marginTop: string; height: string; shadow: boolean}>`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 1px 14px;
  border-radius: 3px;
  box-shadow: ${i => (i.shadow ? '0 2px 6px rgba(0, 0, 0, 0.3)' : '0 1px 2px rgba(0, 0, 0, 0.3)')};
  position: absolute;
  left: ${i => i.left};
  margin-top: ${i => i.marginTop};
  ${theme.breakpoints.down('sm')} {
    left: ${i => (i.boxNumber > 1 ? '186px' : i.feature === 'YIELD' ? '52px' : '206px')};
    width: ${i => (i.boxNumber > 1 ? '153px' : '130px')};
    height: ${i => (i.feature === 'YIELD' ? '35px' : '40px')};
    margin-top: ${i => (i.feature === 'YIELD' ? '14px' : '10px')};
    padding: ${i => (i.boxNumber > 1 ? '0px 1px' : '0px 3px')};
  }
`;

export const SwitchButton = ({option1, option2, isMetric, boxNumber, feature, left, marginTop, height, handleSwitch, shadow = true}: Props) => {
  return (
    <StyledBox boxNumber={boxNumber} feature={feature} left={left} marginTop={marginTop} height={height} shadow={shadow}>
      <Typography>{option1}</Typography>
      <Switch checked={isMetric} onChange={handleSwitch} />
      <Typography>{option2}</Typography>
    </StyledBox>
  );
};
