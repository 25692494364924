import React from 'react';
import {DrawingManager} from '@react-google-maps/api';
import {Map, MapProps} from '../../../../../components/UI/Map/Map';
import PolygonWithInfo from './PolygonWithInfo';

const containerStyle = {
  width: 'calc(100%)',
  height: 'calc(100vh - 112px)',
};

interface Props extends MapProps {
  polygons: any[];
  activeFarmId: string;
  drawingActive: boolean;
  handlePassPolyCoords: (polygon: google.maps.Polygon, coordinates: number[][]) => void;
}

const EditFarmMap = ({center, polygons, activeFarmId, drawingActive, handlePassPolyCoords}: Props) => {
  return (
    <Map center={center || {lat: 43.472709, lng: 4.684064}} mapContainerStyle={containerStyle}>
      {!!polygons &&
        polygons.map(polygon => {
          return <PolygonWithInfo key={polygon.polygonid} polygon={polygon} activeFarmId={activeFarmId} />;
        })}
      {drawingActive && (
        <DrawingManager
          drawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
          }}
          onPolygonComplete={polygon => {
            // identify the polygon coordinates & mutate them in a format that agro monitoring API expects
            const coordinates: number[][] = [];
            const vertices = polygon.getPath();
            for (let i = 0; i < vertices.getLength(); i++) {
              const xy = vertices.getAt(i);
              coordinates.push([xy.lng(), xy.lat()]);
            }
            const xyFirst = vertices.getAt(0);
            coordinates.push([xyFirst.lng(), xyFirst.lat()]);
            // Pass the new polygon's coordinates up to parent component
            handlePassPolyCoords(polygon, coordinates);
          }}
        />
      )}
    </Map>
  );
};

export default EditFarmMap;
