import axios from 'axios';
import {Dispatch} from 'redux';
import {baseApiUrl} from '../../config/const';
import ActionTypes from './actionTypes';
import {Action as SnackbarAction} from './snackbar';
import {ISeasonIndicesResponse} from '@deep-planet/api-interfaces';

interface IGetSeasonIndicesStart {
  type: ActionTypes.SEASON_INDICES_GET_START;
}

export const getSeasonIndicesStart = (): IGetSeasonIndicesStart => ({
  type: ActionTypes.SEASON_INDICES_GET_START,
});

interface IGetSeasonIndicesSuccess {
  type: ActionTypes.SEASON_INDICES_GET_SUCCESS;
  seasonIndices: ISeasonIndicesResponse[];
}

export const getSeasonIndicesSuccess = (seasonIndices: ISeasonIndicesResponse[]): IGetSeasonIndicesSuccess => ({
  type: ActionTypes.SEASON_INDICES_GET_SUCCESS,
  seasonIndices,
});

interface IGetSeasonIndicesFail {
  type: ActionTypes.SEASON_INDICES_GET_FAIL;
  error: unknown;
}

export const getSeasonIndicesFail = (error: unknown): IGetSeasonIndicesFail => ({
  type: ActionTypes.SEASON_INDICES_GET_FAIL,
  error,
});

const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getSeasonIndices = (farmId: string, organizationId: string, fromDate: Date, toDate: Date) => {
  return async (dispatch: Dispatch<Action | SnackbarAction>) => {
    const url = `${baseApiUrl}/season_indices?organizationId=${organizationId}&farmId=${farmId}&fromDate=${formatDate(fromDate)}&toDate=${formatDate(toDate)}`;
    dispatch(getSeasonIndicesStart());
    try {
      const {data} = await axios.get(url);
      // Sort the array of objects based on the date field
      const sortedData = data.sort((a: ISeasonIndicesResponse, b: ISeasonIndicesResponse) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateA - dateB;
      });
      dispatch(getSeasonIndicesSuccess(sortedData));
      return true;
    } catch (e) {
      dispatch(getSeasonIndicesFail(e));
      return false;
    }
  };
};

export type Action = ReturnType<typeof getSeasonIndicesStart> | ReturnType<typeof getSeasonIndicesSuccess> | ReturnType<typeof getSeasonIndicesFail>;
