import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Box, BoxProps, TextField} from '@material-ui/core';
import theme from '../../../utilities/theme';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {noteShareSelector} from '../../../store/selectors';
import {shareNote} from '../../../store/actions/note';
import Modal from '../../../components/UI/Modal/Modal';

const InputWrapperBox = styled(Box)<BoxProps>`
  margin-right: 16px;
  width: 100%;
  ${theme.breakpoints.down('xs')} {
    margin: 8px 0;
    flex-basis: 100%;
    width: 100%;
  }
`;

interface INoteShareFormError {
  email: {
    value: boolean;
    text: string;
  };
  message: {
    value: boolean;
    text: string;
  };
}

interface Props {
  isOpen: boolean;
  noteId: string;
  handleClose: () => void;
}

const MAX_MESSAGE_LENGTH = 1500;

const NoteShareModal = ({isOpen, handleClose, noteId}: Props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<INoteShareFormError>({
    email: {
      value: false,
      text: '',
    },
    message: {
      value: false,
      text: '',
    },
  });
  const {loading} = useSelector(noteShareSelector);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const isValidEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = () => {
    if (message.length > MAX_MESSAGE_LENGTH) {
      setErrors({...errors, message: {value: true, text: t('forms.shorter.or.equal', {length: MAX_MESSAGE_LENGTH})}});
    }
    if (!isValidEmail(email)) {
      setErrors({...errors, email: {value: true, text: t('forms.email.valid')}});
    }
    if (!errors.message.value && !errors.email.value) {
      dispatch(shareNote(noteId, {email, message}, handleClose));
    }
  };

  const handleMessageChange = (currentMessage: string) => {
    setMessage(currentMessage);
    if (message.length > MAX_MESSAGE_LENGTH) {
      setErrors({...errors, message: {value: true, text: t('forms.shorter.or.equal', {length: MAX_MESSAGE_LENGTH})}});
    } else {
      setErrors({...errors, message: {value: false, text: ''}});
    }
  };

  const handleEmailChange = (currentEmail: string) => {
    setEmail(currentEmail);
    if (!isValidEmail(currentEmail)) {
      setErrors({...errors, email: {value: true, text: t('forms.email.valid')}});
    } else {
      setErrors({...errors, email: {value: false, text: ''}});
    }
  };

  return (
    <Modal
      title={t('note.share.title')}
      submitText={t('forms.send')}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isSubmitButtonDisabled={loading || errors.message.value || errors.email.value}
      isLoading={loading}
    >
      <form>
        <InputWrapperBox>
          <TextField
            required
            error={errors.email.value}
            helperText={errors.email.value ? errors.email.text : ''}
            fullWidth
            disabled={loading}
            label={t('forms.email')}
            value={email}
            onChange={({target: {value}}) => handleEmailChange(value)}
          />
        </InputWrapperBox>
        <Box display="flex" mt={2} mb={2}>
          <TextField
            error={errors.message.value}
            helperText={errors.message.value ? errors.message.text : ''}
            disabled={loading}
            value={message}
            onChange={({target: {value}}) => handleMessageChange(value)}
            variant="outlined"
            multiline
            rows={5}
            label={t('note.share.message')}
            fullWidth
          />
        </Box>
      </form>
    </Modal>
  );
};

export default NoteShareModal;
