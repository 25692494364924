import React, {useState} from 'react';
import styled from 'styled-components';
import {Polygon, InfoWindow} from '@react-google-maps/api';
import TextField from '@material-ui/core/TextField';
import {useTranslation} from 'react-i18next';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledField = styled(TextField)`
  && {
    margin-bottom: 0.5rem;
  }
`;

const PolygonWithInfo = ({polygon}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggleOpen = () => setIsOpen(!isOpen);
  const {t} = useTranslation();

  return (
    <>
      <Polygon
        key={polygon.id}
        paths={polygon.geo_json.geometry.renderCoords}
        options={{
          fillColor: 'rgb(37,54,71)',
          fillOpacity: 0.5,
          strokeColor: 'rgb(37,54,71)',
          strokeOpacity: 1,
          strokeWeight: 2,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 1,
        }}
        onClick={onToggleOpen}
      />
      {isOpen && (
        <InfoWindow
          onCloseClick={onToggleOpen}
          position={{
            lat: polygon.geo_json.geometry.renderCoords[0].lat,
            lng: polygon.geo_json.geometry.renderCoords[0].lng,
          }}
        >
          <Content>
            <StyledField id="blockName" name="blockName" label={t('setting.create.farm.block.name')} value={polygon.name} margin="dense" disabled />
            <StyledField id="cropType" name="cropType" label={t('setting.create.farm.block.type')} value={polygon.geo_json.properties.cropType} margin="dense" disabled />
            <StyledField id="rootStock" name="rootStock" label={t('setting.create.farm.block.root.stock')} value={polygon.geo_json.properties.rootStock} margin="dense" disabled />
            <StyledField id="clone" name="clone" label={t('setting.create.farm.block.clone')} value={polygon.geo_json.properties.clone} margin="dense" disabled />
            <StyledField id="datePlanted" name="datePlanted" label={t('setting.create.farm.block.date.planted')} value={polygon.geo_json.properties.datePlanted} margin="dense" disabled />
          </Content>
        </InfoWindow>
      )}
    </>
  );
};

export default PolygonWithInfo;
