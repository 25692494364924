import ActionTypes from './actionTypes';
import axios from 'axios';
import {baseApiUrl} from '../../config/const';

// RESET
export const segReset = () => {
  return {
    type: ActionTypes.SEG_RESET,
  };
};

// GET
export const segGetStart = () => {
  return {
    type: ActionTypes.SEG_GET_START,
  };
};
export const segGetSuccess = data => {
  return {
    type: ActionTypes.SEG_GET_SUCCESS,
    data: data,
  };
};
export const segGetError = error => {
  return {
    type: ActionTypes.SEG_GET_FAIL,
    error: error,
  };
};

export const getSegment = userId => {
  return dispatch => {
    dispatch(segGetStart());
    let url = `${baseApiUrl}/segmentation/${userId}`;
    axios
      .get(url)
      .then(response => {
        dispatch(segGetSuccess(response.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(segGetError(err));
      });
  };
};

// POST
export const segPostStart = () => {
  return {
    type: ActionTypes.SEG_POST_START,
  };
};
export const segPostSuccess = data => {
  return {
    type: ActionTypes.SEG_POST_SUCCESS,
    data: data,
  };
};
export const segPostError = error => {
  return {
    type: ActionTypes.SEG_POST_FAIL,
    error: error,
  };
};

export const postSegment = (userId, payload) => {
  return dispatch => {
    dispatch(segPostStart());
    let url = `${baseApiUrl}/segmentation/${userId}`;
    axios
      .post(url, payload)
      .then(response => {
        dispatch(segPostSuccess(response.data));
      })
      .catch(err => {
        console.log(err);
        dispatch(segPostError(err));
      });
  };
};
