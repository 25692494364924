import React from 'react';
import {Box, Button, Checkbox, FormControl, FormLabel, FormGroup, FormControlLabel, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import DatePeriodPicker from '../../../components/UI/Pickers/DatePeriodPicker';
import {INoteTypeEntity} from '@deep-planet/api-interfaces';

interface Props {
  openModal: () => void;
  types: INoteTypeEntity[];
  selectedTypes: Map<string, boolean>;
  handleTypeSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startDate: Date;
  endDate: Date;
  handleStartDateSelection: (date: Date) => void;
  handleEndDateSelection: (date: Date) => void;
}

const NoteSideBar = ({openModal, types, selectedTypes, handleTypeSelection, startDate, endDate, handleStartDateSelection, handleEndDateSelection}: Props) => {
  const isChecked = (name: string): boolean => selectedTypes.get(name);
  const {t} = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" m={2} flexDirection="column" height="calc(100% - 100px)">
      <Box display="flex" flexDirection="column">
        <Box mb={2}>
          <Typography variant="subtitle2">{t('note.filter.filters')}</Typography>
        </Box>

        <Box mb={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('note.filter.types')}</FormLabel>
            <FormGroup>
              {types.map(({name, id}) => (
                <FormControlLabel key={id} control={<Checkbox checked={isChecked(name)} onChange={handleTypeSelection} name={name} />} label={t(`note.filter.type.${name.toLowerCase()}`)} />
              ))}
            </FormGroup>
          </FormControl>
        </Box>

        <FormControl>
          <FormLabel component="legend">{t('note.filter.date')}</FormLabel>
          <DatePeriodPicker startDate={startDate} endDate={endDate} handleStartDateChange={handleStartDateSelection} handleEndDateChange={handleEndDateSelection} />
        </FormControl>
      </Box>
      <Box display="flex">
        <Button variant="contained" color="primary" onClick={openModal} fullWidth>
          {t('forms.create')}
        </Button>
      </Box>
    </Box>
  );
};

export default NoteSideBar;
